import axios from "axios"
import { act } from "react-dom/test-utils";
import { UPLOAD_BACTCH, SET_BATCH_DATA,SET_SEARCHED_BATCHES_SUCCESS, CLEAR_PAIRS, UPLOAD_BACTCH_SUCCESS, SET_RECENTLY_UPLOADS, REMOVE_PAIR, SET_TEMPLATES, SET_PARTNERS, SET_OPERATORS, SET_CUSTOMERS, SET_UPLOADED_BATCHES, SET_SEARCHED_BATCHES,UPLOAD_REPORTS_SUCCESS, DELETE_BATCH_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  pairsArray: [],
  pairs: [],
  recentUploads: [],
  customers: [],
  partners: [],
  templates: [],
  operators: [],
  uploadedBatches:[],
  batchGetSuccess:false,
  searchedBatches:[],
  updateSuccess:"false",
  deleteSuccess:"false"

}

const SourceUpload = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DELETE_BATCH_SUCCESS:
      // state.deleteSuccess=action.payload;
      return {
        ...state,
        deleteSuccess: action.payload
      }
    case UPLOAD_BACTCH_SUCCESS:
      // state.pairsArray = [];
      // state.updateSuccess=action.payload;
      return {
        ...state,
        pairsArray : [],
        updateSuccess :action.payload
        // batches: [...state.pairs, action.payload],
      }
    case SET_BATCH_DATA:
      // state.pairsArray.push(action.payload)
      return {
        ...state,
        pairsArray: [...state.pairsArray, action.payload],
        // pairsArray: [...state.pairsArray, action.payload],
      }
    case SET_RECENTLY_UPLOADS:
      // state.recentUploads = action.payload
      return {
        ...state,
        recentUploads: action.payload,
        // recentUploads: [...state.recentUploads, action.payload],
      }
    case SET_CUSTOMERS:
      // state.customers = action.payload
      return {
        ...state,
        customers : action.payload
        // customers: [...state.customers, action.payload],
      }
    case SET_UPLOADED_BATCHES:
      // state.uploadedBatches = action.payload
      return {
        ...state,
        uploadedBatches : action.payload
        // uploadeBatches: [...state.uploadedBatches, action.payload],
      }
    case SET_SEARCHED_BATCHES:
      // state.searchedBatches = action.payload
      return {
        ...state,
        searchedBatches : action.payload
        // uploadeBatches: [...state.uploadedBatches, action.payload],
      }
    case SET_OPERATORS:
      // state.operators = action.payload
      return {
        ...state,
        operators : action.payload,
        // operators: [...state.operators, action.payload],
      }
    case SET_PARTNERS:
      // state.partners = action.payload
      return {
        ...state,
        partners : action.payload
      }
    case SET_SEARCHED_BATCHES_SUCCESS:
      // state.batchGetSuccess = action.payload
      return {
        ...state,
        batchGetSuccess : action.payload
      }
    case SET_TEMPLATES:
      // state.templates = action.payload
      return {
        ...state,
        templates : action.payload
        // templates: [...state.templates, action.payload],
      }

    case CLEAR_PAIRS:
      // state.pairsArray = []
      return {
        ...state,
        pairsArray : []
      }
    case REMOVE_PAIR:
      // var id = action.pairId;
      // // console.log("id :",id)
      // for (let i = 0; i < state.pairsArray.length; i++) {
      //   const element = state.pairsArray[i];
      //   if (element.pairId == id) {
      //     state.pairsArray.splice(i, 1)
      //   }

      // }
      const id = action.pairId;
      const newPairsArray = state.pairsArray.filter(element => element.pairId !== id);
    
      return {
        ...state,
        pairsArray: newPairsArray,
      }
    default:
      return state
  }
}

export const selectPairsArray = () => INIT_STATE.pairsArray;


export default SourceUpload
