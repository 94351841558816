import React, { useState, useEffect } from "react"
import {
  Modal,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Tooltip,
  Spinner,
} from "reactstrap"
import Select from "react-select"
import { get } from "lodash"
import {
  useCreateUserMutation,
  useGetOperatorsQuery,
  useGetCustomersQuery,
} from "store/user/user.api"
import { setCustomers, setOperators } from "store/user/user.slice"
import { useSelector, useDispatch } from "react-redux"
import Swal from "sweetalert2"

const AddUserModal = ({ isOpen, onClose, refetch, roles }) => {
  const dispatch = useDispatch()
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [role, setRole] = useState("")
  const [assignCustomer, setAssignCustomer] = useState("")
  const [assignOperator, setAssignOperator] = useState([])
  const [tooltipOpenGenerate, setTooltipOpenGenerate] = useState(false)
  const [tooltipOpenToggle, setTooltipOpenToggle] = useState(false)
  const [tooltipOpenCopy, setTooltipOpenCopy] = useState(false)
  const [createUser, { isLoading: createUserLoading }] = useCreateUserMutation()
  const { data: operators_temp, isLoading: operatorsLoading } =
    useGetOperatorsQuery(
      { customer_id: assignCustomer },
      { refetchOnMountOrArgChange: true }
    )
  const { data: customers_temp, isLoading: customersLoading } =
    useGetCustomersQuery()
  const { operators, customers } = useSelector(state => state.usersNew)

  useEffect(() => {
    if (operators_temp) {
      let temp = get(operators_temp, "data", [])
      temp = temp.map(obj => {
        return {
          id: obj.id,
          label: obj.country + " " + obj.operator_name,
          value: obj.country + " " + obj.operator_name,
        }
      })
      temp.sort((a, b) => {
        // console.log(a,b);
        const countryA = a.label.split(" ")[0]
        const countryB = b.label.split(" ")[0]

        // Compare country names
        if (countryA < countryB) {
          return -1
        } else if (countryA > countryB) {
          return 1
        } else {
          return 0
        }
      })
      dispatch(setOperators(temp))
    }
  }, [operators_temp])

  useEffect(() => {
    if (customers_temp) {
      let temp = get(customers_temp, "data", [])
      dispatch(
        setCustomers(
          temp.map(customer => ({
            id: customer.id,
            label: customer.name,
            value: customer.name,
          }))
        )
      )
    }
  }, [customers_temp])

  const clearFormFields = () => {
    setName("")
    setEmail("")
    setRole("")
    setPassword("")
    setAssignCustomer("")
    setAssignOperator([])
  }

  const closeModal = () => {
    onClose()
    setTimeout(() => {
      clearFormFields()
    }, 500)
  }

  const addUser = () => {
    if (assignOperator.length > 0 && assignCustomer == "") {
      Swal.fire({
        title: "Error!",
        text: "Customer is Mandatory for Operator Selection",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#811113",
      })
    } else {
      let userObject = {
        user: {
          name: name,
          email: email,
          role: role,
          password: password,
          customer: assignCustomer,
          operators: assignOperator,
        },
      }
      createUser(userObject)
        .unwrap()
        .then(() => {
          Swal.fire({
            title: "Success",
            text: "User Added Successfully",
            icon: "success",
            confirmButtonText: "Ok",
          })
          refetch()
          onClose()
          clearFormFields()
        })
        .catch(error => {
          Swal.fire({
            title: "Error",
            text: `${error.data.message}`,
            icon: "error",
            confirmButtonText: "Ok",
          })
        })
    }
  }

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(password)
  }

  const generateRandomPassword = () => {
    const length = 12
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$&"

    let password = ""
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length)
      password += charset[randomIndex]
    }

    return password
  }

  const handleAssignCustomer = e => {
    const selectedAssignCustomer = e.target.value
    setAssignCustomer(selectedAssignCustomer)
  }

  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  const handleGeneratePassword = () => {
    const generatedPassword = generateRandomPassword()
    setPassword(generatedPassword)
  }

  const toggleTooltipGenerate = () =>
    setTooltipOpenGenerate(!tooltipOpenGenerate)
  const toggleTooltipToggle = () => setTooltipOpenToggle(!tooltipOpenToggle)
  const toggleTooltipCopy = () => setTooltipOpenCopy(!tooltipOpenCopy)

  return (
    <Modal isOpen={isOpen} toggle={closeModal} size="lg" centered>
      <div
        className="modal-header ps-4 pe-5 d-flex"
        style={{
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <span
            className="modal-title ps-3 "
            style={{ fontSize: "16px", fontWeight: "500" }}
          >
            ADD USER
          </span>
        </div>
        <div>
          <button type="button" className="close" onClick={closeModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div className="modal-body d-flex row ps-5 pe-5">
        <div className="mb-3 col-md-6 pe-4 ps-2">
          <label htmlFor="name">Name</label>
          <Input
            type="text"
            id="name"
            placeholder="Enter Name"
            onChange={e => {
              setName(e.target.value)
            }}
          />
        </div>
        <div className="mb-3 col-md-6 pe-2 ps-4">
          <label htmlFor="email">Email</label>
          <Input
            type="email"
            id="email"
            placeholder="Enter Email"
            onChange={e => {
              setEmail(e.target.value)
            }}
          />
        </div>
        <div className="mb-3 col-md-6 pe-4 ps-2">
          <label htmlFor="role">Role</label>
          <Select
            id="role"
            styles={{
              control: styles => ({
                ...styles,
                border: "1px solid #CED4DA",
              }),
            }}
            placeholder="Select Role"
            value={roles.filter(obj => obj.id === role)}
            options={roles}
            onChange={selectedOption => setRole(selectedOption.id)}
            autocomplete="off"
          />
        </div>
        <div className="mb-3 col-md-6 pe-2 ps-4">
          <label htmlFor="password">Password</label>
          <InputGroup
            style={{
              paddingTop: "5px",
              height: "38px",
            }}
          >
            <Input
              type={showPassword ? "text" : "password"}
              id="password"
              placeholder="Create Password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              style={{ borderRight: "none" }}
              autocomplete="new-password"
            />
            <InputGroupAddon addonType="append">
              <Tooltip
                isOpen={tooltipOpenGenerate}
                target="generate"
                toggle={toggleTooltipGenerate}
                style={{ color: "black", backgroundColor: "white" }}
              >
                Generate Password
              </Tooltip>
              <Tooltip
                isOpen={tooltipOpenToggle}
                target="toggle"
                toggle={toggleTooltipToggle}
                style={{ color: "black", backgroundColor: "white" }}
              >
                Toggle Password Visibility
              </Tooltip>
              <Tooltip
                isOpen={tooltipOpenCopy}
                target="copy"
                toggle={toggleTooltipCopy}
                style={{ color: "black", backgroundColor: "white" }}
              >
                Copy to Clipboard
              </Tooltip>
              <Button
                id="generate"
                color="black"
                style={{
                  height: "38px",
                  background: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderColor: "#CED4DA",
                  borderTopLeftRadius: "0",
                  borderBottomLeftRadius: "0",
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "0",
                }}
                onClick={handleGeneratePassword}
              >
                <i className="fas fa-sync" style={{ color: "grey" }}></i>
              </Button>
              <Button
                id="toggle"
                color="secondary"
                style={{
                  height: "38px",
                  background: "none",
                  borderLeft: "none",
                  borderTopLeftRadius: "0",
                  borderBottomLeftRadius: "0",
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "0",
                  borderRight: "none",
                  borderColor: "#CED4DA",
                }}
                onClick={handleTogglePassword}
              >
                <i
                  className={`fas ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
                  style={{ color: "grey" }}
                ></i>
              </Button>
              <Button
                id="copy"
                color="secondary"
                style={{
                  height: "38px",
                  background: "none",
                  borderLeft: "none",
                  borderTopLeftRadius: "0",
                  borderBottomLeftRadius: "0",
                  borderColor: "#CED4DA",
                }}
                onClick={handleCopyToClipboard}
              >
                <i className="fas fa-copy" style={{ color: "grey" }}></i>
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
        {role == 1 && (
          <>
            <div className="mb-3 col-md-6 pe-4">
              <label htmlFor="assignCustomer">Assign Customer</label>
              {/* <select
                id="assignCustomer"
                className="form-select"
                value={assignCustomer}
                onChange={handleAssignCustomer}
              >
                <option value="" disabled>
                  Select Customer
                </option>
                <option value="Axiata">Axiata</option>
                <option value="Digicel">Digicel</option>
                <option value="e&">e&</option>
                <option value="HGC">HGC</option>
                <option value="iBasis">iBasis</option>
              </select> */}
              <Select
                isClearable
                isSearchable
                placeholder="Select Customer"
                value={customers.filter(obj => obj.id === assignCustomer)}
                options={customers}
                onChange={selectedOption => {
                  if (selectedOption) {
                    setAssignCustomer(selectedOption.id)
                  } else {
                    setAssignCustomer("")
                  }
                }}
              />
            </div>
            <div className="mb-3 col-md-12">
              <label htmlFor="assignOperator">Assign Operator</label>
              {/* <select
                id="assignOperator"
                className="form-select"
                value={assignOperator}
                onChange={handleAssignOperator}
              >
                <option value="" disabled>
                  Select Operators
                </option>
                <option value="3">3</option>
                <option value="Asiacell">Asiacell</option>
                <option value="Claro">Claro</option>
                <option value="Dialog">Dialog</option>
                <option value="Digicel">Digicel</option>
              </select> */}
              <Select
                isMulti
                isClearable
                isSearchable
                value={operators.filter(obj => assignOperator.includes(obj.id))}
                options={operators}
                onChange={selectedOption =>
                  setAssignOperator(selectedOption.map(option => option.id))
                }
                placeholder="Select Operators"
              />
            </div>
          </>
        )}
      </div>
      <div className="modal-footer">
        <Button
          style={{
            backgroundColor: "#811113",
            border: "none",
            height: "42px",
            width: "100px",
            marginRight: "20px",
          }}
          onClick={addUser}
          disabled={createUserLoading} // Disable the button while the mutation is in progress
        >
          {createUserLoading ? (
            <Spinner size="sm" color="light" /> // Show spinner if loading
          ) : (
            "Add"
          )}
        </Button>{" "}
        <Button
          style={{
            backgroundColor: "#E8E8E9",
            border: "none",
            height: "42px",
            width: "100px",
          }}
          onClick={closeModal}
        >
          <span style={{ color: "#2D3648" }}>Cancel</span>
        </Button>
      </div>
    </Modal>
  )
}

export default AddUserModal
