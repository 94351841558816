import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"

const Authmiddleware = ({
  decodedToken,
  path,
  component: Component,
  layout: Layout,
  isAuthProtected,
  route: route,
  ...rest
}) => {
  return (
    (
      <Route
        {...rest}
        render={props => {
          if (isAuthProtected && !localStorage.getItem("authUser")) {
            return (
              <Redirect
                to={{ pathname: "/login", state: { from: props.location } }} //TODO
              />
            )
          }
          if(decodedToken){
            let role = decodedToken.role;
            if([1].includes(role)){
              if(path === "/source-upload"){
                return <Redirect to="/dashboard" />
              }
              if(path === "/upload-files"){
                return <Redirect to="/dashboard" />
              }
              if(path==="/reports/history/import"){
                return <Redirect to="/dashboard" />
              }
              if(path ==="/customers"){
                return <Redirect to="/dashboard" />
              }
              if(path ==="/operators"){
                return <Redirect to="/dashboard" />
              }
              if(path ==="/country"){
                return <Redirect to="/dashboard" />
              }
              if(path ==="/partners"){
                return <Redirect to="/dashboard" />
              }
              if(path==="/knowledge-base"){
                return <Redirect to="/dashboard"/>
              }
              if(path==="/templates"){
                return <Redirect to="/dashboard"/>
              }
              if(path=="/users"){
                return <Redirect to="/dashboard"/>
              }
              if(path=="/reports/edit"){
                return <Redirect to="/dashboard"/>
              }
              if(path==="/reports/generate"){
                return <Redirect to="/dashboard"/>
              }
              if(path==="/analytics/nptresultoverview"){
                return <Redirect to="/dashboard"/>
              }
              if(path==="/analytics/gatekeeperfakedlr"){
                return <Redirect to="/dashboard"/>
              }
              if(path==="/analytics/gatekeeperbypass"){
                return <Redirect to="/dashboard"/>
              }
              if(path==="/analytics/Bypassroute"){
                return <Redirect to="/dashboard"/>
              }
              if(path==="/analytics/otherchanneldelivery"){
                return <Redirect to="/dashboard"/>
              }
            }
            if([4].includes(role)){
              if(path === "/source-upload"){
                return <Redirect to="/reports/edit" />
              }
              if(path === "/upload-files"){
                return <Redirect to="/reports/edit" />
              }
              if(path==="/reports/generate"){
                return <Redirect to="/reports/edit" />
              }
              if(path==="/reports/history/import"){
                return <Redirect to="/reports/edit" />
              }
              if(path === "/users"){
                return <Redirect to="/reports/edit" />
              }
              if(path ==="/dashboard"){
                return <Redirect to="/reports/edit" />
              }

            }
            if([3].includes(role)){
              if(path === "/dashboard"){
                return <Redirect to="/source-upload" />
              }
            }
          }
    
    
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          )
        }}
      />
    )
  )
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware;
