import * as url from "../../../helpers/url_helper"

export async function fetchKBs(id) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._GET_KBS, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchDeleteKBs(id) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._DELETE_KB+id, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchSelectedKB(id) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._SELECTED_KB+id, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}

export async function dispatchNewKB(data) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            // "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        }
        
        const response = await fetch(url._UPLOAD_KB, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}

export async function dispatchEditKBs(data,id) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            // "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        }
        
        const response = await fetch(url._EDIT_KB, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}

