import axios from "axios"
import * as aT from "./actionTypes"


const INIT_STATE = {
    partners: [],
    partnergetsuccess:false,
    selectedPartner: [],
    selectedPartnerSuccess:false,
    countries:[],
    uploadsuccess:false,
    editsuccess:false,
    deletesuccess:false,
   }
  
  const partner = (state = INIT_STATE, action) => {
    switch (action.type) {
      case aT.GET_NEW_PARTNERS_SUCCESS:
        // state.partnergetsuccess = action.payload;
        return {
          ...state,
        partnergetsuccess : action.payload
        }
      case aT.SET_NEW_PAR_COUNTRIES:
        // state.countries = action.payload;
        return {
          ...state,
          countries : action.payload
        }
      case aT.SET_NEW_PARTNERS:
        // state.partners = action.payload;
        return {
          ...state,
          partners : action.payload
        }
      case aT.SET_SELECTED_PARTNER:
        // state.selectedPartner = action.payload;
        return {
          ...state,
        selectedPartner : action.payload
        }
      case aT.SET_SELECTED_PARTNER_SUCCESS:
        // state.selectedPartnerSuccess = action.payload;
        return {
          ...state,
          selectedPartnerSuccess : action.payload
        }
      case aT.SET_UPLOAD_PARTNER_SUCCESS:
        // state.uploadsuccess = action.payload;
        return {
          ...state,
          uploadsuccess : action.payload
        }
      case aT.SET_EDIT_PARTNER_SUCCESS:
        // state.editsuccess = action.payload;
        return {
          ...state,
          editsuccess : action.payload
        }
      case aT.SET_DELETE_PARTNER_SUCCESS:
        // state.deletesuccess = action.payload;
        return {
          ...state,
          deletesuccess : action.payload
        }
      default:
        return state
    }
  }
  
  export default partner