import React, { useEffect, useState } from "react"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { Row, Col, Card, CardBody, Button, CardTitle } from "reactstrap"
import Swal from "sweetalert2"
import "toastr/build/toastr.min.css"
import BootstrapTable from "react-bootstrap-table-next"
import cellEditFactory from "react-bootstrap-table2-editor"
import store from "../../../../store/index"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { useDispatch, useSelector } from "react-redux"
import {
  getReports,
  updateReportSuccess,
  updateReports,
  setReportsGetSuccess,
} from "store/old/reports/actions"
import jwt from "jsonwebtoken"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

const products = [
  { id: 1, age: 25, qty: 1500, cost: 1000 },
  { id: 2, age: 34, qty: 1900, cost: 1300 },
  { id: 3, age: 67, qty: 1300, cost: 1300 },
  { id: 4, age: 23, qty: 1100, cost: 6400 },
  { id: 5, age: 78, qty: 1400, cost: 4000 },
]

const columns2 = [
  {
    dataField: "id",
    text: "ID",
    headerClasses: "id-header",
  },
  {
    dataField: "country",
    text: "Country",
    headerClasses: "country-header",
  },
  {
    dataField: "operator",
    text: "Operator",
    headerClasses: "operator-header",
  },
  {
    dataField: "is_delivered",
    text: "Is Delivered",
    headerClasses: "is-delivered-header",
  },
  {
    dataField: "delivery_timestamp",
    text: "Delivery Timestamp",
    headerClasses: "timestamp-header",
  },
  {
    dataField: "delivery_duration",
    text: "Delivery Duration",
    headerClasses: "delivery-header",
  },
  {
    dataField: "b_number",
    text: "B Number",
    headerClasses: "b_number-header",
  },
  {
    dataField: "sender_id_type",
    text: "Sender Id Type",
    headerClasses: "sender_id_type-header",
  },
  {
    dataField: "price",
    text: "Price",
    headerClasses: "price-header",
  },
  {
    dataField: "sent_sender_id",
    text: "Send Sender Id",
    headerClasses: "sent_sender_id-header",
  },
  {
    dataField: "received_sender_id",
    text: "Received Sender Id",
    headerClasses: "received_sender_id-header",
  },
  {
    dataField: "is_sender_id_preserved",
    text: "Sender Presereved",
    headerClasses: "is_sender_id_preserved-header",
  },
  {
    dataField: "smsc",
    text: "SMSC",
    headerClasses: "smsc-header",
  },
  {
    dataField: "national_smsc",
    text: "Expected SMSC",
    headerClasses: "expected_sms-header",
  },
  {
    dataField: "smsc_owner",
    text: "SMSC Owner",
    headerClasses: "smsc_owner-header",
  },
  {
    dataField: "sent_text",
    text: "Sent Text",
    headerClasses: "sent_text-header",
  },
  {
    dataField: "received_text",
    text: "Received Text",
    headerClasses: "received_text-header",
  },
  {
    dataField: "is_text_preserved",
    text: "Text Presereved",
    headerClasses: "is_text_preserved-header",
  },
  {
    dataField: "template_spoofing",
    text: "Template Spoofing",
    headerClasses: "gate-header",
  },
  {
    dataField: "gate",
    text: "Gate",
    headerClasses: "gate-header",
  },
  {
    dataField: "partner",
    text: "Partner",
    headerClasses: "gate-header",
  },
  // {
  //   dataField:"createdAt",
  //   text:"Created At"
  // },
]

const notDeliveredTable = props => {
  const decodeToken = token => {
    try {
      const decodedToken = jwt.decode(token)
      return decodedToken
    } catch (error) {
      console.log("Error decoding token:", error)
      return null
    }
  }

  const token = localStorage.getItem("authUser")
  const decodedToken = decodeToken(JSON.parse(token))
  let role = decodedToken.role

  const cellEdit = role !== 4 ? cellEditFactory({
    mode: "dbclick",
    blurToSave: true,
    afterSaveCell: (oldValue, newValue, row, column) => {
      // console.log(row)
    },
  }) : undefined;

  const reportID = useSelector(state => state.Reports.selectedReportId)
  const notdeliveredReports = props.notdeliveredReports
  const Reports = props.notdeliveredReports
  var highlights = props.highlights.highlights
  var reportData = Reports.length == 0 ? products : Reports.not_delivered
  var deliveredData = Reports.length == 0 ? products : Reports.delivered

  const dispatch = useDispatch()

  function showToast() {
    toastr.options = {
      positionClass: "toast-top-right",
      timeOut: "5000",
      extendedTimeOut: "1000",
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: false,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: "500",
      hideDuration: "1000",
    }

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    toastr.success("Values Updated", "Edit Successful")
  }

  async function sendEdits() {
    var formData = new FormData()

    //console.log(JSON.stringify(reportData));

    if (Reports.length != 0) {
      let report_temp = [...deliveredData, ...reportData]
      // console.log(report_temp);
      formData.append("data", JSON.stringify(report_temp))
      formData.append("highlights", JSON.stringify(highlights))
      formData.append("customer", "selectedCustomer")
      formData.append("report_id", JSON.stringify(reportID))
      dispatch(updateReports(formData))
      dispatch(setReportsGetSuccess(false))
      dispatch(updateReportSuccess(false))
      let editPromise = new Promise((resolve, reject) => {
        store.subscribe(() => {
          const updateSuccess = store.getState()
          const status = updateSuccess.Reports.updateSuccess
          if (status == true) {
            resolve("Success!")
          }
          if (status == "error") {
            reject("Error!")
          }
        })
      })
      await editPromise
        .then(async message => {
          // console.log("Report Edit success");
          showToast()
          dispatch(getReports({id: reportID, template: "All"}))
          dispatch(updateReportSuccess(false))
          // await genKPI();
        })
        .catch(message => {
          Swal.fire({
            title: "Error!",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonText: "OK",
          })
          dispatch(getReports(reportID))
          dispatch(updateReportSuccess(false))
        })
      // setTimeout(() => {
      //   showToast()
      //   dispatch(getReports(reportID))
      // }, 2000);
    }
  }

  return (
    <React.Fragment>
      <div className="col-12" style={{ paddingTop: "5px" }}>
        <div>
          {/* <Breadcrumbs title="Tables" breadcrumbItem="Editable Table" /> */}

          <Row>
            <Col>
              {/*  <CardTitle>NOT DELIVERED </CardTitle> */}
              <div
                className="modal-header"
                style={{ paddingTop: "20px", paddingBottom: "10px" }}
              >
                <h5>NOT DELIVERED</h5>
              </div>
              <div className="table-responsive scrollable table_shadow_div reportdata_table">
                <BootstrapTable
                  keyField="id"
                  data={reportData}
                  columns={columns2}
                  cellEdit={cellEdit}
                />
              </div>
              <br />
              <Col className="position-relative ">
                <Button
                  color="danger"
                  paddingTop="50px"
                  className="btn btn-custom md-position-absolute ms-auto p-2 bd-highlight"
                  onClick={() => {
                    sendEdits()
                  }}
                  disabled={role === 4}
                >
                  Save Edits
                </Button>
              </Col>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default notDeliveredTable
