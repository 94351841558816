import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Input,
  Modal,
  ModalFooter,
  Container,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import MetaTags from "react-meta-tags"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  getCountries,
  uploadOperator,
  getNewOperatorSuccess,
  setNewOperators,
  setuploadOperatorSuccess,
  getNewOperators,
  getSelectedOperator,
  deleteOperator,
  setdeleteOperatorSuccess,
  setEditOperatorSuccess,
  editOperator,
} from "store/old/operator/actions"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import NewOperators from "components/Operator/AddNew"
import ViewOperators from "components/Operator/View"
import EditOperators from "components/Operator/Edit"
import Swal from "sweetalert2"
import "toastr/build/toastr.min.css"
import store from "../../store/index"
import LoadingSpinner from "components/Reports/EditReports/EditTables/loader"
import jwt from "jsonwebtoken"
import ReactPaginate from "react-paginate"

// import "./datatables.scss"

const Operators = props => {
  const decodeToken = token => {
    try {
      const decodedToken = jwt.decode(token)
      return decodedToken
    } catch (error) {
      console.log("Error decoding token:", error)
      return null
    }
  }

  const token = localStorage.getItem("authUser")
  const decodedToken = decodeToken(JSON.parse(token))
  let role = decodedToken.role

  const dispatch = useDispatch()
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
    didOpen: toast => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })
  useEffect(() => {
    dispatch(getNewOperatorSuccess(false))
    dispatch(setNewOperators([]))
    dispatch(getNewOperators())
  }, [])

  const operatorData = useSelector(state => state.operator.operators)
  const selectedOperatordata = useSelector(
    state => state.operator.selectedOperator
  )
  const getSuccess = useSelector(state => state.operator.getSuccess)

  const [currentPage, setCurrentPage] = useState(0)
  const itemsPerPage = 10
  const offset = currentPage * itemsPerPage
  const [filteredChannels, setFilteredChannels] = useState(operatorData)
  const currentPageItems = filteredChannels?.slice(
    offset,
    offset + itemsPerPage
  )
  const [searchTerm, setSearchTerm] = useState("")
  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    setFilteredChannels(operatorData)
  }, [operatorData])

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredChannels(operatorData)
    } else {
      setFilteredChannels(
        operatorData.filter(item => {
          let term = searchText.toLowerCase()
          return item.operator_name.toLowerCase().includes(term)
        })
      )
      setCurrentPage(0)
    }
  }, [searchText])

  const [modal_view_screen, setmodal_view_screen] = useState(false)
  const [modal_add_screen, setmodal_add_screen] = useState(false)
  const [modal_edit_screen, setmodal_edit_screen] = useState(false)

  const [add_operatorName, set_add_operatorName] = useState("")
  const [add_operatorCountry, set_add_operatorCountry] = useState("")
  const [add_operatorCountryId, set_add_operatorCountryId] = useState("")
  const [add_mtrCurrency, set_add_mtrCurrency] = useState("")
  const [add_a2pinternational, set_add_a2pinternational] = useState("")
  const [add_a2pnational, set_add_a2pnational] = useState("")
  const [add_p2pinternational, set_add_p2pinternational] = useState("")
  const [add_p2pnational, set_add_p2pnational] = useState("")
  const [add_alphanumeric_changed, set_add_alphanumeric_changed] = useState("")
  const [add_alphanumeric_unchanged, set_add_alphanumeric_unchanged] =
    useState("")
  const [add_shortcode, set_add_shortcode] = useState("")
  const [add_longnumber, set_add_longnumber] = useState("")
  const [add_other, set_add_other] = useState("")
  const [add_gatekeeper, set_add_gatekeeper] = useState("")
  const [add_firewall, set_add_firewall] = useState("")
  const [add_prereg, set_add_prereg] = useState("")
  const [add_ownA2P, set_add_ownA2P] = useState([])
  const [add_ownP2P, set_add_ownP2P] = useState([])
  const [add_smsc, set_add_smsc] = useState([])
  const [add_gatekeeperRoutes, set_add_gatekeeperRoutes] = useState([])
  const [add_operatorSubscriber, set_add_operatorSubscriber] = useState(0)
  const [add_priceThreshold, set_add_priceThreshold] = useState(1.5)

  const [edit_ownA2P, set_edit_ownA2P] = useState([])
  const [edit_ownP2P, set_edit_ownP2P] = useState([])
  const [edit_smsc, set_edit_smsc] = useState([])
  const [edit_gatekeeper, set_edit_gatekeeper] = useState([])

  //console.log("ap2 changes",add_alphanumeric_changed);
  //console.log("a2p unchanged",add_alphanumeric_unchanged);
  // console.log("own a2p",add_ownA2P);
  // console.log("own p2p",add_ownP2P);
  // console.log("smsc",add_smsc);

  function tog_view_screen() {
    setmodal_view_screen(!modal_view_screen)
    removeBodyCss()
  }
  function tog_add_screen() {
    setmodal_add_screen(!modal_add_screen)
    removeBodyCss()
  }
  function tog_edit_screen() {
    setmodal_edit_screen(!modal_edit_screen)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  async function saveOperatorData() {
    // var formData = new FormData()
    // formData.append("smsc_match",input_smscmatch)
    // formData.append("smsc_replace",input_smscreplace)
    if (
      add_operatorName != "" &&
      add_a2pinternational != "" &&
      add_operatorCountry != "" &&
      add_mtrCurrency != ""
    ) {
      var data = {
        operator_data: {
          add_operatorName: add_operatorName,
          add_operatorCountry: add_operatorCountry,
          add_mtrCurrency: add_mtrCurrency,
          add_a2pinternational: add_a2pinternational,
          add_a2pnational: add_a2pnational,
          add_p2pinternational: add_p2pinternational,
          add_p2pnational: add_p2pnational,
          add_alphanumeric_changed: add_alphanumeric_changed,
          add_alphanumeric_unchanged: add_alphanumeric_unchanged,
          add_shortcode: add_shortcode,
          add_longnumber: add_longnumber,
          add_other: add_other,
          add_gatekeeper: add_gatekeeper,
          add_firewall: add_firewall,
          add_prereg: add_prereg,
          add_operatorSubscriber: add_operatorSubscriber,
          add_priceThreshold: add_priceThreshold,
        },
        add_ownA2P: add_ownA2P,
        add_ownP2P: add_ownP2P,
        add_smsc: add_smsc,
        gatekeepers: add_gatekeeperRoutes,
      }
      // console.log(data);
      dispatch(uploadOperator(data))

      let uploadPromise = new Promise(async (resolve, reject) => {
        store.subscribe(() => {
          const uploadSuccess = store.getState()
          const status = uploadSuccess.operator.uploadsuccess
          if (status == true) {
            // console.log(status);
            resolve("Done")
          }
        })
      })
      await uploadPromise.then(async msg => {
        Toast.fire({
          icon: "success",
          title: "Saved Successfully!",
        })
        dispatch(setuploadOperatorSuccess(false))
        setTimeout(() => {
          setmodal_add_screen(false)
          clearAddStates()
          dispatch(getNewOperatorSuccess(false))
          dispatch(setNewOperators([]))
          dispatch(getNewOperators())
        }, 1500)
      })
    } else {
      Toast.fire({
        icon: "error",
        title: "Please Fill All required Fields!",
      })
    }
  }
  async function deleteOps(id) {
    Swal.fire({
      title: "Do you really want to Delete the record?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Delete",
      denyButtonText: `Don't Delete`,
    }).then(async result => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(deleteOperator(id))
        let deletePromise = new Promise(async (resolve, reject) => {
          store.subscribe(() => {
            const deleteSuccess = store.getState()
            const status = deleteSuccess.operator.deletesuccess
            if (status == true) {
              // console.log(status);
              resolve("Done")
            }
          })
        })
        await deletePromise.then(async msg => {
          Toast.fire({
            icon: "success",
            title: "Deleted Successfully!",
          })
          dispatch(setdeleteOperatorSuccess(false))
          setTimeout(() => {
            dispatch(getNewOperatorSuccess(false))
            dispatch(setNewOperators([]))
            dispatch(getNewOperators())
            setCurrentPage(0)
          }, 1500)
        })
      } else if (result.isDenied) {
        Swal.fire("Delete Cancelled", "", "info")
      }
    })
  }
  async function saveOperatorEdit() {
    const edit_id = selectedOperatordata.id
    //console.log(edit_id);
    const editData = selectedOperatordata
    console.log(editData.official_MTR_A2P_international)
    if (
      editData.operator_name != "" &&
      editData.country != "" &&
      editData.mtr_currency != "" &&
      editData.official_MTR_A2P_international != "" &&
      editData.official_MTR_A2P_international != null &&
      !isNaN(editData.official_MTR_A2P_international)
    ) {
      var data = {
        customer_data: editData,
        a2p: edit_ownA2P,
        p2p: edit_ownP2P,
        smsc: edit_smsc,
        gatekeeper_routes: edit_gatekeeper,
      }
      // console.log(data)
      dispatch(
        editOperator({
          data: data,
        })
      )

      let editPromise = new Promise(async (resolve, reject) => {
        store.subscribe(() => {
          const editSuccess = store.getState()
          const status = editSuccess.operator.editsuccess
          if (status == true) {
            // console.log(status);
            resolve("Done")
          }
        })
      })
      await editPromise.then(async msg => {
        Toast.fire({
          icon: "success",
          title: "Edits Saved Successfully!",
        })
        dispatch(setEditOperatorSuccess(false))
        setTimeout(() => {
          setmodal_edit_screen(false)
          dispatch(getNewOperatorSuccess(false))
          dispatch(setNewOperators([]))
          dispatch(getNewOperators())
        }, 1000)
      })
    } else {
      Toast.fire({
        icon: "error",
        title: "Please Fill All required Fields!",
      })
    }
  }

  function clearAddStates() {
    set_add_operatorName("")
    set_add_operatorCountry("")
    set_add_mtrCurrency("")
    set_add_a2pinternational("")
    set_add_a2pnational("")
    set_add_p2pinternational("")
    set_add_p2pnational("")
    set_add_alphanumeric_changed("")
    set_add_alphanumeric_unchanged("")
    set_add_shortcode("")
    set_add_longnumber("")
    set_add_other("")
    set_add_gatekeeper("")
    set_add_firewall("")
    set_add_prereg("")
    set_add_ownA2P([])
    set_add_ownP2P([])
    set_add_smsc([])
    set_add_operatorSubscriber([])
    set_add_priceThreshold([])
    set_add_gatekeeperRoutes([])
  }

  // Table Data
  const columns = [
    {
      dataField: "operator_name",
      text: " Operator Name",
      sort: true,
    },
    {
      dataField: "country",
      text: "Country",
      sort: true,
    },
    {
      dataField: "view",
      text: "View",
      sort: true,
      formatter: function (value, row, index) {
        return (
          <button
            className="view-btn-tbl"
            onClick={async () => {
              dispatch(getSelectedOperator(row.id))
              dispatch(getCountries())
              // await sleep(200);
              let viewOpenPromise = new Promise(async (resolve, reject) => {
                store.subscribe(() => {
                  const all_success = store.getState()
                  const country = all_success.operator.countries
                  const selectedOperator = all_success.operator.selectedOperator
                  if (country != [] && selectedOperator != []) {
                    resolve("Done")
                  }
                })
              })
              await viewOpenPromise.then(async msg => {
                tog_view_screen()
              })
              // setTimeout(() => {
              //   tog_view_screen()
              // }, 200);
            }}
          >
            View
          </button>
        )
      },
    },
    {
      dataField: "edit",
      text: "Edit",
      sort: true,
      formatter: function (value, row, index) {
        return (
          <button
            className={`view-btn-tbl ${role === 4 ? "disabled" : ""}`}
            onClick={async () => {
              dispatch(getSelectedOperator(row.id))
              dispatch(getCountries())
              let editOpenPromise = new Promise(async (resolve, reject) => {
                store.subscribe(() => {
                  const all_success = store.getState()
                  const country = all_success.customer.countries
                  const selectedOp = all_success.customer.selectedOperator
                  if (country != [] && selectedOp != []) {
                    resolve("Done")
                  }
                })
              })
              await editOpenPromise.then(async msg => {
                tog_edit_screen()
              })
              // setTimeout(() => {
              //   tog_edit_screen()
              // }, 200);
            }}
            disabled={role === 4}
          >
            Edit
          </button>
        )
      },
    },
    {
      dataField: "delete",
      text: "Delete",
      sort: true,
      formatter: function (value, row, index) {
        return (
          <button
            className={`view-btn-tbl ${role === 4 ? "disabled" : ""}`}
            onClick={async () => {
              deleteOps(row.id)
            }}
            disabled={role === 4}
          >
            Delete
          </button>
        )
      },
    },
  ]

  const tableData = operatorData?.length == 0 ? [] : operatorData
  const selectedOperator_editdata =
    selectedOperatordata?.length == 0 ? "" : selectedOperatordata

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 5,
    totalSize: tableData.length, // replace later with size(customers),
    custom: true,
  }

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div className="page-content ">
        <MetaTags>
          <title>Operators</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Settings" breadcrumbItem="Operators" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col md="8" sm="12">
                      <div className="search-box me-2 mb-2 d-inline-block w-sm-100">
                        <Row>
                          <Col>
                            <div
                              className="me-3"
                              style={{
                                border: "1px solid #D9D9D9",
                                borderRadius: "50px",
                                marginBottom: "10px",
                                minWidth: "210px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <i
                                class="bx bx-search-alt search-icon sm-left-30 ps-2 pe-2 "
                                style={{
                                  width: "10px",
                                  height: "10px",
                                }}
                              ></i>
                              <input
                                type="text"
                                placeholder="Search"
                                style={{
                                  width: "150px",
                                  height: "31px",
                                  border: "none",
                                  borderRadius: "10px",
                                  textAlign: "start",
                                  borderTopLeftRadius: "20px",
                                  borderBottomLeftRadius: "20px",
                                  marginTop: "2px",
                                  marginLeft: "25px",
                                }}
                                value={searchTerm}
                                onChange={e => {
                                  setSearchTerm(e.target.value)
                                  setSearchText(e.target.value)
                                }}
                                onKeyDown={e => {
                                  if (e.key === "Enter") {
                                    // setPage(1)
                                  }
                                }}
                              />
                              {searchTerm !== "" && (
                                // <i className="bx bx-search-alt search-icon sm-left-30" />
                                <i
                                  className="fas fa-times me-2 ms-2"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    // setPage(1)
                                    setSearchTerm("")
                                    setSearchText("")
                                  }}
                                ></i>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col md="4" sm="12" className="position-relative ">
                      <div className="d-flex ">
                        <Button
                          color="danger"
                          className="btn btn-custom md-position-absolute ms-auto p-2 bd-highlight"
                          onClick={() => {
                            setTimeout(() => {
                              tog_add_screen()
                            }, 200)
                          }}
                          disabled={role === 4}
                        >
                          Add Operator
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <div className="col-12 ms-3">
                    {filteredChannels?.length == 0 ? (
                      getSuccess == false ? (
                        <LoadingSpinner />
                      ) : (
                        <div
                          style={{
                            textAlign: "center",
                            paddingTop: "50px",
                          }}
                        >
                          No Data Found
                        </div>
                      )
                    ) : (
                      <div>
                        <Row style={{ marginRight: "15px" }}>
                          <Table className="table-responsive align-middle mb-0 report_table_edit">
                            <thead>
                              <tr>
                                <th>Operator Name</th>
                                <th>Country</th>
                                <th>View</th>
                                <th>Edit</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentPageItems?.map(file => {
                                return (
                                  <tr key={file.id}>
                                    <td>{file.operator_name}</td>
                                    <td>{file.country} </td>
                                    <td>
                                      <button
                                        className="view-btn-tbl"
                                        onClick={async () => {
                                          dispatch(getSelectedOperator(file.id))
                                          dispatch(getCountries())
                                          // await sleep(200);
                                          let viewOpenPromise = new Promise(
                                            async (resolve, reject) => {
                                              store.subscribe(() => {
                                                const all_success =
                                                  store.getState()
                                                const country =
                                                  all_success.operator.countries
                                                const selectedOperator =
                                                  all_success.operator
                                                    .selectedOperator
                                                if (
                                                  country != [] &&
                                                  selectedOperator != []
                                                ) {
                                                  resolve("Done")
                                                }
                                              })
                                            }
                                          )
                                          await viewOpenPromise.then(
                                            async msg => {
                                              tog_view_screen()
                                            }
                                          )
                                          // setTimeout(() => {
                                          //   tog_view_screen()
                                          // }, 200);
                                        }}
                                      >
                                        View
                                      </button>
                                    </td>
                                    <td>
                                      <button
                                        className={`view-btn-tbl ${
                                          role === 4 ? "disabled" : ""
                                        }`}
                                        onClick={async () => {
                                          dispatch(getSelectedOperator(file.id))
                                          dispatch(getCountries())
                                          let editOpenPromise = new Promise(
                                            async (resolve, reject) => {
                                              store.subscribe(() => {
                                                const all_success =
                                                  store.getState()
                                                const country =
                                                  all_success.customer.countries
                                                const selectedOp =
                                                  all_success.customer
                                                    .selectedOperator
                                                if (
                                                  country != [] &&
                                                  selectedOp != []
                                                ) {
                                                  resolve("Done")
                                                }
                                              })
                                            }
                                          )
                                          await editOpenPromise.then(
                                            async msg => {
                                              tog_edit_screen()
                                            }
                                          )
                                          // setTimeout(() => {
                                          //   tog_edit_screen()
                                          // }, 200);
                                        }}
                                        disabled={role === 4}
                                      >
                                        Edit
                                      </button>
                                    </td>
                                    <td>
                                      <button
                                        className={`view-btn-tbl ${
                                          role === 4 ? "disabled" : ""
                                        }`}
                                        onClick={async () => {
                                          deleteOps(file.id)
                                        }}
                                        disabled={role === 4}
                                      >
                                        Delete
                                      </button>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </Row>
                      </div>
                    )}
                  </div>
                  <div className="col-12">
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(
                        filteredChannels.length / itemsPerPage
                      )}
                      forcePage={currentPage}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={data => {
                        setCurrentPage(data.selected)
                      }}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                  {/* Add Operator */}

                  <Modal
                    size="lg"
                    isOpen={modal_add_screen}
                    toggle={() => {
                      tog_add_screen()
                    }}
                    className="modal-operator-screen form-width"
                  >
                    <div className="modal-header ">
                      <h5
                        className="modal-title mt-0 fs-18"
                        id="exampleModalFullscreenLabel"
                      >
                        ADD OPERATOR
                      </h5>
                      <button
                        onClick={() => {
                          setmodal_add_screen(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <NewOperators
                        add_operatorName={set_add_operatorName}
                        add_operatorCountry={set_add_operatorCountry}
                        add_mtrCurrency={set_add_mtrCurrency}
                        add_a2pinternational={set_add_a2pinternational}
                        add_a2pnational={set_add_a2pnational}
                        add_p2pinternational={set_add_p2pinternational}
                        add_p2pnational={set_add_p2pnational}
                        add_alphanumeric_changed={set_add_alphanumeric_changed}
                        add_alphanumeric_unchanged={
                          set_add_alphanumeric_unchanged
                        }
                        add_shortcode={set_add_shortcode}
                        add_longnumber={set_add_longnumber}
                        add_other={set_add_other}
                        add_gatekeeper={set_add_gatekeeper}
                        add_firewall={set_add_firewall}
                        add_prereg={set_add_prereg}
                        add_ownA2P={set_add_ownA2P}
                        add_ownA2P_state={add_ownA2P}
                        add_ownP2P={set_add_ownP2P}
                        add_ownP2P_state={add_ownP2P}
                        add_smsc={set_add_smsc}
                        add_smsc_state={add_smsc}
                        add_gatekeeperRoutes_state={add_gatekeeperRoutes}
                        add_gatekeeperRoutes={set_add_gatekeeperRoutes}
                        add_operatorSubscriber={set_add_operatorSubscriber}
                        add_priceThreshold={set_add_priceThreshold}
                      />
                    </div>

                    <ModalFooter>
                      {/* <Button
                          type="button"
                          onClick={() => {
                            tog_add_screen()
                          }}
                          className="btn btn-secondary "
                          data-dismiss="modal"
                        >
                          Close
                        </Button>
                        <Button
                          type="button"
                          className="btn btn-primary "
                          onClick={() => {
                            saveOperatorData()
                          }}
                        >
                          Save
                        </Button> */}
                      <div className="modal_footer_op form-width">
                        <Col md="7"></Col>
                        <Col md="5">
                          <div
                            className=""
                            style={{
                              marginRight: "60px",
                            }}
                          >
                            <Button
                              type="button"
                              onClick={() => {
                                tog_add_screen()
                              }}
                              className="btn_cancel btn me-3"
                              data-dismiss="modal"
                            >
                              Close
                            </Button>
                            <Button
                              type="button"
                              className="btn btn_save"
                              onClick={() => {
                                saveOperatorData()
                                // console.log("Clicked Edit");
                              }}
                            >
                              Save
                            </Button>
                          </div>
                        </Col>
                      </div>
                    </ModalFooter>
                  </Modal>
                  {/* Edit Operator */}

                  <Modal
                    size="lg"
                    isOpen={modal_edit_screen}
                    toggle={() => {
                      tog_edit_screen()
                      set_edit_ownA2P([])
                      set_edit_ownP2P([])
                      set_edit_smsc([])
                    }}
                    className="modal-operator-screen form-width"
                  >
                    <div className="modal-header">
                      <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                      >
                        EDIT OPERATOR
                      </h5>
                      <button
                        onClick={() => {
                          setmodal_edit_screen(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <EditOperators
                        data={selectedOperatordata}
                        owna2p_state={edit_ownA2P}
                        owna2p_set={set_edit_ownA2P}
                        ownp2p_state={edit_ownP2P}
                        ownp2p_set={set_edit_ownP2P}
                        ownsmsc_state={edit_smsc}
                        ownsmsc_set={set_edit_smsc}
                        gatekeeper_state={edit_gatekeeper}
                        gatekeeper_set={set_edit_gatekeeper}
                      />
                    </div>

                    <ModalFooter>
                      <div className="modal_footer_op form-width">
                        <Col md="7"></Col>
                        <Col md="5">
                          <div
                            className=""
                            style={{
                              marginRight: "60px",
                            }}
                          >
                            <Button
                              type="button"
                              onClick={() => {
                                tog_edit_screen()
                              }}
                              className="btn_cancel btn me-3"
                              data-dismiss="modal"
                            >
                              Cancel
                            </Button>
                            <Button
                              type="button"
                              className="btn btn_save"
                              onClick={() => {
                                saveOperatorEdit()
                                // console.log("Clicked Edit");
                              }}
                            >
                              Save
                            </Button>
                          </div>
                        </Col>
                      </div>
                    </ModalFooter>
                  </Modal>

                  {/* Operator data view */}
                  <Modal
                    size="lg"
                    isOpen={modal_view_screen}
                    toggle={() => {
                      tog_view_screen()
                    }}
                    className="modal-midsize-screen"
                  >
                    <div className="modal-header">
                      <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                      >
                        OPERATOR DETAILS
                      </h5>
                      <button
                        onClick={() => {
                          setmodal_view_screen(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <ViewOperators data={selectedOperatordata} />
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={() => {
                          tog_view_screen()
                        }}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      {/* <button
                          type="button"
                          className="btn btn-primary "
                        >
                          Save changes
                        </button> */}
                    </div>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Operators
