import React, { useState, useMemo, useEffect } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory from "react-bootstrap-table2-filter"
import Select from "react-select"
import MetaTags from "react-meta-tags"
import Breadcrumbs from "../../components/Common/Breadcrumb"
// import { DateRangePicker, Stack } from "rsuite"

import Swal from "sweetalert2"
import "toastr/build/toastr.min.css"
import LoadingSpinner from "components/Reports/EditReports/EditTables/loader"
import { useGetResultsOverviewMutation } from "store/analytics/analytics.api"
import { setResultsOverview } from "store/analytics/analytics.slice"
import { useDispatch, useSelector } from "react-redux"
import { useGetTemplatesQuery } from "store/template/template.api"
import { setTemplates } from "store/template/template.slice"
import { get } from "lodash"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { DateRangePicker } from "rsuite"
import { SelectPicker } from 'rsuite';
import "rsuite/DateRangePicker/styles/index.css"
import "rsuite/SelectPicker/styles/index.css"
import toastr from "toastr"
import { debounce } from "lodash"
import SortableHeader from "components/Common/Sort"
import { components } from 'react-select';


const predefinedBottomRanges = [
  {
    label: "Last 3 Months",
    value: [
      new Date(new Date().setMonth(new Date().getMonth() - 3)),
      new Date(),
    ],
  },
  {
    label: "Last 6 Months",
    value: [
      new Date(new Date().setMonth(new Date().getMonth() - 6)),
      new Date(),
    ],
  },
  {
    label: "Last Year",
    value: [
      new Date(new Date().getFullYear() - 1, 0, 1),
      new Date(new Date().getFullYear(), 0, 0),
    ],
  },
  {
    label: "This Year",
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
  },
  {
    label: "Q1",
    value: [
      new Date(new Date().getFullYear(), 0, 1),
      new Date(new Date().getFullYear(), 2, 31),
    ],
  },
  {
    label: "Q2",
    value: [
      new Date(new Date().getFullYear(), 3, 1),
      new Date(new Date().getFullYear(), 5, 30),
    ],
  },
  {
    label: "Q3",
    value: [
      new Date(new Date().getFullYear(), 6, 1),
      new Date(new Date().getFullYear(), 8, 30),
    ],
  },
  {
    label: "Q4",
    value: [
      new Date(new Date().getFullYear(), 9, 1),
      new Date(new Date().getFullYear(), 11, 31),
    ],
  },
  {
    label: "Last 5 Years",
    value: [new Date(new Date().getFullYear() - 5, 0, 1), new Date()],
  },
  {
    label: "All Time",
    value: [
      new Date(0), // This sets the date to the earliest possible date in JavaScript
      new Date(),
    ],
  },
]

export default function AnalyticsResultOverview() {
  const { SearchBar } = Search
  const [searchText, setSearchText] = useState("")
  const [selectedXTest, setXTest] = useState("")
  const [selectedTemplate, setTemplate] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const dispatch = useDispatch()

  const [sortOrder, setSortOrder] = useState("asc")
  const [sortOrderFirst, setSortOrderFirst] = useState("asc")
  const [sortOrderCountry, setSortOrderCountry] = useState("asc")
  const [sortOrderOperator, setSortOrderOperator] = useState("asc")
  const [sortOrderCountrySubscriber, setSortOrderCountrySubscriber] = useState("asc")
  const [sortOrderOperatorSubscriber, setSortOrderOperatorSubscriber] = useState("asc")
  const [sortOrderMTR, setSortOrderMTR] = useState("asc")
  const [sortOrdertotalDelivery, setSortOrdertotalDelivery] = useState("asc")
  const [sortOrderAmountOfTests, setSortOrderAmountOfTests] = useState("asc")
  const [dateSetting, setDateSetting] = useState(false)


  const handleSortChange = newSortOrder => {
    setSortOrder(newSortOrder)
  }
  const handleSortChangeFirst = newSortOrder => {
    setSortOrderFirst(newSortOrder)
  }
  const handleSortChangeCountry = newSortOrder => {
    setSortOrderCountry(newSortOrder)
  }
  const handleSortChangeOperator = newSortOrder => {
    setSortOrderOperator(newSortOrder)
  }
  const handleSortChangeCountrySubscriber = newSortOrder => {
    setSortOrderCountrySubscriber(newSortOrder)
  }
  const handleSortChangeOperatorSubscriber = newSortOrder => {
    setSortOrderOperatorSubscriber(newSortOrder)
  }
  const handleSortChangeMTR = newSortOrder => {
    setSortOrderMTR(newSortOrder)
  }
  const handleSortChangetotalDelivery = newSortOrder => {
    setSortOrdertotalDelivery(newSortOrder)
  }
  const handleSortChangeAmountOfTests = newSortOrder => {
    setSortOrderAmountOfTests(newSortOrder)
  }

  const { data: templatesNew, isLoading: templatesLoading } =
    useGetTemplatesQuery()
  const { templates: template } = useSelector(state => state.templateNew)
  const [filteredResults, setFilteredResults] = useState([])
  const [
    getResultsOverview,
    { data: resultsData, isLoading: resultsLoading, isError: resultsError },
  ] = useGetResultsOverviewMutation()
  const { resultsOverview } = useSelector(state => state.analyticsSlice)

  useEffect(() => {
    if (templatesNew) {
      let data = get(templatesNew, "templates", [])
      dispatch(setTemplates(data))
    }
  }, [templatesNew])

  useEffect(() => {
    dispatch(setResultsOverview([]))
    setFilteredResults([])
    if (selectedXTest && !dateSetting && ((startDate && endDate) || (!startDate && !endDate))) {
      getResultsOverview({
        lastXtests: selectedXTest,
        template: selectedTemplate,
        startDate: startDate,
        endDate: endDate,
      })
        .unwrap()
        .then(data => {
          dispatch(setResultsOverview(data))
        })
        .catch(error => {
          toastr.error(error)
        })
    }
  }, [selectedXTest, selectedTemplate, startDate, endDate, dateSetting])

  const columns2 = [
    { 
      dataField: "country", 
      text: "Country",
      text: (
        <SortableHeader
          title="Country"
          sortOrder={sortOrderCountry}
          onSortChange={handleSortChangeCountry}
        />
      ),
      sort: true,
      headerClasses: "country-header",
    },
    { 
      dataField: "operator_name", 
      text: "Network",
      text: (
        <SortableHeader
          title="Network"
          sortOrder={sortOrderOperator}
          onSortChange={handleSortChangeOperator}
        />
      ),
      sort: true,
      headerClasses: "country-header",
    },
    {
      dataField: "first_test",
      text: (
        <SortableHeader
          title="First Test"
          sortOrder={sortOrderFirst}
          onSortChange={handleSortChangeFirst}
        />
      ),
      sort: true,
      headerClasses: "country-header",
    },
    {
      dataField: "latest_test",
      text: (
        <SortableHeader
          title="Last Test"
          sortOrder={sortOrder}
          onSortChange={handleSortChange}
        />
      ),
      sort: true,
      headerClasses: "country-header",
    },
    {
      dataField: "contr_subscribers",
      text: "Country Subscriber",
      sort: true,
      text: (
        <SortableHeader
          title="Country Subscriber"
          sortOrder={sortOrderCountrySubscriber}
          onSortChange={handleSortChangeCountrySubscriber}
        />
      ),
      headerClasses: "country-header",
    },
    {
      dataField: "op_subscribers",
      text: "Operator Subscriber",
      sort: true,
      text: (
        <SortableHeader
          title="Operator Subscriber"
          sortOrder={sortOrderOperatorSubscriber}
          onSortChange={handleSortChangeOperatorSubscriber}
        />
      ),
      headerClasses: "country-header",
    },
    {
      dataField: "marketShare",
      text: "Market Share",
      headerClasses: "id-header",
    },
    { 
      dataField: "mtr", 
      text: "MTR",
      sort: true,
      text: (
        <SortableHeader
          title="MTR"
          sortOrder={sortOrderMTR}
          onSortChange={handleSortChangeMTR}
        />
      ),
     },
    {
      dataField: "delivered_sms",
      text: "Avg. # of Deliveries",
      headerClasses: "country-header",
      sort: true,
      text: (
        <SortableHeader
          title="Avg. # of Deliveries"
          sortOrder={sortOrdertotalDelivery}
          onSortChange={handleSortChangetotalDelivery}
        />
      ),
    },
    {
      dataField: "number_of_tests",
      text: "#. of Tests",
      headerClasses: "country-header",
      sort: true,
      text: (
        <SortableHeader
          title="# of Tests"
          sortOrder={sortOrderAmountOfTests}
          onSortChange={handleSortChangeAmountOfTests}
        />
      ),
    },
    {
      dataField: "bypass_ratio",
      text: "Bypass Ratio",
      headerClasses: "id-header",
    },
    {
      dataField: "delivery_ratio_above_mtr",
      text: "Delivery Above MTR",
      headerClasses: "id-header",
    },
    {
      dataField: "delivery_ratio_below_mtr",
      text: "Delivery Below MTR",
      headerClasses: "id-header",
    },
  ]


  const handleDateChange = debounce(value => {
    if (value) {
      if (value.length > 0) {
        setDateSetting(true)
        let start = new Date(value[0])
        let end = new Date(value[1])
        if (!isNaN(start.getTime()) && !isNaN(end.getTime())) {
          setStartDate("")
          setEndDate("")
          let Startdate = start.toISOString().split("T")[0]
          let Enddate = end.toISOString().split("T")[0]
          setStartDate(Startdate)
          setEndDate(Enddate)
        } else {
          setStartDate("")
          setEndDate("")
        }
        setDateSetting(false)
      } else {
        setStartDate("")
        setEndDate("")
      }
    } else {
      setStartDate("")
      setEndDate("")
    }
  }, 300)

  useEffect(() => {
    if (resultsOverview) {
      setFilteredResults(resultsOverview)
    }
  }, [resultsOverview])

  const handleSearchTest = debounce(value => {
    console.log(value)
    setSearchText(value)
    if (value) {
      let filteredData = resultsOverview.filter(item => {
        return item.operator_name.toLowerCase().includes(value.toLowerCase())
      })
      setFilteredResults(filteredData)
    } else {
      setFilteredResults(resultsOverview)
    }
  })

  const CustomClearIndicator = (props) => {
    return (
      <div onClick={props.clearValue} style={{ cursor: 'pointer', padding: '0 5px' }}>
        <svg width="14px" height="14px" style={{marginRight:10}} color="black" viewBox="0 0 16 16" fill="currentColor" aria-hidden="true" focusable="false" class="rs-icon" aria-label="close" data-category="application">
          <style>
            {`
              .rs-icon:hover {
                fill: red;
                border-width: '5px';
              }
            `}
          </style>
          <path d="M2.784 2.089l.069.058 5.146 5.147 5.146-5.147a.5.5 0 01.765.638l-.058.069L8.705 8l5.147 5.146a.5.5 0 01-.638.765l-.069-.058-5.146-5.147-5.146 5.147a.5.5 0 01-.765-.638l.058-.069L7.293 8 2.146 2.854a.5.5 0 01.638-.765z"></path>
        </svg>
      </div>
    );
  };

  const DropdownIndicator = props => {
    return (
      props.selectProps.value ? null : (
        <components.DropdownIndicator {...props}>
            <svg height="20" fill="#808080" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class=""><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
        </components.DropdownIndicator>
      )
    );
  };
  
  

  return (
    <div className="page-content">
      <MetaTags>
        <title>NPT Result Overview</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumbs title="Analytics" breadcrumbItem="NPT Result Overview" />
        <Card>
          <CardBody className="custom-cardbody">
            <Row className="pb-4" style={{ zIndex: "2", position: "relative" }}>
              <Col md="6" className="d-flex" style={{ alignItems: "center" }}>
                <div className="search-box me-2 mb-2 pt-2 d-inline-block w-sm-100">
                  <div className="position-relative">
                    {/* <SearchBar
                      style={{ height: "35px" }}
                      value={searchText}
                      // onChange={value => handleSearchTest(value)}
                    /> */}
                    {/* <i className="bx bx-search-alt search-icon sm-left-30" /> */}
                  </div>
                </div>
              </Col>
              <Col md="2">
                <Select
                  className="basic-single fixed-height"
                  classNamePrefix="select"
                  isSearchable={true}
                  isClearable
                  name="xTest"
                  placeholder="Last X Test"
                  options={[
                    { value: "10", label: "Last 10 Tests" },
                    { value: "20", label: "Last 20 Tests" },
                    { value: "50", label: "Last 50 Tests" },
                    { value: "100", label: "Last 100 Tests" },
                    { value: "1000", label: "Last 1000 Tests" },
                  ]}
                  components={{ 
                    ClearIndicator: CustomClearIndicator,
                    DropdownIndicator: !selectedXTest? DropdownIndicator : null
                  }}
                  onChange={e => {
                    if (e) {
                      if (e.value) {
                        setXTest(e.value)
                      }
                      if (e.value === "") {
                        setXTest("")
                      }
                    } else {
                      setXTest("")
                    }
                  }}
                />
              </Col>
              <Col md="2" className="">
                <div style={{ width: "100%" }}>
                  <DateRangePicker
                    className="basic-single css-b62m3t-container"
                    ranges={predefinedBottomRanges}
                    placeholder="Select Date Range"
                    style={{ height: "38px", width: "100%" }}
                    isClearable
                    placement="autoVertical"
                    // onChange= {value => {
                    //   if(value){
                    //     if(value.length > 0) {
                    //       let Startdate = value[0].toISOString().split('T')[0]
                    //       let Enddate = value[1].toISOString().split('T')[0]
                    //       setStartDate(Startdate)
                    //       setEndDate(Enddate)
                    //     }
                    //     else{
                    //       setStartDate("")
                    //       setEndDate("")
                    //     }
                    //   }
                    //   else{
                    //     setStartDate("")
                    //     setEndDate("")
                    //   }
                    // }}
                    onChange={handleDateChange}
                  />
                </div>
              </Col>
              <Col md="2">
                <Select
                  className="basic-single fixed-height"
                  classNamePrefix="select"
                  isSearchable={true}
                  isClearable
                  name="template"
                  // options={[
                  //   { value: "1", label: "Template 1" },
                  //   { value: "2", label: "Template 2" },
                  //   { value: "3", label: "Template 3" },
                  // ]}
                  options={[
                    { value: "", label: "No Template Selected" },
                    ...template,
                  ]}
                  onChange={e => {
                    if (e) {
                      if (e.label) {
                        setTemplate(e.label)
                      }
                      if (e.value === "") {
                        setTemplate("")
                      }
                    } else {
                      setTemplate("")
                    }
                  }}
                  components={{ 
                    ClearIndicator: CustomClearIndicator,
                    DropdownIndicator: !selectedTemplate? DropdownIndicator : null
                  }}
                  placeholder="Select Template"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="table-responsive scrollable table_shadow_div reportdata_table2">
                  <BootstrapTable
                    keyField="id"
                    data={filteredResults}
                    columns={columns2}
                    bootstrap4={true}
                    filter={filterFactory()}
                    noDataIndication={() => {
                      if (resultsLoading === true) {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <LoadingSpinner />
                          </div>
                        )
                      } else {
                        return <div>No Data Found</div>
                      }
                    }}
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
