import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Button, Table, Input,  Modal, ModalFooter,  Container } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import MetaTags from "react-meta-tags"

import { getCountries,getNewOperators } from "store/old/customer/actions";

const ViewCustomer = (props) => {

const dispatch = useDispatch();
const viewdata = props.data.message;
const savedOps = props.data.operators;
const contacts=viewdata.contacts;
const testContacts = viewdata.test_node_contacts;
const savedOpsIDs = savedOps?.map(obj=>obj.operator_id)

useEffect(() => {
    dispatch(getCountries())
    dispatch(getNewOperators())
  
}, [])

const countries = useSelector((state) => state.customer.countries);
const operator = useSelector((state) => state.customer.operators);
const operatorData = operator?.map(obj=>({
    id:obj.id,
    label:obj.operator_customer_label,
    value:obj.operator_customer_label
}))

const selectedOperators = operatorData?.filter((obj)=>savedOpsIDs?.includes(obj.id))


  return (
    <React.Fragment>
       <h5>Customer Data</h5>
       <Row>
            <Col md="5" className="input-dtl-leb">Customer Name</Col>
            <Col md="7" className="output-leb">{viewdata?.name?viewdata?.name:"-- No Name --"}</Col>
       
            {/* <Col md="5" className="input-dtl-leb">Country Name</Col>
            <Col md="7" className="output-leb">{viewdata?.country?viewdata.country:"-- No Country--"}</Col> */}
       
            <Col md="5" className="input-dtl-leb">Operator(s)</Col>
            <Col md="7" className="output-leb">
                {selectedOperators?.map((obj,index)=>{
                        return (
                            <span key={index}>{index==0?"":<span>,</span>}{obj.label}&nbsp;</span>
                            
                        )
                    })
                }
            </Col>
                
            {/* <tr>
            <td className="output-leb">{
                selectedOperators?.map((obj,index)=>{
                    return (
                        <span>{index==0?"":<span>,</span>}{obj.label}&nbsp;</span>
                        
                    )
                })
            }</td>
            </tr> */}

       </Row>
       <br/>
       <Row>
        <h5>Contact Details</h5>
        <div style={{ maxWidth: '800px', margin: 'auto' }}>
        <div className="table-responsive">
            <Table className="table mb-0">
            <thead className="table-light">
                <tr>
                {/* <th>Info</th> */}
                <th>Full Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>IM</th>
                </tr>
            </thead>
            <tbody>
            {contacts?.map((obj,index)=>{
                return (
                    <tr key={index}>
                    {/* <th scope="row">{index+1}</th> */}

                    <td>{obj?.name?obj.name:"-- No Full Name--"}</td>
                    <td>{obj?.email?obj.email:"-- No Email--"}</td>
                    <td>{obj?.phone?obj.phone:"-- No Phone--"}</td>
                    <td style={{ minWidth: '100px' }}>{obj?.im ? obj.im : "-- No IM--"}</td>
                    {/* <td>{viewdata?.contact_fullname?viewdata.contact_fullname:"-- No Full Name--"}</td>
                    <td>{viewdata?.contact_email?viewdata.contact_email:"-- No Email--"}</td>
                    <td>{viewdata?.contact_phone?viewdata.contact_phone:"-- No Phone--"}</td>
                    <td>{viewdata?.contact_im?viewdata.contact_im:"-- No IM--"}</td> */}
                    </tr>
                )})}
            </tbody>

            </Table>
        </div>
        </div>
       </Row>
       <br/>
       <Row>
        <h5>Test Node Details</h5>
        <div style={{ maxWidth: '800px', margin: 'auto' }}>
        <div className="table-responsive">
            <Table className="table mb-0">
            <thead className="table-light">
                <tr>
                {/* <th>Info</th> */}
                <th>Operator</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>IM</th>
                </tr>
            </thead>
            <tbody>
                {testContacts?.map((obj, index) => {
                return (
                    <tr key={index}>
                    {/* <th scope="row">{index + 1}</th> */}
                    <td>
                        {obj?.operator
                        ? selectedOperators.reduce((acc, op) => {
                            if (op.id == obj.operator) {
                                acc = op.label;
                            }
                            return acc;
                            }, "") || "-- No Operator __"
                        : "-- No Operator __"}
                    </td>
                    <td>{obj?.name ? obj.name : "-- No Full Name--"}</td>
                    <td>{obj?.email ? obj.email : "-- No Email--"}</td>
                    <td>{obj?.phone ? obj.phone : "-- No Phone--"}</td>
                    <td style={{ minWidth: '100px' }}>{obj?.im ? obj.im : "-- No IM--"}</td>
                    </tr>
                );
                })}
            </tbody>
            </Table>
        </div>
        </div>
       </Row>
           
    </React.Fragment>
  )
}

export default ViewCustomer
