import React from "react";
import { Container } from "reactstrap";
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardTitle,
  CSpinner,
  Table,
  Button,
  Input
} from "reactstrap"

export default function LoadingSpinner() {
  return (
    <React.Fragment>
        <div style={{height:"300px", width:"100%", alignContent:"center",marginTop:"70px"}}>
          <div className="loaderCont">
          <div className="loader"></div>
          </div>
        </div>
    </React.Fragment>
  );
}