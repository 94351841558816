export const GET_NEW_PARTNERS = "GET_NEW_PARTNERS"
export const GET_NEW_PARTNERS_SUCCESS = "GET_NEW_PARTNERS_SUCCESS"
export const SET_NEW_PARTNERS= "SET_NEW_PARTNERS"
export const GET_SELECTED_PARTNER = "GET_SELECTED_PARTNER"
export const SET_SELECTED_PARTNER= "SET_SELECTED_PARTNER"
export const SET_SELECTED_PARTNER_SUCCESS= "SET_SELECTED_PARTNER_SUCCESS"
export const UPLOAD_PARTNER = "UPLOAD_PARTNER"
export const SET_UPLOAD_PARTNER_SUCCESS = "SET_UPLOAD_PARTNER_SUCCESS"
export const EDIT_PARTNER= "EDIT_PARTNER"
export const SET_EDIT_PARTNER_SUCCESS= "SET_EDIT_PARTNER_SUCCESS"
export const DELETE_PARTNER= "DELETE_PARTNER"
export const SET_DELETE_PARTNER_SUCCESS = "SET_DELETE_PARTNER_SUCCESS"
export const GET_NEW_PAR_COUNTRIES = "GET_NEW_PAR_COUNTRIES"
export const SET_NEW_PAR_COUNTRIES = "SET_NEW_PAR_COUNTRIES"
