import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
    name: 'users',
    initialState: {
        users : [],
        selectedUser: {},
        roles: [],
        customers: [],
        operators: []
    },
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload
        },
        setCurrentUser: (state, action) => {
            state.selectedUser = action.payload
        },
        setRoles: (state, action) => {
            state.roles = action.payload
        },
        setCustomers: (state, action) => {
            state.customers = action.payload
        },
        setOperators: (state, action) => {
            state.operators = action.payload
        }
    },
})

export const {
    setUsers,
    setRoles,
    setCurrentUser,
    setCustomers,
    setOperators
} = userSlice.actions
export default userSlice.reducer