import React, { Component, useEffect, useRef, useState } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Card, CardBody, Container, Button } from "reactstrap"
import Select from "react-select"
import Breadcrumbs from "../../Common/Breadcrumb"
import moment from "moment"
import exchangeCurrencies from "constants/currencies"
import DeliveredTab from "./EditTables/DeliveredTab"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import ReportTable from "../EditReports/ReportTable"
import FilteredReports from "./filteredTable"
import { getCustomers, getPartners, getTemplates, getOperators, searchUploadedBatches } from "store/old/source-upload/actions"
import { getNewCustomers } from "store/old/customer/actions"
import { getNewOperators } from "store/old/operator/actions"
import { useDispatch, useSelector } from "react-redux"
import { generateReport, searchReports, setGenerating, setSelectedReport } from "store/old/reports/actions"
const { exchangeRates, currencies } = require("exchange-rates-api")
import jwt from "jsonwebtoken"
import { isArray } from "lodash"


export default function ViewReports() {
  const [date, setDate] = useState("")
  const [foreignExchangeDate, setForeignExchangeDate] = useState("")
  const [selectedCurrency, setSelectCurrency] = useState("")
  const [currencyRate, setCurrencyRate] = useState("")
  const [errorExchange, setErrorExchange] = useState("")
  const dispatch = useDispatch();
  const [selectedTemplate, setTemplate] = useState("")
  const [selectedOperator, setOperator] = useState("")
  const [loading, setLoading] = useState(false)
  const [selectedCustomer, setCustomer] = useState("")
  const [selectedDate, setTestDate] = useState("")
  const [sizePerPage, setSizePerPage] = useState(10)
  const selectedRows = useSelector(state => state.Reports.selectedReportToGenerate);
  const searchedBatches = useSelector((state) => state.SourceUpload.searchedBatches);
  const getSelectedSuccess = useSelector((state) => state.Reports.getSelectedSuccess);


  const decodeToken = token => {
    try {
      const decodedToken = jwt.decode(token)
      return decodedToken
    } catch (error) {
      console.log("Error decoding token:", error)
      return null
    }
  }

  const token = localStorage.getItem("authUser")
  const decodedToken = decodeToken(JSON.parse(token))
  let role = decodedToken.role

  useEffect(() => {

    dispatch(getNewCustomers())
    dispatch(getNewOperators())
    // dispatch(getPartners())
    dispatch(getTemplates())

  }, [])
  const customertest = useSelector((state)=>state.customer.customers);
  const customer = customertest.map(obj=>{
    return {id:obj.id,label:obj.name,value:obj.name}
  })
  const operatortest = useSelector((state)=>state.operator.operators);
  const operator = operatortest.map(obj=>{
    return {id:obj.id,
      label:obj.country+" "+obj.operator_name, 
      value:obj.country+" "+obj.operator_name}
  })
  operator.sort((a, b) => {
    const countryA = a.label.split(' ')[0];
    const countryB = b.label.split(' ')[0];
  
    // Compare country names
    if (countryA < countryB) {
      return -1;
    } else if (countryA > countryB) {
      return 1;
    } else {
      return 0;
    }
  });
  const reports = useSelector((state) => state.Reports.reports)
  // const selectedReports = useSelector((state) => state.Reports.selectedReport)
  const selectedReports = useSelector((state) => state.Reports.selectedReport)
  // const getSelectedSuccess = useSelector((state) => state.Reports.getSelectedSuccess);
  // const displaySelTables = selectedReports.length == 0 ?{display:"none"}:{}
  // let displaySelTables = selectedReports.length==0 && loading==false?{display:"none"}:{}
  const [displaySelTables, setDisplaySelTables] = useState({display:"none"});


  // console.log(displaySelTables);
  // console.log(reports);
  // console.log(customer);
 
  const template = useSelector((state) => state.SourceUpload.templates);
  // const partners = useSelector((state) => state.SourceUpload.partners);

  //Scroll bar
  const scrollto = ()=>{
    window.scrollTo({behavior:'smooth',top:myRef.current.offsetTop});
  };
  

  const searchBatches = () => {
    // console.log('triggered');
    
    // dispatch(setSelectedReport([]))
    dispatch(searchUploadedBatches({
      customer: selectedCustomer,
      operator: selectedOperator,
      testDate: selectedDate,
      template: selectedTemplate,
      size: sizePerPage
    }
    ))
  }

  const searchReport = () => {
    
    dispatch(searchReports({
      customer: selectedCustomer,
      operator: selectedOperator,
      template: selectedTemplate,
      date: selectedDate
    }))
  }
  const currency = []

  for (var key in exchangeCurrencies) {
    let label = key
    let value = exchangeCurrencies[key]

    currency.push({
      label: label,
      value: value,
    })
  }
  const getExchangeRate = async () => {
    if (selectedCurrency != "" && foreignExchangeDate != "") {
      try {
        let resp = await exchangeRates()
          .setApiBaseUrl("https://api.exchangerate.host")
          .at(foreignExchangeDate)
          .base(currencies.USD)
          .symbols(selectedCurrency)
          .fetch()
        setCurrencyRate(resp)
        setErrorExchange("")
      } catch (error) {
        setCurrencyRate("")
        setErrorExchange("Sorry, Please enter valid data")
      }
    }
  }

  useEffect(() => {
    var now = new Date()
    var dateString = moment(now).format("YYYY-MM-DD")
    setDate(dateString)
    setForeignExchangeDate(dateString)
  }, [])

  useEffect(() => {
    getExchangeRate()
  }, [selectedCurrency, foreignExchangeDate])

  useEffect(()=>{
    if(selectedReports.length>0){
      window.scrollTo({behavior:'smooth',top:myRef.current.offsetTop});
      setLoader(false);
    }
    // console.log(selectedReports, getSelectedSuccess);
    
    if(isArray(selectedReports) && (getSelectedSuccess || !loading)){
      // console.log('triggered');
      
      // console.log(selectedReports);
      setDisplaySelTables({display:"none"})
    }
    else{
      if(loading){
        setDisplaySelTables({})
      }
    }
    
    
  },[selectedReports]);

  // useEffect(() => {
  //   console.log(displaySelTables);
    
  // }, [displaySelTables])
  

  useEffect(() => {
    // dispatch(setSelectedReport([]))'
    // console.log('triggered');
    
    searchBatches();
  }, [selectedCustomer, selectedOperator, selectedDate, selectedTemplate, sizePerPage])
  let myRef = useRef();

  const genReport = () => {
    // console.log(selectedRows.length)
    dispatch(setGenerating(true));
    if (selectedRows.length > 1) {
      for (let i = 0; i < selectedRows.length; i++) {
        const element = selectedRows[i];
        // console.log(element.id)
        dispatch(generateReport(
          {
            batch_id: element.id,
            date: date,
            currency_type: selectedCurrency
          }))
      }

    } else if (selectedRows.length == 1) {
      // console.log(seletedBatches)
      dispatch(generateReport(
        {
          "batch_id": selectedRows[0].id,
          "date": date,
          "currency_type": selectedCurrency
        }
      ))
    }
  }
  
  return (
    <React.Fragment>
      <div>
        <MetaTags>
        {[4].includes(role) ? (
          <title>Reports</title>
        ):(
          <title>Report Edit</title>
        )}
        </MetaTags>
        <div className="container-fluid">
          {[4].includes(role) ? (
          <Breadcrumbs title="Browse Reports" breadcrumbItem="Reports " />
          ):(
          <Breadcrumbs title="Edit Reports" breadcrumbItem="Reports " />
          )}
          <Row>
            <Col className="col-12">
              <Card className="bg-white">
                <CardBody>
                  <Row className="mb-2" style={{ display:"none" }}>
                    <Col md="12" sm="12">
                      <Row>
                        <Col md="2">
                          <h5 className="col-form-label fs-5">Filters</h5>
                        </Col>
                        <Col md="2">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            // defaultValue=/* {customer[0]} */
                            isSearchable={true}
                            name="customer"
                            options={[
                              { value: '', label: 'No Customer Selected' },
                              ...customer
                            ]}
                            onChange={e => {
                              // console.log(e.id);
                              // dispatch(setSelectedReport([]))
                              if(e.id){
                              setCustomer(e.id)
                              dispatch(getNewOperators({
                                customer_id: e.id
                              }))
                              }
                              if(e.value === ''){
                              setCustomer('')
                              dispatch(getNewOperators())
                              }
                            }}
                            placeholder="Customer"
                          />
                        </Col>
                        <Col md="2">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            // defaultValue={operator[0]}
                            isSearchable={true}
                            name="operator"
                            placeholder="Operator"
                            options={[
                              { value: '', label: 'No Operator Selected' },
                              ...operator
                            ]}
                            onChange={e => {
                              if(e.id){
                              setOperator(e.id)
                              }
                              if(e.value === ''){
                              setOperator('')
                              }
                            }}
                          />
                        </Col>
                        <Col md="2">
                          <input
                            className="form-control"
                            type="date"
                            id="testDate"
                            value={date}
                            onChange={e => setTestDate(e.target.value)}
                            style={{ height: "38px" }}

                          />
                        </Col>
                        <Col md="2">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            // defaultValue={template[0]}
                            isSearchable={true}
                            name="template"
                            options={template}
                            placeholder="Template"
                            onChange={(e) => {
                              setTemplate(e.id)
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>

                  </Row>
                  <Col md="12" sm="12">
                  <FilteredReports setLoading={setLoading} clickFunction={scrollto} searchedReports = {reports} searchedBatches={searchedBatches} loadRows={setSizePerPage} />
                    <ReportTable/>
                  </Col>

                  <Row className="mb-2" style={{ paddingBottom: "20px" }}>

                    {currencyRate != "" ? (
                      <p>
                        {currencyRate} +{selectedCurrency} +
                        {foreignExchangeDate}
                      </p>
                    ) : errorExchange != "" ? (
                      <p>{errorExchange}</p>
                    ) : null}
                  </Row>
                  <Row>
                      {/* <Col md="3">
                      <Button
                        disabled={selectedCurrency == "" ? true : false}
                        className="btn btn-custom md-position-absolute ms-auto p-2 bd-highlight"
                        onClick={genReport}
                      >
                        Generate Report
                      </Button>
                    </Col> */}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <div>



      <div style={displaySelTables} ref={myRef} ><DeliveredTab setLoading={setLoading} deliveredReports={selectedReports}/></div>

      


      {/* <div style={displaySelTables}><NotDelivered notdeliveredReports={selectedReports}/></div> */}
        
        
      </div>


    </React.Fragment>
  )
}
