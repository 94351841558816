import { call, put, takeEvery ,takeLatest} from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../../helpers/firebase_helper"
// import {
//   postFakeLogin,
//   postJwtLogin,
//   postRealLoginn,
// } from "../../../helpers/fakebackend_helper"
import { post } from "../../../../helpers/api_helper"

import { _LOGIN,POST_FAKE_LOGIN } from "../../../../helpers/url_helper"
import axios from "axios"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  // console.log(process.env.REACT_APP_DEFAULTAUTH);
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      let fetchResponse = yield axios
        .post(
          _LOGIN,
          {
            email: user.email,
            password: user.password,
          },
          { headers: { "Content-Type": "application/json" } }
        )

      // console.log("fetchResponse", fetchResponse.data)

      // if (fetchResponse.data?.Error != undefined) {
      //   yield put(apiError(fetchResponse.data?.Error))

      // } else {
      //   console.log("fetchResponse",fetchResponse);
      //   let accessToken = fetchResponse.data.accessToken;
      //   if (accessToken == "") {
      //     yield put(apiError("Please Check Your Email and Password"))
      //   } else {
      //     localStorage.setItem(
      //       "authUser",
      //       JSON.stringify(fetchResponse.data.accessToken)
      //     )
      //     // sessionStorage.setItem("authUser", fetchResponse.accessToken)
      //     yield put(loginSuccess(fetchResponse.data))
      //     // window.location.href = "/source-upload"
      //   }
      // }
      if(fetchResponse.data?.message == "Email or password does not match!" ){
        yield put(apiError(fetchResponse.data?.message))
      }else{
        let accessToken = fetchResponse.data.accessToken;
        if (accessToken == "") {
          yield put(apiError("Please Check Your Email and Password"))
        } else {
          localStorage.setItem(
            "authUser",
            JSON.stringify(fetchResponse.data.accessToken)
          )
          // sessionStorage.setItem("authUser", fetchResponse.accessToken)
          yield put(loginSuccess(true))
          // window.location.href = "/source-upload"
        }
      }

    }
    // history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error))
  }
}

async function loginAuth(email, password) {
  const res = await post("/auth/signIn", { email: email, password: password })
    .then(function (response) {
      if (response.token) {
        return response.token
      }
      // console.log(response.token);
    })
    .catch(function (error) {
      // console.log(error.response.data.message);

      return error.response.data.message
    })
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    localStorage.removeItem("id")
    localStorage.removeItem("currency")
    localStorage.removeItem("username")
    localStorage.removeItem("name")
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    // history.push("/login")
    window.location.href = "/login";
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
