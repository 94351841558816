import * as url from "../../../helpers/url_helper"

export async function fetchNewPartnes() {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._GET_NEW_PARTNERS, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }

}
export async function fetchNewCountries(id) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._GET_NEW_PAR_COUNTRIES, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchDeletePartner(id) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._DELETE_PARTNER+id, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchselectedPartner(id) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._SELECTED_PARTNER+id, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}

export async function dispatchNewPartner(data) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            // "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        }
        
        const response = await fetch(url._UPLOAD_PARTNER, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}

export async function dispatchEditPartner(data,id) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            // "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        }
        
        const response = await fetch(url._EDIT_PARTNER, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}

