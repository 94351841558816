import { createSlice } from "@reduxjs/toolkit"

const customerPortalSlice = createSlice({
  name: "customerPortal",
  initialState: {
    customers: [],
    operators: [],
    kpiData: [],
    deliveryChartData: [],
    mtrChartData: [],
    pricingChartData: [],
    timeChartData: [],
    kpiDates: [],
    template_options: []
  },
  reducers: {
    setCustomers: (state, action) => {
      state.customers = action.payload
    },
    setOperators: (state, action) => {
      state.operators = action.payload
    },
    setKPIData: (state, action) => {
      state.kpiData = action.payload
    },
    setDeliveryChartData: (state, action) => {
      state.deliveryChartData = action.payload
    },
    setMTRChartData: (state, action) => {
      state.mtrChartData = action.payload
    },
    setPricingChartData: (state, action) => {
      state.pricingChartData = action.payload
    },
    setTimeChartData: (state, action) => {
      state.timeChartData = action.payload
    },
    setKpiDates: (state, action) => {
      state.kpiDates = action.payload
    },
    setTemplateOptions: (state, action) => {
      state.template_options = action.payload
    }
  },
})

export const {
  setCustomers,
  setOperators,
  setKPIData,
  setDeliveryChartData,
  setMTRChartData,
  setPricingChartData,
  setTimeChartData,
  setKpiDates,
  setTemplateOptions
} = customerPortalSlice.actions
export default customerPortalSlice.reducer
