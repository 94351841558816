import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardTitle,
  Container,
  Table,
  Button,
  Input
} from "reactstrap";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import Select from "react-select";
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import { useDispatch, useSelector } from "react-redux";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'



const contacts = (props) => {

  const [contactName, setcontactName] = useState([{id : 1, data: ""}])
  const [contactEmail, setcontactEmail] = useState([{id : 1, data: ""}])
  const [contactPhone, setcontactPhone] = useState([{id : 1, data: ""}])
  const [contactIM, setcontactIM] = useState([{id : 1, data: ""}])
  const [formRows, setFormRows] = useState([{ id: 1 }]);
  const mainState = props.main_state;
  const [valueName, setValueName] = useState([])
  const [valueEmail, setValueEmail] = useState([])
  const [valuePhone, setValuePhone] = useState([])
  const [valueIM, setValueIM] = useState([])


  const onAddFormRow = () => {
    const modifiedRows = [...formRows];
    var temp_id = modifiedRows.length+1;
    var data = {
      key :  temp_id
    }
    modifiedRows.push({ id: modifiedRows.length + 1 });
    // mainState.push(data);
    setFormRows(modifiedRows);
  };

  const onDeleteFormRow = id_1 => {
    var id = id_1
    if (id != 1 && id >1) {
      var previd = 1;
      var modifiedRows = [...formRows];
      var i = modifiedRows.findIndex(obj=>obj["id"]==id)
      modifiedRows = modifiedRows.filter(x => x["id"] != id);
      while (i < modifiedRows.length) {
        modifiedRows[i].id--;
          i++;
      }
      setFormRows(modifiedRows);
    }
    if(id == 1 && formRows.length>1){
      id = id+1;
      var modifiedRows = [...formRows];
      var i = modifiedRows.findIndex(obj=>obj["id"]==id)
      modifiedRows = modifiedRows.filter(x => x["id"] != id);
      while (i < modifiedRows.length) {
        modifiedRows[i].id--;
          i++;
      }
      setFormRows(modifiedRows);
    }
    var i = mainState.findIndex(obj=>obj.key == id_1);
    mainState.splice(i, 1);
    while (i < mainState.length) {
      mainState[i].key--;
        i++;
    }
  };
  const deletAll = (id) =>{
    if((valueName?.filter(obj=>obj.key==id).map(obj=>obj.data))[0]!=null){
      deleteName(id);
    };
    if((valueEmail?.filter(obj=>obj.key==id).map(obj=>obj.data))[0]!=null){
      deleteEmail(id);
    };
    if((valuePhone?.filter(obj=>obj.key==id).map(obj=>obj.data))[0]!=null){
      deletePhone(id);
    };
    if((valueIM?.filter(obj=>obj.key==id).map(obj=>obj.data))[0]!=null){
      deleteIM(id);
    };
  }
  const setName = (e,id)=>{
    var temp = [...valueName];
    if(e!=null && id!=null){
      //console.log(projectLongitude);

      //console.log("KEYS"+mainState.map(obj=>obj.key));
      var tempid = id;
      var templongt = e;
  
      var data = {
        key :tempid,
        data : templongt,
      }
      if(temp.map(obj=>obj.key).includes(data.key) == false){
        temp.push(data);
      }
      else if(temp.map(obj=>obj.key).includes(data.key) == true){
        var objIndex = temp.findIndex(obj=>obj.key == data.key)

        temp[objIndex].data = data.data;
        
      }
    }
    setValueName(temp) 
  }
  const deleteName= (id)=>{
    var temp = [...valueName];
    var i = temp.findIndex(obj=>obj.key == id);
    temp.splice(i, 1);
    while (i < temp.length) {
      temp[i].key--;
        i++;
    }
    setValueName(temp)
  }
  const setEmail = (e,id)=>{
    var temp = [...valueEmail];
    if(e!=null && id!=null){
      //console.log(projectLongitude);

      //console.log("KEYS"+mainState.map(obj=>obj.key));
      var tempid = id;
      var templongt = e;
  
      var data = {
        key :tempid,
        data : templongt,
      }
      if(temp.map(obj=>obj.key).includes(data.key) == false){
        temp.push(data);
      }
      else if(temp.map(obj=>obj.key).includes(data.key) == true){
        var objIndex = temp.findIndex(obj=>obj.key == data.key)

        temp[objIndex].data = data.data;
        
      }
      
    }
    setValueEmail(temp) 
  }
  const deleteEmail= (id)=>{
    var temp = [...valueEmail];
    var i = temp.findIndex(obj=>obj.key == id);
    temp.splice(i, 1);
    while (i < temp.length) {
      temp[i].key--;
        i++;
    }
    setValueEmail(temp)
  }
  const setPhone = (e,id)=>{
    var temp = [...valuePhone];
    if(e!=null && id!=null){
      //console.log(projectLongitude);

      //console.log("KEYS"+mainState.map(obj=>obj.key));
      var tempid = id;
      var templongt = e;
  
      var data = {
        key :tempid,
        data : templongt,
      }
      if(temp.map(obj=>obj.key).includes(data.key) == false){
        temp.push(data);
      }
      else if(temp.map(obj=>obj.key).includes(data.key) == true){
        var objIndex = temp.findIndex(obj=>obj.key == data.key)

        temp[objIndex].data = data.data;
        
      }
    }
    setValuePhone(temp) 
  }
  const deletePhone= (id)=>{
    var temp = [...valuePhone];
    var i = temp.findIndex(obj=>obj.key == id);
    temp.splice(i, 1);
    while (i < temp.length) {
      temp[i].key--;
        i++;
    }
    setValuePhone(temp)
  }
  const setIM = (e,id)=>{
    var temp = [...valueIM];
    if(e!=null && id!=null){
      //console.log(projectLongitude);

      //console.log("KEYS"+mainState.map(obj=>obj.key));
      var tempid = id;
      var templongt = e;
  
      var data = {
        key :tempid,
        data : templongt,
      }
      if(temp.map(obj=>obj.key).includes(data.key) == false){
        temp.push(data);
      }
      else if(temp.map(obj=>obj.key).includes(data.key) == true){
        var objIndex = temp.findIndex(obj=>obj.key == data.key)

        temp[objIndex].data = data.data;
        
      }
    }
    setValueIM(temp) 
  }
  const deleteIM= (id)=>{
    var temp = [...valueIM];
    var i = temp.findIndex(obj=>obj.key == id);
    temp.splice(i, 1);
    while (i < temp.length) {
      temp[i].key--;
        i++;
    }
    setValueIM(temp)
  }

  useEffect(() => {
    //console.log("hello");
    if(contactName["id"]!=null && contactName["data"]!=null){
      var tempid = contactName["id"];
      var tempcountry = contactName["data"];
  
      var data = {
        key :tempid,
        name : contactName["data"],
      }
      if(mainState.map(obj=>obj.key).includes(data.key) == false){
        mainState.push(data);
      }
      else if(mainState.map(obj=>obj.key).includes(data.key) == true){
        var objIndex = mainState.findIndex(obj=>obj.key == data.key)

          mainState[objIndex].name = data.name;
        
      }
    } 

  }, [contactName]);

  useEffect(() => {
    //console.log("hello");
    if(contactEmail["id"]!=null && contactEmail["data"]!=null){
      var tempid = contactEmail["id"];
      var tempcountry = contactEmail["data"];
  
      var data = {
        key :tempid,
        email : contactEmail["data"],
      }
      if(mainState.map(obj=>obj.key).includes(data.key) == false){
        mainState.push(data);
      }
      else if(mainState.map(obj=>obj.key).includes(data.key) == true){
        var objIndex = mainState.findIndex(obj=>obj.key == data.key)

          mainState[objIndex].email = data.email;
        
      }
    } 

  }, [contactEmail]);

  useEffect(() => {
    //console.log("hello");
    if(contactPhone["id"]!=null && contactPhone["data"]!=null){
      var tempid = contactPhone["id"];
      var tempcountry = contactPhone["data"];
  
      var data = {
        key :tempid,
        phone : contactPhone["data"],
      }
      if(mainState.map(obj=>obj.key).includes(data.key) == false){
        mainState.push(data);
      }
      else if(mainState.map(obj=>obj.key).includes(data.key) == true){
        var objIndex = mainState.findIndex(obj=>obj.key == data.key)

          mainState[objIndex].phone = data.phone;
        
      }
    } 

  }, [contactPhone]);

  useEffect(() => {
    //console.log("hello");
    if(contactIM["id"]!=null && contactIM["data"]!=null){
      var tempid = contactIM["id"];
      var tempcountry = contactIM["data"];
  
      var data = {
        key :tempid,
        im : contactIM["data"],
      }
      if(mainState.map(obj=>obj.key).includes(data.key) == false){
        mainState.push(data);
      }
      else if(mainState.map(obj=>obj.key).includes(data.key) == true){
        var objIndex = mainState.findIndex(obj=>obj.key == data.key)

          mainState[objIndex].im = data.im;
        
      }
    } 

  }, [contactIM]);

  return (
    <React.Fragment>

       
                <div className="bg-white ">
                <div className="container-fluid">
                  <Row>
                  {(formRows ).map((formRow, key) => (
                    <Col md="12" key={key}>
                      <div className="zero-margin">
                        <Row >
                          {/* <label htmlFor="name">Contact : {formRow.id}</label> */}

                          <Col md="3" className="d-flex align-items-center">
                            <label className="lable-customer">Full Name</label>
                          </Col>
                          <Col md="3">
                            <Input
                              type="name"
                              className="customer-input"
                              placeholder="Enter the Full Name"
                              value={valueName?.filter(obj=>obj.key==formRow.id).map(obj=>obj.data)}
                              onChange={(e)=>{
                                setName(e.target.value,formRow.id)
                                setcontactName({id:formRow.id,data:e.target.value})
                              }}
                            />

                          </Col>

                          <Col md="3" className="d-flex align-items-center">
                            <label className="lable-customer">Email</label>
                          </Col>
                          <Col md="3">
                            <Input
                            type="name"
                            className="customer-input"
                            placeholder="Enter the Email Add."
                            value={valueEmail?.filter(obj=>obj.key==formRow.id).map(obj=>obj.data)}
                            onChange={(e)=>{
                              setEmail(e.target.value,formRow.id),
                              setcontactEmail({id:formRow.id,data:e.target.value})
                            }}
                          />
                          </Col>

                          <Col md="3" className="d-flex align-items-center">
                            <label className="lable-customer">Phone</label>
                          </Col>
                          <Col md="3">
                            {/* <Input
                            type="name"
                            className="customer-input"
                            placeholder="Enter the Phone No."
                            value={valuePhone?.filter(obj=>obj.key==formRow.id).map(obj=>obj.data)}
                            onChange = {(e)=>{
                              setPhone(e.target.value,formRow.id),
                              setcontactPhone({id:formRow.id,data:e.target.value})
                            }}
                          /> */}
                              <PhoneInput
                                  className="customer-phone-input"
                                  placeholder="Enter phone number"
                                  value={valuePhone?.filter(obj=>obj.key==formRow.id).map(obj=>obj.data).toString()}
                                  onChange = {(e)=>{
                                    setPhone(e,formRow.id),
                                    setcontactPhone({id:formRow.id,data:e})
                                  }}
                                  />
                          </Col>
                          <Col md="3" className="d-flex align-items-center">
                            <label className="lable-customer">IM</label>
                          </Col>
                          <Col md="3">
                            <Input
                            type="name"
                            className="customer-input"
                            placeholder="IM"
                            value={valueIM?.filter(obj=>obj.key==formRow.id).map(obj=>obj.data)}
                            onChange = {(e)=>{
                              setIM(e.target.value,formRow.id),
                              setcontactIM({id:formRow.id,data:e.target.value})
                            }}
                          />
                          </Col>
                          <Col>
                            <Row className="btn-colection-right">
                              <Col md="2">
                                  <div className="d-grid">
                                            <Input
                                              type="button"
                                              className="btn btn-primary"
                                              id="delete2"
                                              value="Delete"
                                              onClick={() => {
                                                onDeleteFormRow(formRow.id);
                                                deletAll(formRow.id);
                                              }}
                                            />
                                    </div>
                              </Col>
                  
                          </Row>
                          </Col>
                          
                        

                        </Row>
                      </div>
                      
                    </Col>
                    
                    
                  ))}

                  </Row>
                  
                  
                  <Row className="btn-colection-right">
                    <Col md="2" >
                    <div className="d-grid">
                      <Button
                          onClick={() => {
                          //   handleAddRowNestedismsc();
                          onAddFormRow();                            }}
                          className="btn btn-primary add-smsc "
                        >
                          Add Contact
                      </Button>
                      </div>
                    </Col>
                  </Row>
                    
                </div>
              </div>

                   {/* <button onClick={() => onAddFormRow()}className="responce-btn" id="img-btn" type="button"><i className="bx bx-add-to-queue"></i> Add New </button> */}
                   
    </React.Fragment>
  )
}


export default contacts 
