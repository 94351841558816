import * as url from "../../../helpers/url_helper"


export async function fetchDownloadReports(data) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            // "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        }
        const response = await fetch(url._DOWNLOAD_REPORT, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchFilterReports(data) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            // "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        }
        const response = await fetch(url.FILTER_REPORTS, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}


export async function fetchReports(payload) {
    try {
        let {id,template} = payload
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url.GET_REPORTS+`${id}/${template}`, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function deleteReports(id) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url.DELETE_REPORTS+id, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}

export async function fetchSearchReports(payload) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url.SEARCH_REPORTS+`?customer=${payload.customer}&template=${payload.template}&operator=${payload.operator}&history=${payload.history}&pagelimit=${payload.limit}`, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchGenerateReport(data) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            // "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        }
        
        const response = await fetch(url.GENERATE_REPORT, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function updateReportData(data) {
    try {
       // const JSONdata = JSON.stringify(data);

        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            // "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            // "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        }
        const response = await fetch(url.UPDATE_REPORTS, {
            method: "POST",
            headers: headers,
            body: data,
        })

        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function updateKPIData(data) {
    try {
       // const JSONdata = JSON.stringify(data);

        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            // "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            // "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        }
        const response = await fetch(url.UPDATE_KPI, {
            method: "POST",
            headers: headers,
            body: data,
        })

        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function regenKPIData(data) {
    try {
       // const JSONdata = JSON.stringify(data);

        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            // "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            // "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        }
        const response = await fetch(url.REGEN_KPI, {
            method: "POST",
            headers: headers,
            body: data,
        })

        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}


// export async function fetchCustomers() {
//     try {
//         const response = await fetch(url._GET_CUSTOMERS, {
//             method: "GET"
//         })
//         return await response.json()
//     } catch (err) {
//         return console.log(err)
//     }
// }

// export async function fetchOperators() {
//     try {
//         const response = await fetch(url._GET_OPERATORS, {
//             method: "GET"
//         })
//         return await response.json()
//     } catch (err) {
//         return console.log(err)
//     }
// }

// export async function uploadFiles(data) {
//     try {
//         const headers = {
//             // "Content-Type": "multipart/form-data",
//             "Access-Control-Allow-Origin": "*",
//             "Access-Control-Allow-Credentials": "true",
//             // "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
//         }
//         const response = await fetch(url.UPLOAD_SOURCE_FILES, {
//             method: "POST",
//             headers: headers,
//             body: data,
//         })
//         return await response.json()
//     } catch (err) {
//         return console.log(err)
//     }
// }