import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Button, Table, Input, ModalBody,  Modal, ModalFooter,  Container } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import MetaTags from "react-meta-tags"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { getNewPartners, setNewPartners,getNewCountry, getNewPartnersSuccess, getSelectedPartner, setSelectedPartner, setSelectedPartnerSuccess , uploadPartner, setuploadPartnerSuccess, editPartner, setEditPartnerSuccess, deletePartner, setDeletePartnerSuccess } from "store/old/partner/actions"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AddPartner from "components/Partners/AddNew"
import EditPartner from "components/Partners/Edit"
import ViewPartner from "components/Partners/View"
import Swal from "sweetalert2"
import "toastr/build/toastr.min.css";
import store from "../../store/index";
import LoadingSpinner from "components/Reports/EditReports/EditTables/loader"
import jwt from "jsonwebtoken"
// import "./datatables.scss"
import ReactPaginate from "react-paginate"


const Partners = (props) => {
  const decodeToken = token => {
    try {
      const decodedToken = jwt.decode(token)
      return decodedToken
    } catch (error) {
      console.log("Error decoding token:", error)
      return null
    }
  }

  const token = localStorage.getItem("authUser")
  const decodedToken = decodeToken(JSON.parse(token))
  let role = decodedToken.role


  const dispatch = useDispatch();
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  useEffect(() => {
    dispatch(getNewPartnersSuccess(false))
    dispatch(setNewPartners([]))
    dispatch(getNewPartners())
  }, []);

  const partnerData = useSelector((state)=>state.partner.partners);
  const selectedPartnerdata = useSelector((state)=>state.partner.selectedPartner);
  const getSuccess = useSelector((state)=>state.partner.partnergetsuccess);

  const [currentPage, setCurrentPage] = useState(0)
  const itemsPerPage = 10
  const offset = currentPage * itemsPerPage
  const [filteredChannels, setFilteredChannels] = useState(partnerData)
  const currentPageItems = filteredChannels?.slice(offset, offset + itemsPerPage)
  const [searchTerm, setSearchTerm] = useState("")
  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    setFilteredChannels(partnerData)
  }, [partnerData])

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredChannels(partnerData)
    } else {
      setFilteredChannels(
        partnerData.filter(item => {
          let term = searchText.toLowerCase()
          return item.partner_name.toLowerCase().includes(term)
        })
      )
      setCurrentPage(0)
    }
  }, [searchText])

  const [modal_view_screen, setmodal_view_screen] = useState(false)
  const [modal_add_screen, setmodal_add_screen] = useState(false)
  const [modal_edit_screen, setmodal_edit_screen] = useState(false)

  const [add_partnerName,set_add_partnerName]=useState("")
  const [add_partnerTemplate,set_add_partnerTemplate]=useState("")

  const [edit_partnerName,set_edit_partnerName]=useState("")
  const [edit_partnerTemplate,set_edit_partnerTemplate]=useState("")
  
  function tog_view_screen() {
    setmodal_view_screen(!modal_view_screen)
    removeBodyCss()
  }
  function tog_add_screen() {
    setmodal_add_screen(!modal_add_screen)
    removeBodyCss()
  }
  function tog_edit_screen() {
    setmodal_edit_screen(!modal_edit_screen)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  async function savePartnerData() {
    if (add_partnerName!="" && add_partnerTemplate!="") {
      var data = {
        partner_name:add_partnerName,
        template_id:add_partnerTemplate,
      }
      // console.log(data);
      dispatch(uploadPartner(data));
  
      let uploadPromise = new Promise(async(resolve,reject)=>{
        store.subscribe(()=>{
          const uploadSuccess = store.getState();
          const status = uploadSuccess.partner.uploadsuccess;
          if(status == true){
            // console.log(status);
            resolve("Done")
          }
        })
      })
      await uploadPromise.then(async(msg)=>{
        Toast.fire({
          icon: 'success',
          title: 'Saved Successfully!'
        })
        dispatch(setuploadPartnerSuccess(false))
        setTimeout(() => {
        setmodal_add_screen(false)
        clearAddStates()
        dispatch(getNewPartnersSuccess(false))
        dispatch(setNewPartners([]))
        dispatch(getNewPartners())
        }, 1500);
      })
    }
    else{
      Toast.fire({
        icon: 'error',
        title: 'Please Fill All Fields'
      })
    }


  }
  async function deletePartnerData(id){
    Swal.fire({
      title: 'Do you really want to Delete the record?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Delete',
      denyButtonText: `Don't Delete`,
    }).then(async(result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        dispatch(deletePartner(id))
        let deletePromise = new Promise(async(resolve,reject)=>{
          store.subscribe(()=>{
            const deleteSuccess = store.getState();
            const status = deleteSuccess.partner.deletesuccess;
            if(status == true){
              // console.log(status);
              resolve("Done")
            }
          })
        })
        await deletePromise.then(async(msg)=>{
          Toast.fire({
            icon: 'success',
            title: 'Deleted Successfully!'
          })
          dispatch(setDeletePartnerSuccess(false))
          setTimeout(() => {
            dispatch(getNewPartnersSuccess(false))
            dispatch(setNewPartners([]))
            dispatch(getNewPartners())
            setCurrentPage(0)
          }, 1500);
        })

      } else if (result.isDenied) {
        Swal.fire('Delete Cancelled', '', 'info')
      }
    })
  }
  async function savePartnerEdit(){
    if(selectedPartnerdata.message.partner_name != "" && selectedPartnerdata.message.template_id != ""){
      const edit_id = selectedPartner_editdata.id
      // console.log(edit_id);
  
      const editData = selectedPartnerdata;
      var data = {
        partner_data : editData,
      }
      // console.log(data);
      dispatch(editPartner(data));
  
      let editPromise = new Promise(async(resolve,reject)=>{
        store.subscribe(()=>{
          const editSuccess = store.getState();
          const status = editSuccess.partner.editsuccess;
          if(status == true){
            // console.log(status);
            resolve("Done")
          }
        })
      })
      await editPromise.then(async(msg)=>{
        Toast.fire({
          icon: 'success',
          title: 'Edits Saved Successfully!'
        })
        dispatch(setEditPartnerSuccess(false))
        setTimeout(() => {
        setmodal_edit_screen(false)
        dispatch(getNewPartnersSuccess(false))
        dispatch(setNewPartners([]))
        dispatch(getNewPartners())
        }, 1000);
      })
    }
    else{
      Toast.fire({
        icon: 'error',
        title: 'Please Fill All Fields'
      })
    }

  }

  function clearAddStates(){
    set_add_partnerName("")
    set_add_partnerTemplate("")
  }

  function clearEditStates(){
    set_edit_partnerName("")
    set_edit_partnerTemplate("")
  }


  // Table Data
  const productData = [
    {
      id: 1,
      partner_name: "Dialog",
      country: "Sri Lanka",
      email: "LKR",
      customer:"Axiata"
    },

    {
      id: 2,
      partner_name: "Dialog",
      country: "Sri Lanka",
      email: "LKR",
      customer:"Axiata"
    },

    {
      id: 3,
      partner_name: "Dialog",
      country: "Sri Lanka",
      email: "LKR",
      customer:"Axiata"
    },

    {
      id: 4,
      partner_name: "Dialog",
      country: "Sri Lanka",
      email: "LKR",
      customer:"Axiata"
    },

    {
      id: 5,
      partner_name: "Dialog",
      country: "Sri Lanka",
      email: "LKR",
      customer:"Axiata"
    },

    {
      id: 6,
      partner_name: "Dialog",
      country: "Sri Lanka",
      email: "LKR",
      customer:"Axiata"
    },
  ]
  const columns = [
    {
      dataField: "partner_name",
      text: " Partner Name",
      sort: true,
    },
    {
      dataField: "template_id",
      text: "Template",
      sort: true,
      formatter : function(value,row,index) {
        let label = ""
        // console.log(value);
        if(value==1){
          label = "Horisen"
        }
        else if(value==2){
          label = "Alaris"
        }
        return (
          <div>
            {label}
          </div>
        )
      }
    },
    {
        dataField: "edit",
        text: "Edit",
        sort: true,
        formatter : function(value,row,index) { 
          return (
              <button className={`view-btn-tbl ${role === 3 || role === 4 ? 'disabled' : ''}`}    
                      onClick={async () => {
                          dispatch(getSelectedPartner(row.id));
                          dispatch(getNewCountry())
                          const editOpenPromise = new Promise((resolve, reject) => {
                            setmodal_edit_screen(true);
                            resolve("Modal opened");
                          });
                          await editOpenPromise.then((msg) => {
                            // console.log(msg);
                            setTimeout(() => {
                              tog_edit_screen()
                            }, 500);
                          });
                      }}
                      disabled={role === 3 || role === 4}
                      
              >Edit
              </button>
          )
          }
      },
      {
        dataField:"delete",
        text: "Delete",
        sort: true,
        formatter : function(value,row,index) { 
          return (
              <button className={`view-btn-tbl ${role === 3 || role === 4 ? 'disabled' : ''}`}    
                      onClick={async () => {
                        deletePartnerData(row.id)  
                      }}
                      disabled={role === 3 || role === 4}
                      
              >Delete
              </button>
          )
          }
    },
  ]

  const tableData = partnerData?.length==0?[]:partnerData;
  const selectedPartner_editdata = selectedPartnerdata?.length==0?"":selectedPartnerdata;



  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: tableData.length },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Partners</title>
        </MetaTags>
          <div className="container-fluid">
          <Breadcrumbs title="Settings" breadcrumbItem="Partners" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                <Row className="mb-2">
                  <Col md="8" sm="12">
                    <div className="search-box me-2 mb-2 d-inline-block w-sm-100">
                      <Row>
                        <Col>
                          <div
                            className="me-3"
                            style={{
                              border: "1px solid #D9D9D9",
                              borderRadius: "50px",
                              marginBottom: "10px",
                              minWidth: "210px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <i
                              class="bx bx-search-alt search-icon sm-left-30 ps-2 pe-2 "
                              style={{
                                
                                width: "10px",
                                height: "10px",
                              }}
                            ></i>
                            <input
                              type="text"
                              placeholder="Search"
                              style={{
                                width: "150px",
                                height: "31px",
                                border: "none",
                                borderRadius: "10px",
                                textAlign: "start",
                                borderTopLeftRadius: "20px",
                                borderBottomLeftRadius: "20px",
                                marginTop: "2px",
                                marginLeft:"25px"
                              }}
                              value={searchTerm}
                              onChange={e => {
                                setSearchTerm(e.target.value)
                                setSearchText(e.target.value)
                              }}
                              onKeyDown={e => {
                                if (e.key === "Enter") {
                                  // setPage(1)
                                }
                              }}
                            />
                            {searchTerm !== "" && (
                              // <i className="bx bx-search-alt search-icon sm-left-30" />
                              <i
                                className="fas fa-times me-2 ms-2"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  // setPage(1)
                                  setSearchTerm("")
                                  setSearchText("")
                                }}
                              ></i>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col md="4" sm="12" className="position-relative ">
                    <div className="d-flex ">
                      <Button
                        color="danger"
                        className="btn btn-custom md-position-absolute ms-auto p-2 bd-highlight"
                        onClick={() => {
                          setTimeout(() => {
                            tog_add_screen()
                          }, 200);
                        }}
                        disabled={role === 4}
                      >
                        Add Partner
                      </Button>
                    </div>
                  </Col>
                </Row>
                <div className="col-12 ms-3">
                    {filteredChannels?.length == 0 ? (
                      getSuccess == false ? (
                        <LoadingSpinner />
                      ) : (
                        <div
                          style={{
                            textAlign: "center",
                            paddingTop: "50px",
                          }}
                        >
                          No Data Found
                        </div>
                      )
                    ) : (
                      <div>

                      <Row style={{ marginRight: "15px" }}>
                      <Table className="table-responsive align-middle mb-0 report_table_edit">
                      <thead>
                        <tr>
                          <th>Partner Name</th>
                          <th>Template</th>
                          <th>Edit</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentPageItems?.map(file => {
                          return (
                            <tr key={file.id}>
                              <td>{file.partner_name}</td>
                              <td>
                                {file.template_id == 1 ? "Horisen" : file.template_id==2 ?"Alaris" : file.template_id==3 ? "TelQ - Horisen" : file.template_id==4 ? "TelQ - Alaris" : ""}
                              </td>
                              <td>           
                              <button className={`view-btn-tbl ${role === 3 || role === 4 ? 'disabled' : ''}`}    
                                    onClick={async () => {
                                        dispatch(getSelectedPartner(file.id));
                                        dispatch(getNewCountry())
                                        const editOpenPromise = new Promise((resolve, reject) => {
                                          setmodal_edit_screen(true);
                                          resolve("Modal opened");
                                        });
                                        await editOpenPromise.then((msg) => {
                                          // console.log(msg);
                                          setTimeout(() => {
                                            tog_edit_screen()
                                          }, 500);
                                        });
                                    }}
                                    disabled={role === 3 || role === 4}
                                    
                            >Edit
                            </button>
                              </td>
                              <td>
                              <button className={`view-btn-tbl ${role === 3 || role === 4 ? 'disabled' : ''}`}    
                                      onClick={async () => {
                                        deletePartnerData(file.id)  
                                      }}
                                      disabled={role === 3 || role === 4}
                                      
                              >Delete
                              </button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </Row>
                      </div>
                    )}
                  </div>
                  <div className="col-12">
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(
                        filteredChannels.length / itemsPerPage
                      )}
                      forcePage={currentPage}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={data => {
                        setCurrentPage(data.selected)
                      }}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                    {/* Add Customer*/}
                    <Modal
                    size="md"
                    isOpen={modal_add_screen}
                    toggle={() => {
                      clearAddStates()
                      tog_add_screen()
                    }}
                    // className="modal-popscreen"
                    className="modal-popscreen"
                  >
                    <div className="modal-header">
                      <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                      >
                        ADD PARTNER  
                      </h5>
                      <button
                        onClick={() => {
                          clearAddStates()
                          setmodal_add_screen(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  <ModalBody>
                    <AddPartner
                      modalClose = {tog_add_screen}
                      upload={savePartnerData}
                      set_partner_name={set_add_partnerName}
                      set_partner_template={set_add_partnerTemplate}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="button"
                      onClick={() => {
                        tog_add_screen()
                      }}
                      className="btn btn-secondary "
                      data-dismiss="modal"
                    >
                      Close
                    </Button>
                    <Button
                      type="button"
                      className="btn btn-primary "
                      onClick = {(e)=>{
                        savePartnerData()
                      }}
                    >
                      Save changes
                    </Button>
                  </ModalFooter>

                  </Modal>

                  {/* Customer Data Edit */}
                  <Modal
                    size="md"
                    isOpen={modal_edit_screen}
                    toggle={() => {
                      clearEditStates()
                      tog_edit_screen()
                    }}
                    className="modal-popscreen"
                  >
                    <div className="modal-header">
                      <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                      >
                        EDIT PARTNER DETAILS 
                      </h5>
                      <button
                        onClick={() => {
                          clearEditStates()
                          setmodal_edit_screen(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  <ModalBody>
                      <EditPartner 
                      data={selectedPartnerdata}
                      upload={savePartnerData}
                      set_partner_name={set_edit_partnerName}
                      set_partner_template={set_edit_partnerTemplate}
                      />
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="button"
                      onClick={() => {
                        clearEditStates()
                        tog_edit_screen()
                      }}
                      className="btn btn-secondary "
                      data-dismiss="modal"
                    >
                      Close
                    </Button>
                    <Button
                      type="button"
                      className="btn btn-primary "
                      onClick={()=>{
                        savePartnerEdit()
                      }}
                    >
                      Save Changes
                    </Button>
                    
                  </ModalFooter>

                  </Modal>


                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Partners
