import React from "react"
import { Redirect, Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Registry"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import UploadFiles from "pages/UploadedFiles"
import SourceUpload from "pages/SourceUpload"
import smscNew from "pages/Smsc"
import Users from "pages/Users"
import Customers from "pages/Customers"
import Operators from "pages/Operators"
import Partners from "pages/Partners"
import Countries from "pages/Countries"
import Settings from "pages/Settings"
import Reports from "pages/Reports"
import HistoricalImport from "pages/HistoricalData/Import"
import HistoricalView from "pages/HistoricalData/View"
import CustomerPortal from "pages/CustomerPortal"
import ReportEdit from "../components/Reports/ReportEdit"
import ReportGenerate from "../components/Reports/ReportGenerate"
import ReportHistory from "../components/Reports/ReportHistory"
import { components } from "react-select"
import SMSC from "components/Operator/Add_Comps/smsc"
import AnalyticsResultOverview from "components/Analytics/AnalyticsResultOverview"
import AnalyticsBypassRoute from "components/Analytics/AnalyticsBypassRoute"
import AnalyticsBypass from "components/Analytics/AnalyticsBypass"
import AnalyticsFakeDRL from "components/Analytics/AnalyticsFakeDRL"
import AnalyticsOtherChannelDelivery from "components/Analytics/AnalyticsOtherChannelDelivery"
import AnalyticsSenderID from "components/Analytics/AnalyticsSenderID"
import CustomerAnalyticsResultOverview from "components/CustomerAnalytics/AnalyticsResultOverview"
import CustomerAnalyticsBypassRoutes from "components/CustomerAnalytics/AnalyticsBypassRoute"
import CustomerAnalyticsSenderID from "components/CustomerAnalytics/AnalyticsSenderID"

const userRoutes = [
  // TODO
  { path: "/dashboard", component: Dashboard },
  { path: "/source-upload", component: SourceUpload },
  { path: "/upload-files", component: UploadFiles },
  // { path: "/reports/:sub", component: Reports },
  { path: "/smsc-inspector", component: smscNew },
  { path: "/users", component: Users },
  { path: "/customers", component: Customers },
  { path: "/partners", component: Partners },
  { path: "/operators", component: Operators },
  // { path: "/country", component: Countries },
  { path: "/reports/edit", component: ReportEdit },
  { path: "/settings/:sub", component: Settings },
  { path: "/reports/generate", component: ReportGenerate },
  { path: "/reports/history/import", component: HistoricalImport },
  { path: "/reports/history/view", component: HistoricalView },
  { path: "/analytics/nptresultoverview", component: AnalyticsResultOverview },
  { path: "/analytics/gatekeeperfakedlr", component: AnalyticsFakeDRL },
  { path: "/analytics/gatekeeperbypass", component: AnalyticsBypass },
  { path: "/analytics/Bypassroute", component: AnalyticsBypassRoute },
  { path: "/analytics/senderidcount", component: AnalyticsSenderID },
  {
    path: "/analytics/otherchanneldelivery",
    component: AnalyticsOtherChannelDelivery,
  },

  {
    path: "/analytics/customerbypassroute",
    component: CustomerAnalyticsBypassRoutes,
  },
  {
    path: "/analytics/customersenderidcount",
    component: CustomerAnalyticsSenderID,
  },
  {
    path: "/analytics/customernptresultoverview",
    component: CustomerAnalyticsResultOverview,
  },

  // //profile
  { path: "/profile", component: UserProfile },
  // { path : "/customerPortal", component: CustomerPortal},
  // this route should be at the end of all other routes
  // { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: "/", exact: true, component: () => <Redirect to="/source-upload" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  // { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  // { path: "/dashboard", component: Dashboard },
  // { path: "/source-upload", component: SourceUpload },
  // { path: "/upload-files", component: UploadFiles },
  // { path: "/reports/:sub", component: Reports },
  // // { path: "/users", component: Users },
  // { path: "/customers", component: Customers },
  // { path: "/operators", component: Operators },
  // { path: "/partners", component: Partners },
  // { path: "/settings/:sub", component: Settings },
]

export { userRoutes, authRoutes }
