import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Button, Table, Input,  Modal, ModalFooter,  Container } from "reactstrap"

import MetaTags from "react-meta-tags"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
// import "./datatables.scss"

const ViewOperators = (props) => {

    const data = props.data;
    const a2p_data=props.data.SMSCs?.filter(obj=>obj.type=="owna2p");
    const p2p_data=props.data.SMSCs?.filter(obj=>obj.type=="ownp2p");
    const smsc_data=props.data.SMSCs?.filter(obj=>obj.type=="smsc");
    const gatekeepers = props.data.gatekeeper_routes;

  return (
    <React.Fragment>
         <div className="modal-content" >
            <Row>
                <Col md="6" sm= "6">
                    <Row>
                        <h5>Operator Data</h5>

                        <Col sm= "6" md="6" className="input-dtl-leb2"> Operator Name </Col>
                        <Col sm= "6" md="6" className="output-leb2">{data?.operator_name?data?.operator_name:""} </Col>
                        
                        <Col sm= "6" md="6" className="input-dtl-leb2"> Operator Country </Col>
                        <Col sm= "6" md="6" className="output-leb2">{data?.country?data?.country:""} </Col>

                        <Col sm= "6" md="6" className="input-dtl-leb2"> Subscribers </Col>
                        <Col sm= "6" md="6" className="output-leb2">{data?.subscribers?data?.subscribers:""} </Col>

                        {/* <Col md="6" className="input-dtl-leb2"> Customer Name </Col>
                        <Col md="6" className="output-leb2">Dialog </Col> */}

                    </Row>
                    <br/>
                    <Row>
                        <h5>MTR Details</h5>

                        <Col sm= "6" md="6" className="input-dtl-leb2"> MTR Currency </Col>
                        <Col sm= "6" md="6" className="output-leb2">{data?.mtr_currency?data?.mtr_currency:""}</Col>

                        <Col sm= "6" md="6" className="input-dtl-leb2"> Rate Threshold Factor </Col>
                        <Col sm= "6" md="6" className="output-leb2">{data?.price_threshold?data?.price_threshold:""}</Col>
                        
                        <Col sm= "6" md="6" className="input-dtl-leb2"> Official MTR A2P (Int) </Col>
                        <Col sm= "6" md="6" className="output-leb2">{data?.official_MTR_A2P_international?data?.official_MTR_A2P_international:""}</Col>

                        <Col md="6" className="input-dtl-leb2"> Official MTR P2P (Int) </Col>
                        <Col md="6" className="output-leb2">{data?.official_MTR_P2P_international?data?.official_MTR_P2P_international:""} </Col>

                        <Col md="6" className="input-dtl-leb2"> Official MTR A2P (Nat) </Col>
                        <Col md="6" className="output-leb2">{data?.official_MTR_A2P_national?data?.official_MTR_A2P_national:""} </Col>

                        <Col md="6" className="input-dtl-leb2"> Official MTR P2P (Nat) </Col>
                        <Col md="6" className="output-leb2">{data?.official_MTR_P2P_national?data?.official_MTR_P2P_national:""} </Col>
                    </Row>
                    <br/>
                    <Row>
                        <h5>Desired Sender ID Delivery</h5>
                        <Col md="6" className="input-dtl-leb2"> Alphanumeric Unchanged </Col>
                        <Col md="6" className="output-leb2">{data?.alphanumeric_unchanged?data?.alphanumeric_unchanged:""} </Col>

                        <Col md="6" className="input-dtl-leb2"> Alphanumeric Changed </Col>
                        <Col md="6" className="output-leb2">{data?.alphanumeric_changed?data?.alphanumeric_changed:""} </Col>

                        <Col md="6" className="input-dtl-leb2"> Shortcode </Col>
                        <Col md="6" className="output-leb2">{data?.shortCode?data?.shortCode:""} </Col>

                        <Col md="6" className="input-dtl-leb2"> Long Number </Col>
                        <Col md="6" className="output-leb2">{data?.longnumber?data?.longnumber:""} </Col>

                        <Col md="6" className="input-dtl-leb2"> Other </Col>
                        <Col md="6" className="output-leb2">{data?.other?data?.other:""} </Col>

                    </Row>

                </Col>
                <Col md="6">
                    <Row>
                        <h5 className="title-operator">Protection / Additional Info</h5>

                        <Col md="6" className="input-dtl-leb2"> Gatekeeper </Col>
                        <Col md="6" className="output-leb2">{data?.gatekeeper?data?.gatekeeper:""} </Col>

                        <Col md="6" className="input-dtl-leb2"> Firewall Vendor </Col>
                        <Col md="6" className="output-leb2">{data?.firewall_vendor?data?.firewall_vendor:""} </Col>

                        <Col md="6" className="input-dtl-leb2"> Pre-Registered Sender ID </Col>
                        <Col md="6" className="output-leb2">{data?.Pre_registered_senderID?data?.Pre_registered_senderID:""} </Col>
                    </Row>
                    <br/>
                    <Row>
                        <h5 >SMSC</h5>
                        <Col md="6" className="input-dtl-leb2"> Own A2P </Col>
                        <Col md="6" className="output-leb2">
                            <Row>
                                {a2p_data!=null?a2p_data.map((item,index)=>{
                                    return(
                                        
                                        <Col key={index} md="12" className="output-leb2">{item?.gts}</Col>
                                        
                                    )
                                }):<Col  md="12" className="output-leb2">-- Not Supplied --</Col>
                            }
                            </Row>
                        </Col>

                        <Col md="6" className="input-dtl-leb2"> Own P2P </Col>
                        <Col md="6" className="output-leb2">
                            <Row>
                                {p2p_data!=null?p2p_data.map((item,index)=>{
                                    return(
                                        
                                        <Col key={index} md="12" className="output-leb2">{item?.gts}</Col>
                                        
                                    )
                                }):<Col  md="12" className="output-leb2">-- Not Supplied --</Col>
                            }
                            </Row>
                        </Col>

                        <Col md="6" className="input-dtl-leb2"> International SMSC </Col>
                        <Col md="6" className="output-leb2">
                            <Row>
                                 {smsc_data!=null?smsc_data.map((item,index)=>{
                                    return(
                                        
                                        <Col key={index} md="12" className="output-leb2">{item?.gts}</Col>
                                        
                                    )
                                }):<Col  md="12" className="output-leb2">-- Not Supplied --</Col>
                            }
                            </Row>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <h5 className="title-operator">Gatekeeper Routes</h5>
                        <Col md="6" className="input-dtl-leb2"> Gates </Col>
                        <Col md="6" className="output-leb2">
                            <Row>
                                {gatekeepers!=null?gatekeepers.map((item,index)=>{
                                    return(
                                        
                                        <Col key={index} md="12" className="output-leb2">{item?.gate}</Col>
                                        
                                    )
                                }):<Col  md="12" className="output-leb2">-- Not Supplied --</Col>
                            }
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            
        </div>
    </React.Fragment>
  )
}

export default ViewOperators
