export const GET_NEW_TEMPLATES = "GET_NEW_TEMPLATES"
export const GET_NEW_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS"
export const SET_NEW_TEMPLATES= "SET_TEMPLATES"
export const GET_SELECTED_TEMPLATES = "GET_SELECTED_TEMPLATES"
export const SET_SELECTED_TEMPLATES= "SET_SELECTED_TEMPLATES"
export const SET_SELECTED_TEMPLATE_SUCCESS= "SET_SELECTED_TEMPLATE_SUCCESS"
export const UPLOAD_TEMPLATE = "UPLOAD_TEMPLATE"
export const SET_UPLOAD_TEMPLATE_SUCCESS = "SET_UPLOAD_TEMPLATE_SUCCESS"
export const EDIT_TEMPLATE = "EDIT_TEMPLATE"
export const SET_EDIT_TEMPLATE_SUCCESS= "SET_EDIT_TEMPLATE_SUCCESS"
export const DELETE_TEMPLATE = "DELETE_TEMPLATE"
export const SET_DELETE_TEMPLATE_SUCCESS = "SET_DELETE_TEMPLATE_SUCCESS"
