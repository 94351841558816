import { call, put, takeEvery, all, fork, takeLatest } from "redux-saga/effects";
import { setNewCountry, getNewCountrySuccess,setEditCountrySuccess, setuploadCountrySuccess, setdeleteCountrySuccess,setSelectedCountry,setSelectedCountrySuccess} from "./actions";
import {logoutUser} from "../auth/login/actions"
import { GET_NEW_COUNTRY,UPLOAD_COUNTRY,EDIT_COUNTRY,DELETE_COUNTRY,GET_SELECTED_COUNTRY} from "./actionTypes";
import {fetchNewCountry, fetchDeleteCountry, fetchSelectedCountry, dispatchEditCountry, dispatchNewCountry} from "./request";

export function* getNewCountry({payload}) {
    
    try {
        const response = yield call(fetchNewCountry,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        if(response.message="Success"){
            yield put(setNewCountry(response.countries));  
        }
        if(response.message?.length>0){
            yield put(getNewCountrySuccess(true));
        }
        else{
            yield put(getNewCountrySuccess('empty'));
        }
        
    } catch (error) {
        console.log(error)
    }
}

export function* getSelectedCountry({payload}) {
    
    try {
        const response = yield call(fetchSelectedCountry,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        yield put(setSelectedCountry(response)); 
        yield put(setSelectedCountrySuccess(true)); 
        
    } catch (error) {
        console.log(error)
    }
}

export function* uploadNewCountry({payload}) {
    try {
       
        const response = yield call(dispatchNewCountry,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        if(response.message=='Country successfully added'){
            yield put(setuploadCountrySuccess(true))
        }       
    } catch (error) {
        console.log(error)
    }
}

export function* EditCountry({payload}) {
    try {
       
        const response = yield call(dispatchEditCountry,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        if(response.message=="Country successfully updated"){
            yield put(setEditCountrySuccess(true))
        }       
    } catch (error) {
        console.log(error)
    }
}

export function* deleteCountry({payload}) {
    try {
       
        const response = yield call(fetchDeleteCountry,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        if(response.message=="Country successfully deleted"){
            yield put(setdeleteCountrySuccess(true))
        }       
    } catch (error) {
        console.log(error)
    }
}

export function* KBSaga() {
   
    yield takeLatest(GET_NEW_COUNTRY, getNewCountry);
    yield takeLatest(UPLOAD_COUNTRY, uploadNewCountry);
    yield takeLatest(EDIT_COUNTRY, EditCountry);
    yield takeLatest(DELETE_COUNTRY, deleteCountry);
    yield takeLatest(GET_SELECTED_COUNTRY, getSelectedCountry);



}

export default KBSaga;