import { combineReducers } from "redux"

// Front
import Layout from "./old/layout/reducer"

// Authentication
import Login from "./old/auth/login/reducer"
import Account from "./old/auth/register/reducer"
import ForgetPassword from "./old/auth/forgetpwd/reducer"
import Profile from "./old/auth/profile/reducer"


//Dashboard 
import Dashboard from "./old/dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./old/dashboard-saas/reducer";

import SourceUpload from "./old/source-upload/reducer";

import Reports from "./old/reports/reducer";

import kb from "./old/kb/reducer";

import customer from "./old/customer/reducer";

import operator from "./old/operator/reducer"

import history from "./old/history/reducer";

import country from "./old/country/reducer";

import partner from "./old/partner/reducer";

import users from "./old/users/reducer";

import templates from "./old/templates/reducer";

import sourceUploadNew  from './sourceUpload/sourceUpload.slice'

import { sourceFilesAPI } from "./sourceUpload/sourceUpload.api"

import customerNew from "./customer/customer.slice"

import { customerAPI } from "./customer/customer.api"

import operatorNew from "./operator/operator.slice"

import { operatorAPI } from "./operator/operator.api"

import partnerNew from "./partner/partner.slice"

import { partnerAPI } from "./partner/partner.api"

import templateNew from "./template/template.slice"

import { templateAPI } from "./template/template.api"

import usersNew from "./user/user.slice"

import { usersAPI } from "./user/user.api"

import customerPortal from "./customerPortal/customerPortal.slice"

import { customerPortalApi } from "./customerPortal/customerPortal.api"

import reportsNew from "./reports/reports.slice"

import { reportsApi } from "./reports/reports.api"

// import inspectorNew from "./inspector/inspector.slice"

import { inspectorAPI } from "./inspector/inspector.api"

import analyticsSlice from "./analytics/analytics.slice"

import { analyticsAPI } from "./analytics/analytics.api"

import countryNew from "./country/country.slice"

import { countryAPI } from "./country/country.api"

import otherChannels from "./otherChannels/otherChannels.slice"

import { otherChannelAPI } from "./otherChannels/otherChannels.api"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Dashboard,
  DashboardSaas,
  SourceUpload,
  Reports,
  kb,
  customer,
  operator,
  country,
  partner,
  history,
  users,
  templates,
  sourceUploadNew,
  [sourceFilesAPI.reducerPath]: sourceFilesAPI.reducer,
  customerNew,
  [customerAPI.reducerPath]: customerAPI.reducer,
  operatorNew,
  [operatorAPI.reducerPath]: operatorAPI.reducer,
  partnerNew,
  [partnerAPI.reducerPath]: partnerAPI.reducer,
  templateNew,
  [templateAPI.reducerPath]: templateAPI.reducer,
  usersNew,
  [usersAPI.reducerPath]: usersAPI.reducer,
  customerPortal,
  [customerPortalApi.reducerPath]: customerPortalApi.reducer,
  reportsNew,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [inspectorAPI.reducerPath]: inspectorAPI.reducer,
  analyticsSlice,
  [analyticsAPI.reducerPath]: analyticsAPI.reducer,
  countryNew,
  [countryAPI.reducerPath]: countryAPI.reducer,
  otherChannels,
  [otherChannelAPI.reducerPath]: otherChannelAPI.reducer,
})

export default rootReducer
