export const GET_USERS = "GET_USERS"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const SET_USERS= "SET_USERS"
export const GET_SELECTED_USER = "GET_SELECTED_USER"
export const SET_SELECTED_USER= "SET_SELECTED_USER"
export const SET_SELECTED_USER_SUCCESS= "SET_SELECTED_USER_SUCCESS"
export const UPLOAD_USER = "UPLOAD_USER"
export const SET_UPLOAD_USER_SUCCESS = "SET_UPLOAD_USER_SUCCESS"
export const SET_UPLOAD_USER_MESSAGE= "SET_UPLOAD_USER_MESSAGE"
export const EDIT_USER= "EDIT_USER"
export const SET_EDIT_USER_SUCCESS= "SET_EDIT_USER_SUCCESS"
export const DELETE_USER= "DELETE_USER"
export const SET_DELETE_USER_SUCCESS = "SET_DELETE_USER_SUCCESS"
export const ACTIVATE_USER= "ACTIVATE_USER"
export const SET_ACTIVATE_USER_SUCCESS = "SET_ACTIVATE_USER_SUCCESS"
export const REMOVE_USER = "REMOVE_USER"
export const SET_REMOVE_USER_SUCCESS = "SET_REMOVE_USER_SUCCESS"