export const GET_KBS = "GET_KBS"
export const GET_KBS_SUCCESS = "GET_KBS_SUCCESS"
export const SET_KBS= "SET_KBS"
export const GET_SELECTED_KBS = "GET_SELECTED_KBS"
export const SET_SELECTED_KBS= "SET_SELECTED_KBS"
export const SET_SELECTED_KBS_SUCCESS= "SET_SELECTED_KBS_SUCCESS"
export const UPLOAD_KB = "UPLOAD_KB"
export const SET_UPLOAD_KB_SUCCESS = "SET_UPLOAD_KB_SUCCESS"
export const EDIT_KB = "EDIT_KB"
export const SET_EDIT_KB_SUCCESS= "SET_EDIT_KB_SUCCESS"
export const DELETE_KB = "DELETE_KB"
export const SET_DELETE_KB_SUCCESS = "SET_DELETE_KB_SUCCESS"
