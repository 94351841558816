import { createSlice } from '@reduxjs/toolkit'

const sourceUploadSlice = createSlice({
    name: 'sourceUpload',
    initialState: {
        pairsArray: [],
        recentUploads: [],
        customers: [],
        partners: [],
        templates: [],
        operators: [],
        uploadedBatches:[],
        batchGetSuccess:false,
        searchedBatches:[],
        updateSuccess:"false",
        deleteSuccess:"false"
    },
    reducers: {
        setBatchData(state, action) {
            return{
                ...state,
                pairsArray: [...state.pairsArray, action.payload],
            }
        },
        updatePartner(state, action) {
            const { id, value } = action.payload;
            const pair = state.pairsArray.find(pair => pair.pairId === id);
            if (pair) {
              pair.partner = value;
            }
        },
        setRecentlyUploads(state, action) {
            return{
                ...state,
                recentUploads: action.payload,
            }
        },
        setCustomers(state, action) {
            return{
                ...state,
                customers: action.payload,
            }
        },
        setUploadedBatches(state, action) {
            return{
                ...state,
                uploadedBatches: action.payload,
            }
        },
        setSearchedBatches(state, action) {
            return{
                ...state,
                searchedBatches: action.payload,
            }
        },
        setOperators(state, action) {
            return{
                ...state,
                operators: action.payload,
            }
        },
        setPartners(state, action) {
            return{
                ...state,
                partners: action.payload,
            }
        },
        setTemplates(state, action) {
            return{
                ...state,
                templates: action.payload,
            }
        },
        clearPairs(state,action){
            return{
                ...state,
                pairsArray: [],
            }
        },
        removePairs(state,action){
            return{
                ...state,
                pairsArray: state.pairsArray.filter((item) => item.pairId !== action.payload),
            }
        }
    },
})

export const {
    setBatchData,
    setRecentlyUploads,
    setCustomers,
    setUploadedBatches,
    setSearchedBatches,
    setOperators,
    setPartners,
    setTemplates,
    clearPairs,
    removePairs,
    updatePartner
} = sourceUploadSlice.actions
export default sourceUploadSlice.reducer

