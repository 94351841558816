import * as url from "../../../helpers/url_helper"

export async function fetchNewOperators(payload) {
    try {
    // console.log(payload);
      const headers = {
        auth: JSON.parse(localStorage.getItem("authUser")),
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
      };
      const responseData = await fetch(url._GET_NEW_OPERATORS, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload)
      });
      const response = await responseData.json(); 
  
      return { response, responseData }; 
    } catch (err) {
      console.log(err);
      throw err; // Rethrow the error if needed
    }
  }
export async function fetchNewCountries(id) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._GET_NEWCOUNTRIES, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchCountries(id) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._GET_COUNTRIES_OP, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchDeleteOperator(id) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._DELETE_OPERATOR+id, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchSelectedOperator(id) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._SELECTED_OPERATOR+id, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}

export async function dispatchNewOperator(data) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            // "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        }
        
        const response = await fetch(url._UPLOAD_OPERATOR, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}

export async function dispatchEditOperator(data,id) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            // "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        }
        
        const response = await fetch(url._EDIT_OPERATOR, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}

