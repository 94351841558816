import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./old/auth/register/saga"
import AuthSaga from "./old/auth/login/saga"
import ForgetSaga from "./old/auth/forgetpwd/saga"
import ProfileSaga from "./old/auth/profile/saga"
import LayoutSaga from "./old/layout/saga"
// import ecommerceSaga from "./old/e-commerce/saga"
// import calendarSaga from "./old/calendar/saga"
// import chatSaga from "./old/chat/saga"
// import cryptoSaga from "./old/crypto/saga"
// import invoiceSaga from "./old/invoices/saga"
// import projectsSaga from "./old/projects/saga"
// import tasksSaga from "./old/tasks/saga"
// import mailsSaga from "./old/mails/saga"
// import contactsSaga from "./old/contacts/saga";
// import dashboardSaga from "./old/dashboard/saga";
// import dashboardSaasSaga from "./old/dashboard-saas/saga";
import sourceUploadSaga from "./old/source-upload/saga";
import reportsSaga from "./old/reports/saga"
import kbSaga from "./old/kb/saga"
import customerSaga from "./old/customer/saga"
import operatorSaga from "./old/operator/saga"
import historySaga from "./old/history/saga"
import countrySaga from "./old/country/saga"
import partnerSaga from "./old/partner/saga"
import UserSaga from "./old/users/saga"
import TemplateSaga from "./old/templates/saga"


export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    // fork(ProfileSaga),
    fork(LayoutSaga),
    // fork(ecommerceSaga),
    // fork(calendarSaga),
    // fork(chatSaga),
    // fork(mailsSaga),
    // fork(cryptoSaga),
    // fork(invoiceSaga),
    // fork(projectsSaga),
    // fork(tasksSaga),
    // fork(contactsSaga),
    // fork(dashboardSaga),
    // fork(dashboardSaasSaga),
    fork(sourceUploadSaga),
    fork(reportsSaga),
    fork(kbSaga),
    fork(customerSaga),
    fork(operatorSaga),
    fork(historySaga),
    fork(countrySaga),
    fork(partnerSaga),
    fork(UserSaga),
    fork(TemplateSaga)
  ])
}
