import { act } from "react-dom/test-utils";
import {GET_REPORTS,GET_REPORTS_SUCCESS, DOWNLOAD_REPORT_SUCCESS,SET_REPORTS_SUCCESS,SET_GENERATING,SET_DOWNLOAD_DATA, SET_REPORTS, SET_SELECTED_REPORT,SET_SELECTED_REPORT_ID ,UPLOAD_REPORTS_SUCCESS,DELETE_REPORT_SUCCESS,UPLOAD_KPI_SUCCESS,SET_SELECTED_REPORT_TO_GENERATE} from "./actionTypes"

const INIT_STATE = {
  reports: [],
  getReportsSuccess:false,
  selectedReport:[],
  selectedReportToGenerate:[],
  generateSuccess:false,
  generating:false,
  selectedReportId:"",
  getSelectedSuccess:false,
  updateSuccess:false,
  updateKpiSuccess:false,
  deleteSuccess:false,
  downloadSuccess:false,
  downloadData :""
 }

const Reports = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPLOAD_KPI_SUCCESS:
      // state.updateKpiSuccess = action.payload;
      return{
        ...state,
        updateKpiSuccess : action.payload
      }
    case GET_REPORTS_SUCCESS:
      // state.getReportsSuccess = action.payload;
      return {
        ...state,
        getReportsSuccess : action.payload
      }
    case SET_REPORTS_SUCCESS:
      // state.getSelectedSuccess = action.payload;
      return {
        ...state,
        getSelectedSuccess : action.payload
      }
    case SET_DOWNLOAD_DATA:
      // state.downloadData = action.payload;
      return {
        ...state,
        downloadData : action.payload
      }
    case DOWNLOAD_REPORT_SUCCESS:
      // state.downloadSuccess = action.payload;
      return {
        ...state,
        downloadSuccess : action.payload
      }
    case DELETE_REPORT_SUCCESS:
      // state.deleteSuccess = true;
      return {
        ...state,
        deleteSuccess : true
      }
    case UPLOAD_REPORTS_SUCCESS:
      // state.updateSuccess = true;
      return {
        ...state,
        updateSuccess : true
      }  
    case GET_REPORTS:
      return {
        ...state,
        // reports: [...state.reports, action.payload],
      }
    case SET_REPORTS:
      // state.reports=action.payload;
      return {
        ...state,
        reports : action.payload
        // reports: [...state.reports, action.payload],
      }
    case SET_SELECTED_REPORT:
      // state.selectedReport=action.payload;
      return {
        ...state,
        selectedReport : action.payload
        // reports: [...state.reports, action.payload],
      }
    case SET_SELECTED_REPORT_ID:
        // state.selectedReportId=action.payload;
        return {
          ...state,
          selectedReportId : action.payload
          // reports: [...state.reports, action.payload],
      }
    case SET_GENERATING:
      // state.generating=action.payload;
      return {
        ...state,
        generating : action.payload
        // reports: [...state.reports, action.payload],
      }
    case SET_SELECTED_REPORT_TO_GENERATE:
      // state.selectedReportToGenerate=action.payload;
      return {
        ...state,
        selectedReportToGenerate : action.payload
        // reports: [...state.reports, action.payload],
      }
     
    default:
      return state
  }
}

export default Reports
