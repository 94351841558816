import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Button, Table, Input,  Modal, ModalFooter,  Container } from "reactstrap"
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux"
import MetaTags from "react-meta-tags"

// datatable related plugins
import Contacts from "./Edit_comps/Contacts";
import TestNode from "./Edit_comps/TestNode";

const EditCustomer = (props) => {

    const EditData = props.data;
    // const EditData_fields = EditData.message;
    const EditData_fields = EditData?.message ?? {};
    const contactData = EditData_fields?.contacts;
    const testData = EditData_fields?.test_node_contacts;

    // const EditData_operators = EditData.operators?.map(obj=>obj.operator_id);
    const EditData_operators = EditData?.operators?.map(obj => obj.operator_id) ?? [];
    // props.setCustomer(EditData_fields?.contacts);
    // props.setTest(EditData_fields?.test_node_contacts);
    const setadd_customerName = props.set_customer_name;
    const setadd_customerCountry = props.set_customer_country;
    const setadd_customerOperator = props.set_customer_operators
    const selectedOps = props.selectedOps;
    // console.log(selectedOps);

  
    const [selectedMulti, setselectedMulti] = useState(null);
    const dispatch = useDispatch();
    
    // console.log(EditData);
    // console.log(EditData_operators);


    function handleMulti(selectedMulti) {
      setselectedMulti(selectedMulti);
    }
  

  
    // useEffect(() => {
    
    // }, [])
  
    const countries = useSelector((state) => state.customer.countries);
    const countryData = countries?.message?.map(obj=>({
        id:obj.id,
        label:obj.country_name,
        value:obj.iso_3
    })) 
    //const customer = useSelector((state) => state.SourceUpload.customers);
    const operator = useSelector((state) => state.customer.operators);
    const operatorData = operator?.map(obj=>({
        id:obj.id,
        label:obj.operator_customer_label,
        value:obj.operator_customer_label
    }))
    
    return (
    <React.Fragment>
       <form>
            <h5>Customer Data</h5>
            <div className="customer-field-container" >
            <Row>
                <Col md="4">
                <label className="lable-customer">Customer Name</label>
                </Col>
                <Col md="8">
                <Input
                type="name"
                className="customer-input"
                defaultValue={EditData_fields?.name}
                onChange={(e)=>{
                    //setadd_customerName(e.target.value)
                    EditData_fields.name=e.target.value;
                }}
                placeholder="Vodafone Ltd"
                />
                </Col>
            </Row>
            {/* <Row>
                <Col md="4">
                <label className="lable-customer">Country</label>
                </Col>
                <Col md="8">
                <Select
                    className="customer-mult-input"
                     //isSearchable={true}
                    name="country"
                    options={countryData}
                    // defaultValue = {countryData.filter(obj=>obj.label==EditData_fields?.country)}
                    defaultValue = {countryData?.filter(obj => obj.label === EditData_fields?.country) ?? []}
                    placeholder="Select Country"
                    onChange={(e)=>{
                        // setadd_customerCountry(e.label)
                        EditData_fields.country=e.label;
                    }}        
                          />
                </Col>
            </Row> */}
            <Row>
                <Col md="4">
                <label className="lable-customer">Operator</label>
                </Col>
                <Col md="8">
                <Select
                    className="customer-mult-input"
                    //value={selectedMulti}
                    isMulti={true}
                    onChange={(e) => {
                        setadd_customerOperator(e);
                        //EditData_operators=e;
                    }}
                    name="operator"
                    options={operatorData}
                    defaultValue={operatorData.filter(obj=>EditData_operators?.includes(obj.id))}
                    placeholder="Select Operator"
                    classNamePrefix="select2-selection"
                    />
                </Col>
            </Row>
            </div>
            
            <h5>Contact Details</h5>
            <div className="customer-field-container" >
            <Contacts
                main_state={props.customer_contact}
                setState = {props.setCustomer}
                existData = {contactData}
                />
            </div>

            <h5>Test Node Details</h5>
            <div className="customer-field-container" >
            <TestNode
                main_state={props.test_contact}
                setState = {props.setTest}
                existData = {testData}
                operators = {operatorData}
                selectedMulti = {selectedOps}
                editing = {props.editing}
                />
            </div>
            
        </form>
    </React.Fragment>
  )
}

export default EditCustomer
