import * as url from "../../../helpers/url_helper"

export async function fetchNewCustomers(id) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._GET_NEW_CUSTOMERS, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchNewOperators(id) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._GET_NEWOPERATORS, {
            method: "GET",
            headers: headers,

        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchNewCountries(id) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._GET_NEWCOUNTRIES, {
            method: "GET",
            headers: headers,

        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchCountries(id) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._GET_COUNTRIES, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchDeleteCustomer(id) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._DELETE_CUSTOMER+id, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchSelectedCustomer(id) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._SELECTED_CUSTOMER+id, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}

export async function dispatchNewCustomer(data) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            // "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        }
        
        const response = await fetch(url._UPLOAD_CUSTOMER, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}

export async function dispatchEditCustomer(data,id) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            // "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        }
        
        const response = await fetch(url._EDIT_CUSTOMER, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}

