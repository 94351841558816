import { call, put, takeEvery, all, fork, takeLatest } from "redux-saga/effects";
import { setNewOperators,
    setSelectedOperator,
    setEditOperatorSuccess,
    setdeleteOperatorSuccess,
    setuploadOperatorSuccess,
    setSelectedOperatorSuccess,
    setNewCountry,
    getNewOperatorSuccess,
    setCountries} from "./actions";
import { GET_COUNTRIES,
    UPLOAD_OPERATOR,
    EDIT_OPERATOR,
    DELETE_OPERATOR,
    GET_NEW_OPERATOR,
    GET_SELECTED_OPERATOR, 
    GET_NEWCOUNTRIES} from "./actionTypes";
import {fetchNewOperators, 
    fetchDeleteOperator, 
    fetchSelectedOperator, 
    dispatchEditOperator, 
    dispatchNewOperator,
    fetchCountries, 
    fetchNewCountries} from "./request";
import {logoutUser} from "../auth/login/actions"

export function* getNewOperators({payload}) {
    
    try {
        // console.log(payload);
        const {response,responseData} = yield call(fetchNewOperators,payload)
        if(responseData.status==401){
            yield put(logoutUser())
        }
        yield put(setNewOperators(response.message));
        if(response.message.length>0){
            yield put(getNewOperatorSuccess(true))
        }
        else{
            yield put(getNewOperatorSuccess('empty'))
        }
        
    } catch (error) {
        console.log(error)
    }
}
export function* getCountries({payload}) {
    
    try {
        const response = yield call(fetchCountries,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        yield put(setCountries(response));  
        
    } catch (error) {
        console.log(error)
    }
}

export function* getNewCountry({payload}) {
    
    try {
        const response = yield call(fetchNewCountries,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        yield put(setNewCountry(response));  
        
    } catch (error) {
        console.log(error)
    }
}

export function* getSelectedOperator({payload}) {
    
    try {
        const response = yield call(fetchSelectedOperator,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        yield put(setSelectedOperator(response.message));  
        yield put(setSelectedOperatorSuccess(true))
        
    } catch (error) {
        console.log(error)
    }
}

export function* uploadNewOperator({payload}) {
    try {
       
        const response = yield call(dispatchNewOperator,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        if(response.message=='New Operator Saved'){
            yield put(setuploadOperatorSuccess(true))
        }       
    } catch (error) {
        console.log(error)
    }
}

export function* editOperator({payload}) {
    try {
       
        const response = yield call(dispatchEditOperator,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        if(response.message=="Edits Saved"){
            yield put(setEditOperatorSuccess(true))
        }       
    } catch (error) {
        console.log(error)
    }
}

export function* deleteOperator({payload}) {
    try {
       
        const response = yield call(fetchDeleteOperator,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        if(response.message=="Deleted"){
            yield put(setdeleteOperatorSuccess(true))
        }       
    } catch (error) {
        console.log(error)
    }
}

export function* OpSaga() {
   
    yield takeLatest(GET_NEW_OPERATOR, getNewOperators);
    yield takeLatest(GET_COUNTRIES, getCountries);
    yield takeLatest(UPLOAD_OPERATOR, uploadNewOperator);
    yield takeLatest(EDIT_OPERATOR, editOperator);
    yield takeLatest(DELETE_OPERATOR, deleteOperator);
    yield takeLatest(GET_SELECTED_OPERATOR, getSelectedOperator);
    yield takeLatest(GET_NEWCOUNTRIES, getNewCountry);



}

export default OpSaga;