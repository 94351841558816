import * as aT from "./actionTypes";

export const getNewUsers  = data =>({
  type:aT.GET_USERS,
  payload:data
})
export const getNewUsersSuccess  = data =>({
  type:aT.GET_USER_SUCCESS,
  payload:data
})
export const setNewUsers  = data =>({
  type:aT.SET_USERS,
  payload:data
})
export const getSelectedUser = data => ({
  type: aT.GET_SELECTED_USER,
  payload:data
})
export const setSelectedUser  = data =>({
  type:aT.SET_SELECTED_USER,
  payload:data
})
export const setSelectedUserSuccess  = data =>({
  type:aT.SET_SELECTED_USER_SUCCESS,
  payload:data
})
export const uploadUser = data=>({
  type:aT.UPLOAD_USER,
  payload:data
})
export const setuploadUserSuccess = data =>({
  type:aT.SET_UPLOAD_USER_SUCCESS,
  payload:data
})
export const setuploadUserMessage = data =>({
  type:aT.SET_UPLOAD_USER_MESSAGE,
  payload:data
})
export const editUser = data=>({
  type:aT.EDIT_USER,
  payload:data
})
export const setEditUserSuccess = data =>({
  type:aT.SET_EDIT_USER_SUCCESS,
  payload:data
})
export const deleteUser = data=>({
  type:aT.DELETE_USER,
  payload:data
})
export const setDeleteUserSuccess = data =>({
  type:aT.SET_DELETE_USER_SUCCESS,
  payload:data
})
export const activateUser = data=>({
  type:aT.ACTIVATE_USER,
  payload:data
})
export const setActivateUserSuccess = data =>({
  type:aT.SET_ACTIVATE_USER_SUCCESS,
  payload:data
})
export const removeUser = data=>({
  type:aT.REMOVE_USER,
  payload:data
})
export const setRemoveUserSuccess = data =>({
  type:aT.SET_REMOVE_USER_SUCCESS,
  payload:data
})