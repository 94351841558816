import { createSlice } from '@reduxjs/toolkit'

const partnerSlice = createSlice({
    name: 'partner',
    initialState: {
        partners : [],
    },
    reducers: {
        setPartners: (state, action) => {
            state.partners = action.payload
        },
    },
})

export const {
    setPartners,
} = partnerSlice.actions
export default partnerSlice.reducer