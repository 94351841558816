import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  Input,
  Button,
  Table
} from "reactstrap"
import MetaTags from "react-meta-tags"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  getKBentries,
  getSelectedKBentry,
  uploadKB,
  editKB,
  deleteKB,
  setUploadKBSuccess,
  setEditKBSuccess,
  setDeleteKBsuccess,
} from "store/old/kb/actions"

import {
  getTemplates,
  getTemplatesSuccess,
  setTemplates,
  setSelectedTemplates,
  getSelectedTemplates,
  uploadTemplates,
  setUploadTemplateSuccess,
  deleteTemplate,
  setDeleteTemplateSuccess,
  editTemplate,
  setEditTemplateSuccess,
} from "store/old/templates/actions"
//Import Breadcrumb
import Breadcrumbs from "../Common/Breadcrumb"
import toastr from "toastr"
import Swal from "sweetalert2"
import "toastr/build/toastr.min.css"
import store from "../../store/index"
import "react-phone-number-input/style.css"
import LoadingSpinner from "components/Reports/EditReports/EditTables/loader"
import jwt from "jsonwebtoken"
import ReactPaginate from "react-paginate"


const KnowledgeBase = () => {
  const decodeToken = token => {
    try {
      const decodedToken = jwt.decode(token)
      return decodedToken
    } catch (error) {
      console.log("Error decoding token:", error)
      return null
    }
  }

  const token = localStorage.getItem("authUser")
  const decodedToken = decodeToken(JSON.parse(token))
  let role = decodedToken.role

  const dispatch = useDispatch()
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
    didOpen: toast => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  useEffect(() => {
    dispatch(getTemplatesSuccess(false))
    dispatch(setTemplates([]))
    dispatch(getTemplates())
  }, [])

  const columns = [
    {
      dataField: "id",
      text: "Template ID",
      sort: true,
    },
    {
      dataField: "label",
      text: "Template Name",
      sort: true,
    },
    {
      text: "Action",
      //field: 'operate',
      title: "View",
      //align: 'center',
      //valign: 'middle',
      clickToSelect: false,
      formatter: function (value, row, index) {
        //return '<input name="elementname"  value="'+value+'"/>';
        return (
          <button
            className={`view-btn-tbl ${role === 4 ? "disabled" : ""}`}
            onClick={async () => {
              // console.log(row.id);
              dispatch(setSelectedTemplates([]))
              dispatch(getSelectedTemplates({ id: row.id }))
              setTimeout(() => {
                tog_edit_screen()
              }, 200)
            }}
            disabled={role === 4}
          >
            Edit
          </button>
        )
      },
    },
    {
      text: "Action",
      //field: 'operate',
      title: "View",
      //align: 'center',
      //valign: 'middle',
      clickToSelect: false,
      formatter: function (value, row, index) {
        //return '<input name="elementname"  value="'+value+'"/>';
        return (
          <button
            className={`view-btn-tbl ${role === 4 ? "disabled" : ""}`}
            onClick={async () => {
              deleteKBs(row.id)
            }}
            disabled={role === 4}
          >
            Delete
          </button>
        )
      },
    },
  ]
  const getSuccess = useSelector(state => state.templates.getSuccess)
  const templates = useSelector(state => state.templates.templates)
  const selectedTemplate = useSelector(
    state => state.templates.selectedTemplate
  )

  const [currentPage, setCurrentPage] = useState(0)
  const itemsPerPage = 10
  const offset = currentPage * itemsPerPage
  const [filteredChannels, setFilteredChannels] = useState(templates)
  const currentPageItems = filteredChannels?.slice(offset, offset + itemsPerPage)
  const [searchTerm, setSearchTerm] = useState("")
  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    setFilteredChannels(templates)
  }, [templates])

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredChannels(templates)
    } else {
      setFilteredChannels(
        templates.filter(item => {
          let term = searchText.toLowerCase()
          return item.label.toLowerCase().includes(term)
        })
      )
      setCurrentPage(0)
    }
  }, [searchText])

  const [modal_add_screen, setmodal_add_screen] = useState(false)
  const [modal_edit_screen, setmodal_edit_screen] = useState(false)

  const [templateName, setTemplateName] = useState("")
  const [editTemplateValue, setEditTemplate] = useState("")

  const [edit_smscmatch, setedit_smscmatch] = useState("")
  const [edit_smscreplace, setedit_smscreplace] = useState("")

  const templateData = templates ? templates : []
  const tableData = templateData ? templateData : productData

  function tog_add_screen() {
    setmodal_add_screen(!modal_add_screen)
    removeBodyCss()
  }
  function tog_edit_screen() {
    setmodal_edit_screen(!modal_edit_screen)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  async function saveKBData() {
    // var formData = new FormData()
    // formData.append("smsc_match",templateName)
    // formData.append("smsc_replace",input_smscreplace)
    const nametest = tableData.filter(
      item => item.label.toLowerCase() === templateName.toLowerCase()
    )
    if (nametest.length > 0) {
      Toast.fire({
        icon: "error",
        title: "Template Name already exists!",
      })
      return
    } else if (templateName == "") {
      Toast.fire({
        icon: "error",
        title: "Please enter a Template Name!",
      })
      return
    } else {
      var data = {
        template_name: templateName,
      }
      dispatch(uploadTemplates(data))

      let uploadPromise = new Promise(async (resolve, reject) => {
        store.subscribe(() => {
          const uploadSuccess = store.getState()
          const status = uploadSuccess.templates.uploadSuccess
          if (status == true) {
            // console.log(status);
            resolve("Done")
          }
        })
      })
      await uploadPromise.then(async msg => {
        Toast.fire({
          icon: "success",
          title: "Saved Successfully!",
        })
        dispatch(setUploadTemplateSuccess(false))
        setTimeout(() => {
          setmodal_add_screen(false)
          dispatch(getTemplatesSuccess(false))
          dispatch(setTemplates([]))
          dispatch(getTemplates())
        }, 700)
      })
    }
  }
  async function saveKBEdit(id) {
    // console.log(id);
    var data = {
      id: id,
      smsc_match: edit_smscmatch == "" ? "no edits" : edit_smscmatch,
      smsc_owner_replace:
        edit_smscreplace == "" ? "no edits" : edit_smscreplace,
    }
    dispatch(editKB(data))

    let editPromise = new Promise(async (resolve, reject) => {
      store.subscribe(() => {
        const editSuccess = store.getState()
        const status = editSuccess.kb.editsuccess
        if (status == true) {
          // console.log(status);
          resolve("Done")
        }
      })
    })
    await editPromise.then(async msg => {
      Toast.fire({
        icon: "success",
        title: "Edits Saved Successfully!",
      })
      dispatch(setEditKBSuccess(false))
      setTimeout(() => {
        setmodal_edit_screen(false)
        dispatch(getKBentries())
      }, 1500)
    })
  }
  async function deleteKBs(id) {
    Swal.fire({
      title: "Do you really want to Delete the record?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Delete",
      denyButtonText: `Don't Delete`,
    }).then(async result => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(deleteTemplate({ id: id }))
        let deletePromise = new Promise(async (resolve, reject) => {
          store.subscribe(() => {
            const deleteSuccess = store.getState()
            const status = deleteSuccess.templates.deleteSuccess
            if (status == true) {
              // console.log(status);
              resolve("Done")
            }
            if (status == false) {
              reject("Failed")
            }
          })
        })
        await deletePromise
          .then(async msg => {
            Toast.fire({
              icon: "success",
              title: "Deleted Successfully!",
            })
            dispatch(setDeleteTemplateSuccess(false))
            setTimeout(() => {
              dispatch(getTemplatesSuccess(false))
              dispatch(setTemplates([]))
              dispatch(getTemplates())
              setCurrentPage(0)
            }, 700)
          })
          .catch(error => {
            Toast.fire({
              icon: "error",
              title:
                "Deletion Failed! Please check for Batches that are associated with the Template.",
              timer: 3000,
            })
          })
      } else if (result.isDenied) {
        Swal.fire("Delete Cancelled", "", "info")
      }
    })
  }
  async function editKBs(id) {
    // console.log(id);
    if (editTemplateValue) {
      dispatch(editTemplate({ id: id, template_name: editTemplateValue }))
      let editPromise = new Promise(async (resolve, reject) => {
        store.subscribe(() => {
          const editSuccess = store.getState()
          const status = editSuccess.templates.editSuccess
          if (status == true) {
            // console.log(status);
            resolve("Done")
          }
        })
      })
      await editPromise.then(async msg => {
        Toast.fire({
          icon: "success",
          title: "Edits Saved Successfully!",
        })
        dispatch(setEditTemplateSuccess(false))
        setTimeout(() => {
          setmodal_edit_screen(false)
          dispatch(getTemplatesSuccess(false))
          dispatch(setTemplates([]))
          dispatch(getTemplates())
        }, 1500)
      })
    } else {
      setTimeout(() => {
        setmodal_edit_screen(true)
      }, 1000)
    }
  }

  // Table Data
  const productData = [
    {
      id: 1,
      label: "+94115000001",
      value: "+94115077701",
    },
    {
      id: 2,
      label: "+94115000001",
      value: "+94115077701",
    },
  ]
  // const tableData = kBdata?.length==0?productData:kBdata.message;

  // const selectedKB_editdata = selectedKBdata?.length==0?"":selectedKBdata.message;
  useEffect(() => {
    // console.log(selectedTemplate);
  }, [selectedTemplate])

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length, // replace later with size(customers),
    custom: true,
    onSizePerPageChange: size => {
      setSizePerPage(size)
    },
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: tableData.length },
  ]

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div>
        {" "}
        {/*className="page-content" */}
        <MetaTags>
          <title>Templates</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Settings" breadcrumbItem="Templates" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                <Row className="mb-2">
                  <Col md="8" sm="12">
                    <div className="search-box me-2 mb-2 d-inline-block w-sm-100">
                      <Row>
                        <Col>
                          <div
                            className="me-3"
                            style={{
                              border: "1px solid #D9D9D9",
                              borderRadius: "50px",
                              marginBottom: "10px",
                              minWidth: "210px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <i
                              class="bx bx-search-alt search-icon sm-left-30 ps-2 pe-2 "
                              style={{
                                
                                width: "10px",
                                height: "10px",
                              }}
                            ></i>
                            <input
                              type="text"
                              placeholder="Search"
                              style={{
                                width: "150px",
                                height: "31px",
                                border: "none",
                                borderRadius: "10px",
                                textAlign: "start",
                                borderTopLeftRadius: "20px",
                                borderBottomLeftRadius: "20px",
                                marginTop: "2px",
                                marginLeft:"25px"
                              }}
                              value={searchTerm}
                              onChange={e => {
                                setSearchTerm(e.target.value)
                                setSearchText(e.target.value)
                              }}
                              onKeyDown={e => {
                                if (e.key === "Enter") {
                                  // setPage(1)
                                }
                              }}
                            />
                            {searchTerm !== "" && (
                              // <i className="bx bx-search-alt search-icon sm-left-30" />
                              <i
                                className="fas fa-times me-2 ms-2"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  // setPage(1)
                                  setSearchTerm("")
                                  setSearchText("")
                                }}
                              ></i>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col md="4" sm="12" className="position-relative ">
                    <div className="d-flex ">
                      <Button
                        color="danger"
                        className="btn btn-custom md-position-absolute ms-auto p-2 bd-highlight"
                        onClick={() => {
                          setTimeout(() => {
                            tog_add_screen()
                          }, 200);
                        }}
                        disabled={role === 4}
                      >
                        Add Template
                      </Button>
                    </div>
                  </Col>
                </Row>
                <div className="col-12 ms-3">
                    {filteredChannels?.length == 0 ? (
                      getSuccess == false ? (
                        <LoadingSpinner />
                      ) : (
                        <div
                          style={{
                            textAlign: "center",
                            paddingTop: "50px",
                          }}
                        >
                          No Data Found
                        </div>
                      )
                    ) : (
                      <div>

                      <Row style={{ marginRight: "15px" }} >
                      <Table className="table-responsive align-middle mb-0 report_table_edit">
                      <thead>
                        <tr>
                          <th>Template ID</th>
                          <th>Template Name</th>
                          <th>Action</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentPageItems?.map(file => {
                          return (
                            <tr key={file.id}>
                              <td>{file.id}</td>
                              <td>
                                {file.label}{" "}
                              </td>
                              <td>           
                              <button
                              className={`view-btn-tbl ${role === 4 ? "disabled" : ""}`}
                              onClick={async () => {
                                // console.log(row.id);
                                dispatch(setSelectedTemplates([]))
                                dispatch(getSelectedTemplates({ id: file.id }))
                                setTimeout(() => {
                                  tog_edit_screen()
                                }, 200)
                              }}
                              disabled={role === 4}
                            >
                              Edit
                            </button>
                              </td>
                              <td>
                              <button
                                className={`view-btn-tbl ${role === 4 ? "disabled" : ""}`}
                                onClick={async () => {
                                  deleteKBs(file.id)
                                }}
                                disabled={role === 4}
                              >
                                Delete
                              </button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </Row>
                      </div>
                    )}
                  </div>
                  <div className="col-12">
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(
                        filteredChannels.length / itemsPerPage
                      )}
                      marginPagesDisplayed={2}
                      forcePage={currentPage}
                      pageRangeDisplayed={5}
                      onPageChange={data => {
                        setCurrentPage(data.selected)
                      }}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>

                  {/* Add Instance */}
                  <Modal
                    size="md"
                    isOpen={modal_add_screen}
                    toggle={() => {
                      saveKBData()
                    }}
                    className="modal-popscreen"
                  >
                    <div className="modal-header">
                      <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                      >
                        SMSC Details
                      </h5>
                      <button
                        onClick={() => {
                          setmodal_add_screen(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <form>
                        <div className="customer-field-container">
                          <Row>
                            <Col md="4">
                              <label className="lable-customer">
                                Template Name
                              </label>
                            </Col>
                            <Col md="8">
                              <Input
                                type="name"
                                className="customer-input"
                                placeholder="Enter Template Name"
                                onChange={e => {
                                  setTemplateName(e.target.value)
                                }}
                              />
                              {/* <PhoneInput
                                    className="customer-phone-input"
                                    placeholder="Enter the Number"
                                    onChange={(e)=>{
                                      setTemplateName(e)
                                    }}
                                    /> */}
                            </Col>
                          </Row>
                        </div>
                      </form>
                    </div>

                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={() => {
                          tog_add_screen()
                        }}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <Button
                        type="button"
                        className="btn btn-primary"
                        onClick={e => {
                          saveKBData()
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </Modal>

                  {/* Edit */}
                  <Modal
                    size="md"
                    isOpen={modal_edit_screen}
                    toggle={() => {
                      tog_edit_screen()
                    }}
                    className="modal-popscreen"
                  >
                    <div className="modal-header">
                      <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                      >
                        Edit Template Details
                      </h5>
                      <button
                        onClick={() => {
                          setmodal_edit_screen(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <form>
                        <div className="customer-field-container">
                          {/* <Row>
                            <Col md="4">
                            <label className="lable-customer">SMSC Match</label>
                            </Col>
                            <Col md="8">
                                  <PhoneInput
                                    className="customer-phone-input"
                                    placeholder="Enter phone number"
                                    value={selectedKB_editdata.smsc_match}
                                    onChange={(e)=>{
                                      setedit_smscmatch(e)
                                    }}
                                    />
                            </Col>
                          </Row> */}
                          <Row>
                            <Col md="4">
                              <label className="lable-customer">
                                Template Name
                              </label>
                            </Col>
                            <Col md="8">
                              <Input
                                type="name"
                                className="customer-input"
                                defaultValue={selectedTemplate[0]?.label}
                                placeholder="Enter Template Name"
                                onChange={e => {
                                  setEditTemplate(e.target.value)
                                }}
                              />
                              {/* <PhoneInput
                                    className="customer-phone-input"
                                    placeholder="Enter the Number"
                                    onChange={(e)=>{
                                      setTemplateName(e)
                                    }}
                                    /> */}
                            </Col>
                          </Row>
                        </div>
                      </form>
                    </div>

                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={() => {
                          tog_edit_screen()
                        }}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={() => {
                          editKBs(selectedTemplate[0]?.id)
                        }}
                      >
                        Save changes
                      </button>
                    </div>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default KnowledgeBase
