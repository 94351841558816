export const GET_REPORTS = "GET_REPORTS"
export const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS"
export const SET_REPORTS_SUCCESS = "SET_REPORTS_SUCCESS"
export const SET_REPORTS = "SET_REPORTS"
export const SET_SELECTED_REPORT = "SET_SELECTED_REPORTS"
export const SET_SELECTED_REPORT_ID = "SET_SELECTED_REPORT_ID"
export const SET_GENERATING = "SET_GENERATING"
export const UPDATE_REPORTS = "UPDATE_REPORTS"
export const UPLOAD_REPORTS_SUCCESS = "UPLOAD_REPORTS_SUCCESS"

export const SEARCH_REPORTS = "SEARCH_REPORTS"
export const GENERATE_REPORT = "GENERATE_REPORT"
export const GENERATE_REPORT_SUCCESS = "GENERATE_REPORT_SUCCESS"
export const GENERATE_REPORT_ERROR = "GENERATE_REPORT_ERROR"
export const UPDATE_KPI = "UPDATE_KPI"
export const UPLOAD_KPI_SUCCESS = "UPDATE_KPI_SUCCESS"
export const REGEN_KPI = "REGEN_KPI"
export const REGEN_KPI_SUCCESS = "REGEN_KPI_SUCCESS"
export const SET_SELECTED_REPORT_TO_GENERATE = "SET_SELECTED_REPORT_TO_GENERATE"

export const DELETE_REPORT = "DELETE_REPORT"
export const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS"
export const FILTER_REPORTS = "FILTER_REPORTS"
export const FILTER_REPORTS_SUCCESS = "FILTER_REPORTS_SUCCESS"    

export const DOWNLOAD_REPORT="DOWNLOAD_REPORT"
export const DOWNLOAD_REPORT_SUCCESS="DOWNLOAD_REPORT_SUCCESS"
export const SET_DOWNLOAD_DATA="SET_DOWNLOAD_DATA"


// export const GET_CUSTOMERS = "GET_CUSTOMERS"
// export const GET_OPERATORS = "GET_OPERATORS"



