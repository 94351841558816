import {GET_KBS,GET_KBS_SUCCESS,SET_KBS,UPLOAD_KB,SET_UPLOAD_KB_SUCCESS,
  EDIT_KB,SET_EDIT_KB_SUCCESS, DELETE_KB,SET_DELETE_KB_SUCCESS,GET_SELECTED_KBS,SET_SELECTED_KBS,SET_SELECTED_KBS_SUCCESS} from "./actionTypes"

export const getKBentries = data => ({
    type: GET_KBS,
    payload:data
  })
export const getKBentriesSuccess  = data =>({
    type:GET_KBS_SUCCESS,
    payload:data
})
export const setKBentries  = data =>({
  type:SET_KBS,
  payload:data
})
export const getSelectedKBentry = data => ({
  type: GET_SELECTED_KBS,
  payload:data
})
export const setSelectedKBentries  = data =>({
  type:SET_SELECTED_KBS,
  payload:data
})
export const setSelectedKBentriesSuccess  = data =>({
  type:SET_SELECTED_KBS_SUCCESS,
  payload:data
})
export const uploadKB = data=>({
  type:UPLOAD_KB,
  payload:data
})
export const setUploadKBSuccess = data =>({
  type:SET_UPLOAD_KB_SUCCESS,
  payload:data
})
export const editKB = data =>({
  type:EDIT_KB,
  payload:data
})
export const setEditKBSuccess = data =>({
  type:SET_EDIT_KB_SUCCESS,
  payload:data
})
export const deleteKB = data =>({
  type:DELETE_KB,
  payload:data
})
export const setDeleteKBsuccess = data=>({
  type:SET_DELETE_KB_SUCCESS,
  payload:data
})