import axios from "axios"
import {
  SET_NEW_COUNTRY,
  GET_NEW_COUNTRY_SUCCESS,
  SET_SELECTED_COUNTRY,
  SET_UPLOAD_COUNTRY_SUCCESS,
  SET_EDIT_COUNTRY_SUCCESS,
  SET_DELETE_COUNTRY_SUCCESS,
  SET_SELECTED_COUNTRY_SUCCESS
} from "./actionTypes"


const INIT_STATE = {
    countries: [],
    countrygetsuccess:false,
    selectedcountry: [],
    selectedcountrysuccess:false,
    uploadsuccess:false,
    editsuccess:false,
    deletesuccess:false,
}
  
const country = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NEW_COUNTRY_SUCCESS:
      // state.countrygetsuccess = action.payload;
      return {
        ...state,
        countrygetsuccess : action.payload
      }
    case SET_NEW_COUNTRY:
      // state.countries = action.payload;
      return {
        ...state,
        countries : action.payload
      }
    case SET_SELECTED_COUNTRY:
      // state.selectedcountry = action.payload;
      return {
        ...state,
        selectedcountry : action.payload
      }
    case SET_UPLOAD_COUNTRY_SUCCESS:
      // state.uploadsuccess = action.payload;
      return {
        ...state,
        uploadsuccess : action.payload
      }
    case SET_EDIT_COUNTRY_SUCCESS:
      // state.editsuccess = action.payload;
      return {
        ...state,
        editsuccess : action.payload
      }
    case SET_DELETE_COUNTRY_SUCCESS:
      // state.deletesuccess = action.payload;
      return {
        ...state,
        deletesuccess : action.payload
      }
    case SET_SELECTED_COUNTRY_SUCCESS:
      // state.selectedcountrysuccess = action.payload;
      return {
        ...state,
        selectedcountrysuccess : action.payload
      }
    default:
      return state
  }
}
  
  export default country