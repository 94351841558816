import { call, put, takeEvery, all, fork, takeLatest } from "redux-saga/effects";
import * as aC from "./actions";
import * as aT from "./actionTypes";
import * as req from "./request";
import {logoutUser} from "../auth/login/actions"
import { func } from "prop-types";


export function* getTemplates({payload}) {
    
    try {
        // console.log(payload);
        const {response,responseData} = yield call(req.fetchTemplates,payload)
        if(responseData.status==401){
            yield put(logoutUser())
        }
        yield put(aC.setTemplates(response.templates));  
        if(response.templates.length>0){
            yield put(aC.getTemplatesSuccess(true));
        }
        else{
            yield put(aC.getTemplatesSuccess('empty'));
        }
        
    } catch (error) {
        console.log(error)
    }
}
export function* getSelectedTemplate({payload}) {
        
        try {
            const {response,responseData}  = yield call(req.fetchSelectedTemplate,payload)
            if(responseData.status==401){
                yield put(logoutUser())
            }
            yield put(aC.setSelectedTemplates(response.template)); 
            yield put(aC.setSelectedTemplateSuccess(true)); 
            
        } catch (error) {
            console.log(error)
        }
}

export function* uploadTemplate({payload}) {
        
        try {
            const {response,responseData}   = yield call(req.uploadTemplate,payload)
            if(responseData.status==401){
                yield put(logoutUser())
            }
            if(response.message=="success"){
                yield put(aC.setUploadTemplateSuccess(true));
            }
        } catch (error) {
            console.log(error)
        }
}
export function* deleteTemplate({payload}) {
            try {
                const {response,responseData}   = yield call(req.deleteTemplate,payload)
                if(responseData.status==401){
                    yield put(logoutUser())
                }
                if(responseData.status==500){
                    yield put(aC.setDeleteTemplateSuccess(false));
                }
                if(response.message=="success"){
                    yield put(aC.setDeleteTemplateSuccess(true));
                }
            } catch (error) {
                console.log(error)
            }
}
export function* editTemplate({payload}) {
            try {
                const {response,responseData}   = yield call(req.editTemplate,payload)
                if(responseData.status==401){
                    yield put(logoutUser())
                }
                if(response.message=="success"){
                    yield put(aC.setEditTemplateSuccess(true));
                }
            } catch (error) {
                console.log(error)
            }
}
export function* UserSaga() {
    
    yield takeLatest(aT.GET_NEW_TEMPLATES, getTemplates);
    yield takeLatest(aT.GET_SELECTED_TEMPLATES, getSelectedTemplate);
    yield takeLatest(aT.UPLOAD_TEMPLATE, uploadTemplate);
    yield takeLatest(aT.DELETE_TEMPLATE, deleteTemplate);
    yield takeLatest(aT.EDIT_TEMPLATE, editTemplate);
    // yield takeLatest(aT.GET_COUNTRIES, getCountries);

}

export default UserSaga;