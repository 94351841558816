import {
  GET_NEW_COUNTRY,
  GET_NEW_COUNTRY_SUCCESS,
  SET_NEW_COUNTRY,
  GET_SELECTED_COUNTRY,
  SET_SELECTED_COUNTRY,
  UPLOAD_COUNTRY,
  SET_UPLOAD_COUNTRY_SUCCESS,
  EDIT_COUNTRY,
  SET_EDIT_COUNTRY_SUCCESS,
  DELETE_COUNTRY,
  SET_DELETE_COUNTRY_SUCCESS,
  SET_SELECTED_COUNTRY_SUCCESS
} from "./actionTypes"


export const getNewCountry = data => ({
    type: GET_NEW_COUNTRY,
    payload:data
})
export const getNewCountrySuccess = data => ({
  type: GET_NEW_COUNTRY_SUCCESS,
  payload:data
})
export const setNewCountry  = data =>({
  type:SET_NEW_COUNTRY,
  payload:data
})
export const getSelectedCountry = data => ({
  type: GET_SELECTED_COUNTRY,
  payload:data
})
export const setSelectedCountry  = data =>({
  type:SET_SELECTED_COUNTRY,
  payload:data
})
export const setSelectedCountrySuccess  = data =>({
  type:SET_SELECTED_COUNTRY_SUCCESS,
  payload:data
})
export const uploadCountry = data=>({
  type:UPLOAD_COUNTRY,
  payload:data
})
export const setuploadCountrySuccess = data =>({
  type:SET_UPLOAD_COUNTRY_SUCCESS,
  payload:data
})
export const editCountry = data =>({
  type:EDIT_COUNTRY,
  payload:data
})
export const setEditCountrySuccess= data =>({
  type:SET_EDIT_COUNTRY_SUCCESS,
  payload:data
})
export const deleteCountry = data =>({
  type:DELETE_COUNTRY,
  payload:data
})
export const setdeleteCountrySuccess = data=>({
  type:SET_DELETE_COUNTRY_SUCCESS,
  payload:data
})