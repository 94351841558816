import PagesMaintenance from "components/Pages/Maintenance/PagesMaintenance"
import React from "react"

export default function AnalyticsOtherChannelDelivery() {
  return (
    <div className="mt-5 pt-5">
      <PagesMaintenance />
    </div>
  )
}
