export const GET_NEW_COUNTRY = "GET_NEW_COUNTRY"
export const GET_NEW_COUNTRY_SUCCESS = "GET_NEW_COUNTRY_SUCCESS"
export const SET_NEW_COUNTRY= "SET_NEW_COUNTRY"
export const GET_SELECTED_COUNTRY = "GET_SELECTED_COUNTRY"
export const SET_SELECTED_COUNTRY= "SET_SELECTED_COUNTRY"
export const SET_SELECTED_COUNTRY_SUCCESS= "SET_SELECTED_COUNTRY_SUCCESS"
export const UPLOAD_COUNTRY = "UPLOAD_COUNTRY"
export const SET_UPLOAD_COUNTRY_SUCCESS = "SET_UPLOAD_COUNTRY_SUCCESS"
export const EDIT_COUNTRY = "EDIT_COUNTRY"
export const SET_EDIT_COUNTRY_SUCCESS= "SET_EDIT_COUNTRY_SUCCESS"
export const DELETE_COUNTRY = "DELETE_COUNTRY"
export const SET_DELETE_COUNTRY_SUCCESS = "SET_DELETE_COUNTRY_SUCCESS"
