import React from "react"

function Dashboarddetails({ head2, head1, text1, text2 }) {
  return (
    <div>
      <div
        style={{
          fontFamily: "Poppins",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "36px",
          letterSpacing: "0em",
          textAlign: "left",
        }}
      >
        {head2}
      </div>
      <div
        style={{
          fontFamily: "Poppins",
          fontSize: "18px",
          fontWeight: 500,
          lineHeight: "36px",
          letterSpacing: "0em",
          textAlign: "left",
        }}
      >
        {head1}
      </div>

      <div
        style={{
          fontFamily: "Poppins",
          fontSize: "13px",
          fontWeight: 700,
          lineHeight: "20px",
          letterSpacing: "0em",
          textAlign: "left",
          color: "#B7B8BB",
        }}
      >
        {text1}
      </div>

      <div
        style={{
          fontFamily: "Poppins",
          fontSize: "13px",
          fontWeight: 600,
          lineHeight: "24px",
          letterSpacing: "0.17000000178813934px",
          textAlign: "left",
        }}
      >
        {text2}
      </div>
    </div>
  )
}

export default Dashboarddetails
