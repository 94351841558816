import { GENERATE_REPORT, GENERATE_REPORT_ERROR, GENERATE_REPORT_SUCCESS, GET_REPORTS, SEARCH_REPORTS, SET_GENERATING, 
  SET_REPORTS, SET_SELECTED_REPORT,SET_SELECTED_REPORT_ID,UPDATE_REPORTS,UPLOAD_REPORTS_SUCCESS,UPDATE_KPI,
  UPLOAD_KPI_SUCCESS,REGEN_KPI,
  REGEN_KPI_SUCCESS,DELETE_REPORT,DELETE_REPORT_SUCCESS,
  FILTER_REPORTS,FILTER_REPORTS_SUCCESS,DOWNLOAD_REPORT,
  DOWNLOAD_REPORT_SUCCESS,SET_DOWNLOAD_DATA,SET_REPORTS_SUCCESS,GET_REPORTS_SUCCESS,SET_SELECTED_REPORT_TO_GENERATE} from "./actionTypes"

export const getReportsSuccess = data => ({
  type: GET_REPORTS_SUCCESS,
  payload:data
})
export const setReportsGetSuccess = data =>({
  type: SET_REPORTS_SUCCESS,
  payload:data
})
export const setDownloadData = data => ({
  type: SET_DOWNLOAD_DATA,
  payload:data
})
export const downloadReport = data => ({
  type: DOWNLOAD_REPORT,
  payload:data
})
export const downloadReportSuccess = data => ({
  type: DOWNLOAD_REPORT_SUCCESS,
  payload:data
})
export const filterReports = data => ({
  type: FILTER_REPORTS,
  payload:data
})
export const filterReportsSuccess = data => ({
  type: FILTER_REPORTS_SUCCESS,
  payload:data
})
export const deleteReport = data => ({
  type: DELETE_REPORT,
  payload:data
})
export const setdeleteReportSuccess = data => ({
  type: DELETE_REPORT_SUCCESS,
  payload:data
})
export const getReports = data => ({
  type: GET_REPORTS,
  payload:data
})
export const setReports = data => ({
  type: SET_REPORTS,
  payload:data
})
export const generateReportSuccessful = data => ({
  type: GENERATE_REPORT_SUCCESS,
  payload:data
})
export const generateReportError = data => ({
  type: GENERATE_REPORT_ERROR,
  payload:data
})
export const setSelectedReport= data => ({
  type: SET_SELECTED_REPORT,
  payload:data
})
export const setSelectedReportID = data => ({
  type: SET_SELECTED_REPORT_ID,
  payload:data
})
export const setGenerating = data => ({
  type: SET_GENERATING,
  payload:data
})
export const searchReports = data => ({
  type: SEARCH_REPORTS,
  payload:data
})
export const generateReport = data => ({
  type: GENERATE_REPORT,
  payload:data
})
// export const getCustomers = data => ({
//   type: GET_CUSTOMERS
// })
// export const getOperators = data => ({
//   type: GET_OPERATORS
// })
export const updateReports = data => ({
  type: UPDATE_REPORTS,
  payload: data,
})
export const updateReportSuccess = data => ({
  type: UPLOAD_REPORTS_SUCCESS,
  payload: data,
})
export const updateKpi = data => ({
  type: UPDATE_KPI,
  payload: data,
})
export const updateKpiSuccess = data => ({
  type: UPLOAD_KPI_SUCCESS,
  payload: data,
})
export const regenKpi = data => ({
  type: REGEN_KPI,
  payload: data,
})
export const regenKpiSuccess = data => ({
  type: REGEN_KPI_SUCCESS,
  payload: data,
})
export const setSelectedReportToGenerate = data => ({
  type: SET_SELECTED_REPORT_TO_GENERATE,
  payload: data,
})


