import * as types from './actionTypes'

const INIT_STATE = {
    reportsbatch :[],
    customers:[],
    partners:[],
    operators:[],
    templates:[],
    countries:[],
    pairsArray: [],
    recentUploads:[],
    uploadSuccess:false,
    deleteSuccess:false,
    updateSuccess:false,
}
const History = (state = INIT_STATE, action) => {
    switch (action.type) {
        case types.UPLOAD_REPORT:
            // state.pairsArray = [];
            return{
                ...state,
                pairsArray : []
            }
        case types.SET_REPORT_DATA:
            let tempArray = state.pairsArray;
            tempArray.push(action.payload)
            return {
                ...state,
                pairsArray: tempArray,
            }
        case types.SET_CUSTOMERS:
            // state.customers = action.payload
            return {
                ...state,
                customers : action.payload
            }
        case types.SET_PARTNERS:
            // state.partners = action.payload
            return {
                ...state,
                partners : action.payload
            }
        case types.SET_OPERATORS:
            // state.operators = action.payload
            return {
                ...state,
                operators : action.payload
            }
        case types.SET_OPERATORS:
            // state.operators = action.payload
            return {
                ...state,
                operators : action.payload
            }
        case types.SET_COUNTRIES:
        // state.countries = action.payload
        return {
            ...state,
            countries : action.payload
        }
        case types.SET_TEMPLATES:
            // state.templates = action.payload
            return {
                ...state,
                templates : action.payload
            }
        case types.SET_RECENTLY_UPLOADED_REPORTS:
            // state.recentUploads = action.payload
            return {
                ...state,
                recentUploads : action.payload
            }
        case types.UPLOAD_REPORT_SUCCESS:
            // state.uploadSuccess = action.payload
            return {
                ...state,
                uploadSuccess : action.payload
            }
        case types.DELETE_REPORT_SUCCESS:
            // state.deleteSuccess = action.payload
            return {
                ...state,
                deleteSuccess : action.payload
            }
        case types.REMOVE_PAIR:
            // var id = action.pairId;
            // // console.log("id :",id)
            // for (let i = 0; i < state.pairsArray.length; i++) {
            //     const element = state.pairsArray[i];
            //     if (element.pairId == id) {
            //     state.pairsArray.splice(i, 1)
            //     }

            // }
            // console.log(state.pairsArray)
            const id = action.pairId;
            const newPairsArray = state.pairsArray.filter(element => element.pairId !== id);
          
            return {
              ...state,
              pairsArray: newPairsArray,
            }
        default:
            return state
  
            
    }
}
export default History;