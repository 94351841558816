import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import dotenv from 'dotenv'
import * as urls from "../../helpers/url_helper"

dotenv.config()
const url = process.env.LOCALHOST?process.env.LOCALHOST:'https://qa_api_gtc.amerck.com'

export const operatorAPI = createApi({
  reducerPath: 'operatorApi',
  baseQuery: fetchBaseQuery({ baseUrl: url, timeout: 10000 }),
  endpoints: (builder) => ({
    getOperators: builder.query({
      query: ({customer_body}) => ({
        url: `${urls._GET_NEW_OPERATORS}`,
        method: 'POST',
        body:customer_body,
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        timeout: 4000,
      }),
    }),
    getOperatorsByCountry: builder.query({
      query: ({country_id}) => ({
        url: `${urls._GET_OP_BY_COUNTRY}`,
        method: 'GET',
        params: {
          country_id: country_id,
        },
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        timeout: 4000,
      }),
    }),
    getGatekeepers: builder.query({
      query: ({operator_id}) => ({
        url: `${urls._GET_GATEKEEPERS}`,
        method: 'GET',
        params: {
          operator_id: operator_id,
        },
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        timeout: 4000,
      }),
    }),
    getOperatorsByCountryAndCustomer: builder.query({
      query: ({country_id,customer_id}) => ({
        url: `${urls._GET_OP_BY_COUNTRY_AND_CUSTOMER}`,
        method: 'GET',
        params: {
          country_id: country_id,
          customer_id: customer_id,
        },
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        timeout: 4000,
      }),
    }),
    getCustomerOperatorsByCountry: builder.query({
      query: ({country_id}) => ({
        url: `${urls._GET_CUSTOMER_OP_BY_COUNTRY}`,
        method: 'GET',
        params: {
          country_id: country_id,
        },
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        timeout: 4000,
      }),
    })
  }),
})

export const {
  useGetOperatorsQuery,
  useLazyGetOperatorsByCountryQuery,
  useLazyGetGatekeepersQuery,
  useLazyGetOperatorsByCountryAndCustomerQuery,
  useLazyGetCustomerOperatorsByCountryQuery
} = operatorAPI