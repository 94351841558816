import React from "react"
import { Container, Row, Col } from "reactstrap"
//Import Images
import maintenance from "../../../assets/images/maintanence3.gif"

const PagesMaintenance = () => {
  //meta title
  document.title = "Maintenance | GTC"

  return (
    <React.Fragment>
      <section className="mt-5">
        <Container>
          <Row>
            <Col className="col-12 text-center">
              <div className="home-wrapper">
                <Row className="justify-content-center">
                  <Col sm={4}>
                    <div className="maintenance-img">
                      <img
                        src={maintenance}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
                <h3 className="mt-4">This Page is Under Maintenance</h3>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default PagesMaintenance
