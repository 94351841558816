import { call, put, takeEvery, all, fork, takeLatest } from "redux-saga/effects";
import { setNewCustomers, getCustomerSuccess,setEditCustomerSuccess, setuploadCustomerSuccess, setdeleteCustomerSuccess,setSelectedCustomer,setCountries,setNewOperators,setNewCountry, setSelectedCustomerSuccess} from "./actions";
import { GET_NEW_CUSTOMER,UPLOAD_CUSTOMER,EDIT_CUSTOMER,DELETE_CUSTOMER,GET_SELECTED_CUSTOMER,GET_COUNTRIES,GET_NEWOPERATORS, GET_NEWCOUNTRIES} from "./actionTypes";
import {fetchNewCustomers, fetchDeleteCustomer, fetchSelectedCustomer, dispatchEditCustomer, dispatchNewCustomer,fetchCountries,fetchNewOperators, fetchNewCountries} from "./request";
import {logoutUser} from "../auth/login/actions"


export function* getNewCustomers({payload}) {
    
    try {
        const response = yield call(fetchNewCustomers,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        if(response.message="Success"){
            yield put(setNewCustomers(response.data)); 
            if(response.data.length>0){
                yield put(getCustomerSuccess(true));
            }
            else{
                yield put(getCustomerSuccess('empty'));
            }
        }
        
    } catch (error) {
        console.log(error)
    }
}
export function* getNewCountry({payload}) {
    
    try {
        const response = yield call(fetchNewCountries,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        yield put(setNewCountry(response));  
        
    } catch (error) {
        console.log(error)
    }
}
export function* getCountries({payload}) {
    
    try {
        const response = yield call(fetchCountries,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        yield put(setCountries(response));  
        
    } catch (error) {
        console.log(error)
    }
}
export function* getNewOperators({payload}) {
    
    try {
        const response = yield call(fetchNewOperators,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        yield put(setNewOperators(response.data));

    } catch (error) {
        console.log(error)  
    }
}
export function* getSelectedCustomer({payload}) {
    
    try {
        const response = yield call(fetchSelectedCustomer,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        yield put(setSelectedCustomer(response)); 
        yield put(setSelectedCustomerSuccess(true)); 
        
    } catch (error) {
        console.log(error)
    }
}

export function* uploadNewCustomer({payload}) {
    try {
       
        const response = yield call(dispatchNewCustomer,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        if(response.message=='New Customer Saved'){
            yield put(setuploadCustomerSuccess(true))
        }       
    } catch (error) {
        console.log(error)
    }
}

export function* EditCustomer({payload}) {
    try {
       
        const response = yield call(dispatchEditCustomer,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        if(response.message=="Edits Saved"){
            yield put(setEditCustomerSuccess(true))
        }       
    } catch (error) {
        console.log(error)
    }
}

export function* deleteCustomer({payload}) {
    try {
       
        const response = yield call(fetchDeleteCustomer,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        if(response.message=="Deleted"){
            yield put(setdeleteCustomerSuccess(true))
        }       
    } catch (error) {
        console.log(error)
    }
}

export function* KBSaga() {
   
    yield takeLatest(GET_NEW_CUSTOMER, getNewCustomers);
    yield takeLatest(GET_COUNTRIES, getCountries);
    yield takeLatest(UPLOAD_CUSTOMER, uploadNewCustomer);
    yield takeLatest(EDIT_CUSTOMER, EditCustomer);
    yield takeLatest(DELETE_CUSTOMER, deleteCustomer);
    yield takeLatest(GET_SELECTED_CUSTOMER, getSelectedCustomer);
    yield takeLatest(GET_NEWOPERATORS, getNewOperators);
    yield takeLatest(GET_NEWCOUNTRIES, getNewCountry);



}

export default KBSaga;