import * as url from "../../../helpers/url_helper"

export async function fetchTemplates() {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const responseData = await fetch(url._GET_TEMPLATES_LIST, {
            method: "GET",
            headers: headers,
        })
        const response = await responseData.json()
        return {response,responseData}
    } catch (err) {
        return console.log(err)
    }

}

export async function fetchSelectedTemplate(payload) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
        }
        const responseData = await fetch(url._SELECTED_TEMPLATE, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        })
        const response = await responseData.json()
        return {response,responseData}
    } catch (err) {
        return console.log(err)
    }

}

export async function uploadTemplate(payload) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
        }
        const responseData = await fetch(url._UPLOAD_TEMPLATE, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        })
        const response = await responseData.json()
        return {response,responseData}
    } catch (err) {
        return console.log(err)
    }

}
export async function deleteTemplate(payload) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
        }
        const responseData = await fetch(url._DELETE_TEMPLATE, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        })
        const response = await responseData.json()
        return {response,responseData}
    } catch (err) {
        return console.log(err)
    }

}
export async function editTemplate(payload) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
        }
        const responseData = await fetch(url._EDIT_TEMPLATE, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        })
        const response = await responseData.json()
        return {response,responseData}
    } catch (err) {
        return console.log(err)
    }
}