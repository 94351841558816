import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import dotenv from 'dotenv'
import * as urls from "../../helpers/url_helper"

dotenv.config()
const url = process.env.LOCALHOST?process.env.LOCALHOST:'https://qa_api_gtc.amerck.com'

export const inspectorAPI = createApi({
  reducerPath: 'inspectorAPI',
  baseQuery: fetchBaseQuery({timeout: 10000 }),
  endpoints: (builder) => ({
    // getCombinedFiles : builder.mutation({
    //     query: ({formData}) => ({
    //         url: urls.SMSC_INSPECTOR,
    //         method: 'POST',
    //         headers: {
    //             auth: JSON.parse(localStorage.getItem("authUser")),
    //             "Access-Control-Allow-Origin": "*",
    //             "Access-Control-Allow-Credentials": "true",
    //         },
    //         body: formData,
    //     }),
    // })
    getCombinedFiles : builder.mutation({
      query: ({formData}) => ({
        url: urls.SMSC_INSPECTOR,
        method: 'POST',
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        body: formData,
        responseType: 'arraybuffer', // Inform axios to expect a stream of bytes in the response
      }),
    })
  }),
})

export const {useGetCombinedFilesMutation} = inspectorAPI