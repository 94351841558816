import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Button, Table, Input,  Modal, ModalFooter,  Container } from "reactstrap"
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux"

const EditCountry = (props) => {


const EditData = props.data;
const EditData_fields = EditData?.message ?? {};
// console.log(EditData_fields);

const setCountryName = props.set_country_name;
const setCountryCode = props.set_countryCode;
const setMobileCountryCode = props.set_mobileCountryCode;
const setISO3 = props.set_ISO3;
const setSubscriber = props.set_subscribe;
const edit_CountryCode = props.edit_CountryCode;
const edit_MobileCountryCode = props.edit_MobileCountryCode;
const edit_ISO3 = props.edit_ISO3;
const [subscribers, setSubscribers] = useState("");
useEffect(() => {
  if(EditData_fields){
    setCountryCode(EditData_fields?.country_code);
    setMobileCountryCode(EditData_fields?.mobile_country_code);
    setISO3(EditData_fields?.iso_3);
  }
}, [EditData_fields])


const dispatch = useDispatch(); 

return (
    <React.Fragment>
        <form>
                      <div className="customer-field-container" >
                        <Row>
                          <Col md="4">
                          <label className="lable-customer">Country Name</label>
                          </Col>
                          <Col md="8">
                          <Input
                            type="name"
                            className="input-operator"
                            placeholder="Enter the Name"
                            defaultValue={EditData_fields?.country_name}
                            onChange={(e)=>{
                              // setCountryName(e.target.value)
                              EditData_fields.country_name=e.target.value;
                          }}
                          />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4">
                          <label className="lable-customer">Mobile Country Code</label>
                          </Col>
                          <Col md="8">
                          <Input
                            type="name"
                            className="input-operator"
                            placeholder="Enter the Code"
                            value={edit_MobileCountryCode}
                            // defaultValue={EditData_fields?.country_code}
                            // defaultValue={EditData_fields?.mobile_country_code}
                            onChange={(e)=>{
                              let inputValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 3);
                              if (inputValue.length > 0) {
                                inputValue = `+${inputValue}`;
                              }
                              setMobileCountryCode(inputValue);
                              // EditData_fields.mobile_country_code=e.target.value;
                              EditData_fields.mobile_country_code=inputValue;
                              // EditData_fields.country_code=e.target.value;
                            }}
                          />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4">
                          <label className="lable-customer">Country Code</label>
                          </Col>
                          <Col md="8">
                          <Input
                            type="name"
                            className="input-operator"
                            placeholder="Enter the Code"            
                            value={edit_CountryCode}
                            // defaultValue={EditData_fields?.country_code}
                            // defaultValue={EditData_fields?.mobile_country_code}
                            onChange={(e)=>{
                              let inputValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 3);
                              if (inputValue.length > 0) {
                                inputValue = `+${inputValue}`;
                              }
                              // EditData_fields.country_code=e.target.value;
                              EditData_fields.country_code=inputValue; 
                              setCountryCode(inputValue);
                              // EditData_fields.mobile_country_code=e.target.value;
                            }}
                          />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4">
                          <label className="lable-customer">ISO 3 Code</label>
                          </Col>
                          <Col md="8">
                          <Input
                            type="name"
                            className="input-operator"
                            placeholder="Enter the Code"                            
                            // defaultValue={EditData_fields?.iso_3}
                            value={edit_ISO3}
                            onChange={(e)=>{
                              let inputValue = e.target.value.slice(0, 3).toUpperCase();
                              inputValue = inputValue.replace(/[^A-Z]/g, '');
                              // EditData_fields.iso_3=e.target.value;
                              EditData_fields.iso_3=inputValue;
                              setISO3(inputValue);
                            }}
                          />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4">
                          <label className="lable-customer">Country Subscriber</label>
                          </Col>
                          <Col md="8">
                          {/* <Input
                            type="name"
                            className="input-operator"
                            placeholder="Enter the Subscriber"                            
                            defaultValue={EditData_fields?.subscriber}
                            onChange={(e)=>{
                              EditData_fields.subscriber=e.target.value;
                            }}
                          /> */}
                          <Input
                            type="number"
                            className="input-operator"
                            placeholder="Enter the Subscriber"
                            defaultValue={EditData_fields?.subscriber}
                            // value={subscribers?subscribers:EditData_fields?.subscriber}
                            onChange={(e) => {
                              // const value = parseInt(e.target.value.replace(/\./g, '')); // Remove existing dots and parse the integer value
                              // EditData_fields.subscriber = value.toLocaleString('en-US', { useGrouping: true, groupingSeparator: '.' }); // Format the value with separators
                              EditData_fields.subscriber = e.target.value// Format the value with separators
                              // console.log(EditData_fields.subscriber);
                            }}
                          />
                          </Col>
                        </Row>
                        
                      </div>
                     
                    

                      
                    </form>
    </React.Fragment>
  )
}

export default EditCountry
