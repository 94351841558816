import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardTitle,
  Container,
  Table,
  Button,
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter
} from "reactstrap"
import Select from "react-select"
import LoadingSpinnerUpload from "./LoadingSpinner_upload"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import { isEmpty, size, map, forEach, set, keys } from "lodash"
//redux
import { useSelector, useDispatch } from "react-redux"
import * as history from "store/old/history/actions"
import { getNewCustomers } from "store/old/customer/actions"
import { getNewOperators } from "store/old/operator/actions"
import excelimage from "../../../assets/images/excel.png"
import moment from "moment"
import axios from "axios"
import toastr from "toastr";
import Swal from "sweetalert2"
import "toastr/build/toastr.min.css";
import store from "../../../store/index";
import * as XLSX from "xlsx";



const History = () => {
  const dispatch = useDispatch()
  const [selectedOperator, setOperator] = useState("")
  const [selectedCustomer, setCustomer] = useState("")
  const [selectedDate, setTestDate] = useState("")
  const [modal_pipe_upload, setmodal_pipe_upload] = useState(false)
  const [selectedReportFile, setSelectedReportFile] = useState([])
  const [selectedPartnerFiles, setselectedPartnerFiles] = useState([])
  const [pairNumber, setPairNumber] = useState(0)

  const pairsArray = useSelector((state)=>state.history.pairsArray);

  useEffect(() => {
    // dispatch(history.getRecentlyUploadedReports())
    dispatch(getNewCustomers())
    dispatch(getNewOperators())
    dispatch(history.getPartners())
    // dispatch(history.getTemplates())

  }, [])

  // const [recentUploads, setRecentUploads] = useSelector();
  // const recentUploads = useSelector((state)=>state.SourceUpload.recentUploads);
  const customertest = useSelector((state)=>state.customer.customers);
  const customer = customertest.map(obj=>{
    return {id:obj.id,label:obj.name,value:obj.name}
  })
  const operatortest = useSelector((state)=>state.operator.operators);
  const operator = operatortest.map(obj=>{
    return {id:obj.id,
      label:obj.operator_name+" "+obj.country, 
      value:obj.operator_name+" "+obj.country}
  })

  function handleAcceptedServerFiles(files) {
    if(files.length!=0){
      // console.log(files);
      setSelectedReportFile([])
      const reader = new FileReader();
      let sheetnamecheck = true;
      reader.onload = () => {
        const fileData = reader.result;
        const workbook = XLSX.read(fileData, { type: 'binary' });
        const expectedSheetNames = [
          'KPI Tracking',
          'Official GTs',
          'KPI Graphs',
        ]
        if(!workbook.SheetNames.includes(expectedSheetNames[0])){
          sheetnamecheck = false;
        }
        workbook.SheetNames.forEach(async (sheetName,index) => {
          if(!expectedSheetNames.includes(sheetName)){
            //check if the sheet is of the format yyyy-mm-dd
            if(!sheetName){
              console.log("Invalid sheet name",sheetName);
              // console.log("Invalid sheet name",sheetName);
              sheetnamecheck = false;
              // return;
            }
          }
        })
        // workbook.SheetNames.forEach(async (sheetName,index) => {
        // })
        // const sheetName = workbook.SheetNames[0];
        // const worksheet = workbook.Sheets[sheetName];
        // const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    
        // Check the order of the header columns
        // const expectedHeaders = ['Message ID', 'Date', 'Username'];
        // const actualHeaders = data[0].slice(0,3);
        // console.log(actualHeaders);
        // const isHeaderOrderCorrect = expectedHeaders.every((header, index) => actualHeaders[index] === header);
    
        if (!sheetnamecheck) {
          // Handle error here
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Incorrect File Uploaded. Please upload a valid Historical Data file or check the Sheet Names for invalid Sheet Names',
          })
          // console.log('Incorrect File Uploaded. Please upload a valid test file');
          return;
        }
        files.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          })
        )
        setSelectedReportFile(files)
      }
      reader.readAsBinaryString(files[0]);
    }
  }



  function AddToBatchHandler() {
    if(selectedReportFile[0]!=null){
      if(pairNumber==0){
        setPairNumber(pairNumber + 1)

        const pairData = {
          pairId: pairNumber + 1,
          files: {
            report: selectedReportFile[0],
          },
          partner: "",
        }
    
        // setPairArray(pairsArray => [...pairsArray, pairData])
        dispatch(history.setReportData(pairData))
    
    
        setselectedPartnerFiles([])
        setSelectedReportFile([])
      }
      else{
        // alert("You can only upload one report document at a time.")
        Swal.fire({
          icon: 'error',
          // title: 'Oops...',
          text: 'You can only upload one report document at a time.',
          // footer: '<a href="">Why do I have this issue?</a>'
        })
      }
    }
    else{
      alert("Please add a Report Document to the Dropzone.")
    }

  }

  function epoch(date) {
    return Date.parse(date)
  }

  async function UploadButtonHandler() {
  
    if(selectedCustomer && selectedOperator){
      const dateToday = new Date()
      const timestamp = epoch(dateToday)
      setmodal_pipe_upload(true)
     
      var formData = new FormData()
      var key = []
      for (let i = 0; i < pairsArray.length; i++) {
        var element = pairsArray[i]
       
        key.push(element.pairId)
        // formData.append("pair_" + element.pairId + "_fpartner", element.files.partner)
        formData.append("pair_" + element.pairId + "_freport", element.files.report)
        formData.append("pair_" + element.pairId + "_partner", element.partner)
        
      }
      formData.append("reportFile",selectedReportFile[0])
      formData.append("customer", selectedCustomer)
      formData.append("operator", selectedOperator)
      // formData.append("test_date", selectedDate)
      // formData.append("template", selectedTemplate)
      formData.append("timeStamp", timestamp)
      formData.append("keys", JSON.stringify(key))
  
  
      dispatch(history.uploadReport(formData))
      setPairNumber(0)
      let uploadPromise = new Promise(async(resolve,reject)=>{
        store.subscribe(()=>{
          const deleteSuccess = store.getState();
          const status = deleteSuccess.history.uploadSuccess
          if(status == true){
            // console.log(status);
            resolve("Done")
          }
        })
      })
      await uploadPromise.then(async(msg)=>{
        // Toast.fire({
        //   icon: 'success',
        //   title: 'Delete Success!'
        // })
        setmodal_pipe_upload(false);
        // console.log("Upload Success");
        Swal.fire('Upload Success!', '', 'success')
        // Toast.fire({
        //   icon: 'success',
        //   title: 'File Deleted'
        // })
        setCustomer('')
        setOperator('')
        dispatch(history.uploadReportSuccess(false))
      })
    }
    else{
      alert("Please fill up the required fields.")
    }
    
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }


  useEffect(() => {
    var now = new Date()
    var dateString = moment(now).format("YYYY-MM-DD")
    setTestDate(dateString)

  }, [])

  


  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{ maxWidth: "100%", backgroundColor: "#ffffff" }}
      >
        <MetaTags>
          <title>Historical Data</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Historical Data Upload" breadcrumbItem="Reports" />
          <Row>
            <h5 style={{ paddingTop: "10px", fontWeight: "bold" }}>
              Upload here
            </h5>
          </Row>
          <Row>
            <Col>
              <Card style={{ boxShadow: "none" }}>
                <CardBody>
                  <CardTitle>Historical Data File (Sheet)</CardTitle>

                  <Form>
                    <Dropzone
                      maxFiles={1}
                      multiple={false}
                      validator={file => {
                        var x = file.path?.split(".")
                        let extension = x[x.length - 1]
                        if (
                          extension === "xlsx" ||
                          extension === "xls" ||
                          extension === "csv"
                        ) {
                          // return null
                        } else {
                          alert("Please Select SpreadSheet File to Continue")
                          return {
                            error: "Please Select SpreadSheet File to Continue",
                          }
                        }
                      }}
                      onDrop={acceptedFiles => {
                        handleAcceptedServerFiles(acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedReportFile.map((f, i) => {
                        return (
                          <Card
                            style={{ boxShadow: "none" }}
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="rounded bg-light"
                                    // alt={f.name}
                                    src={excelimage}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            {/* <Col>
               <Card style={{ boxShadow: "none" }}>
                <CardBody>
                  <CardTitle>GTC Partner</CardTitle>

                  <Form>
                    <Dropzone
                      maxFiles={1}
                      multiple={false}
                      validator={file => {
                        var x = file.path?.split(".")
                        let extension = x[x.length - 1]
                        if (
                          extension === "xlsx" ||
                          extension === "xls" ||
                          extension === "csv"
                        ) {
                          return null
                        } else {
                          alert("Please Select SpreadSheet File to Continue")
                          return {
                            error: "Please Select SpreadSheet File to Continue",
                          }
                        }
                      }}
                      onDrop={acceptedFiles => {
                        handleAcceptedPartnerFiles(acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedPartnerFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
          <Row>
            <div className="d-flex ">
              {selectedPartnerFiles.length != 1 &&
                selectedReportFile.length != 1 ? (
                <Button
                  color="danger"
                  className="btn btn-custom md-position-absolute ms-auto p-2 bd-highlight"
                  disabled
                >
                  Add to Reports Batch
                </Button>
              ) : (
                <>
                  <Button
                    color="danger"
                    className="btn btn-custom md-position-absolute ms-auto p-2 bd-highlight"
                    onClick={AddToBatchHandler}
                  >
                    Add to Reports Batch
                  </Button>
                </>
              )}
            </div>
          </Row>
          <Row>
            <h5 style={{ paddingTop: "10px", fontWeight: "bold" }}>
              Pending Report Upload
            </h5>
          </Row>
          <div style={{ marginLeft: "30px" }} className="container">
            {pairsArray?.map((file, key) => {
              return (
                <div key={key} style={{ paddingBottom: "20px" }}>
                  <div className="row" id={"pair" + file.pairId}>
                    <div className="col-sm"> {file.pairId}</div>
                    <div className="col-sm">
                      {" "}
                      <i className="fas fa-ad far fa-file-excel text-success"></i>{" "}
                      {file?.files?.report.path} 
                    </div>
                    {/* <div className="col-sm">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="Select Partner"
                        isSearchable={true}
                        name="Partners"
                        onChange={value =>
                          partnerOnChangeHandler(value, file.pairId)
                        }
                        options={partners}
                      />
                    </div> */}
                    <div className="col-sm">
                      <Button className="align-middle" onClick={() => {
                        dispatch(history.removeItemFromArray(file.pairId))
                        setPairNumber(pairNumber - 1)
                      }}>del</Button>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          {pairsArray.length == 0 ? <></> : <><Row style={{ marginTop: "20px" }}>
            <Col sm="6" md="6" lg="6">
              <div>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable={true}
                  name="Customer"
                  placeholder="Select Customer"
                  options={[
                    { value: '', label: 'No Customer Selected' },
                    ...customer
                  ]}
                  onChange={e => {
                    // console.log(e.id);
                    if(e.id){
                    setCustomer(e.id)
                    dispatch(getNewOperators({
                      customer_id: e.id
                    }))
                    }
                    if(e.value === ''){
                    setCustomer('')
                    dispatch(getNewOperators())
                    }
                  }}
                />
              </div>
            </Col>

            <Col sm="6" md="6" lg="6">
              <div>
                <Select
                  placeholder="Select Operator"
                  className="basic-single"
                  classNamePrefix="select"
                  value={operator.filter(function (option) {
                    return option.id === selectedOperator
                  })}
                  isSearchable={true}
                  name="Operator"
                  options={[
                    { value: '', label: 'No Operator Selected' },
                    ...operator
                  ]}
                  onChange={e => {
                    if(e.id){
                    setOperator(e.id)
                    }
                    if(e.value === ''){
                    setOperator('')
                    }
                  }}
                />
              </div>
            </Col>
            {/*<Col sm="4" md="4" lg="4" style={{
              paddingTop: "5px"
            }}>
              <input
                className="form-control"
                type="date"
                id="test-date-input"
                style={{ height: "38px" }}
                value={selectedDate}
                onChange={e => {
                  setTestDate(e.target.value)
                  // setTestDate(1)
                }}
              />
            </Col>
              */}
            {/* <Col sm="3" md="3" lg="3">
              <div>
                <Select
                  placeholder="Select Template"
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable={true}
                  name="template"
                  options={template}
                  onChange={e => {
                    setTemplate(e.id)
                  }}
                />
              </div>
            </Col> */}
          </Row>
            <Row style={{ marginTop: "10px", backgroundColor: "white" }}>
              <Col>
                <div className="d-flex ">
                  <Button
                    color="danger"
                    className="btn btn-custom md-position-absolute ms-auto p-2 bd-highlight"
                    onClick={()=>{
                      UploadButtonHandler()
                    }}
                  >
                    Upload Reports
                  </Button>
                </div>
              </Col>
            </Row></>}

          {/* <Row style={{ marginTop: "100px", backgroundColor: "white" }}>
            <Row>
              <h5 style={{ paddingTop: "10px", fontWeight: "bold" }}>
                Recently Uploaded Reports{" "}
              </h5>
            </Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div
                    className="table-responsive"
                    hidden={!recentUploads?.length > 0}
                  >
                    <Table className="align-middle mb-0">
                      <thead>
                        <tr>
                          <th>Report</th>
                          <th>Date Uploaded</th>
                          <th>Uploaded By</th>
                          <th>Actions</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {recentUploads?.map(file => {
                          return (
                            <tr id={file.id} key={file.id}>
                              <td>
                                <i className="fas fa-ad far fa-file-excel text-success"></i>{" "}
                                {file.sourcedata}
                              </td>

                              <td>{file.createdAt}</td>
                              
                              Anushka
                              <td>{file.id}</td> 

                              <td>
                                <div className="d-flex gap-3">
                                  <Link className="text-danger" to="#">
                                    <i
                                      className="bx bx-x font-size-30"
                                      id="deletetooltip"
                                      onClick={() =>
                                        handleDeleteUser(file.id)
                                      }
                                    ></i>
                                  </Link>
                                  <Link className="text-success" to="#">
                                    <i
                                      className="bx bx-down-arrow-alt font-size-30"
                                      id="edittooltip"
                                      onClick="window.open('file.doc')"
                                      onClick={() => handleUserClick(file.id)}
                                    ></i>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </Container>
      </div>
      <Modal isOpen={modal_pipe_upload}>
        <ModalHeader>Source Upload</ModalHeader>
        <ModalBody>
          <LoadingSpinnerUpload/>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </Modal>  
    </React.Fragment>
  )
}

export default History
