import { call, put, takeEvery, all, fork, takeLatest } from "redux-saga/effects";

// Crypto Redux States
import { CLEAR_PAIRS, DELETE_BATCH, GET_CUSTOMERS, GET_OPERATORS, GET_PARTNERS, GET_RECENTLY_UPLOADS, GET_TEMPLATES, GET_UPLOADED_BATCHES, SEARCH_UPLOADED_BATCHES, UPLOAD_BACTCH, UPLOAD_BACTCH_SUCCESS} from "./actionTypes";
import { setBatchData, setCustomers, setOperators, setPartners, 
    setRecentlyUploads, setSearchedBatches, setTemplates, 
    setUploadBatches, searchBatchSuccess, uploadBatchSuccess,setDeleteBatchSuccess } from "./actions";
import { fetchCustomers, fetchOperators, fetchPartners, fetchSearchUploadedBatches, 
    fetchTemplates, fetchUploadedBatches, fetchUploadedFiles, 
    uploadFiles,fetchDeleteBatch } from "./request";



export function* deleteBatch({ payload: data }) {
        try {
    
            const response = yield call(fetchDeleteBatch,data)
            
            if (response.message === "Batch Deleted Successfully") { 
                if(process.env.NODE_ENV === 'development'){
                    // console.log("Batch successfully Deleted.")
                }
                yield put(setDeleteBatchSuccess(true))
            }
    
        } catch (error) {
            console.log(error)
        }
    }
export function* onUpload({ payload: data }) {
    try {

        const response = yield call(uploadFiles,data)
        
        if (response.message === "Batch has been saved.") { 
            yield put(uploadBatchSuccess(true))
        }

    } catch (error) {
        console.log(error)
    }
}

export function* getRecentlyUpload(dispatch) {
    try {
        // const getSourceFiles = () => get(url.GET_SOURCE_FILES);
        const response = yield call(fetchUploadedFiles)
        yield put(setRecentlyUploads(response));


    } catch (error) {
        console.log(error)
    }
}
export function* getUploadedBatches(dispatch) {
    try {
        
        const response = yield call(fetchUploadedBatches)
        yield put(setUploadBatches(response));


    } catch (error) {
        console.log(error)
    }
}

export function* getCustomers(dispatch) {
    try {
        const response = yield call(fetchCustomers)

        yield put(setCustomers(response.data));


    } catch (error) {
        console.log(error)
    }
}
export function* getPartners(dispatch) {
    try {
        const response = yield call(fetchPartners)
        yield put(setPartners(response.partners));


    } catch (error) {
        console.log(error)
    }
}
export function* getOperators(dispatch) {
    try {
        const response = yield call(fetchOperators)
        yield put(setOperators(response.data));


    } catch (error) {
        console.log(error)
    }
}
export function* getTemplates(dispatch) {
    try {
        const response = yield call(fetchTemplates)
        yield put(setTemplates(response.templates));


    } catch (error) {
        console.log(error)
    }
}
export function* searchUploadedBatches({ payload: data }) {
    try {
        // console.log(data)
        const response = yield call(fetchSearchUploadedBatches,data)
        yield put(setSearchedBatches(response.results));
        if(response.results.length > 0){
            yield put(searchBatchSuccess(true))
        }
        else{
            yield put(searchBatchSuccess('empty'))
        }

    } catch (error) {
        console.log(error)
    }
}

export function* sourceUploadSaga() {
    yield takeLatest(UPLOAD_BACTCH, onUpload);
    yield takeLatest(GET_RECENTLY_UPLOADS, getRecentlyUpload);
    yield takeLatest(GET_CUSTOMERS, getCustomers);
    yield takeLatest(GET_PARTNERS, getPartners);
    yield takeLatest(GET_OPERATORS, getOperators);
    yield takeLatest(GET_TEMPLATES, getTemplates);
    yield takeLatest(GET_UPLOADED_BATCHES, getUploadedBatches);
    yield takeLatest(SEARCH_UPLOADED_BATCHES, searchUploadedBatches);
    yield takeLatest(DELETE_BATCH, deleteBatch);
}



export default sourceUploadSaga;
