import { createSlice } from '@reduxjs/toolkit'

const templateSlice = createSlice({
    name: 'template',
    initialState: {
        templates : [],
    },
    reducers: {
        setTemplates: (state, action) => {
            state.templates = action.payload
        },
    },
})

export const {
    setTemplates,
} = templateSlice.actions
export default templateSlice.reducer