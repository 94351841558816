import { call, put, takeEvery, all, fork, takeLatest } from "redux-saga/effects";
import { setUploadKBSuccess, getKBentriesSuccess,setEditKBSuccess,setDeleteKBsuccess,setKBentries,setSelectedKBentries,setSelectedKBentriesSuccess} from "./actions";
import { GET_KBS, UPLOAD_KB,EDIT_KB,DELETE_KB,GET_SELECTED_KBS} from "./actionTypes";
import {fetchKBs,dispatchNewKB,dispatchEditKBs,fetchDeleteKBs,fetchSelectedKB} from "./request";
import {logoutUser} from "../auth/login/actions"


export function* getKBs({payload}) {
    
    try {
        const response = yield call(fetchKBs,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        yield put(setKBentries(response));  
        if(response.message?.length>0){
            yield put(getKBentriesSuccess(true));
        }
        else{
            yield put(getKBentriesSuccess('empty'));
        }
        
    } catch (error) {
        console.log(error)
    }
}
export function* getSelectKBs({payload}) {
    
    try {
        const response = yield call(fetchSelectedKB,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        yield put(setSelectedKBentries(response));  
        yield put(setSelectedKBentriesSuccess(true));
        
    } catch (error) {
        console.log(error)
    }
}

export function* uploadNewKB({payload}) {
    try {
       
        const response = yield call(dispatchNewKB,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        if(response.message=='New KB Saved'){
            yield put(setUploadKBSuccess(true))
        }       
    } catch (error) {
        console.log(error)
    }
}

export function* EditKB({payload}) {
    try {
       
        const response = yield call(dispatchEditKBs,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        if(response.message=="Edits Saved"){
            yield put(setEditKBSuccess(true))
        }       
    } catch (error) {
        console.log(error)
    }
}

export function* deleteKB({payload}) {
    try {
       
        const response = yield call(fetchDeleteKBs,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        if(response.message=="Deleted"){
            yield put(setDeleteKBsuccess(true))
        }       
    } catch (error) {
        console.log(error)
    }
}

export function* KBSaga() {
   
    yield takeLatest(GET_KBS, getKBs);
    yield takeLatest(UPLOAD_KB, uploadNewKB);
    yield takeLatest(EDIT_KB, EditKB);
    yield takeLatest(DELETE_KB, deleteKB);
    yield takeLatest(GET_SELECTED_KBS, getSelectKBs);



}

export default KBSaga;