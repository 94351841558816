import { createSlice } from '@reduxjs/toolkit'

const otherChannelSlice = createSlice({
    name: 'otherChannels',
    initialState: {
        other_channels : [],
        selectedChannel : {}
    },
    reducers: {
        setOtherChannels: (state, action) => {
            state.other_channels = action.payload
        },
        setSelectedChannel: (state, action) => {
            state.selectedChannel = action.payload
        }
    },
})

export const {
    setOtherChannels,
    setSelectedChannel
} = otherChannelSlice.actions
export default otherChannelSlice.reducer