import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Input,
  ModalBody,
  Modal,
  ModalFooter,
  Container,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import MetaTags from "react-meta-tags"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  uploadCountry,
  setuploadCountrySuccess,
  getNewCountry,
  getSelectedCountry,
  setSelectedCountry,
  getNewCountrySuccess,
  setNewCountry,
  deleteCountry,
  setdeleteCountrySuccess,
  setEditCountrySuccess,
  editCountry,
  setSelectedCountrySuccess,
} from "store/old/country/actions"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Swal from "sweetalert2"
import "toastr/build/toastr.min.css"
import store from "../../store/index"
import AddCountry from "components/Counties/AddNew"
import EditCountry from "components/Counties/Edit"
import LoadingSpinner from "components/Reports/EditReports/EditTables/loader"
import jwt from "jsonwebtoken"
import ReactPaginate from "react-paginate"

const Countries = props => {
  const decodeToken = token => {
    try {
      const decodedToken = jwt.decode(token)
      return decodedToken
    } catch (error) {
      console.log("Error decoding token:", error)
      return null
    }
  }

  const token = localStorage.getItem("authUser")
  const decodedToken = decodeToken(JSON.parse(token))
  let role = decodedToken.role

  const dispatch = useDispatch()
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
    didOpen: toast => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  useEffect(() => {
    dispatch(getNewCountrySuccess(false))
    dispatch(setNewCountry([]))
    dispatch(getNewCountry())
  }, [])

  const CountryData = useSelector(state => state.country?.countries)
  const uploadState = useSelector(state => state.country.uploadsuccess)
  const getSuccess = useSelector(state => state.country.countrygetsuccess)
  const selectedCountrydata = useSelector(
    state => state.country.selectedcountry
  )

  const [currentPage, setCurrentPage] = useState(0)
  const itemsPerPage = 10
  const offset = currentPage * itemsPerPage
  const [filteredChannels, setFilteredChannels] = useState(CountryData)
  const currentPageItems = filteredChannels?.slice(offset, offset + itemsPerPage)
  const [searchTerm, setSearchTerm] = useState("")
  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    setFilteredChannels(CountryData)
  }, [CountryData])

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredChannels(CountryData)
    } else {
      setFilteredChannels(
        CountryData.filter(item => {
          let term = searchText.toLowerCase()
          return item.country_name.toLowerCase().includes(term)
        })
      )
      setCurrentPage(0)
    }
  }, [searchText])

  // console.log("CountryData-->", CountryData);

  const [modal_view_screen, setmodal_view_screen] = useState(false)
  const [modal_add_screen, setmodal_add_screen] = useState(false)
  const [modal_edit_screen, setmodal_edit_screen] = useState(false)

  const [add_CountryName, set_addCountryName] = useState("")
  const [add_CountryCode, set_addCountryCode] = useState("")
  const [add_MobileCountryCode, set_addMobileCountryCode] = useState("")
  const [add_ISO3, set_addISO3] = useState("")
  const [add_Subscriber, set_addSubscriber] = useState(0)

  const [edit_CountryName, set_editCountryName] = useState("")
  const [edit_CountryCode, set_editCountryCode] = useState("")
  const [edit_MobileCountryCode, set_editMobileCountryCode] = useState("")
  const [edit_ISO3, set_editISO3] = useState("")
  const [edit_Subscriber, set_editSubscriber] = useState("")

  // Table Data
  const productData = [
    {
      id: 1,
      country_name: "Abkhazia",
      mobile_country_code: "289",
      country_code: "995",
      iso3: "ABK",
      coun_sub_cour: "2455445",
    },

    {
      id: 2,
      country_name: "Afghanistan",
      mobile_country_code: "412",
      country_code: "93",
      iso3: "AFG",
      coun_sub_cour: "455445544",
    },
    {
      id: 3,
      country_name: "Sri Lanka",
      mobile_country_code: "289",
      country_code: "995",
      iso3: "ABK",
      coun_sub_cour: "2455445",
    },
    {
      id: 4,
      country_name: "Sri Lanka",
      mobile_country_code: "289",
      country_code: "995",
      iso3: "ABK",
      coun_sub_cour: "2455445",
    },

    {
      id: 5,
      country_name: "Sri Lanka",
      mobile_country_code: "289",
      country_code: "995",
      iso3: "ABK",
      coun_sub_cour: "2455445",
    },

    {
      id: 6,
      country_name: "Sri Lanka",
      mobile_country_code: "289",
      country_code: "995",
      iso3: "ABK",
      coun_sub_cour: "2455445",
    },
  ]
  const columns = [
    {
      dataField: "country_name",
      text: "Country",
      sort: true,
    },
    {
      dataField: "mobile_country_code",
      text: " Mobile Country Code",
      sort: true,
    },
    {
      dataField: "country_code",
      text: " Country Code",
      sort: true,
    },
    {
      dataField: "iso_3",
      text: " ISO 3",
      sort: true,
    },
    {
      dataField: "subscriber",
      text: " Subscriber",
      sort: true,
      formatter: (cell, row) => {
        if (cell) {
          return (
            <div>{parseInt(cell.replace(/\./g, '')).toLocaleString('en-US', { useGrouping: true })}</div>
          );
        } else {
          return cell;
        }
      }
    },

    {
      dataField: "edit",
      text: "Edit",
      sort: true,
      formatter: function (value, row, index) {
        return (
          <button
          className={`view-btn-tbl ${role === 3 || role === 4 ? 'disabled' : ''}`}
            onClick={async () => {
              dispatch(getSelectedCountry(row.id))
              // const editOpenPromise = new Promise((resolve, reject) => {
              //   setmodal_edit_screen(true);
              //   resolve("Modal opened");
              // });
              // await editOpenPromise.then((msg) => {
              //   // console.log(msg);
              //   setTimeout(() => {
              //     tog_edit_screen()
              //   }, 500);
              // });
              let uploadPromise = new Promise(async (resolve, reject) => {
                store.subscribe(() => {
                  const uploadSuccess = store.getState()
                  const status = uploadSuccess.country.selectedcountrysuccess
                  if (status == true) {
                    // console.log(status);
                    resolve("Done")
                  }
                })
              })
              await uploadPromise.then(async msg => {
                // Toast.fire({
                //   icon: 'success',
                //   title: 'Saved Successfully!'
                // })
                // set_editCountryCode(selectedCountrydata?.message?.country_code)
                // set_editMobileCountryCode(selectedCountrydata?.message?.mobile_country_code)
                // set_editISO3(selectedCountrydata?.message?.iso_3)
                dispatch(setSelectedCountrySuccess(false))
                setTimeout(() => {
                  tog_edit_screen()
                }, 500)
              })
            }}
            disabled={role === 3 || role === 4}
          >
            Edit
          </button>
        )
      },
    },

    {
      dataField: "delete",
      text: "Delete",
      sort: true,
      formatter: function (value, row, index) {
        // console.log(row);
        return (
          <button
          className={`view-btn-tbl ${role === 3 || role === 4 ? 'disabled' : ''}`}
            onClick={async () => {
              deleteCountryData(row.id)
            }}
            disabled={role === 3 || role === 4}
          >
            Delete
          </button>
        )
      },
    },
  ]

  // const tableData = CountryData?.length === 0 ? productData : CountryData;
  const tableData = CountryData?.length == 0 ? productData : CountryData
  const selectedCountry_editdata =
    selectedCountrydata?.length == 0 ? "" : selectedCountrydata

  // console.log('selectedCountry_editdata:', selectedCountry_editdata);

  function tog_view_screen() {
    setmodal_view_screen(!modal_view_screen)
    removeBodyCss()
  }
  function tog_add_screen() {
    setmodal_add_screen(!modal_add_screen)
    removeBodyCss()
  }
  function tog_edit_screen() {
    setmodal_edit_screen(!modal_edit_screen)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  async function saveCountryData() {
    // var formData = new FormData()
    // formData.append("smsc_match",input_smscmatch)
    // formData.append("smsc_replace",input_smscreplace)
    if (
      add_CountryName != "" &&
      add_CountryCode != "" &&
      add_MobileCountryCode != "" &&
      add_ISO3 != ""
    ) {
      var data = {
        country_name: add_CountryName,
        country_code: add_CountryCode,
        mobile_country_code: add_MobileCountryCode,
        iso_3: add_ISO3,
        subscriber: add_Subscriber,
      }
      // console.log(data);
      dispatch(uploadCountry(data))

      let uploadPromise = new Promise(async (resolve, reject) => {
        store.subscribe(() => {
          const uploadSuccess = store.getState()
          const status = uploadSuccess.country.uploadsuccess
          if (status == true) {
            // console.log(status);
            resolve("Done")
          }
        })
      })
      await uploadPromise.then(async msg => {
        Toast.fire({
          icon: "success",
          title: "Saved Successfully!",
        })
        dispatch(setuploadCountrySuccess(false))
        setTimeout(() => {
          setmodal_add_screen(false)
          clearAddStates()
          dispatch(getNewCountrySuccess(false))
          dispatch(setNewCountry([]))
          dispatch(getNewCountry())
        }, 1500)
      })
    } else {
      Toast.fire({
        icon: "error",
        title: "Please Fill All Fields!",
      })
    }
  }
  async function deleteCountryData(id) {
    Swal.fire({
      title: "Do you really want to delete the Country?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Delete",
      denyButtonText: `Don't Delete`,
    }).then(async result => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(deleteCountry(id))
        let deletePromise = new Promise(async (resolve, reject) => {
          store.subscribe(() => {
            const deleteSuccess = store.getState()
            const status = deleteSuccess.country.deletesuccess
            if (status == true) {
              // console.log(status);
              resolve("Done")
            }
          })
        })
        await deletePromise.then(async msg => {
          Toast.fire({
            icon: "success",
            title: "Deleted Successfully!",
          })
          dispatch(setdeleteCountrySuccess(false))
          setTimeout(() => {
            dispatch(getNewCountrySuccess(false))
            dispatch(setNewCountry([]))
            dispatch(getNewCountry())
            setCurrentPage(0)
          }, 1500)
        })
      } else if (result.isDenied) {
        Swal.fire("Delete Cancelled", "", "info")
      }
    })
  }
  async function saveCountryEdit() {
    const edit_id = selectedCountry_editdata.id
    // console.log(edit_id);

    const editData = selectedCountrydata
    const testcondt = editData.message
    // console.log(editData);
    if (
      testcondt.country_name != "" &&
      testcondt.country_code != "" &&
      testcondt.iso_3 != "" &&
      testcondt.mobile_country_code != ""
    ) {
      var data = {
        country_data: editData,
        country_name: edit_CountryName,
        country_code: edit_CountryCode,
        mobile_country_code: edit_MobileCountryCode,
        iso_3: edit_ISO3,
        subscriber: editData.subscriber,
      }
      // console.log(data);
      dispatch(editCountry(data))

      let editPromise = new Promise(async (resolve, reject) => {
        store.subscribe(() => {
          const editSuccess = store.getState()
          const status = editSuccess.country.editsuccess
          if (status == true) {
            // console.log(status);
            resolve("Done")
          }
        })
      })
      await editPromise.then(async msg => {
        Toast.fire({
          icon: "success",
          title: "Edits Saved Successfully!",
        })
        dispatch(setEditCountrySuccess(false))
        setTimeout(() => {
          setmodal_edit_screen(false)
          dispatch(getNewCountrySuccess(false))
          dispatch(setNewCountry([]))
          dispatch(getNewCountry())
        }, 1000)
      })
    } else {
      Toast.fire({
        icon: "error",
        title: "Please Fill All Fields!",
      })
    }
  }

  function clearAddStates() {
    set_addCountryName("")
    set_addCountryCode("")
    set_addMobileCountryCode("")
    set_addISO3("")
    set_addSubscriber("")
  }

  function clearEditStates() {
    set_editCountryName("")
    set_editCountryCode("")
    set_editMobileCountryCode("")
    set_editISO3("")
    set_editSubscriber("")
  }

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: CountryData.length,
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: CountryData.length },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div className="">
        {" "}
        {/*   if u need top padding use this class --> page-content */}
        <MetaTags>
          <title>Countries</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Settings" breadcrumbItem="Countries" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                <Row className="mb-2">
                  <Col md="8" sm="12">
                    <div className="search-box me-2 mb-2 d-inline-block w-sm-100">
                      <Row>
                        <Col>
                          <div
                            className="me-3"
                            style={{
                              border: "1px solid #D9D9D9",
                              borderRadius: "50px",
                              marginBottom: "10px",
                              minWidth: "210px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <i
                              class="bx bx-search-alt search-icon sm-left-30 ps-2 pe-2 "
                              style={{
                                
                                width: "10px",
                                height: "10px",
                              }}
                            ></i>
                            <input
                              type="text"
                              placeholder="Search"
                              style={{
                                width: "150px",
                                height: "31px",
                                border: "none",
                                borderRadius: "10px",
                                textAlign: "start",
                                borderTopLeftRadius: "20px",
                                borderBottomLeftRadius: "20px",
                                marginTop: "2px",
                                marginLeft:"25px"
                              }}
                              value={searchTerm}
                              onChange={e => {
                                setSearchTerm(e.target.value)
                                setSearchText(e.target.value)
                              }}
                              onKeyDown={e => {
                                if (e.key === "Enter") {
                                  // setPage(1)
                                }
                              }}
                            />
                            {searchTerm !== "" && (
                              // <i className="bx bx-search-alt search-icon sm-left-30" />
                              <i
                                className="fas fa-times me-2 ms-2"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  // setPage(1)
                                  setSearchTerm("")
                                  setSearchText("")
                                }}
                              ></i>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col md="4" sm="12" className="position-relative ">
                    <div className="d-flex ">
                      <Button
                        color="danger"
                        className="btn btn-custom md-position-absolute ms-auto p-2 bd-highlight"
                        onClick={() => {
                          setTimeout(() => {
                            tog_add_screen()
                          }, 200);
                        }}
                        disabled={role === 3 || role === 4}
                      >
                        Add Country
                      </Button>
                    </div>
                  </Col>
                </Row>
                <div className="col-12 ms-3">
                    {filteredChannels?.length == 0 ? (
                      getSuccess == false ? (
                        <LoadingSpinner />
                      ) : (
                        <div
                          style={{
                            textAlign: "center",
                            paddingTop: "50px",
                          }}
                        >
                          No Data Found
                        </div>
                      )
                    ) : (
                      <div>

                      <Row style={{ marginRight: "15px" }} >
                      <Table className="table-responsive align-middle mb-0 report_table_edit">
                      <thead>
                        <tr>
                          <th>Country</th>
                          <th>Mobile Country Code</th>
                          <th>Country Code</th>
                          <th>ISO 3</th>
                          <th>Subscriber</th>
                          <th>Edit</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentPageItems?.map(file => {
                          return (
                            <tr key={file.id}>
                              <td>{file.country_name}</td>
                              <td>
                                {file.mobile_country_code}{" "}
                              </td>
                              <td>
                                {file.country_code}{" "}
                              </td>
                              <td>
                                {file.iso_3}{" "}
                              </td>
                              <td>
                                {file.subscriber?<div>{parseInt(file.subscriber.replace(/\./g, '')).toLocaleString('en-US', { useGrouping: true })}</div>:""}
                              </td>
                              <td>           
                              <button
                                className={`view-btn-tbl ${role === 3 || role === 4 ? 'disabled' : ''}`}
                                  onClick={async () => {
                                    dispatch(getSelectedCountry(file.id))
                                    // const editOpenPromise = new Promise((resolve, reject) => {
                                    //   setmodal_edit_screen(true);
                                    //   resolve("Modal opened");
                                    // });
                                    // await editOpenPromise.then((msg) => {
                                    //   // console.log(msg);
                                    //   setTimeout(() => {
                                    //     tog_edit_screen()
                                    //   }, 500);
                                    // });
                                    let uploadPromise = new Promise(async (resolve, reject) => {
                                      store.subscribe(() => {
                                        const uploadSuccess = store.getState()
                                        const status = uploadSuccess.country.selectedcountrysuccess
                                        if (status == true) {
                                          // console.log(status);
                                          resolve("Done")
                                        }
                                      })
                                    })
                                    await uploadPromise.then(async msg => {
                                      // Toast.fire({
                                      //   icon: 'success',
                                      //   title: 'Saved Successfully!'
                                      // })
                                      // set_editCountryCode(selectedCountrydata?.message?.country_code)
                                      // set_editMobileCountryCode(selectedCountrydata?.message?.mobile_country_code)
                                      // set_editISO3(selectedCountrydata?.message?.iso_3)
                                      dispatch(setSelectedCountrySuccess(false))
                                      setTimeout(() => {
                                        tog_edit_screen()
                                      }, 500)
                                    })
                                  }}
                                  disabled={role === 3 || role === 4}
                                >
                                  Edit
                                </button>
                              </td>
                              <td>
                              <button
                                className={`view-btn-tbl ${role === 3 || role === 4 ? 'disabled' : ''}`}
                                  onClick={async () => {
                                    deleteCountryData(file.id)
                                  }}
                                  disabled={role === 3 || role === 4}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </Row>
                      </div>
                    )}
                  </div>
                  <div className="col-12">
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(
                        filteredChannels.length / itemsPerPage
                      )}
                      marginPagesDisplayed={2}
                      forcePage={currentPage}
                      pageRangeDisplayed={5}
                      onPageChange={data => {
                        setCurrentPage(data.selected)
                      }}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                  {/* Add Customer*/}
                  <Modal
                    size="md"
                    isOpen={modal_add_screen}
                    toggle={() => {
                      clearAddStates()
                      tog_add_screen()
                    }}
                    // className="modal-popscreen"
                    className="modal-popscreen"
                  >
                    <div className="modal-header">
                      <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                      >
                        ADD COUNTRY
                      </h5>
                      <button
                        onClick={() => {
                          clearAddStates()
                          setmodal_add_screen(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <ModalBody>
                      <AddCountry
                        modalClose={tog_add_screen}
                        upload={saveCountryData}
                        set_country_name={set_addCountryName}
                        set_country_code={set_addCountryCode}
                        add_CountryCode={add_CountryCode}
                        add_MobileCountryCode={add_MobileCountryCode}
                        set_mobile_country_code={set_addMobileCountryCode}
                        add_ISO3={add_ISO3}
                        set_iso3={set_addISO3}
                        set_subscribe={set_addSubscriber}
                      />
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        type="button"
                        onClick={() => {
                          tog_add_screen()
                          clearAddStates()
                        }}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                      >
                        Close
                      </Button>
                      <Button
                        type="button"
                        className="btn btn-primary "
                        onClick={e => {
                          saveCountryData()
                        }}
                      >
                        Save
                      </Button>
                    </ModalFooter>
                  </Modal>

                  {/* Customer Data Edit */}
                  <Modal
                    size="md"
                    isOpen={modal_edit_screen}
                    toggle={() => {
                      clearEditStates()
                      tog_edit_screen()
                      dispatch(setSelectedCountry([]))
                    }}
                    className="modal-popscreen"
                  >
                    <div className="modal-header">
                      <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                      >
                        EDIT COUNTRY DETAILS
                      </h5>
                      <button
                        onClick={() => {
                          clearEditStates()
                          setmodal_edit_screen(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <ModalBody>
                      <EditCountry
                        data={selectedCountrydata}
                        upload={saveCountryData}
                        set_countryName={set_editCountryName}
                        set_countryCode={set_editCountryCode}
                        set_mobileCountryCode={set_editMobileCountryCode}
                        set_ISO3={set_editISO3}
                        set_subscriber={set_editSubscriber}
                        edit_CountryCode={edit_CountryCode}
                        edit_MobileCountryCode={edit_MobileCountryCode}
                        edit_ISO3={edit_ISO3}
                      />
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        type="button"
                        onClick={() => {
                          clearEditStates()
                          tog_edit_screen()
                        }}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                      >
                        Close
                      </Button>
                      <Button
                        type="button"
                        className="btn btn-primary "
                        onClick={() => {
                          saveCountryEdit()
                        }}
                      >
                        Save Changes
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Countries
