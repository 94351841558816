import axios from "axios"
import * as aT from "./actionTypes"


const INIT_STATE = {
  users:[],
  selectedUser:[],
  getSuccess:false,
  uploadSuccess:false,
  uploaderror:"",
  deleteSuccess:false,
  activateSuccess:false,
  removeSuccess:false,
   }
  
  const partner = (state = INIT_STATE, action) => {
    switch (action.type) {
      // case aT.SET_NEW_PAR_COUNTRIES:
      //   state.countries = action.payload;
      //   return {
      //     ...state,
      //   }
      case aT.GET_USER_SUCCESS:
        // state.getSuccess = action.payload;
        return {
          ...state,
          getSuccess : action.payload
        }
      case aT.SET_UPLOAD_USER_SUCCESS:
        // state.uploadSuccess = action.payload;
        return {
          ...state,
          uploadSuccess : action.payload
        }
      case aT.SET_UPLOAD_USER_MESSAGE:
        // state.uploaderror = action.payload;
        return {
          ...state,
          uploaderror : action.payload
        }
      case aT.SET_USERS:
        // state.users = action.payload;
        return {
          ...state,
          users : action.payload
        }
      case aT.SET_DELETE_USER_SUCCESS:
        // state.deleteSuccess = action.payload;
        return {
          ...state,
          deleteSuccess : action.payload
        }
      case aT.SET_ACTIVATE_USER_SUCCESS:
        // state.activateSuccess = action.payload;
        return {
          ...state,
          activateSuccess : action.payload
        }
      case aT.SET_REMOVE_USER_SUCCESS:
        // state.removeSuccess = action.payload;
        return {
          ...state,
          removeSuccess : action.payload
        }
      case aT.SET_SELECTED_USER:
        // state.selectedUser = action.payload;
        return {
          ...state,
          selectedUser : action.payload
        }
      default:
        return state
    }
  }
  
  export default partner