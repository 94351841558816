import { call, put, takeEvery, all, fork, takeLatest } from "redux-saga/effects";
import { generateReportSuccessful, setGenerating,setReportsGetSuccess,setReports,setDownloadData, setSelectedReport,updateReports,filterReports,
    updateReportSuccess,getReportsSuccess,updateKpiSuccess,regenKpiSuccess,setdeleteReportSuccess,downloadReport,downloadReportSuccess} from "./actions";
import { GENERATE_REPORT, DOWNLOAD_REPORT,GET_REPORTS, SEARCH_REPORTS, UPDATE_REPORTS,UPDATE_KPI,REGEN_KPI,DELETE_REPORT,FILTER_REPORTS } from "./actionTypes";
import { fetchGenerateReport, fetchDownloadReports,fetchReports, fetchSearchReports,updateReportData,updateKPIData,regenKPIData,deleteReports,fetchFilterReports} from "./request";

export function* getReports({payload}) {
    
    try {
        let {id,template} = payload
        const response = yield call(fetchReports,{id,template})
        yield put(setSelectedReport(response));
        yield put(setReportsGetSuccess(true));        
    } catch (error) {
        console.log(error)
    }
}
export function* deleteReport({payload}) {
    
    try {
        const response = yield call(deleteReports,payload)
        if(response.message=="Report Deleted Successfully"){
            yield put(setdeleteReportSuccess(true));
        }
        
        
    } catch (error) {
        console.log(error)
    }
}
export function* downloadReports({payload}) {

    try {
        const response = yield call(fetchDownloadReports,payload)
        yield put(downloadReportSuccess(true));
        // console.log(response);
        yield put(setDownloadData(response));
    } catch (error) {
        console.log(error)
    }
}

export function* searchReports({payload}) {
    try {
        const response = yield call(fetchSearchReports,payload)
        yield put(setReports(response.results));
        if(response.results.length > 0){
            yield put(getReportsSuccess(true));
        }
        else{
            yield put(getReportsSuccess('empty'))
        }
        
        
    } catch (error) {
        console.log(error)
    }
}

export function* generateReport({payload}) {
    try {
       
        const response = yield call(fetchGenerateReport,payload)
        if(response.message=='successfully generated the report'){
            yield put(generateReportSuccessful(response.results));
            yield put(setGenerating(false))
        }
         
    } catch (error) {
        console.log(error)
    }
}
export function* onUpdate({ payload: data }) {
    try {
        
        const response = yield call(updateReportData,data)
        
        if (response.message === "Success") { 
            yield put(updateReportSuccess(true))
        }
        if(response.message === "Error"){
            yield put(updateReportSuccess('error'))
        }

    } catch (error) {
        console.log(error)
    }
}
export function* onKPIUpdate({ payload: data }) {
    try {
        
        const response = yield call(updateKPIData,data)
        
        if (response.message === "Success") { 
            yield put(updateKpiSuccess(true))
        }
        if(response.message === "Error"){
            yield put(updateKpiSuccess('error'))
        }

    } catch (error) {
        console.log(error)
    }
}
export function* onkpiRegen({ payload: data }) {
    try {
        
        const response = yield call(regenKPIData,data)
        
        if (response.message === "Success") { 
            yield put(regenKpiSuccess(true))
        }

    } catch (error) {
        console.log(error)
    }
}
export function* onFilter({ payload: data }) {
    try {
        const response = yield call(fetchFilterReports,data)
        yield put(setReports(response.data));
    } catch (error) {
        console.log(error)
    }
}



export function* reportsSaga() {
   
    yield takeLatest(GET_REPORTS, getReports);
    yield takeLatest(SEARCH_REPORTS, searchReports);
    yield takeLatest(GENERATE_REPORT, generateReport);
    yield takeEvery(UPDATE_REPORTS, onUpdate);
    yield takeEvery(UPDATE_KPI, onKPIUpdate);
    yield takeEvery(REGEN_KPI, onkpiRegen);
    yield takeEvery(DELETE_REPORT, deleteReport);
    yield takeEvery(FILTER_REPORTS, onFilter);
    // yield takeLatest(DOWNLOAD_REPORT, downloadReports);
    
}

// export function* getCustomers(dispatch) {
//     try {
//         const response = yield call(fetchCustomers)

//         yield put(setCustomers(response.customers));


//     } catch (error) {
//         console.log(error)
//     }
// }
// export function* getOperators(dispatch) {
//     try {
//         const response = yield call(fetchOperators)
//         yield put(setOperators(response.operators));


//     } catch (error) {
//         console.log(error)
//     }
// }

export default reportsSaga;
