import { SET_BATCH_DATA, GET_RECENTLY_UPLOADS, UPLOAD_BACTCH, SET_RECENTLY_UPLOADS,REMOVE_PAIR, SET_OPERATORS, 
  SET_CUSTOMERS, SET_PARTNERS, SET_TEMPLATES, GET_TEMPLATES, 
  GET_OPERATORS, GET_PARTNERS, GET_CUSTOMERS, UPLOAD_BACTCH_SUCCESS,
   GET_UPLOADED_BATCHES, SET_UPLOADED_BATCHES, SEARCH_UPLOADED_BATCHES,
    SET_SEARCHED_BATCHES,UPDATE_REPORTS,UPLOAD_REPORTS_SUCCESS
  ,DELETE_BATCH,DELETE_BATCH_SUCCESS,SET_SEARCHED_BATCHES_SUCCESS } from "./actionTypes"

import { GET_NEW_TEMPLATES } from "store/old/templates/actionTypes"


export const deleteBatch = data => ({
  type: DELETE_BATCH,
  payload:data
})
export const setDeleteBatchSuccess = data => ({
  type: DELETE_BATCH_SUCCESS,
  payload:data
})

export const uploadBatch = data => ({
  type: UPLOAD_BACTCH,
  payload: data,
})
export const searchBatchSuccess = data => ({
  type: SET_SEARCHED_BATCHES_SUCCESS,
  payload: data,
})
export const uploadBatchSuccess = data => ({
  type: UPLOAD_BACTCH_SUCCESS,
  payload: data,
})
export const getRecentlyUploads = data => ({
  type: GET_RECENTLY_UPLOADS
})
export const getUploadedBatches = data => ({
  type: GET_UPLOADED_BATCHES
})
export const searchUploadedBatches = data => ({
  type: SEARCH_UPLOADED_BATCHES,
  payload:data
})
export const getCustomers = data => ({
  type: GET_CUSTOMERS
})
export const getPartners = data => ({
  type: GET_PARTNERS
})
export const getOperators = data => ({
  type: GET_OPERATORS
})
export const getTemplates = data => ({
  type: GET_NEW_TEMPLATES 
})

export const setRecentlyUploads = data => ({
  type: SET_RECENTLY_UPLOADS,
  payload:data
})
export const setUploadBatches = data => ({
  type: SET_UPLOADED_BATCHES,
  payload:data
})
export const setSearchedBatches = data => ({
  type: SET_SEARCHED_BATCHES,
  payload:data
})
export const setCustomers = data => ({
  type: SET_CUSTOMERS,
  payload:data
})
export const setOperators = data => ({
  type: SET_OPERATORS,
  payload:data
})
export const setPartners = data => ({
  type: SET_PARTNERS,
  payload:data
})
export const setTemplates = data => ({
  type: SET_TEMPLATES,
  payload:data
})

export const setBatchData = data => ({
  type: SET_BATCH_DATA,
  payload: data,
})
export const removeItemFromArray = data => ({
  type: REMOVE_PAIR,
  pairId: data,
})
