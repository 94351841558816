import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Input,
  Modal,
  ModalFooter,
  Container,
} from "reactstrap"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import {
  getCountries,
  setSelectedOperatorSuccess,
  getNewCountry,
} from "store/old/operator/actions"
import OwnA2p from "./Edit_comps/owna2p"
import OwnP2p from "./Edit_comps/ownp2p"
import SMSC from "./Edit_comps/smsc"
import LoadingSpinner from "./LoaderOperator"
import store from "../../store/index"
import Gate from "./Edit_comps/gate"

const NewOperators = props => {
  const data = props.data
  // console.log("Edit Data ->",data);
  const dispatch = useDispatch()

  const currenct_data = [
    {
      id: 1,
      value: "AED",
      label: "AED",
    },
    {
      id: 2,
      value: "AFN",
      label: "AFN",
    },
    {
      id: 3,
      value: "ALL",
      label: "ALL",
    },
    {
      id: 4,
      value: "AMD",
      label: "AMD",
    },
    {
      id: 5,
      value: "ANG",
      label: "ANG",
    },
    {
      id: 6,
      value: "AOA",
      label: "AOA",
    },
    {
      id: 7,
      value: "ARS",
      label: "ARS",
    },
    {
      id: 8,
      value: "AUD",
      label: "AUD",
    },
    {
      id: 9,
      value: "AWG",
      label: "AWG",
    },
    {
      id: 10,
      value: "AZN",
      label: "AZN",
    },
    {
      id: 11,
      value: "BAM",
      label: "BAM",
    },
    {
      id: 12,
      value: "BBD",
      label: "BBD",
    },
    {
      id: 13,
      value: "BDT",
      label: "BDT",
    },
    {
      id: 14,
      value: "BGN",
      label: "BGN",
    },
    {
      id: 15,
      value: "BHD",
      label: "BHD",
    },
    {
      id: 16,
      value: "BIF",
      label: "BIF",
    },
    {
      id: 17,
      value: "BMD",
      label: "BMD",
    },
    {
      id: 18,
      value: "BND",
      label: "BND",
    },
    {
      id: 19,
      value: "BOB",
      label: "BOB",
    },
    {
      id: 20,
      value: "BRL",
      label: "BRL",
    },
    {
      id: 21,
      value: "BSD",
      label: "BSD",
    },
    {
      id: 22,
      value: "BTC",
      label: "BTC",
    },
    {
      id: 23,
      value: "BTN",
      label: "BTN",
    },
    {
      id: 24,
      value: "BWP",
      label: "BWP",
    },
    {
      id: 25,
      value: "BYN",
      label: "BYN",
    },
    {
      id: 26,
      value: "BYR",
      label: "BYR",
    },
    {
      id: 27,
      value: "BZD",
      label: "BZD",
    },
    {
      id: 28,
      value: "CAD",
      label: "CAD",
    },
    {
      id: 29,
      value: "CDF",
      label: "CDF",
    },
    {
      id: 30,
      value: "CHF",
      label: "CHF",
    },
    {
      id: 31,
      value: "CLF",
      label: "CLF",
    },
    {
      id: 32,
      value: "CLP",
      label: "CLP",
    },
    {
      id: 33,
      value: "CNY",
      label: "CNY",
    },
    {
      id: 34,
      value: "COP",
      label: "COP",
    },
    {
      id: 35,
      value: "CRC",
      label: "CRC",
    },
    {
      id: 36,
      value: "CUC",
      label: "CUC",
    },
    {
      id: 37,
      value: "CUP",
      label: "CUP",
    },
    {
      id: 38,
      value: "CVE",
      label: "CVE",
    },
    {
      id: 39,
      value: "CZK",
      label: "CZK",
    },
    {
      id: 40,
      value: "DJF",
      label: "DJF",
    },
    {
      id: 41,
      value: "DKK",
      label: "DKK",
    },
    {
      id: 42,
      value: "DOP",
      label: "DOP",
    },
    {
      id: 43,
      value: "DZD",
      label: "DZD",
    },
    {
      id: 44,
      value: "EGP",
      label: "EGP",
    },
    {
      id: 45,
      value: "ERN",
      label: "ERN",
    },
    {
      id: 46,
      value: "ETB",
      label: "ETB",
    },
    {
      id: 47,
      value: "EUR",
      label: "EUR",
    },
    {
      id: 48,
      value: "FJD",
      label: "FJD",
    },
    {
      id: 49,
      value: "FKP",
      label: "FKP",
    },
    {
      id: 50,
      value: "GBP",
      label: "GBP",
    },
    {
      id: 51,
      value: "GEL",
      label: "GEL",
    },
    {
      id: 52,
      value: "GGP",
      label: "GGP",
    },
    {
      id: 53,
      value: "GHS",
      label: "GHS",
    },
    {
      id: 54,
      value: "GIP",
      label: "GIP",
    },
    {
      id: 55,
      value: "GMD",
      label: "GMD",
    },
    {
      id: 56,
      value: "GNF",
      label: "GNF",
    },
    {
      id: 57,
      value: "GTQ",
      label: "GTQ",
    },
    {
      id: 58,
      value: "GYD",
      label: "GYD",
    },
    {
      id: 59,
      value: "HKD",
      label: "HKD",
    },
    {
      id: 60,
      value: "HNL",
      label: "HNL",
    },
    {
      id: 61,
      value: "HRK",
      label: "HRK",
    },
    {
      id: 62,
      value: "HTG",
      label: "HTG",
    },
    {
      id: 63,
      value: "HUF",
      label: "HUF",
    },
    {
      id: 64,
      value: "IDR",
      label: "IDR",
    },
    {
      id: 65,
      value: "ILS",
      label: "ILS",
    },
    {
      id: 66,
      value: "IMP",
      label: "IMP",
    },
    {
      id: 67,
      value: "INR",
      label: "INR",
    },
    {
      id: 68,
      value: "IQD",
      label: "IQD",
    },
    {
      id: 69,
      value: "IRR",
      label: "IRR",
    },
    {
      id: 70,
      value: "ISK",
      label: "ISK",
    },
    {
      id: 71,
      value: "JEP",
      label: "JEP",
    },
    {
      id: 72,
      value: "JMD",
      label: "JMD",
    },
    {
      id: 73,
      value: "JOD",
      label: "JOD",
    },
    {
      id: 74,
      value: "JPY",
      label: "JPY",
    },
    {
      id: 75,
      value: "KES",
      label: "KES",
    },
    {
      id: 76,
      value: "KGS",
      label: "KGS",
    },
    {
      id: 77,
      value: "KHR",
      label: "KHR",
    },
    {
      id: 78,
      value: "KMF",
      label: "KMF",
    },
    {
      id: 79,
      value: "KPW",
      label: "KPW",
    },
    {
      id: 80,
      value: "KRW",
      label: "KRW",
    },
    {
      id: 81,
      value: "KWD",
      label: "KWD",
    },
    {
      id: 82,
      value: "KYD",
      label: "KYD",
    },
    {
      id: 83,
      value: "KZT",
      label: "KZT",
    },
    {
      id: 84,
      value: "LAK",
      label: "LAK",
    },
    {
      id: 85,
      value: "LBP",
      label: "LBP",
    },
    {
      id: 86,
      value: "LKR",
      label: "LKR",
    },
    {
      id: 87,
      value: "LRD",
      label: "LRD",
    },
    {
      id: 88,
      value: "LSL",
      label: "LSL",
    },
    {
      id: 89,
      value: "LTL",
      label: "LTL",
    },
    {
      id: 90,
      value: "LVL",
      label: "LVL",
    },
    {
      id: 91,
      value: "LYD",
      label: "LYD",
    },
    {
      id: 92,
      value: "MAD",
      label: "MAD",
    },
    {
      id: 93,
      value: "MDL",
      label: "MDL",
    },
    {
      id: 94,
      value: "MGA",
      label: "MGA",
    },
    {
      id: 95,
      value: "MKD",
      label: "MKD",
    },
    {
      id: 96,
      value: "MMK",
      label: "MMK",
    },
    {
      id: 97,
      value: "MNT",
      label: "MNT",
    },
    {
      id: 98,
      value: "MOP",
      label: "MOP",
    },
    {
      id: 99,
      value: "MRO",
      label: "MRO",
    },
    {
      id: 100,
      value: "MUR",
      label: "MUR",
    },
    {
      id: 101,
      value: "MVR",
      label: "MVR",
    },
    {
      id: 102,
      value: "MWK",
      label: "MWK",
    },
    {
      id: 103,
      value: "MXN",
      label: "MXN",
    },
    {
      id: 104,
      value: "MYR",
      label: "MYR",
    },
    {
      id: 105,
      value: "MZN",
      label: "MZN",
    },
    {
      id: 106,
      value: "NAD",
      label: "NAD",
    },
    {
      id: 107,
      value: "NGN",
      label: "NGN",
    },
    {
      id: 108,
      value: "NIO",
      label: "NIO",
    },
    {
      id: 109,
      value: "NOK",
      label: "NOK",
    },
    {
      id: 110,
      value: "NPR",
      label: "NPR",
    },
    {
      id: 111,
      value: "NZD",
      label: "NZD",
    },
    {
      id: 112,
      value: "OMR",
      label: "OMR",
    },
    {
      id: 113,
      value: "PAB",
      label: "PAB",
    },
    {
      id: 114,
      value: "PEN",
      label: "PEN",
    },
    {
      id: 115,
      value: "PGK",
      label: "PGK",
    },
    {
      id: 116,
      value: "PHP",
      label: "PHP",
    },
    {
      id: 117,
      value: "PKR",
      label: "PKR",
    },
    {
      id: 118,
      value: "PLN",
      label: "PLN",
    },
    {
      id: 119,
      value: "PYG",
      label: "PYG",
    },
    {
      id: 120,
      value: "QAR",
      label: "QAR",
    },
    {
      id: 121,
      value: "RON",
      label: "RON",
    },
    {
      id: 122,
      value: "RSD",
      label: "RSD",
    },
    {
      id: 123,
      value: "RUB",
      label: "RUB",
    },
    {
      id: 124,
      value: "RWF",
      label: "RWF",
    },
    {
      id: 125,
      value: "SAR",
      label: "SAR",
    },
    {
      id: 126,
      value: "SBD",
      label: "SBD",
    },
    {
      id: 127,
      value: "SCR",
      label: "SCR",
    },
    {
      id: 128,
      value: "SDG",
      label: "SDG",
    },
    {
      id: 129,
      value: "SEK",
      label: "SEK",
    },
    {
      id: 130,
      value: "SGD",
      label: "SGD",
    },
    {
      id: 131,
      value: "SHP",
      label: "SHP",
    },
    {
      id: 132,
      value: "SLE",
      label: "SLE",
    },
    {
      id: 133,
      value: "SLL",
      label: "SLL",
    },
    {
      id: 134,
      value: "SOS",
      label: "SOS",
    },
    {
      id: 135,
      value: "SRD",
      label: "SRD",
    },
    {
      id: 136,
      value: "STD",
      label: "STD",
    },
    {
      id: 137,
      value: "SVC",
      label: "SVC",
    },
    {
      id: 138,
      value: "SYP",
      label: "SYP",
    },
    {
      id: 139,
      value: "SZL",
      label: "SZL",
    },
    {
      id: 140,
      value: "THB",
      label: "THB",
    },
    {
      id: 141,
      value: "TJS",
      label: "TJS",
    },
    {
      id: 142,
      value: "TMT",
      label: "TMT",
    },
    {
      id: 143,
      value: "TND",
      label: "TND",
    },
    {
      id: 144,
      value: "TOP",
      label: "TOP",
    },
    {
      id: 145,
      value: "TRY",
      label: "TRY",
    },
    {
      id: 146,
      value: "TTD",
      label: "TTD",
    },
    {
      id: 147,
      value: "TWD",
      label: "TWD",
    },
    {
      id: 148,
      value: "TZS",
      label: "TZS",
    },
    {
      id: 149,
      value: "UAH",
      label: "UAH",
    },
    {
      id: 150,
      value: "UGX",
      label: "UGX",
    },
    {
      id: 151,
      value: "USD",
      label: "USD",
    },
    {
      id: 152,
      value: "UYU",
      label: "UYU",
    },
    {
      id: 153,
      value: "UZS",
      label: "UZS",
    },
    {
      id: 154,
      value: "VEF",
      label: "VEF",
    },
    {
      id: 155,
      value: "VND",
      label: "VND",
    },
    {
      id: 156,
      value: "VUV",
      label: "VUV",
    },
    {
      id: 157,
      value: "WST",
      label: "WST",
    },
    {
      id: 158,
      value: "XAF",
      label: "XAF",
    },
    {
      id: 159,
      value: "XAG",
      label: "XAG",
    },
    {
      id: 160,
      value: "XAU",
      label: "XAU",
    },
    {
      id: 161,
      value: "XCD",
      label: "XCD",
    },
    {
      id: 162,
      value: "XDR",
      label: "XDR",
    },
    {
      id: 163,
      value: "XOF",
      label: "XOF",
    },
    {
      id: 164,
      value: "XPF",
      label: "XPF",
    },
    {
      id: 165,
      value: "YER",
      label: "YER",
    },
    {
      id: 166,
      value: "ZAR",
      label: "ZAR",
    },
    {
      id: 167,
      value: "ZMK",
      label: "ZMK",
    },
    {
      id: 168,
      value: "ZMW",
      label: "ZMW",
    },
    {
      id: 169,
      value: "ZWL",
      label: "ZWL",
    },
  ]

  useEffect(() => {
    dispatch(getNewCountry())
  }, [])

  //const customer = useSelector((state) => state.SourceUpload.customers);
  const countries = useSelector(state => state.operator.countries)
  const countryData = countries?.message?.map(obj => ({
    id: obj.id,
    label: obj.country_name,
    value: obj.iso_3,
  }))

  const defaultCountry = countryData?.filter(obj => obj.label === data?.country)
  const defaultCurrency = currenct_data.filter(
    obj => obj.label === data?.mtr_currency
  )
  // console.log(defaultCountry);
  const [initialState, setInitialState] = useState(1)
  const [loading, setLoading] = useState(true)
  // const operator = useSelector((state) => state.SourceUpload.operators);
  // const template = useSelector((state) => state.SourceUpload.templates);
  // const partners = useSelector((state) => state.SourceUpload.partners);
  const pageBody = (
    <React.Fragment>
      <form className="form-width ps-1">
        <Row className="p-3">
          <div className="mb-3">
            <span className="sub_h fw-600">Operator Data</span>
          </div>
          <div className="operator-field-container">
            <Row>
              <Col lg="6">
                <label className="normal_h operator-label">Operator Name</label>
                <Input
                  type="name"
                  className="input-operator p-2"
                  placeholder="Enter the Name"
                  defaultValue={data?.operator_name}
                  onChange={e => {
                    data.operator_name = e.target.value
                  }}
                />
              </Col>
              <Col lg="6">
                <label className="normal_h operator-label">Country</label>
                <Select
                  className="operator-mult-input"
                  //isSearchable={true}
                  name="country"
                  options={countryData}
                  placeholder="Select Country"
                  defaultValue={defaultCountry}
                  onChange={e => {
                    data.country = e.label
                  }}
                />
              </Col>
              <Col lg="6">
                <label className="normal_h operator-label mt-4">Subscriber</label>
                <Input
                  type="number"
                  className="input-operator  p-2"
                  placeholder="Enter Subscriber"
                  defaultValue={data?.subscribers}
                  // onChange={e => {
                  //   data.subscribers = e.target.value
                  // }}
                  onChange={e => {
                    let value = Number(e.target.value);
                    if (value >= 0 && value <= 2000000000) {
                      value = Number(value.toFixed(2));
                      data.subscribers = value
                    }
                  }}
                />
              </Col>

              {/* <Col lg="3">
                      <label className="operator-label">Customer</label>
                      </Col>
                      <Col lg="3">
                      <Select
                        className="customer-mult-input"
                        //isSearchable={true}
                        name="customer"
                        options={customer}
                        placeholder="Select Customer"
                        
                      />
                      </Col> */}
            </Row>
          </div>
          <hr className="addNew-hr" />
        </Row>

        <Row className="p-3">
          <div className="mb-3">
            <span className="sub_h fw-600">MTR Details</span>
          </div>
          <div className="operator-field-container">
            <Row>
              <Col lg="6" className="mb-2">
                <label className="normal_h operator-label">MTR Currency</label>
                {/* <Input
                        type="name"
                        className="input-operator"
                        placeholder="Enter the MTR Currency"
                        onChange={(e)=>{
                          add_mtrCurrency
                        }}
                      /> */}
                <Select
                  className="operator-mult-input"
                  //isSearchable={true}
                  name="mtrcurrency"
                  options={currenct_data}
                  placeholder="Select Currency"
                  defaultValue={defaultCurrency}
                  onChange={e => {
                    data.mtr_currency = e.label
                  }}
                />
              </Col>

              <Col lg="6">
                <label className="normal_h operator-label">Rate Threshold Factor</label>
                <Input
                  type="number"
                  step="0.1"
                  className="input-operator mt-2 p-2"
                  defaultValue={data?.price_threshold}
                  placeholder="Enter the Value"
                  onChange={e => {
                    data.price_threshold = parseFloat(e.target.value)
                  }}
                />
              </Col>

              <Col lg="6">
                <label className="normal_h operator-label">
                  Official MTR A2P International
                </label>
                <Input
                  type="number"
                  step="0.0001"
                  className="input-operator mt-2 p-2"
                  defaultValue={data?.official_MTR_A2P_international}
                  placeholder="Enter the Value"
                  onChange={e => {
                    data.official_MTR_A2P_international = parseFloat(
                      e.target.value
                    )
                  }}
                />
              </Col>
              <Col lg="6">
                <label className="normal_h operator-label" style={{}}>
                  Official MTR P2P International
                </label>
                <Input
                  type="number"
                  step="0.0001"
                  className="input-operator mt-2"
                  placeholder="Enter the Value"
                  defaultValue={
                    data?.official_MTR_P2P_international != 0
                      ? data?.official_MTR_P2P_international
                      : ""
                  }
                  onChange={e => {
                    // console.log(e.target.value);
                    data.official_MTR_P2P_international = parseFloat(
                      e.target.value
                    )
                    // console.log(data);
                  }}
                />
              </Col>
              <Col lg="6">
                <label className="normal_h operator-label mt-4">
                  Official MTR A2P National
                </label>
                <Input
                  type="number"
                  step="0.0001"
                  className="input-operator"
                  placeholder="Enter the Value"
                  defaultValue={
                    data?.official_MTR_A2P_national != 0
                      ? data?.official_MTR_A2P_national
                      : ""
                  }
                  onChange={e => {
                    // console.log(e.target.value);
                    data.official_MTR_A2P_national = parseFloat(e.target.value)
                  }}
                />
              </Col>
              <Col lg="6">
                <label className="normal_h soperator-label mt-4">
                  Official MTR P2P National
                </label>
                <Input
                  type="number"
                  step="0.01"
                  className="input-operator"
                  placeholder="Enter the Value"
                  defaultValue={
                    data?.official_MTR_P2P_national != 0
                      ? data?.official_MTR_P2P_national
                      : ""
                  }
                  onChange={e => {
                    data.official_MTR_P2P_national = parseFloat(e.target.value)
                  }}
                />
              </Col>
            </Row>
          </div>
          <hr className="addNew-hr" />
        </Row>

        <Row className="p-3">
          <div className="mb-3">
            <span className="sub_h fw-600">Desired Sender ID Details</span>
          </div>
          <div className="operator-field-container">
            <Row>
              <Row lg="10" className="d-flex align-items-center">
                <Col>
                  <label className="normal_h operator-label fs-14 ">
                    Alphanumeric Unchanged
                  </label>
                </Col>

                <Col
                  className="radio-input1 d-flex align-items-center"
                  onChange={e => {
                    data.alphanumeric_unchanged = e.target.value
                  }}
                >
                  <Col md="4">
                    <Input
                      type="radio"
                      name="alphachange"
                      value="yes"
                      defaultChecked={data?.alphanumeric_unchanged == "yes"}
                    />
                    &nbsp;
                    <label htmlFor="yes"> Yes</label>
                  </Col>
                  <Col md="4">
                    <Input
                      type="radio"
                      name="alphachange"
                      value="no"
                      defaultChecked={data?.alphanumeric_unchanged == "no"}
                    />
                    &nbsp;
                    <label htmlFor="no">No</label>
                  </Col>
                </Col>
              </Row>

              <Row lg="10" className="d-flex align-items-center">
                <Col>
                  <label className="normal_h operator-label fs-14 ">
                    Alphanumeric Change
                  </label>
                </Col>

                <Col
                  className="radio-input1 d-flex align-items-center"
                  onChange={e => {
                    data.alphanumeric_changed = e.target.value
                  }}
                >
                  <Col md="4">
                    <Input
                      type="radio"
                      name="alphaunchange"
                      value="yes"
                      defaultChecked={data?.alphanumeric_changed == "yes"}
                    />
                    &nbsp;
                    <label htmlFor="yes"> Yes</label>
                  </Col>
                  <Col md="4">
                    <Input
                      type="radio"
                      name="alphaunchange"
                      value="no"
                      defaultChecked={data?.alphanumeric_changed == "no"}
                    />
                    &nbsp;
                    <label htmlFor="no">No</label>
                  </Col>
                </Col>
              </Row>

              <Row lg="10" className="d-flex align-items-center">
                <Col>
                  {" "}
                  <label className="normal_h operator-label fs-14 ">Shortcode</label>
                </Col>
                <Col
                  className="radio-input1 d-flex align-items-center"
                  onChange={e => {
                    data.shortCode = e.target.value
                  }}
                >
                  <Col md="4">
                    <Input
                      type="radio"
                      name="shortcode"
                      value="yes"
                      defaultChecked={data?.shortCode == "yes"}
                    />
                    &nbsp;
                    <label htmlFor="yes"> Yes</label>
                  </Col>
                  <Col md="4">
                    <Input
                      type="radio"
                      name="shortcode"
                      value="no"
                      defaultChecked={data?.shortCode == "no"}
                    />
                    &nbsp;
                    <label htmlFor="no">No</label>
                  </Col>
                </Col>
              </Row>

              <Row lg="10" className="d-flex align-items-center">
                <Col>
                  <label className="normal_h operator-label fs-14 ">Long Number</label>
                </Col>
                <Col
                  className="radio-input1 d-flex align-items-center"
                  onChange={e => {
                    data.longnumber = e.target.value
                  }}
                >
                  <Col md="4">
                    <Input
                      type="radio"
                      name="lgnum"
                      value="yes"
                      defaultChecked={data?.longnumber == "yes"}
                    />
                    &nbsp;
                    <label htmlFor="yes"> Yes</label>
                  </Col>
                  <Col md="4">
                    <Input
                      type="radio"
                      name="lgnum"
                      value="no"
                      defaultChecked={data?.longnumber == "no"}
                    />
                    &nbsp;
                    <label htmlFor="no">No</label>
                  </Col>
                </Col>
              </Row>

              <Col lg="6">
                <label className="normal_h operator-label">Other</label>
                <Input
                  type="text"
                  step="0.01"
                  className="input-operator"
                  placeholder="Other Details"
                  defaultValue={data?.other}
                  onChange={e => {
                    data.other = e.target.value
                  }}
                />
              </Col>
            </Row>
          </div>
          <hr className="addNew-hr" />
        </Row>

        <Row className="p-3">
          <div className="mb-3">
            <span className="sub_h fw-600">Protection / Additional Info</span>
          </div>
          <div className="operator-field-container">
            <Row>
              <Col lg="6">
                <label className="normal_h operator-label">Gatekeeper</label>
                <Input
                  type="name"
                  className="input-operator"
                  placeholder="Enter the Gatekeeper"
                  defaultValue={data?.gatekeeper}
                  onChange={e => {
                    data.gatekeeper = e.target.value
                  }}
                />
              </Col>
              <Col lg="6">
                <label className="normal_h operator-label">Firewall Vendor</label>
                <Input
                  type="name"
                  className="input-operator"
                  placeholder="Enter the Firewall Vendor"
                  defaultValue={data?.firewall_vendor}
                  onChange={e => {
                    data.firewall_vendor = e.target.value
                  }}
                />
              </Col>
              <Col lg="6">
                <label className="normal_h operator-label mt-4">
                  Pre-Registered Sender ID
                </label>
                <Input
                  type="number"
                  className="input-operator"
                  placeholder="Enter the ID"
                  defaultValue={data?.Pre_registered_senderID}
                  onChange={e => {
                    data.Pre_registered_senderID = e.target.value
                  }}
                />
              </Col>
            </Row>
          </div>
          <hr className="addNew-hr" />
        </Row>

        <Row className="p-3">
          <div className="mb-3">
            <span className="sub_h fw-600">Gatekeeper Routes</span>
          </div>
          <div className="operator-field-container ">
            <Gate
              defaultState={data?.gatekeeper_routes}
              sendState={props.gatekeeper_state}
              setSendState={props.gatekeeper_set}
            />
          </div>
          <hr className="addNew-hr" />
        </Row>

        <Row className="p-3">
          <div className="mb-3">
          <span className="sub_h fw-600">SMSC</span>
          </div>
          <div className="operator-field-container d-flex">
            <Col
              md={6}
              style={{
                padding: "0px",
                paddingRight: "10px",
              }}
            >
              <div className=" ">
                {/* <label className="operator-label">Own A2P</label> */}
                <Row className="mb-2">
                  <OwnA2p
                    defaultState={data?.SMSCs?.filter(
                      obj => obj.type == "owna2p"
                    )}
                    sendState={props.owna2p_state}
                    setSendState={props.owna2p_set}
                  />
                </Row>
              </div>
            </Col>
            <Col
              md={6}
              style={{
                padding: "0px",
                paddingLeft: "10px",
              }}
            >
              <div className="">
                {/* <label className="operator-label">Own P2P</label> */}
                <Row className="mb-2">
                  <OwnP2p
                    defaultState={data?.SMSCs?.filter(
                      obj => obj.type == "ownp2p"
                    )}
                    sendState={props.ownp2p_state}
                  />
                </Row>
              </div>
            </Col>
          </div>
          <hr className="addNew-hr" />
        </Row>
        <Row className="p-3">
          <span className="sub_h fw-600">International SMSC</span>
          <div className="operator-field-container">
            <SMSC
              defaultState={data?.SMSCs?.filter(obj => obj.type == "ownsmsc")}
              sendState={props.ownsmsc_state}
            />
          </div>
        </Row>
      </form>
    </React.Fragment>
  )

  if (initialState == 1) {
    // setTimeout(()=>{
    //   setLoading(false)
    // },1000)
    // props.owna2p_set(data?.SMSCs?.filter(obj=>obj.type=="owna2p"));
    // props.ownp2p_set(data?.SMSCs?.filter(obj=>obj.type=="ownp2p"));
    // props.ownsmsc_set(data?.SMSCs?.filter(obj=>obj.type=="smsc"));
    // setInitialState(0)

    let loadPromise = new Promise(async (resolve, reject) => {
      store.subscribe(() => {
        const loadSuccess = store.getState()
        const status = loadSuccess.operator.selectedOpSuccess
        if (status == true) {
          // console.log(status);
          resolve("Done")
        }
      })
    })
    loadPromise.then(async msg => {
      dispatch(setSelectedOperatorSuccess(false))
      props.owna2p_set(data?.SMSCs?.filter(obj => obj.type == "owna2p"))
      props.ownp2p_set(data?.SMSCs?.filter(obj => obj.type == "ownp2p"))
      props.ownsmsc_set(data?.SMSCs?.filter(obj => obj.type == "smsc"))
      props.gatekeeper_set(data?.gatekeeper_routes)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    })
  }
  return (
    <React.Fragment>{loading ? <LoadingSpinner /> : pageBody}</React.Fragment>
  )
}

export default NewOperators
