/** Get Dashboard Chart data */
export const UPLOAD_BACTCH = "UPLOAD_BACTCH"
export const UPLOAD_BACTCH_SUCCESS = "UPLOAD_BACTCH_SUCCESS"
export const SET_BATCH_DATA = "SET_BATCH_DATA"
export const GET_RECENTLY_UPLOADS = "GET_RECENTLY_UPLOADS"
export const GET_UPLOADED_BATCHES = "GET_UPLOADED_BATCHES"
export const SET_UPLOADED_BATCHES = "SET_UPLOADED_BATCHES"
export const SET_SEARCHED_BATCHES = "SET_SEARCHED_BATCHES"
export const SET_SEARCHED_BATCHES_SUCCESS = "SET_SEARCHED_BATCHES_SUCCESS"
export const SET_RECENTLY_UPLOADS = "SET_RECENTLY_UPLOADS"
export const CLEAR_PAIRS = "CLEAR_PAIRS"
export const REMOVE_PAIR = "REMOVE_PAIR"
export const GET_CUSTOMERS = "GET_CUSTOMERS"
export const GET_PARTNERS = "GET_PARTNERS"
export const GET_OPERATORS = "GET_OPERATORS"
export const GET_TEMPLATES = "GET_TEMPLATES"
export const SET_TEMPLATES = "SET_TEMPLATES"
export const SET_PARTNERS = "SET_PARTNERS"
export const SET_OPERATORS = "SET_OPERATORS"
export const SET_CUSTOMERS = "SET_CUSTOMERS"
export const SEARCH_UPLOADED_BATCHES = "SEARCH_UPLOADED_BATCHES"
export const UPDATE_REPORTS = "UPDATE_REPORTS"
export const UPLOAD_REPORTS_SUCCESS = "UPLOAD_REPORTS_SUCCESS"

export const DELETE_BATCH = "DELETE_BATCH"
export const DELETE_BATCH_SUCCESS = "DELETE_BATCH_SUCCESS"

