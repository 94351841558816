import { call, put, takeEvery, all, fork, takeLatest } from "redux-saga/effects";
import * as aC from "./actions";
import * as aT from "./actionTypes";
import * as req from "./request";

export function* getNewPartners({payload}) {
    
    try {
        const response = yield call(req.fetchNewPartnes,payload)
        if(response.message="Success"){
            yield put(aC.setNewPartners(response.partners));  
        }
        if(response.partners?.length>0){
            yield put(aC.getNewPartnersSuccess(true));
        }
        else{
            yield put(aC.getNewPartnersSuccess('empty'));
        }
        
    } catch (error) {
        console.log(error)
    }
}
export function* getNewCountry({payload}) {
    
    try {
        const response = yield call(req.fetchNewCountries,payload)
        yield put(aC.setNewCountry(response));  
        
    } catch (error) {
        console.log(error)
    }
}
export function* getSelectedPartner({payload}) {
    
    try {
        const response = yield call(req.fetchselectedPartner,payload)
        yield put(aC.setSelectedPartner(response)); 
        yield put(aC.setSelectedPartnerSuccess(true)); 
        
    } catch (error) {
        console.log(error)
    }
}

export function* uploadPartner({payload}) {
    try {
       
        const response = yield call(req.dispatchNewPartner,payload)
        if(response.message=='Parner successfully added'){
            yield put(aC.setuploadPartnerSuccess(true))
        }       
    } catch (error) {
        console.log(error)
    }
}

export function* EditPartner({payload}) {
    try {
       
        const response = yield call(req.dispatchEditPartner,payload)
        if(response.message=="Partner successfully updated"){
            yield put(aC.setEditPartnerSuccess(true))
        }       
    } catch (error) {
        console.log(error)
    }
}

export function* deletePartner({payload}) {
    try {
       
        const response = yield call(req.fetchDeletePartner,payload)
        if(response.message=="Partner successfully deleted"){
            yield put(aC.setDeletePartnerSuccess(true))
        }       
    } catch (error) {
        console.log(error)
    }
}

export function* PartnerSaga() {
   
    yield takeLatest(aT.GET_NEW_PARTNERS, getNewPartners);
    // yield takeLatest(aT.GET_COUNTRIES, getCountries);
    yield takeLatest(aT.UPLOAD_PARTNER, uploadPartner);
    yield takeLatest(aT.EDIT_PARTNER, EditPartner);
    yield takeLatest(aT.DELETE_PARTNER, deletePartner);
    yield takeLatest(aT.GET_SELECTED_PARTNER, getSelectedPartner);
    yield takeLatest(aT.GET_NEW_PAR_COUNTRIES, getNewCountry);

}

export default PartnerSaga;