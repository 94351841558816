import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap"
import MetaTags from "react-meta-tags"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AddCustomer from "components/Customer/AddNew"
import EditCustomer from "components/Customer/Edit"
import ViewCustomer from "components/Customer/View"
import {
  uploadCustomer,
  setuploadCustomerSuccess,
  getCountries,
  getNewCustomers,
  getSelectedCustomer,
  editCustomer,
  deleteCustomer,
  setEditCustomerSuccess,
  getCustomerSuccess,
  setNewCustomers,
  setdeleteCustomerSuccess,
  getNewOperators,
  getNewCountry,
} from "store/old/customer/actions"
import toastr from "toastr"
import Swal from "sweetalert2"
import "toastr/build/toastr.min.css"
import store from "../../store/index"
import LoadingSpinner from "components/Reports/EditReports/EditTables/loader"
import jwt from "jsonwebtoken"
import ReactPaginate from "react-paginate"
import { set } from "lodash"


const Customers = () => {
  const decodeToken = token => {
    try {
      const decodedToken = jwt.decode(token)
      return decodedToken
    } catch (error) {
      console.log("Error decoding token:", error)
      return null
    }
  }

  const token = localStorage.getItem("authUser")
  const decodedToken = decodeToken(JSON.parse(token))
  let role = decodedToken.role

  const dispatch = useDispatch()
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
    didOpen: toast => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  useEffect(() => {
    dispatch(getNewCustomers())
  }, [])

  const customerData = useSelector(state => state.customer.customers)
  const uploadState = useSelector(state => state.customer.uploadsuccess)
  const getState = useSelector(state => state.customer.customergetsuccess)
  const selectedCustomerdata = useSelector(
    state => state.customer.selectedcustomer
  )
  const [currentPage, setCurrentPage] = useState(0)
  const itemsPerPage = 10
  const offset = currentPage * itemsPerPage
  const [filteredChannels, setFilteredChannels] = useState(customerData)
  const currentPageItems = filteredChannels?.slice(offset, offset + itemsPerPage)
  const [searchTerm, setSearchTerm] = useState("")
  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    setFilteredChannels(customerData)
  }, [customerData])

  useEffect(() => {
    if (searchTerm !== "") {
      const results = customerData.filter(customer =>
        customer.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      setFilteredChannels(results)
      setCurrentPage(0)
    } else {
      setFilteredChannels(customerData)
    }
  }, [searchTerm])
  
  
  // console.log("customerData", customerData);

  const [modal_view_screen, setmodal_view_screen] = useState(false)
  const [modal_edit_screen, setmodal_edit_screen] = useState(false)
  const [modal_add_screen, setmodal_add_screen] = useState(false)

  const [add_customerName, setadd_customerName] = useState("")
  const [add_customerCountry, setadd_customerCountry] = useState("")
  const [add_customerOperator, setadd_customerOperator] = useState([])
  const [add_customer_contact_FullName, setadd_customer_contact_FullName] =
    useState("")
  const [add_customer_contact_Email, setadd_customer_contact_Email] =
    useState("")
  const [add_customer_contact_Phone, setadd_customer_contact_Phone] =
    useState("")
  const [add_customer_contact_IM, setadd_customer_contact_IM] = useState("")
  const [add_customer_contact, setadd_customer_contact] = useState([])

  const [add_customer_test_fullName, setadd_customer_test_fullName] =
    useState("")
  const [add_customer_test_email, setadd_customer_test_email] = useState("")
  const [add_customer_test_phone, setadd_customer_test_phone] = useState("")
  const [add_customer_test_IM, setadd_customer_test_IM] = useState("")
  const [add_customer_test, setadd_customer_test] = useState([])

  const [edit_customerName, setedit_customerName] = useState("")
  const [edit_customerCountry, setedit_customerCountry] = useState("")
  const [edit_customerOperator, setedit_customerOperator] = useState([
    "no_change",
  ])
  const [edit_customer_contact, setedit_customer_contact] = useState([
    "no_change",
  ])
  const [edit_customer_test, setedit_customer_test] = useState([])

  const [edit_customer_contact_FullName, setedit_customer_contact_FullName] =
    useState("")
  const [edit_customer_contact_Email, setedit_customer_contact_Email] =
    useState("")
  const [edit_customer_contact_Phone, setedit_customer_contact_Phone] =
    useState("")
  const [edit_customer_contact_IM, setedit_customer_contact_IM] = useState("")
  const [edit_customer_test_fullName, setedit_customer_test_fullName] =
    useState("")
  const [edit_customer_test_email, setedit_customer_test_email] = useState("")
  const [edit_customer_test_phone, setedit_customer_test_phone] = useState("")
  const [edit_customer_test_IM, setedit_customer_test_IM] = useState("")

  const [editing, setEditing]  = useState(false)

  // console.log(customerData);
  // console.log(selectedCustomerdata);

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },

    {
      dataField: "view",
      text: "View",
      sort: true,
      formatter: function (value, row, index) {
        return (
          <button
            className="view-btn-tbl"
            onClick={async () => {
              dispatch(getCountries())
              dispatch(getNewOperators())
              dispatch(getNewCountry())
              dispatch(getSelectedCustomer(row.id))
              let viewOpenPromise = new Promise(async (resolve, reject) => {
                store.subscribe(() => {
                  const all_success = store.getState()
                  const country = all_success.customer.countries
                  const selectedCustomer = all_success.customer.selectedcustomer
                  const operators = all_success.customer.operators
                  if (
                    country != [] &&
                    operators != [] &&
                    selectedCustomer != []
                  ) {
                    resolve("Done")
                  }
                })
              })
              await viewOpenPromise.then(async msg => {
                setTimeout(() => {
                  tog_view_screen()
                }, 500)
              })

              // setTimeout(() => {
              //   tog_view_screen()
              // }, 500);
            }}
          >
            View
          </button>
        )
      },
    },
    {
      dataField: "edit",
      text: "Edit",
      sort: true,
      formatter: function (value, row, index) {
        return (
          <button
            className={`view-btn-tbl ${role === 4 ? "disabled" : ""}`}
            onClick={async () => {
              dispatch(getCountries())
              dispatch(getNewOperators())
              dispatch(getNewCountry())
              dispatch(getSelectedCustomer(row.id))
              let editOpenPromise = new Promise(async (resolve, reject) => {
                store.subscribe(() => {
                  const all_success = store.getState()
                  const country = all_success.customer.countries
                  const selectedOp = all_success.customer.selectedcustomer
                  const operators = all_success.customer.operators
                  if (country != [] && operators != [] && selectedOp != []) {
                    resolve("Done")
                    setedit_customer_contact(selectedOp.message.contacts)
                    setedit_customer_test(selectedOp.message.test_node_contacts)
                  }
                })
              })
              await editOpenPromise.then(async msg => {
                //
                // setedit_customer_test(selectedCustomerdata.message.test_node_contacts)
                setTimeout(() => {
                  tog_edit_screen()
                }, 500)
              })
              // setTimeout(() => {
              //   tog_edit_screen()
              // }, 500);
            }}
            disabled={role === 4}
          >
            Edit
          </button>
        )
      },
    },
    {
      dataField: "delete",
      text: "Delete",
      sort: true,
      formatter: function (value, row, index) {
        return (
          <button
            className={`view-btn-tbl ${role === 4 ? "disabled" : ""}`}
            onClick={async () => {
              deleteCusts(row.id)
            }}
            disabled={role === 4}
          >
            Delete
          </button>
        )
      },
    },
  ]
  // Table Data
  const productData = [
    {
      id: 1,
      name: "Axiata",
      country: "Sri Lanka",
      operator: "Dialog",
      email: "abc@gmail.com",
      contact: "+11 123 456 2345",
      dateAdded: "May 1st 2022",
    },

    {
      id: 2,

      name: "Axiata",
      country: "Sri Lanka",
      operator: "Dialog",
      email: "abc@gmail.com",
      contact: "+11 123 456 2345",
      dateAdded: "May 1st 2022",
    },

    {
      id: 3,

      name: "Axiata",
      country: "Sri Lanka",
      operator: "Dialog",
      email: "abc@gmail.com",
      contact: "+11 123 456 2345",
      dateAdded: "May 1st 2022",
    },

    {
      id: 4,

      name: "Axiata",
      country: "Sri Lanka",
      operator: "Dialog",
      email: "abc@gmail.com",
      contact: "+11 123 456 2345",
      dateAdded: "May 1st 2022",
    },

    {
      id: 5,
      name: "Axiata",
      country: "Sri Lanka",
      operator: "Dialog",
      email: "abc@gmail.com",
      contact: "+11 123 456 2345",
      dateAdded: "May 1st 2022",
    },

    {
      id: 6,
      name: "Axiata",
      country: "Sri Lanka",
      operator: "Dialog",
      email: "abc@gmail.com",
      contact: "+11 123 456 2345",
      dateAdded: "May 1st 2022",
    },

    {
      id: 7,
      name: "Axiata",
      country: "Sri Lanka",
      operator: "Dialog",
      email: "abc@gmail.com",
      contact: "+11 123 456 2345",
      dateAdded: "May 1st 2022",
    },

    {
      id: 8,
      name: "Axiata",
      country: "Sri Lanka",
      operator: "Dialog",
      email: "abc@gmail.com",
      contact: "+11 123 456 2345",
      dateAdded: "May 1st 2022",
    },

    {
      id: 9,
      name: "Axiata",
      country: "Sri Lanka",
      operator: "Dialog",
      email: "abc@gmail.com",
      contact: "+11 123 456 2345",
      dateAdded: "May 1st 2022",
    },

    {
      id: 10,
      name: "Axiata",
      country: "Sri Lanka",
      operator: "Dialog",
      email: "abc@gmail.com",
      contact: "+11 123 456 2345",
      dateAdded: "May 1st 2022",
    },

    {
      id: 11,
      name: "Axiata",
      country: "Sri Lanka",
      operator: "Dialog",
      email: "abc@gmail.com",
      contact: "+11 123 456 2345",
      dateAdded: "May 1st 2022",
    },
  ]

  const tableData = customerData?.length == 0 ? [] : customerData
  const selectedCustomer_editdata =
    selectedCustomerdata?.length == 0 ? "" : selectedCustomerdata

  function tog_view_screen() {
    setmodal_view_screen(!modal_view_screen)
    removeBodyCss()
  }
  function tog_edit_screen() {
    setmodal_edit_screen(!modal_edit_screen)
    removeBodyCss()
  }
  function tog_add_screen() {
    setmodal_add_screen(!modal_add_screen)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  async function saveCustomerData() {
    // var formData = new FormData()
    // formData.append("smsc_match",input_smscmatch)
    // formData.append("smsc_replace",input_smscreplace)
    if (add_customerName == "") {
      alert("Please enter customer name")
    } else {
      var data = {
        add_customerName: add_customerName,
        // add_customerCountry :add_customerCountry,
        add_customerOperator: add_customerOperator,
        add_customer_contact: add_customer_contact,
        add_customer_test: add_customer_test,
        // add_customer_contact_FullName:add_customer_contact_FullName,
        // add_customer_contact_Email:add_customer_contact_Email,
        // add_customer_contact_Phone:add_customer_contact_Phone,
        // add_customer_contact_IM:add_customer_contact_IM,
        // add_customer_test_fullName:add_customer_test_fullName,
        // add_customer_test_email:add_customer_test_email,
        // add_customer_test_phone:add_customer_test_phone,
        // add_customer_test_IM:add_customer_test_IM
      }
      // console.log(data);
      dispatch(uploadCustomer(data))

      let uploadPromise = new Promise(async (resolve, reject) => {
        store.subscribe(() => {
          const uploadSuccess = store.getState()
          const status = uploadSuccess.customer.uploadsuccess
          if (status == true) {
            // console.log(status);
            resolve("Done")
          }
        })
      })
      await uploadPromise.then(async msg => {
        Toast.fire({
          icon: "success",
          title: "Saved Successfully!",
        })
        dispatch(setuploadCustomerSuccess(false))
        setTimeout(() => {
          setmodal_add_screen(false)
          clearAddStates()
          dispatch(getCustomerSuccess(false))
          dispatch(setNewCustomers([]))
          dispatch(getNewCustomers())
          setSearchTerm("")
        }, 1500)
      })
    }
  }
  async function deleteCusts(id) {
    Swal.fire({
      title: "Do you really want to Delete the record?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Delete",
      denyButtonText: `Don't Delete`,
    }).then(async result => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(deleteCustomer(id))
        let deletePromise = new Promise(async (resolve, reject) => {
          store.subscribe(() => {
            const deleteSuccess = store.getState()
            const status = deleteSuccess.customer.deletesuccess
            if (status == true) {
              // console.log(status);
              resolve("Done")
            }
          })
        })
        await deletePromise.then(async msg => {
          Toast.fire({
            icon: "success",
            title: "Deleted Successfully!",
          })
          dispatch(setdeleteCustomerSuccess(false))
          setTimeout(() => {
            dispatch(getCustomerSuccess(false))
            dispatch(setNewCustomers([]))
            dispatch(getNewCustomers())
            setCurrentPage(0)
          }, 1500)
        })
      } else if (result.isDenied) {
        Swal.fire("Delete Cancelled", "", "info")
      }
    })
  }
  async function saveCustomerEdit() {
    setEditing(true)
    const edit_id = selectedCustomer_editdata.id
    // console.log(edit_id);
    // const editData = {
    //   id:edit_id,
    //   contact_email : edit_customer_contact_Email,
    //   contact_fullname :edit_customer_contact_FullName,
    //   contact_im :edit_customer_contact_IM,
    //   contact_phone :edit_customer_contact_Phone,
    //   country :edit_customerCountry,
    //   name :edit_customerName,
    //   test_email :edit_customer_test_email,
    //   test_fullname :edit_customer_test_fullName,
    //   test_im :edit_customer_test_IM,
    //   test_phone :edit_customer_test_phone,
    // }
    const editData = selectedCustomerdata
    const editOperators = edit_customerOperator
    var data = {
      customer_data: editData,
      operator_data: editOperators,
      contacts: edit_customer_contact,
      testNodeContacts: edit_customer_test,
    }
    dispatch(editCustomer(data))

    let editPromise = new Promise(async (resolve, reject) => {
      store.subscribe(() => {
        const editSuccess = store.getState()
        const status = editSuccess.customer.editsuccess
        if (status == true) {
          // console.log(status);
          resolve("Done")
        }
      })
    })
    await editPromise.then(async msg => {
      Toast.fire({
        icon: "success",
        title: "Edits Saved Successfully!",
      })
      dispatch(setEditCustomerSuccess(false))
      setTimeout(() => {
        setEditing(false)
        setmodal_edit_screen(false)
        dispatch(getCustomerSuccess(false))
        dispatch(setNewCustomers([]))
        dispatch(getNewCustomers())
        setSearchTerm("")
      }, 100)
    })
  }
  function clearAddStates() {
    setadd_customerName("")
    setadd_customerCountry("")
    setadd_customerOperator("")
    setadd_customer_contact_FullName("")
    setadd_customer_contact_Email("")
    setadd_customer_contact_IM("")
    setadd_customer_test_fullName("")
    setadd_customer_test_email("")
    setadd_customer_test_phone("")
    setadd_customer_test_IM("")
  }
  function clearEditStates() {
    setedit_customerName("")
    setedit_customerCountry("")
    setedit_customerOperator("")
    setedit_customer_contact_FullName("")
    setedit_customer_contact_Email("")
    setedit_customer_contact_IM("")
    setedit_customer_test_fullName("")
    setedit_customer_test_email("")
    setedit_customer_test_phone("")
    setedit_customer_test_IM("")
    setedit_customer_contact(["no_change"])
    setedit_customer_test([])
  }

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 5,
    totalSize: tableData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: productData.length },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Customers</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Settings" breadcrumbItem="Customers" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                <Row className="mb-2">
                  <Col md="8" sm="12">
                    <div className="search-box me-2 mb-2 d-inline-block w-sm-100">
                      <Row>
                        <Col>
                          <div
                            className="me-3"
                            style={{
                              border: "1px solid #D9D9D9",
                              borderRadius: "50px",
                              marginBottom: "10px",
                              minWidth: "210px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <i
                              class="bx bx-search-alt search-icon sm-left-30 ps-2 pe-2 "
                              style={{
                                
                                width: "10px",
                                height: "10px",
                              }}
                            ></i>
                            <input
                              type="text"
                              placeholder="Search"
                              style={{
                                width: "150px",
                                height: "31px",
                                border: "none",
                                borderRadius: "10px",
                                textAlign: "start",
                                borderTopLeftRadius: "20px",
                                borderBottomLeftRadius: "20px",
                                marginTop: "2px",
                                marginLeft:"25px"
                              }}
                              value={searchTerm}
                              onChange={e => {
                                setSearchTerm(e.target.value)
                                setSearchText(e.target.value)
                              }}
                              onKeyDown={e => {
                                if (e.key === "Enter") {
                                  // setPage(1)
                                }
                              }}
                            />
                            {searchTerm !== "" && (
                              // <i className="bx bx-search-alt search-icon sm-left-30" />
                              <i
                                className="fas fa-times me-2 ms-2"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  // setPage(1)
                                  setSearchTerm("")
                                  setSearchText("")
                                }}
                              ></i>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col md="4" sm="12" className="position-relative ">
                    <div className="d-flex ">
                      <Button
                        color="danger"
                        className="btn btn-custom md-position-absolute ms-auto p-2 bd-highlight"
                        onClick={() => {
                          setTimeout(() => {
                            tog_add_screen()
                          }, 200);
                        }}
                        disabled={role === 4}
                      >
                        Add Customer
                      </Button>
                    </div>
                  </Col>
                </Row>
                <div className="col-12 ms-3">
                    {filteredChannels?.length == 0 ? (
                      getState == false ? (
                        <LoadingSpinner />
                      ) : (
                        <div
                          style={{
                            textAlign: "center",
                            paddingTop: "50px",
                          }}
                        >
                          No Data Found
                        </div>
                      )
                    ) : (
                      <div>

                      <Row style={{ marginRight: "15px" }} >
                      <Table className="table-responsive align-middle mb-0 report_table_edit">
                      <thead>
                        <tr>
                          <th>Customer Name</th>
                          <th>View</th>
                          <th>Edit</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentPageItems?.map(file => {
                          return (
                            <tr key={file.id}>
                              <td>{file.name}</td>
                              <td>           
                              <button
                                className="view-btn-tbl"
                                onClick={async () => {
                                  dispatch(getCountries())
                                  dispatch(getNewOperators())
                                  dispatch(getNewCountry())
                                  dispatch(getSelectedCustomer(file.id))
                                  let viewOpenPromise = new Promise(async (resolve, reject) => {
                                    store.subscribe(() => {
                                      const all_success = store.getState()
                                      const country = all_success.customer.countries
                                      const selectedCustomer = all_success.customer.selectedcustomer
                                      const operators = all_success.customer.operators
                                      if (
                                        country != [] &&
                                        operators != [] &&
                                        selectedCustomer != []
                                      ) {
                                        resolve("Done")
                                      }
                                    })
                                  })
                                  await viewOpenPromise.then(async msg => {
                                    setTimeout(() => {
                                      tog_view_screen()
                                    }, 500)
                                  })

                                  // setTimeout(() => {
                                  //   tog_view_screen()
                                  // }, 500);
                                }}
                              >
                                View
                              </button>
                              </td>
                              <td>
                              <button
                                className={`view-btn-tbl ${role === 4 ? "disabled" : ""}`}
                                onClick={async () => {
                                  dispatch(getCountries())
                                  dispatch(getNewOperators())
                                  dispatch(getNewCountry())
                                  dispatch(getSelectedCustomer(file.id))
                                  let editOpenPromise = new Promise(async (resolve, reject) => {
                                    store.subscribe(() => {
                                      const all_success = store.getState()
                                      const country = all_success.customer.countries
                                      const selectedOp = all_success.customer.selectedcustomer
                                      const operators = all_success.customer.operators
                                      if (country != [] && operators != [] && selectedOp != []) {
                                        resolve("Done")
                                        // console.log(selectedOp.message);
                                        const OpIds = selectedOp.operators.map(op => { return op.operator_id })
                                        const selectedOps = operators.filter(op => { return OpIds.includes(op.id) })
                                        setedit_customerOperator(selectedOps)
                                        setedit_customer_contact(selectedOp?.message?.contacts)
                                        setedit_customer_test(selectedOp?.message?.test_node_contacts)
                                      }
                                    })
                                  })
                                  await editOpenPromise.then(async msg => {
                                    //
                                    // setedit_customer_test(selectedCustomerdata.message.test_node_contacts)
                                    setTimeout(() => {
                                      tog_edit_screen()
                                    }, 500)
                                  })
                                  // setTimeout(() => {
                                  //   tog_edit_screen()
                                  // }, 500);
                                }}
                                disabled={role === 4}
                              >
                                Edit
                              </button>
                              </td>
                              <td>
                              <button
                                  className={`view-btn-tbl ${role === 4 ? "disabled" : ""}`}
                                  onClick={async () => {
                                    deleteCusts(file.id)
                                  }}
                                  disabled={role === 4}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </Row>
                      </div>
                    )}
                  </div>
                  <div className="col-12">
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(
                        filteredChannels.length / itemsPerPage
                      )}
                      forcePage={currentPage}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={data => {
                        setCurrentPage(data.selected)
                      }}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                  {/* Add Customer*/}
                  <Modal
                    size="lg"
                    isOpen={modal_add_screen}
                    toggle={() => {
                      clearAddStates()
                      tog_add_screen()
                    }}
                    // className="modal-popscreen"
                    className="modal-operator-screen"
                  >
                    <div className="modal-header">
                      <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                      >
                        ADD CUSTOMER
                      </h5>
                      <button
                        onClick={() => {
                          clearAddStates()
                          setmodal_add_screen(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <ModalBody>
                      <AddCustomer
                        modalClose={tog_add_screen}
                        upload={saveCustomerData}
                        set_customer_name={setadd_customerName}
                        set_customer_country={setadd_customerCountry}
                        set_customer_operators={setadd_customerOperator}
                        set_customer_contact_name={
                          setadd_customer_contact_FullName
                        }
                        set_customer_contact_email={
                          setadd_customer_contact_Email
                        }
                        set_customer_contact_phone={
                          setadd_customer_contact_Phone
                        }
                        set_customer_contact_im={setadd_customer_contact_IM}
                        set_customer_contact={setadd_customer_contact}
                        contact_state={add_customer_contact}
                        set_customer_test_name={setadd_customer_test_fullName}
                        set_customer_test_email={setadd_customer_test_email}
                        set_customer_test_phone={setadd_customer_test_phone}
                        set_customer_test_IM={setadd_customer_test_IM}
                        set_customer_test={setadd_customer_test}
                        test_state={add_customer_test}
                      />
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        type="button"
                        onClick={() => {
                          tog_add_screen()
                        }}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                      >
                        Close
                      </Button>
                      <Button
                        type="button"
                        className="btn btn-primary "
                        onClick={e => {
                          saveCustomerData()
                        }}
                      >
                        Save changes
                      </Button>
                    </ModalFooter>
                  </Modal>

                  {/* Customer Data Edit */}
                  <Modal
                    size="lg"
                    isOpen={modal_edit_screen}
                    toggle={() => {
                      clearEditStates()
                      tog_edit_screen()
                    }}
                    className="modal-operator-screen"
                  >
                    <div className="modal-header">
                      <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                      >
                        EDIT CUSTOMERS DETAILS
                      </h5>
                      <button
                        onClick={() => {
                          clearEditStates()
                          setmodal_edit_screen(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <ModalBody>
                      <EditCustomer
                        data={selectedCustomerdata}
                        upload={saveCustomerData}
                        set_customer_name={setedit_customerName}
                        set_customer_country={setedit_customerCountry}
                        set_customer_operators={setedit_customerOperator}
                        customer_contact={edit_customer_contact}
                        setCustomer={setedit_customer_contact}
                        test_contact={edit_customer_test}
                        setTest={setedit_customer_test}
                        selectedOps={edit_customerOperator}
                        editing={editing}
                      />
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        type="button"
                        onClick={() => {
                          clearEditStates()
                          tog_edit_screen()
                        }}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                      >
                        Close
                      </Button>
                      <Button
                        type="button"
                        className="btn btn-primary "
                        onClick={() => {
                          // console.log(edit_customer_test);
                          saveCustomerEdit()
                        }}
                      >
                        Save Changes
                      </Button>
                    </ModalFooter>
                  </Modal>

                  {/* Customer Dat View */}
                  <Modal
                    size="lg"
                    isOpen={modal_view_screen}
                    toggle={() => {
                      tog_view_screen()
                    }}
                    className="modal-popscreen"
                  >
                    <div className="modal-header">
                      <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                      >
                        CUSTOMERS DETAILS
                      </h5>
                      <button
                        onClick={() => {
                          setmodal_view_screen(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="modal-content">
                        <ViewCustomer data={selectedCustomerdata} />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={() => {
                          tog_view_screen()
                        }}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      {/* <button
                          type="button"
                          className="btn btn-primary "
                        >
                          Save changes
                        </button> */}
                    </div>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Customers
