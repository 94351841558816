import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import dotenv from 'dotenv'
import * as urls from "../../helpers/url_helper"

dotenv.config()
const url = process.env.LOCALHOST?process.env.LOCALHOST:'https://qa_api_gtc.amerck.com'

export const sourceFilesAPI = createApi({
  reducerPath: 'sourcefilesApi',
  baseQuery: fetchBaseQuery({ baseUrl: url, timeout: 40000 }),
  endpoints: (builder) => ({
    getUploadedFiles: builder.query({
      query: () => ({
        url: urls.GET_SOURCE_FILES,
        // url: '/source-files/get-uploaded-batch',
        method: 'GET',
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        timeout: 4000,
      }),
    }),
    uploadFiles: builder.mutation({
      query:({formData}) => ({
        url: urls.default,
        method: 'POST',
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        body: formData,
      })
    }),
    searchBatches: builder.query({
      query: ({
        customer,
        template,
        operator,
        testDate,
        size
      })=>({
        url: `${urls.SEARCH_UPLOADED_BATCHES}?batch_id=""&customer=${customer}&template=${template}&operator=${operator}&test_date=${testDate}&size=${size}`,
        method: 'GET',
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        timeout: 4000,
      }),
    }),
    deleteBatch : builder.mutation({
      query: (id) => ({
        url: `${urls._DELETE_BATCH}${id}`,
        method: 'GET',
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        timeout: 4000,
      }),
    })
  }),
})

export const { useGetUploadedFilesQuery, useUploadFilesMutation,useSearchBatchesQuery,useDeleteBatchMutation} = sourceFilesAPI