import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardTitle,
  Container,
  Table,
  Button,
  Input
} from "reactstrap";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import Select from "react-select";
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import { useDispatch, useSelector } from "react-redux";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"



const OwnA2p = (props) => {
  const [projectVideoLink, setProjectVideoLink] = useState([{id : 1, data: ""}])
  const [initialState,setIntialState]=useState(1)
  const [formRows, setFormRows] = useState([]);
  const [valueVideo, setValueVideo] = useState([])

  const SendState = props.sendState;
  const endOfFormRowsRef = useRef(null);


  if(initialState==1){
    const modifiedRows = [...formRows]
    var tempVidLink=[...valueVideo]
    if(SendState.length==0){modifiedRows.push({ id: modifiedRows.length + 1 });}
    for(var obj of SendState){
     modifiedRows.push({ id: modifiedRows.length + 1 });
    }
    setFormRows(modifiedRows);
    SendState.forEach(async (ele,index) => {
      ele.key = index+1; 
    });
    SendState.forEach((ele,index) => {
      var data = {
        key :index+1,
        a2p : ele.gts,
      }
      tempVidLink.push(data);
      // console.log(tempVidLink);
    });
    //setSendState(defaultState);
    setValueVideo(tempVidLink)
    setIntialState(2)
  }
  const scrollToBottom = () => {
    setTimeout(() => {
      endOfFormRowsRef.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }, 100); // Adjust delay as needed
  }
  
  
  //console.log(defaultState);
  // console.log(SendState);
  
  const onAddFormRow = () => {
    const modifiedRows = [...formRows];
    // console.log(modifiedRows);
    var temp_id = modifiedRows.length+1;
    var data = {
      key :  temp_id
    }
    modifiedRows.push({ id: modifiedRows.length + 1 });
    //defaultState.push(data);
    SendState.push(data);
    setFormRows(modifiedRows);
    scrollToBottom();
  };

  const onDeleteFormRow = id_1 => {
    var id = id_1
    // console.log(id);
    if (id != 1 && id >1) {
      var previd = 1;
      var modifiedRows = [...formRows];
      // console.log(modifiedRows);
      var i = modifiedRows.findIndex(obj=>obj["id"]==id)
      modifiedRows = modifiedRows.filter(x => x["id"] != id);
      while (i < modifiedRows.length) {
        modifiedRows[i].id--;
          i++;
      }
      // console.log(modifiedRows);
      setFormRows(modifiedRows);
    }
    if(id == 1 && formRows.length>1){
      id = id+1;
      var modifiedRows = [...formRows];
      // console.log(modifiedRows);
      var i = modifiedRows.findIndex(obj=>obj["id"]==id)
      modifiedRows = modifiedRows.filter(x => x["id"] != id);
      while (i < modifiedRows.length) {
        modifiedRows[i].id--;
          i++;
      }
      // console.log(modifiedRows);
      setFormRows(modifiedRows);
    }
    var i = SendState?.findIndex(obj=>obj.key == id_1);
    // console.log(i);
    SendState.splice(i, 1);
    while (i < SendState.length) {
      SendState[i].key--;
        i++;
    }
    // console.log(SendState);
  };

  const onDeleteSendState = (id) =>{
    var i = SendState?.findIndex(obj=>obj.key == id);
    // console.log(i);
    SendState.splice(i, 1);
    while (i < SendState.length) {
      SendState[i].key--;
        i++;
    }
    // console.log(SendState);
  }
  

  const deletAll = (id) =>{
    if((valueVideo?.filter(obj=>obj?.key==id).map(obj=>obj?.a2p))[0]!=null){
      deletVid(id);
    };
  }
  const setVid = (e,id)=>{
    var temp = [...valueVideo];
    if(e!=null && id!=null){
      //console.log(projectLongitude);

      //console.log("KEYS"+mainState.map(obj=>obj.key));
      var tempid = id;
      var templongt = e;
  
      var data = {
        key :tempid,
        a2p : templongt,
      }
      if(temp.map(obj=>obj.key).includes(data.key) == false){
        temp.push(data);
      }
      else if(temp.map(obj=>obj.key).includes(data.key) == true){
        var objIndex = temp.findIndex(obj=>obj.key == data.key)

        temp[objIndex].a2p = data.a2p;
        
      }
      
      // console.log(mainState);
    }
    setValueVideo(temp) 
  }
  const deletVid = (id)=>{
    var temp = [...valueVideo];
    var i = temp.findIndex(obj=>obj.key == id);
    temp.splice(i, 1);
    while (i < temp.length) {
      temp[i].key--;
        i++;
    }
    setValueVideo(temp)
  }

  useEffect(() => {
    //console.log("hello");
    if(projectVideoLink["id"]!=null && projectVideoLink["data"]!=null){
      var tempid = projectVideoLink["id"];
      var tempcountry = projectVideoLink["data"];
  
      var data = {
        key :tempid,
        a2p : projectVideoLink["data"],
      }
      if(SendState.map(obj=>obj.key).includes(data.key) == false){
        SendState.push(data);
      }
      else if(SendState.map(obj=>obj.key).includes(data.key) == true){
        var objIndex = SendState.findIndex(obj=>obj.key == data.key)
        SendState[objIndex].a2p = data.a2p;
      }
      
      // console.log(SendState);
    } 

  }, [projectVideoLink]);

  return (
    <React.Fragment>
    <div className="bg-white ">
      <div className="container-fluid " style={{ padding: "0px" }}>
        <div>
          <Col
            className="smsc-card d-block p-2 "
            md={12}
            style={{ maxHeight: "225px", overflowY: "auto" }} // Adjust maxHeight as needed
          >
            <div className="row d-flex align-items-center ps-2">
            <label className="operator-label">Own A2P</label>
            </div>
            <div>
              {formRows.map((formRow, key) => (
                <Col md="12" key={key} className="">
                  <div className=" row d-flex align-items-center mb-3 mt-2 ps-2">
                    <label className="mt-1" htmlFor="name">
                      A2P : {formRow.id}
                    </label>
                    <Col md={10}>
                      <PhoneInput
                        className="customer-phone-input"
                        placeholder="Enter Phone Number"
                        // value={valueVideo
                        //   .filter(obj => obj.key == formRow.id)
                        //   .map(obj => obj.a2p)
                        //   .toString()}
                        value={valueVideo?.filter(obj=>obj.key==formRow.id)?.map(obj=>obj.a2p).toString()} 
                        onChange={e => {
                          if (e) {
                            setVid(e, formRow.id)
                            setProjectVideoLink({
                              id: formRow.id,
                              data: e,
                            })
                          }
                        }}
                      />
                    </Col>
                    <Col md={2} className="mt-2">
                      <FontAwesomeIcon
                        icon={faTimes}
                        className=""
                        style={{ color: "red" }}
                        onClick={() => {
                          if(SendState.length!=0){
                            onDeleteFormRow(formRow.id)
                            deletAll(formRow.id)
                          }
                        }}
                      />
                    </Col>
                  </div>
                </Col>
              ))}
              <div ref={endOfFormRowsRef} />
            </div>
          </Col>
        </div>
      </div>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={onAddFormRow}
          className="add-smsc mt-4 flex-end"
          style={{ width: "124px" }}
        >
          Add
        </Button>
      </div>
    </div>

    {/* <button onClick={() => onAddFormRow()}className="responce-btn" id="img-btn" type="button"><i className="bx bx-add-to-queue"></i> Add New </button> */}
  </React.Fragment>
  )
}


export default OwnA2p 
