import { createSlice } from "@reduxjs/toolkit"

const operatorSlice = createSlice({
  name: "operator",
  initialState: {
    operators: [],
    gatekeepers: [],
  },
  reducers: {
    setOperators: (state, action) => {
      state.operators = action.payload
    },
    setGatekeepers: (state, action) => {
      state.gatekeepers = action.payload
    }
  },
})

export const { setOperators,setGatekeepers } = operatorSlice.actions
export default operatorSlice.reducer
