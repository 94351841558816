import axios from "axios"
import * as aT from "./actionTypes"


const INIT_STATE = {
  templates:[],
  getSuccess:false,
  selectedTemplate:[],
  uploadSuccess:false,
  deleteSuccess:false,
  editSuccess:false,
  selectedSuccess:false,
   }
  
  const partner = (state = INIT_STATE, action) => {
    switch (action.type) {
      // case aT.SET_NEW_PAR_COUNTRIES:
      //   state.countries = action.payload;
      //   return {
      //     ...state,
      //   }
      case aT.GET_NEW_TEMPLATES_SUCCESS:
        // state.getSuccess = action.payload;
        return {
          ...state,
          getSuccess : action.payload
        }
      case aT.SET_EDIT_TEMPLATE_SUCCESS:
        // state.editSuccess = action.payload;
        return {
          ...state,
          editSuccess : action.payload
        }
      case aT.SET_UPLOAD_TEMPLATE_SUCCESS:
        // state.uploadSuccess = action.payload;
        return {
          ...state,
          uploadSuccess : action.payload
        }
      case aT.SET_NEW_TEMPLATES:
        // state.templates = action.payload;
        return {
          ...state,
          templates : action.payload
        }
      case aT.SET_DELETE_TEMPLATE_SUCCESS:
        // state.deleteSuccess = action.payload;
        return {
          ...state,
          deleteSuccess : action.payload
        }
      case aT.SET_SELECTED_TEMPLATES:
        // state.selectedTemplate = action.payload;
        return {
          ...state,
          selectedTemplate : action.payload
        }
      case aT.SET_SELECTED_TEMPLATE_SUCCESS:
        // state.selectedSuccess = action.payload;
        return {
          ...state,
          selectedSuccess : action.payload
        }
      default:
        return state
    }
  }
  
  export default partner