import React from "react";
import { Container } from "reactstrap";
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardTitle,
  CSpinner,
  Table,
  Button,
  Input
} from "reactstrap"

export default function LoadingSpinner() {
  return (
    <CardBody fluid>
    <div >
      <div className="spinner-container">
        <div className="loading-spinner">
        </div>
      </div>
      <div>
      <h1 className="loader-text">Uploading Batch...</h1>
      </div>
    </div>
    </CardBody>

  );
}