import React from "react"
import MetaTags from "react-meta-tags"
import { Route, Switch } from "react-router-dom"
import EmailTemplate from "../../components/Settings/EmailTemplate"
import Formulas from "../../components/Settings/Formulas"
import KnowledgeBase from "../../components/Settings/KnowledgeBase"
import Users from "../../pages/Users/index"
import Countries from "../../pages/Countries/index"
import jwt from 'jsonwebtoken'
import OtherChannel from "components/Settings/OtherChannel"




export default function Settings() {
  const decodeToken = (token) => {
    try {
      const decodedToken = jwt.decode(token);
      return decodedToken;
    } catch (error) {
      console.log('Error decoding token:', error);
      return null;
    }
  }
  
  // Usage
  const token = localStorage.getItem('authUser');
  const decodedToken = decodeToken(JSON.parse(token));
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Settings</title>
        </MetaTags>

        <Switch>
          {/* <Route path="/settings/email-template" component={EmailTemplate} /> */}
          <Route path="/settings/knowledge-base" component={KnowledgeBase} />
          {[2,5].includes(decodedToken.role)?<Route path="/settings/users" component={Users} />:""}
          {/* <Route path="/settings/users" component={Users} /> */}
          <Route path="/settings/country" component={Countries} />
          <Route path="/settings/templates" component={EmailTemplate} />
          <Route path="/settings/formula" component={Formulas} />
          <Route path="/settings/otherchannel" component={OtherChannel} />

          
        </Switch>
      </div>
    </React.Fragment>
  )
}
