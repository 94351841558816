export const GET_NEW_CUSTOMER = "GET_NEW_CUSTOMER"
export const GET_NEW_CUSTOMER_SUCCESS = "GET_NEW_CUSTOMER_SUCCESS"
export const SET_NEW_CUSTOMER= "SET_NEW_CUSTOMER"
export const GET_COUNTRIES = "GET_COUNTRIES"
export const SET_COUNTRIES = "SET_COUNTRIES"
export const GET_SELECTED_CUSTOMER = "GET_SELECTED_CUSTOMER"
export const SET_SELECTED_CUSTOMER= "SET_SELECTED_CUSTOMER"
export const SET_SELECTED_CUSTOMER_SUCCESS= "SET_SELECTED_CUSTOMER_SUCCESS"
export const UPLOAD_CUSTOMER = "UPLOAD_CUSTOMER"
export const SET_UPLOAD_CUSTOMER_SUCCESS = "SET_UPLOAD_CUSTOMER_SUCCESS"
export const EDIT_CUSTOMER = "EDIT_CUSTOMER"
export const SET_EDIT_CUSTOMER_SUCCESS= "SET_EDIT_CUSTOMER_SUCCESS"
export const DELETE_CUSTOMER = "DELETE_CUSTOMER"
export const SET_DELETE_CUSTOMER_SUCCESS = "SET_DELETE_CUSTOMER_SUCCESS"
export const GET_NEWOPERATORS = "GET_NEWOPERATORS"
export const SET_NEWOPERATORS = "SET_NEWOPERATORS"
export const GET_NEWCOUNTRIES = "GET_NEWCOUNTRIES"
export const SET_NEWCOUNTRIES = "SET_NEWCOUNTRIES"
