import React, { Component } from "react"
import MetaTags from "react-meta-tags"
import { Route, Switch } from "react-router-dom"
import { Container } from "reactstrap"
import ReportEdit from "../../../components/Reports/ReportEdit"
import ReportGenerate from "../../../components/Reports/ReportGenerate"
import ReportHistory from "../../../components/Reports/ReportHistory"
import ReportSend from "../../../components/Reports/ReportSend"

export default function Reports() {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Reports</title>
        </MetaTags>

        <Switch>
          <Route path="/reports/history/view" component={ReportEdit} />
        </Switch>
      </div>
    </React.Fragment>
  )
}
