import * as url from "../../../helpers/url_helper"

export async function uploadReports(data) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            // "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            // "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        }
        const response = await fetch(url._UPLOAD_REPORT, {
            method: "POST",
            headers: headers,
            body: data,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function deleteReports(id) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._DELETE_HISTORICAL_REPORT+id, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function updateReportData(data) {
    try {
       // const JSONdata = JSON.stringify(data);

        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            // "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            // "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        }
        const response = await fetch(url._UPDATE_REPORT, {
            method: "POST",
            headers: headers,
            body: data,
        })

        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function updateKPIData(data) {
    try {
       // const JSONdata = JSON.stringify(data);

        const headers = {
            // "Content-Type": "multipart/form-data",
            auth: JSON.parse(localStorage.getItem("authUser")),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            // "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        }
        const response = await fetch(url._UPDATE_KPI, {
            method: "POST",
            headers: headers,
            body: data,
        })

        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchRecentlyUploadedReports() {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._GET_RECENTLY_UPLOADED_REPORTS, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}

export async function fetchCustomers() {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._GET_NEW_CUSTOMERS, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchPartners() {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._GET_PARTNERS, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchOperators() {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._GET_NEW_OPERATORS, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchCountries() {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._GET_NEW_COUNTRY, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchTemplates() {
    try {
        const response = await fetch(url._GET_TEMPLATES, {
            method: "GET",
            headers: {
                auth: JSON.parse(localStorage.getItem("authUser")),
                "Accept": "*/*"
            }
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}

