import * as url from "../../../helpers/url_helper"

export async function fetchUploadedFiles() {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url.GET_SOURCE_FILES, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchUploadedBatches() {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url.GET_UPLOADED_BATCHES, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchCustomers() {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._GET_NEW_CUSTOMERS, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchPartners() {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._GET_PARTNERS, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchOperators() {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._GET_NEW_OPERATORS, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchTemplates() {
    try {
        const response = await fetch(url._GET_TEMPLATES, {
            method: "GET",
            headers: {
                auth: JSON.parse(localStorage.getItem("authUser")),
                "Accept": "*/*"
            }
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function uploadFiles(data) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            // "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            // "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        }
        const response = await fetch(url.default, {
            method: "POST",
            headers: headers,
            body: data,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchSearchUploadedBatches(data) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url.SEARCH_UPLOADED_BATCHES + `?batch_id=""&customer=${data.customer}&template=${data.template}&operator=${data.operator}&test_date=${data.testDate}&size=${data.size}`, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
export async function fetchDeleteBatch(id) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const response = await fetch(url._DELETE_BATCH+id, {
            method: "GET",
            headers: headers,
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}
