import React, { Component, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Container } from "reactstrap"
import Dashboarddetails from "./dashBoardDetails"
import Select from "react-select"
import sheetImage from "../../assets/images/sheets.png"
import Deliverykpi from "../../pages/AllCharts/apex/deliverykpicopy"
import Pricingkpi from "../AllCharts/apex/pricingkpicopy"
import MTR from "../AllCharts/apex/mtr_kpi_copy"
import Time from "../AllCharts/apex/time_kpi_copy"
import jwt from "jsonwebtoken"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter"
import cellEditFactory from "react-bootstrap-table2-editor"
import {
  BarLoader,
  CircleLoader,
  FadeLoader,
  PacmanLoader,
} from "react-spinners"
import {
  useGetCustomersQuery,
  useGetOperatorsQuery,
  useLazyGetLatestReportQuery,
  useLazyGetKPIDataQuery,
} from "store/customerPortal/customerPortal.api"
import {
  setCustomers,
  setOperators,
  setDeliveryChartData,
  setKPIData,
  setMTRChartData,
  setPricingChartData,
  setTimeChartData,
  setKpiDates,
  setTemplateOptions
} from "store/customerPortal/customerPortal.slice"
import { get } from "lodash"
import SearchFolder from "../../assets/images/search-folder.png"
import Swal from "sweetalert2"
import axios from "axios"
import * as urls from "../../helpers/url_helper"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import { components } from "react-select"


const columnskpi = [
  {
    dataField: "test_date",
    text: "Test date",
    headerClasses: "min-width-th-110",
    editable: false,
  },
  {
    dataField: "template",
    text: "Template",
    editable: false,
    classes: "text-align-left",
  },
  {
    dataField: "tested_routes",
    text: "# Tested routes",
    editable: false,
    headerClasses: "min-width-th-90",
  },
  {
    dataField: "test_per_routes",
    text: "# Test per route",
    editable: false,
    headerClasses: "min-width-th-70",
  },
  {
    dataField: "delivered_sms",
    text: "# Delivered SMS",
    editable: false,
    headerClasses: "min-width-th-110",
  },
  {
    dataField: "delivery_ratio",
    text: "Delivery ratio - all routes (%)",
    editable: false,
    headerClasses: "min-width-th-90",
  },
  {
    dataField: "delivered_sms_below_mtr",
    text: "# Delivered SMS - below MTR",
    editable: false,
    headerClasses: "min-width-th-110",
  },
  {
    dataField: "dlv_routes_below_mtr",
    text: "Delivered SMS - ratio below MTR (%)",
    editable: false,
    headerClasses: "min-width-th-100",
  },
  {
    dataField: "delivery_ratio_above_mtr",
    text: "Delivery ratio - routes above MTR (%)",
    editable: false,
    headerClasses: "min-width-th-100",
  },
  {
    dataField: "delivery_ratio_below_mtr",
    text: "Delivery ratio - routes below MTR (%)",
    editable: false,
    headerClasses: "min-width-th-100",
  },
  {
    dataField: "total_dlrs",
    text: "# Total DLRs",
    editable: false,
    headerClasses: "min-width-th-80",
  },
  {
    dataField: "positive_dlrs",
    text: "# Positive DLRs",
    editable: false,
    headerClasses: "min-width-th-100",
  },
  {
    dataField: "negative_dlrs",
    text: "# Negative DLRs",
    editable: false,
    headerClasses: "min-width-th-100",
  },
  {
    dataField: "honest_positive_dlr",
    text: "# Honest positive DLRs",
    editable: false,
    headerClasses: "min-width-th-90",
  },

  {
    dataField: "honest_positive_dlr_ratio",
    text: "Honest positive DLR ratio (%)",
    editable: false,
  },
  {
    dataField: "fakedlrs",
    text: "# Fake DLRs",
    editable: false,
    headerClasses: "min-width-th-80",
  },
  {
    dataField: "fakedlr_positivedlr_ratio",
    text: "Fake DLR ratio (%)",
    editable: false,
  },
  {
    dataField: "fakedlr_ratio_above_mtr",
    text: "Fake DLR ratio - routes above MTR (%)",
    editable: false,
    headerClasses: "min-width-th-100",
  },
  {
    dataField: "fakedlr_ratio_under_mtr",
    text: "Fake DLR ratio - routes below MTR (%)",
    editable: false,
    headerClasses: "min-width-th-100",
  },
  {
    dataField: "sender_mod_ratio",
    text: "Sender modification ratio (%)",
    editable: false,
  },
  {
    dataField: "content_mod_ratio",
    text: "Content modification ratio (%)",
    editable: false,
  },
  {
    dataField: "content_mod_level",
    text: "Content modification level",
    editable: false,
  },
  {
    dataField: "template_spoofing",
    text: "# Template spoofing",
    editable: false,
    headerClasses: "min-width-th-110",
  },
  {
    dataField: "other_channel_deliveries",
    text: "# Other channel deliveries",
    editable: false,
  },
  {
    dataField: "sim_nonet_smsc",
    text: "# On-net SIM",
    editable: false,
    headerClasses: "min-width-th-70",
  },
  {
    dataField: "nonet_nonoffic_smsc_nosim",
    text: "# On-net unofficial SMSC",
    editable: false,
    headerClasses: "min-width-th-90",
  },
  {
    dataField: "simbox_nationaloffnetsmsc",
    text: "# Off-net SIM",
    editable: false,
    headerClasses: "min-width-th-80",
  },
  {
    dataField: "national_smsc_without_sim",
    text: "# Off-net unofficial SMSC",
    editable: false,
    headerClasses: "min-width-th-90",
  },
  {
    dataField: "internationalsmsc",
    text: "# International SMSC",
    editable: false,
    headerClasses: "min-width-th-130",
  },
  {
    dataField: "bypass_ratio",
    text: "Bypass ratio (%)",
    editable: false,
    headerClasses: "min-width-th-90",
  },
  // {
  //   dataField: "official_mtr_usd",
  //   text:"Official MTR (USD)"
  // },
  // {
  //   dataField:"exchange_rate",
  //   text:"Exchange Rate"
  // },
  {
    dataField: "currency",
    text: "Currency",
    editable: false,
    classes: "text-align-left",
  },
  {
    dataField: "official_mtr_eur",
    text: "Official MTR",
    editable: false,
  },
  {
    dataField: "avg_price_dlv_routes",
    text: "Avg. price of delivering routes",
    editable: false,
  },
  {
    dataField: "avg_price_official_routes",
    text: "Avg. price of official routes",
    editable: false,
  },
  {
    dataField: "avg_dlv_time",
    text: "Avg. delivery time",
    editable: false,
  },
  {
    dataField: "routes_dlvtime_less_1",
    text: "Routes with delivery time < 1min (%)",
    editable: false,
    headerClasses: "min-width-th-100",
  },
  {
    dataField: "routes_dlvtime_less_5",
    text: "Routes with delivery time < 5min (%)",
    editable: false,
    headerClasses: "min-width-th-100",
  },
]

const Dashboard = () => {
  const decodeToken = token => {
    try {
      const decodedToken = jwt.decode(token)
      return decodedToken
    } catch (error) {
      console.log("Error decoding token:", error)
      return null
    }
  }
  const getRole = () =>{
    if(localStorage.getItem("authUser")){
      const obj = JSON.parse(localStorage.getItem("authUser"))
      const decodedToken = decodeToken(obj)
      return decodedToken.role
  }}
  const dispatch = useDispatch()
  const [username, setUsername] = useState("")
  const [selectedCustomer, setSelectedCustomer] = useState("")
  const [selectedOperator, setSelectedOperator] = useState("")
  const [selectedTemplate, setSelectedTemplate] = useState("All")
  const [reportName, setReportName] = useState("")
  const [role, setRole] = useState(getRole())
  const [downloadReportLoading, setDownloadReportLoading] = useState(false)
  const {
    data: customers,
    isLoading: customersLoading,
    isFetching: customersFetching,
    isError: customerFetchError,
  } = useGetCustomersQuery()
  const {
    data: operators,
    isLoading: operatorsLoading,
    isFetching: operatorsFetching,
    isError: operatorsFetchError,
  } = useGetOperatorsQuery(
    {
      customer_id: selectedCustomer?.value,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  )
  const [
    getLatestReport,
    {
      data: latestReport,
      isLoading: reportLoading,
      isFetching: reportFetching,
      isError: reportError,
    },
  ] = useLazyGetLatestReportQuery({
    refetchOnMountOrArgChange: true,
  })
  const [
    getKPIData,
    {
      data: KPIData,
      isLoading: kpiLoading,
      isFetching: kpiFetching,
      isError: kpiError,
    },
  ] = useLazyGetKPIDataQuery({
    refetchOnMountOrArgChange: true,
  })
  const {
    customers: customersData,
    operators: operatorsData,
    deliveryChartData,
    kpiData,
    mtrChartData,
    pricingChartData,
    timeChartData,
    kpiDates: kpi_dates,
    template_options: kpiTemplateOptions,
  } = useSelector(state => state.customerPortal)

  const [sortedOptions, setSortedOptions] = useState([]);

  useEffect(() => {
    if (kpiTemplateOptions) {
      const sorted = [...kpiTemplateOptions].sort((a, b) => a.label.localeCompare(b.label));
      setSortedOptions(sorted);
    } 
  }, [kpiTemplateOptions]);
  
  // console.log(kpiData)

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      const decodedToken = decodeToken(obj)
      setUsername(decodedToken.name)
      setRole(decodedToken.role)
    }
  }, [])

  useEffect(() => {
    if (customers) {
      let temp = customers.map(customer => ({
        value: customer.id,
        label: customer.name,
      }))
      dispatch(setCustomers(temp))
      if (role === 1) {
        setSelectedCustomer(temp[0])
      }
    }
  }, [customers])

  useEffect(() => {
    if (operators) {
      const temp = operators.map(obj => {
        return {
          id: obj.id,
          label: obj.country + " " + obj.operator_name,
          value: obj.country + " " + obj.operator_name,
        }
      })
      temp.sort((a, b) => {
        // console.log(a,b);
        const countryA = a.label.split(" ")[0]
        const countryB = b.label.split(" ")[0]

        // Compare country names
        if (countryA < countryB) {
          return -1
        } else if (countryA > countryB) {
          return 1
        } else {
          return 0
        }
      })
      dispatch(setOperators(temp))
      if (role === 1) {
        setSelectedOperator(temp[0])
      }
    }
  }, [operators])

  useEffect(() => {
    if (selectedCustomer && selectedOperator) {
      getLatestReport({
        customer_id: selectedCustomer.value,
        operator_id: selectedOperator.id,
      }).then(data => {
        let response = get(data, "data", [])
        if (response) {
          setReportName(response.report_name)
        } else {
          setReportName("")
        }
      })
      getKPIData({
        customer_id: selectedCustomer.value,
        operator_id: selectedOperator.id,
        template: selectedTemplate,
      }).then(data => {
        let kpi_data_test = get(data, "data.kpidata", [])
        if (kpi_data_test.length > 0) {
          let temp_KPIData = get(data, "data.kpidata", [])
          let temp_deliveryChartData = get(data, "data.delivery_chart_data", [])
          let temp_mtrChartData = get(data, "data.mtr_chart_data", [])
          let temp_pricingChartData = get(data, "data.pricing_chart_data", [])
          let temp_timeChartData = get(data, "data.time_chart_data", [])
          let temp_kpi_dates = get(data, "data.kpi_dates", [])
          let temp_template_options = get(data, "data.template_options", [])

          dispatch(setKPIData(temp_KPIData))
          dispatch(setDeliveryChartData(temp_deliveryChartData))
          dispatch(setMTRChartData(temp_mtrChartData))
          dispatch(setPricingChartData(temp_pricingChartData))
          dispatch(setTimeChartData(temp_timeChartData))
          dispatch(setKpiDates(temp_kpi_dates))
          dispatch(setTemplateOptions(temp_template_options))

        } else {
          clearKPIstates()
        }
      })
    }
  }, [selectedCustomer, selectedOperator, selectedTemplate])

  useEffect(() => {
    // Any setup code here...

    // Cleanup function
    return () => {
      clearKPIstates()
    }
  }, [])

  async function downloadReports() {
    // console.log(customerId,customer);
    if (selectedCustomer && selectedOperator) {
      const customerLabel = selectedCustomer.label
      const operatorLabel = selectedOperator.label
      var data = {
        customer_id: selectedCustomer.value,
        operator_id: selectedOperator.id,
        report_limit: 10,
      }
      const headers = {
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        responseType: "arraybuffer",
      }
      // console.log(headers);
      setDownloadReportLoading(true)
      await axios
        .post(urls.PORTAL_DOWNLOAD_REPORT, data, headers)
        .then(res => {
          // console.log(res);
          // const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          const contentType = "application/octet-stream"
          const blob = new Blob([res.data], { type: contentType })
          var link = document.createElement("a")
          link.href = URL.createObjectURL(blob)
          link.download = `NPT Results - ${customerLabel} - ${operatorLabel}.xlsx`
          link.click()
          setDownloadReportLoading(false)
        })
        .catch(err => {
          console.log(err)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong on the Server.",
          })
          setDownloadReportLoading(false)
        })
    }
  }

  const clearKPIstates = () => {
    dispatch(setKPIData([]))
    dispatch(setDeliveryChartData([]))
    dispatch(setMTRChartData([]))
    dispatch(setPricingChartData([]))
    dispatch(setTimeChartData([]))
    dispatch(setKpiDates([]))
    dispatch(setTemplateOptions([]))
  }

  const DropdownIndicator = props => {
    return (
      // props.selectProps.value ? null : (
      <components.DropdownIndicator {...props}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.0011 12V19.88C14.0411 20.18 13.9411 20.5 13.7111 20.71C13.6186 20.8027 13.5087 20.8762 13.3877 20.9264C13.2668 20.9766 13.1371 21.0024 13.0061 21.0024C12.8751 21.0024 12.7455 20.9766 12.6245 20.9264C12.5035 20.8762 12.3936 20.8027 12.3011 20.71L10.2911 18.7C10.1821 18.5933 10.0992 18.4629 10.0489 18.319C9.99861 18.175 9.98225 18.0213 10.0011 17.87V12H9.97111L4.21111 4.62C4.04872 4.41153 3.97544 4.14726 4.0073 3.88493C4.03915 3.6226 4.17354 3.38355 4.38111 3.22C4.57111 3.08 4.78111 3 5.00111 3H19.0011C19.2211 3 19.4311 3.08 19.6211 3.22C19.8287 3.38355 19.9631 3.6226 19.9949 3.88493C20.0268 4.14726 19.9535 4.41153 19.7911 4.62L14.0311 12H14.0011Z"
            fill="#B7B8BB"
          />
        </svg>
      </components.DropdownIndicator>
      // )
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          {role === 1 ? (
            <title>Dashboard</title>
          ) : (
            <title>Customer Portal</title>
          )}
        </MetaTags>

        <Container fluid>
          <div className="pb-4">
            <Dashboarddetails head2="DASHBOARD" />
          </div>

          <div className="card">
            <div className="card-body">
              <div className="row d-flex align-items-center ">
                <div className="col-md-3 pt-3 ps-3 pe-3">
                  {role === 1 ? (
                    <div style={{ textAlign: "center" }}>
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          display: "block",
                        }}
                      >
                        Customer
                      </span>
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                          display: "block",
                        }}
                      >
                        {selectedCustomer?.label
                          ? selectedCustomer.label
                          : "--"}
                      </span>
                    </div>
                  ) : (
                    // <Select
                    //   options={customersData}
                    //   isClearable
                    //   placeholder="Customers"
                    //   onChange={e => {
                    //     if (e) {
                    //       setSelectedCustomer(e)
                    //       setSelectedOperator("")
                    //       clearKPIstates()
                    //     } else {
                    //       setSelectedCustomer("")
                    //       setSelectedOperator("")
                    //       clearKPIstates()
                    //       setReportName("")
                    //     }
                    //   }}
                    //   styles={{
                    //     control: styles => ({
                    //       ...styles,
                    //       border: "1px solid #D9D9D9",
                    //     }),
                    //   }}
                    // />
                    <Autocomplete
                      className=""
                      disablePortal
                      options={customersData}
                      onChange={(e, value) => {
                        if (value) {
                          setSelectedCustomer(value)
                          setSelectedOperator("")
                          clearKPIstates()
                        } else {
                          setSelectedCustomer("")
                          setSelectedOperator("")
                          clearKPIstates()
                          setReportName("")
                        }
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: 45,
                        },
                        "& .MuiFormLabel-root": {
                          fontSize: 13,
                          fontFamily: "Poppins",
                          color: "#808080",
                        },
                        "& .MuiAutocomplete-input": {
                          fontFamily: "Poppins",
                          fontSize: "0.875rem",
                          fontWeight: "normal",
                          lineHeight: "1.43",
                          letterSpacing: "0.01071em",
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "rgb(129, 17, 19)",
                          },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "rgb(129, 17, 19)",
                        },
                      }}
                      noOptionsText={
                        <div
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "0.875rem",
                          }}
                        >
                          No Options
                        </div>
                      }
                      renderOption={(props, option, { selected }) => (
                        <li
                          {...props}
                          style={{ fontFamily: "Poppins", fontSize: "13px" }}
                        >
                          {option.label}
                        </li>
                      )}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Customer"
                          InputLabelProps={{
                            style: { top: "-2px" },
                          }}
                        />
                      )}
                    />
                  )}
                </div>
                <div className="col-md-3 pt-3 ps-3 pe-3">
                  {/* <Select
                    options={operatorsData}
                    value={selectedOperator}
                    placeholder="Operator"
                    onChange={e => {
                      if (e) {
                        setSelectedOperator(e)
                        clearKPIstates()
                      } else {
                        setSelectedOperator("")
                        clearKPIstates()
                        setReportName("")
                      }
                    }}
                    styles={{
                      control: styles => ({
                        ...styles,
                        border: "1px solid #D9D9D9",
                      }),
                    }}
                  /> */}
                  <Autocomplete
                    disablePortal
                    options={operatorsData}
                    value={selectedOperator}
                    onChange={(e, value) => {
                      if (value) {
                        setSelectedOperator(value)
                        clearKPIstates()
                      } else {
                        setSelectedOperator("")
                        clearKPIstates()
                        setReportName("")
                      }
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 45,
                      },
                      "& .MuiFormLabel-root": {
                        fontSize: 13,
                        fontFamily: "Poppins",
                        color: "#808080",
                      },
                      "& .MuiAutocomplete-input": {
                        fontFamily: "Poppins",
                        fontSize: "0.875rem",
                        fontWeight: "normal",
                        lineHeight: "1.43",
                        letterSpacing: "0.01071em",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "rgb(129, 17, 19)",
                        },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "rgb(129, 17, 19)",
                      },
                    }}
                    noOptionsText={
                      <div
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "0.875rem",
                        }}
                      >
                        No Options
                      </div>
                    }
                    renderOption={(props, option, { selected }) => (
                      <li
                        {...props}
                        style={{ fontFamily: "Poppins", fontSize: "13px" }}
                      >
                        {option.label}
                      </li>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Operator"
                        InputLabelProps={{
                          style: { top: "-2px" },
                        }}
                      />
                    )}
                  />
                </div>
                <div className="col-md-6 pt-3"></div>
              </div>
              <div className="row d-flex align-items-center">
                {reportLoading || reportFetching ? (
                  <div className="col-md-12 pt-5">
                    <div className="col-md-12 pt-4 d-flex justify-content-center align-items-center">
                      <CircleLoader
                        color="#85181B"
                        size={40}
                        speedMultiplier={1}
                      />
                    </div>
                  </div>
                ) : reportName ? (
                  <div className="col-md-12 pt-4 pb-2">
                    <div className="row d-flex align-items-center ps-1">
                      <div className="ps-3 pt-3">
                        <Dashboarddetails text1="Latest Report" />
                      </div>
                      <div className="col-lg-12 col-md-10 col-sm-12 col-12 pt-3 d-flex align-items-center">
                        <img
                          src={sheetImage}
                          style={{
                            marginRight: "15px",
                            width: "35px",
                            height: "35px",
                          }}
                        />
                        <Dashboarddetails text2={reportName} />
                        {downloadReportLoading ? ( // If downloadReportLoading is true, show the spinner
                          <div>
                            <BarLoader
                              className="ms-5"
                              color="#85181B"
                              cssOverride={{
                                marginLeft: "50px",
                              }}
                              height={8}
                              speedMultiplier={1}
                              width={90}
                            />{" "}
                            {/* Adjust loader properties as needed */}
                          </div>
                        ) : (
                          <div>
                            <button
                              className="btn ms-5"
                              disabled={downloadReportLoading}
                              onClick={downloadReports}
                              style={{
                                backgroundColor: "",
                                color: "#811113",
                                width: "92px",
                                // border: "1px solid grey",
                                boxShadow:
                                  "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                              }}
                            >
                              Download
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="row pt-4">
                      {!selectedCustomer || !selectedOperator ? (
                        <div className="col-md-12 pt-4">
                          <div
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "13px",
                              fontWeight: 600,
                              lineHeight: "24px",
                              letterSpacing: "0.17000000178813934px",
                              textAlign: "center",
                            }}
                          >
                            {role === 1 ? (
                              <i class="fa fa-exclamation-circle">
                                {" "}
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "13px",
                                    fontWeight: 400,
                                    lineHeight: "24px",
                                    letterSpacing: "0.17000000178813934px",
                                    textAlign: "center",
                                  }}
                                >
                                  {" "}
                                  Operator is not Selected
                                </span>
                              </i>
                            ) : (
                              <i class="fa fa-exclamation-circle ">
                                {""}
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "13px",
                                    fontWeight: 400,
                                    lineHeight: "24px",
                                    letterSpacing: "0.17000000178813934px",
                                    textAlign: "center",
                                  }}
                                >
                                  {" "}
                                  Customer and Operator Combination not Selected
                                </span>
                              </i>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-12 pt-4">
                          <div
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "13px",
                              fontWeight: 600,
                              lineHeight: "24px",
                              letterSpacing: "0.17000000178813934px",
                              textAlign: "center",
                            }}
                          >
                            <div className="row">
                              <div>
                                <img
                                  src={SearchFolder}
                                  alt="Seach not found"
                                  style={{
                                    width: "49px",
                                    height: "49px",
                                    opacity: "0.5",
                                  }}
                                ></img>
                              </div>
                              <div
                                className="pt-2"
                                style={{
                                  fontWeight: 400,
                                }}
                              >
                                Oops! It seems like there's nothing to display
                                here yet.
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="card p-4">
            <div className="p-1 pb-3">
              <Dashboarddetails head1="KPIs" />
            </div>
            {selectedCustomer && selectedOperator && (
              <div>
                <div className="ms-1 mb-2">
                  <div className="col-lg-6">
                    <div
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#495057",
                      }}
                    >
                      Filter By
                    </div>
                  </div>
                </div>
                <div className="ms-1 mb-4">
                  <div className="col-lg-3">
                    <div>
                      <Select
                        styles={{
                          control: base => ({
                            ...base,
                            height: "40px",
                            minHeight: "40px",
                            width: "100%",
                            maxWidth: "220px",
                          }),
                          menu: base => ({
                            ...base,
                            maxWidth: "220px",
                          })
                        }}
                        value={sortedOptions.filter(
                          option => option.value === selectedTemplate
                        )}
                        options={sortedOptions}
                        isClearable
                        placeholder="Select Template"
                        components={{
                          IndicatorSeparator: () => null,
                          DropdownIndicator,
                        }}
                        onChange={e => {
                          if(e){
                            setSelectedTemplate(e.value)
                          }
                          else{
                            setSelectedTemplate("All")
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!kpiLoading && !kpiFetching ? (
              // Check if not loading or fetching
              kpiData.length > 0 ? ( // If there is KPI data
                <div className="row">
                  <div className="col-md-6">
                    <div className="card cus-box p-3 ">
                      <Deliverykpi
                        dates={kpi_dates}
                        series={deliveryChartData}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card cus-box p-3">
                      <Pricingkpi dates={kpi_dates} series={pricingChartData} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card cus-box p-3">
                      <MTR dates={kpi_dates} series={mtrChartData} />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="card cus-box p-3">
                      <Time dates={kpi_dates} series={timeChartData} />
                    </div>
                  </div>
                  <div className="card mt-3" style={{ borderRadius: "8px" }}>
                    <div
                      className="table-responsive scrollable table_shadow_div "
                      style={{ borderRadius: "8px" }}
                    >
                      <BootstrapTable
                        keyField="id"
                        data={kpiData}
                        columns={columnskpi}
                        filter={filterFactory()}
                        cellEdit={cellEditFactory({
                          mode: "dbclick",
                          blurToSave: true,
                        })}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                // Display the message if KPI data is empty
                <div className="row">
                  <div className="col-md-12">
                    <div
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "13px",
                        fontWeight: 600,
                        lineHeight: "24px",
                        letterSpacing: "0.17000000178813934px",
                        textAlign: "center",
                      }}
                    >
                      <div className="row">
                        <div>
                          <img
                            src={SearchFolder}
                            alt="Seach not found"
                            style={{
                              width: "49px",
                              height: "49px",
                              opacity: "0.5",
                            }}
                          ></img>
                        </div>
                        <div
                          className="pt-2"
                          style={{
                            fontWeight: 400,
                          }}
                        >
                          Oops! It seems like there's nothing to display here
                          yet.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            ) : (
              // Loader component or loading indicator while data is loading
              <div className="col-md-12 pt-4">
                <div
                  className="col-md-12 pt-4 d-flex justify-content-center align-items-center"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    height: "100px",
                  }}
                >
                  <CircleLoader color="#85181B" size={40} speedMultiplier={1} />
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
