import { createSlice } from '@reduxjs/toolkit'

const reportsSlice = createSlice({
    name: 'reports',
    initialState: {
    },
    reducers: {
    },
})

export const {

} = reportsSlice.actions
export default reportsSlice.reducer

