import { createSlice } from "@reduxjs/toolkit"
import { set } from "lodash"

const analyticsSlice = createSlice({
  name: "analytics",
  initialState: {
    resultsOverview: [],
    gatekeeperFakeDlr: [],
    gatekeeperBypass: [],
    senderIDcount: [],
    bypassRoutesCount: [],
  },
  reducers: {
    setResultsOverview: (state, action) => {
      state.resultsOverview = action.payload
    },
    setGatekeeperFakeDlr: (state, action) => {
      state.gatekeeperFakeDlr = action.payload
    },
    setGatekeeperBypass: (state, action) => {
      state.gatekeeperBypass = action.payload
    },
    setSenderIdCount: (state, action) => {
      state.senderIDcount = action.payload
    },
    setBypassRoutesCount: (state, action) => {
      state.bypassRoutesCount = action.payload
    }
  },
})

export const { setResultsOverview, setGatekeeperFakeDlr, setGatekeeperBypass, setSenderIdCount, setBypassRoutesCount} =
  analyticsSlice.actions

export default analyticsSlice.reducer
