import { call, put, takeEvery, all, fork, takeLatest } from "redux-saga/effects";
import * as aC from "./actions";
import * as aT from "./actionTypes";
import * as req from "./request";
import {logoutUser} from "../auth/login/actions"
import { func } from "prop-types";


export function* getUsers({payload}) {
    
    try {
        // console.log(payload);
        const {response,responseData} = yield call(req.fetchUsers,payload)
        if(responseData.status==401){
            yield put(logoutUser())
        }
        yield put(aC.setNewUsers(response.message));  
        if(response.message?.length>0){
            yield put(aC.getNewUsersSuccess(true))
        }
        else{
            yield put(aC.getNewUsersSuccess('empty'))
        }
        
    } catch (error) {
        console.log(error)
    }
}
export function* uploadUser({payload}) {
    try {
       
        const {response,responseData}  = yield call(req.uploadNewUser,payload)
        if(responseData.status==401){
            yield put(logoutUser())
        }
        if(response.message=='User created successfully'){
            yield put(aC.setuploadUserSuccess(true))
        }
        else if(responseData.status==409){
            yield put(aC.setuploadUserSuccess("failed"))
            yield put(aC.setuploadUserMessage(response.message))
        }
    } catch (error) {
        console.log(error)
    }
}
export function* deleteUser({payload}) {
    try {
       
        const {response,responseData}  = yield call(req.deleteUser,payload)
        if(responseData.status==401){
            yield put(logoutUser())
        }
        if(response.message=='User Disabled'){
            yield put(aC.setDeleteUserSuccess(true))
        }       
    } catch (error) {
        console.log(error)
    }
}
export function* activateUser({payload}) {
    try {
       
        const {response,responseData}  = yield call(req.activateUser,payload)
        if(responseData.status==401){
            yield put(logoutUser())
        }
        if(response.message=='User Activated'){
            yield put(aC.setActivateUserSuccess(true))
        }       
    } catch (error) {
        console.log(error)
    }
}
export function* removeUser({payload}) {
    try {
       
        const {response,responseData}  = yield call(req.removeUser,payload)
        if(responseData.status==401){
            yield put(logoutUser())
        }
        if(response.message=='User Deleted'){
            yield put(aC.setRemoveUserSuccess(true))
        }       
    } catch (error) {
        console.log(error)
    }
}
export function* getSelectedUser({payload}) {
    try {
       
        const {response,responseData}  = yield call(req.getSelectedUser,payload)
        if(responseData.status==401){
            yield put(logoutUser())
        }
        yield put(aC.setSelectedUser(response.message));       
    } catch (error) {
        console.log(error)
    }
}
export function* UserSaga() {
    
    yield takeLatest(aT.GET_USERS, getUsers);
    yield takeLatest(aT.UPLOAD_USER, uploadUser);
    yield takeLatest(aT.DELETE_USER, deleteUser);
    yield takeLatest(aT.ACTIVATE_USER, activateUser);
    yield takeLatest(aT.REMOVE_USER, removeUser);
    yield takeLatest(aT.GET_SELECTED_USER, getSelectedUser);

    // yield takeLatest(aT.GET_COUNTRIES, getCountries);

}

export default UserSaga;