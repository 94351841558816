import {
  GET_NEW_CUSTOMER,
  GET_NEW_CUSTOMER_SUCCESS,
  SET_NEW_CUSTOMER,
  GET_SELECTED_CUSTOMER,
  SET_SELECTED_CUSTOMER,
  UPLOAD_CUSTOMER,
  SET_UPLOAD_CUSTOMER_SUCCESS,
  EDIT_CUSTOMER,
  SET_EDIT_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER,
  SET_DELETE_CUSTOMER_SUCCESS,
  GET_COUNTRIES,
  SET_COUNTRIES,
  GET_NEWOPERATORS,
  SET_NEWOPERATORS,
  GET_NEWCOUNTRIES,
  SET_NEWCOUNTRIES,
  SET_SELECTED_CUSTOMER_SUCCESS
} from "./actionTypes"

export const getNewOperators = data => ({
  type: GET_NEWOPERATORS,
})
export const getCustomerSuccess = data => ({
  type: GET_NEW_CUSTOMER_SUCCESS,
  payload:data
})
export const setNewOperators = data => ({
  type: SET_NEWOPERATORS,
  payload:data
})
export const getCountries = data => ({
  type: GET_COUNTRIES,
  payload:data
})
export const setCountries = data => ({
  type: SET_COUNTRIES,
  payload:data
})
export const getNewCountry = data => ({
  type: GET_NEWCOUNTRIES,
  payload:data
})
export const setNewCountry = data => ({
  type: SET_NEWCOUNTRIES,
  payload:data
})
export const getNewCustomers = data => ({
    type: GET_NEW_CUSTOMER,
    payload:data
  })
export const setNewCustomers  = data =>({
  type:SET_NEW_CUSTOMER,
  payload:data
})
export const getSelectedCustomer = data => ({
  type: GET_SELECTED_CUSTOMER,
  payload:data
})
export const setSelectedCustomer  = data =>({
  type:SET_SELECTED_CUSTOMER,
  payload:data
})
export const setSelectedCustomerSuccess  = data =>({
  type:SET_SELECTED_CUSTOMER_SUCCESS,
  payload:data
})
export const uploadCustomer = data=>({
  type:UPLOAD_CUSTOMER,
  payload:data
})
export const setuploadCustomerSuccess = data =>({
  type:SET_UPLOAD_CUSTOMER_SUCCESS,
  payload:data
})
export const editCustomer = data =>({
  type:EDIT_CUSTOMER,
  payload:data
})
export const setEditCustomerSuccess= data =>({
  type:SET_EDIT_CUSTOMER_SUCCESS,
  payload:data
})
export const deleteCustomer = data =>({
  type:DELETE_CUSTOMER,
  payload:data
})
export const setdeleteCustomerSuccess = data=>({
  type:SET_DELETE_CUSTOMER_SUCCESS,
  payload:data
})