import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import dotenv from "dotenv"
import * as urls from "../../helpers/url_helper"

dotenv.config()
const url = process.env.LOCALHOST
  ? process.env.LOCALHOST
  : "https://qa_api_gtc.amerck.com"

export const templateAPI = createApi({
  reducerPath: "templateApi",
  baseQuery: fetchBaseQuery({ baseUrl: url, timeout: 10000 }),
  endpoints: builder => ({
    getTemplates: builder.query({
      query: () => ({
        url: urls._GET_TEMPLATES,
        method: "GET",
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        timeout: 4000,
      }),
    }),
  }),
})

export const { useGetTemplatesQuery } = templateAPI
