import * as url from "../../../../helpers/url_helper"


export async function dispatchUserRegister(data) {
    try {
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            // "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        }
        
        const response = await fetch(url._REGISTER, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        })
        return await response.json()
    } catch (err) {
        return console.log(err)
    }
}