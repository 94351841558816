import axios from "axios"
import {SET_KBS,GET_KBS_SUCCESS,SET_UPLOAD_KB_SUCCESS,SET_EDIT_KB_SUCCESS,
  SET_DELETE_KB_SUCCESS,SET_SELECTED_KBS,SET_SELECTED_KBS_SUCCESS} from "./actionTypes"


const INIT_STATE = {
    kb: [],
    getSuccess:false,
    selectedkb: [],
    selectedkbsuccess:false,
    uploadsuccess:false,
    editsuccess:false,
    deletesuccess:false,
   }
  
  const kb = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_KBS_SUCCESS:
        // state.getSuccess = action.payload;
        return {
          ...state,
          getSuccess : action.payload
        }
      case SET_SELECTED_KBS_SUCCESS:
        // state.selectedkbsuccess = action.payload;
        return {
          ...state,
          selectedkbsuccess : action.payload
        }
      case SET_SELECTED_KBS:
        // state.selectedkb = action.payload;
        return {
          ...state,
          selectedkb : action.payload
          // reports: [...state.reports, action.payload],
        }
      case SET_KBS:
        // state.kb = action.payload;
        return {
          ...state,
          kb : action.payload
          // reports: [...state.reports, action.payload],
        }
      case SET_UPLOAD_KB_SUCCESS:
        // state.uploadsuccess = action.payload;
        return {
          ...state,
          uploadsuccess : action.payload
          // reports: [...state.reports, action.payload],
        }
      case SET_EDIT_KB_SUCCESS:
        // state.editsuccess = action.payload;
        return {
          ...state,
          editsuccess : action.payload
          // reports: [...state.reports, action.payload],
        }
      case SET_DELETE_KB_SUCCESS:
        // state.deletesuccess = action.payload;
        return {
          ...state,
          deletesuccess : action.payload
          // reports: [...state.reports, action.payload],
        }
      default:
        return state
    }
  }
  
  export default kb