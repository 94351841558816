import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import dotenv from 'dotenv'
import * as urls from "../../helpers/url_helper"

dotenv.config()
const url = process.env.LOCALHOST?process.env.LOCALHOST:'https://qa_api_gtc.amerck.com'

export const partnerAPI = createApi({
  reducerPath: 'partnerApi',
  baseQuery: fetchBaseQuery({ baseUrl: url, timeout: 10000 }),
  endpoints: (builder) => ({
    getPartners: builder.query({
      query: () => ({
        url: urls._GET_NEW_PARTNERS,
        method: 'GET',
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        timeout: 4000,
      }),
    }),
  }),
})

export const {useGetPartnersQuery} = partnerAPI