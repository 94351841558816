import React, { useState, useMemo, useEffect } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory from "react-bootstrap-table2-filter"
import Select from "react-select"
import MetaTags from "react-meta-tags"
import Breadcrumbs from "../../components/Common/Breadcrumb"
// import { DateRangePicker, Stack } from "rsuite"

import Swal from "sweetalert2"
import "toastr/build/toastr.min.css"
import LoadingSpinner from "components/Reports/EditReports/EditTables/loader"
import { useGetGatekeeperFakeDlrMutation } from "store/analytics/analytics.api"
import { setGatekeeperFakeDlr } from "store/analytics/analytics.slice"
import { useDispatch, useSelector } from "react-redux"
import { useGetOperatorCountriesQuery } from "store/country/country.api"
import { setCountries } from "store/country/country.slice"
import {
  useLazyGetOperatorsByCountryQuery,
  useLazyGetGatekeepersQuery,
} from "store/operator/operator.api"
import { setOperators, setGatekeepers } from "store/operator/operator.slice"
import { get } from "lodash"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { DateRangePicker } from "rsuite"
import "rsuite/DateRangePicker/styles/index.css"
import { debounce } from "lodash"
import toastr from "toastr"
import SortableHeader from "components/Common/Sort"
import { components } from 'react-select';



const predefinedBottomRanges = [
  {
    label: "Last 3 Months",
    value: [new Date(new Date().setMonth(new Date().getMonth() - 3)), new Date()],
  },
  {
    label: "Last 6 Months",
    value: [new Date(new Date().setMonth(new Date().getMonth() - 6)), new Date()],
  },
  {
    label: "Last Year",
    value: [
      new Date(new Date().getFullYear() - 1, 0, 1),
      new Date(new Date().getFullYear(), 0, 0),
    ],
  },
  {
    label: "This Year",
    value: [
      new Date(new Date().getFullYear(), 0, 1),
      new Date(),
    ],
  },
  {
    label: "Q1",
    value: [
      new Date(new Date().getFullYear(), 0, 1),
      new Date(new Date().getFullYear(), 2, 31),
    ],
  },
  {
    label: "Q2",
    value: [
      new Date(new Date().getFullYear(), 3, 1),
      new Date(new Date().getFullYear(), 5, 30),
    ],
  },
  {
    label: "Q3",
    value: [
      new Date(new Date().getFullYear(), 6, 1),
      new Date(new Date().getFullYear(), 8, 30),
    ],
  },
  {
    label: "Q4",
    value: [
      new Date(new Date().getFullYear(), 9, 1),
      new Date(new Date().getFullYear(), 11, 31),
    ],
  },
  {
    label: "Last 5 Years",
    value: [
      new Date(new Date().getFullYear() - 5, 0, 1),
      new Date(),
    ],
  },
  {
    label: "All Time",
    value: [
      new Date(0), // This sets the date to the earliest possible date in JavaScript
      new Date(),
    ],
  },
];

export default function AnalyticsFakeDRL() {
  const { SearchBar } = Search
  const [selectedCountry, setCountry] = useState("")
  const [selectedOperator, setOperator] = useState("")
  const [selectedGatekeeper, setGatekeeper] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const dispatch = useDispatch()
  const [dateSetting, setDateSetting] = useState(false)


  const [sortOrder, setSortOrder] = useState("asc")

  const handleSortChange = newSortOrder => {
    setSortOrder(newSortOrder)
  }

  const { data: countriesNew, isLoading: countriesLoading } =
    useGetOperatorCountriesQuery()
  const { countries: country } = useSelector(state => state.countryNew)

  const [fetchOperators, { data: operatorsNew, isLoading: operatorsLoading, isSuccess: operatorLoadSuccess}] =
    useLazyGetOperatorsByCountryQuery()

  const [
    fetchGatekeepers,
    { data: gatekeepersNew, isLoading: gatekeepersLoading },
  ] = useLazyGetGatekeepersQuery()
  const { operators: operator, gatekeepers } = useSelector(
    state => state.operatorNew
  )

  const [filteredResults, setFilteredResults] = useState([])

  const [
    getGatekeeperFakeDlr,
    {
      data: gatekeeperFakeDlrData,
      isLoading: gatekeeperFakeDlrLoading,
      isError: gatekeeperFakeDlrError,
    },
  ] = useGetGatekeeperFakeDlrMutation()
  const { gatekeeperFakeDlr } = useSelector(state => state.analyticsSlice)

  useEffect(() => {
    if (countriesNew) {
      let data = countriesNew
      // console.log(data);
      dispatch(setCountries(data))
    }
  }, [countriesNew])

  useEffect(() => {
    if (operatorsNew && selectedCountry) {
      let data = operatorsNew
      // console.log(data);
      dispatch(setOperators(data))
    }
  }, [operatorsNew,selectedCountry])

  useEffect(() => {
    if (gatekeepersNew) {
      let data = gatekeepersNew
      dispatch(setGatekeepers(data))
    }
  }, [gatekeepersNew])

  useEffect(() => {
    if (selectedCountry) {
      // console.log(selectedCountry);
      fetchOperators({ country_id: selectedCountry })
    } else {
      setOperator("")
      dispatch(setOperators([]))
    }
  }, [selectedCountry])

  useEffect(() => {
    if (selectedOperator) {
      fetchGatekeepers({ operator_id: selectedOperator })
    } else {
      setGatekeeper("")
      dispatch(setGatekeepers([]))
    }
  }, [selectedOperator])

  useEffect(() => {
    dispatch(setGatekeeperFakeDlr([]))
    setFilteredResults([])
    if (selectedCountry && !dateSetting && selectedOperator && gatekeepersNew && gatekeepersNew.length!=0 && ((startDate && endDate) || (!startDate && !endDate))){
      getGatekeeperFakeDlr({
        country_id: selectedCountry,
        operator_id: selectedOperator,
        gatekeeper: selectedGatekeeper
          ? selectedGatekeeper
          : gatekeepersNew.map(obj => {
              return obj.label
            }),
        startDate: startDate,
        endDate: endDate,
      })
        .unwrap()
        .then(data => {
          let temp = get(data, "data", [])
          // console.log(temp);
          dispatch(setGatekeeperFakeDlr(temp))
        })
        .catch(error => {
          toastr.error(error)
        })
    }
  }, [
    selectedCountry,
    selectedOperator,
    startDate,
    endDate,
    selectedGatekeeper,
    gatekeepersNew,
    dateSetting
  ])

  const columns2 = [
    {
      dataField: "test_date",
      text: (
        <SortableHeader
          title="Test Date"
          sortOrder={sortOrder}
          onSortChange={handleSortChange}
        />
      ),
      sort: true,
    },
    { dataField: "template", text: "Template" },
    { dataField: "delivered", text: "Delivered" },
    { dataField: "fake_dlr", text: "Fake DLR" },
    { dataField: "partner", text: "Partner" },
    { dataField: "smsc", text: "SMSC" },
    { dataField: "smsc_owner", text: "SMSC Owner" },
    { dataField: "gate", text: "Gate" },
    { dataField: "price", text: "Price / MTR" },
  ]


  const handleDateChange = debounce(value => {
    if (value) {
      if (value.length > 0) {
        setDateSetting(true)
        let start = new Date(value[0])
        let end = new Date(value[1])
        if (!isNaN(start.getTime()) && !isNaN(end.getTime())) {
          setStartDate("")
          setEndDate("")
          let Startdate = start.toISOString().split("T")[0]
          let Enddate = end.toISOString().split("T")[0]
          setStartDate(Startdate)
          setEndDate(Enddate)
        } else {
          setStartDate("")
          setEndDate("")
        }
        setDateSetting(false)
      } else {
        setStartDate("")
        setEndDate("")
      }
    } else {
      setStartDate("")
      setEndDate("")
    }
  }, 300)

  useEffect(() => {
    if (gatekeeperFakeDlr) {
      // console.log(gatekeeperFakeDlr)
      setFilteredResults(gatekeeperFakeDlr)
    }
  }, [gatekeeperFakeDlr])

  const CustomClearIndicator = (props) => {
    return (
      <div onClick={props.clearValue} style={{ cursor: 'pointer', padding: '0 5px' }}>
        <svg width="14px" height="14px" style={{marginRight:10}} color="black" viewBox="0 0 16 16" fill="currentColor" aria-hidden="true" focusable="false" class="rs-icon" aria-label="close" data-category="application">
          <style>
            {`
              .rs-icon:hover {
                fill: red;
                border-width: '5px';
              }
            `}
          </style>
          <path d="M2.784 2.089l.069.058 5.146 5.147 5.146-5.147a.5.5 0 01.765.638l-.058.069L8.705 8l5.147 5.146a.5.5 0 01-.638.765l-.069-.058-5.146-5.147-5.146 5.147a.5.5 0 01-.765-.638l.058-.069L7.293 8 2.146 2.854a.5.5 0 01.638-.765z"></path>
        </svg>
      </div>
    );
  };

  const DropdownIndicator = props => {
    return (
      // props.selectProps.value ? null : (
        <components.DropdownIndicator {...props}>
          <svg height="20" fill="#808080" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class=""><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
        </components.DropdownIndicator>
      // )
    );
  };
  // console.log(selectedCountry);
  return (
    <div className="page-content">
      <MetaTags>
        <title>Gatekeeper Fake DLR</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumbs title="Analytics" breadcrumbItem="Gatekeeper Fake DLR" />
        <Card>
          <CardBody className="custom-cardbody">
            <Row
              className="pb-4"
              style={{
                zIndex: "2",
                position: "relative",
                display: "flex",
                alignItems: "baseline",
              }}
            >
              <Col md="4" className="d-flex" style={{ alignItems: "center" }}>
                <div className="search-box me-2 mb-2 pt-2 d-inline-block w-sm-100">
                  <div className="position-relative">
                    {/* <SearchBar
                      style={{ height: "35px" }}
                      value={searchText}
                      onChange={value => setSearchText(value)}
                    />
                    <i className="bx bx-search-alt search-icon sm-left-30" /> */}
                  </div>
                </div>
              </Col>
              <Col md="2">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable={true}
                  isClearable
                  value={country.filter(
                    option => option.value === selectedCountry
                  )}
                  name="country"
                  // options={[
                  //   { value: "10", label: "Last 10 Tests" },
                  //   { value: "20", label: "Last 20 Tests" },
                  //   { value: "50", label: "Last 50 Tests" },
                  // ]}
                  options={[
                    { value: "", label: "No Country Selected" },
                    ...country,
                  ]}
                  onChange={e => {
                    if (e) {
                      if (e.value) {
                        setOperator("")
                        dispatch(setOperators([]))
                        setCountry(e.value)
                      }
                      if (e.value === "") {
                        setCountry("")
                      }
                    } else {
                      setCountry("")
                    }
                  }}
                  placeholder="Country"
                  components={{ 
                    ClearIndicator: CustomClearIndicator,
                    DropdownIndicator: !selectedCountry? DropdownIndicator : null
                  }}
                />
              </Col>
              <Col md="2">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable={true}
                  isClearable
                  name="operator"
                  value={operator.filter(
                    option => option.id === selectedOperator
                  )}
                  isDisabled={selectedCountry === "" ? true : false}
                  // options={[
                  //   { value: "1", label: "Template 1" },
                  //   { value: "2", label: "Template 2" },
                  //   { value: "3", label: "Template 3" },
                  // ]}
                  options={[
                    { value: "", label: "No Operator Selected" },
                    ...operator,
                  ]}
                  onChange={e => {
                    if (e) {
                      if (e.id) {
                        setOperator(e.id)
                      }
                      if (e.value === "") {
                        setOperator("")
                      }
                    } else {
                      setOperator("")
                    }
                  }}
                  components={{ 
                    ClearIndicator: CustomClearIndicator,
                    DropdownIndicator: !selectedOperator? DropdownIndicator : null
                  }}
                  placeholder="Operator"
                />
              </Col>
              <Col md="2" className="">
                <div style={{ width: "100%" }}>
                  <DateRangePicker
                    ranges={predefinedBottomRanges}
                    className="basic-single "
                    placeholder="Select Date Range"
                    style={{ height: "38px", width: "100%" }}
                    placement="autoVertical"
                    onChange={handleDateChange}
                  />
                </div>
              </Col>
              <Col md="2">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable={true}
                  isDisabled={selectedOperator === "" ? true : false}
                  isClearable
                  value={gatekeepers.filter(
                    option => option.label === selectedGatekeeper
                  )}
                  name="gatekeeper"
                  placeholder="Gatekeeper"
                  options={[
                    // { value: "", label: "No Gatekeeper Selected" },
                    ...gatekeepers,
                  ]}
                  onChange={e => {
                    if (e) {
                      if (e.label) {
                        setGatekeeper(e.label)
                      }
                      if (e.label === "") {
                        setGatekeeper("")
                      }
                    } else {
                      setGatekeeper("")
                    }
                  }}
                  components={{ 
                    ClearIndicator: CustomClearIndicator,
                    DropdownIndicator: !selectedGatekeeper? DropdownIndicator : null
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="table-responsive scrollable table_shadow_div reportdata_table2">
                  <BootstrapTable
                    keyField="id"
                    data={filteredResults}
                    columns={columns2}
                    bootstrap4={true}
                    filter={filterFactory()}
                    noDataIndication={() => {
                      if (gatekeeperFakeDlrLoading === true) {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <LoadingSpinner />
                          </div>
                        )
                      } else {
                        return <div>No Data Found</div>
                      }
                    }}
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
