import {
  GET_NEW_OPERATOR,
  GET_NEW_OPERATOR_SUCCESS,
  SET_NEW_OPERATOR,
  GET_SELECTED_OPERATOR,
  SET_SELECTED_OPERATOR,
  SET_SELECTED_OPERATOR_SUCCESS,
  UPLOAD_OPERATOR,
  SET_UPLOAD_OPERATOR_SUCCESS,
  EDIT_OPERATOR,
  SET_EDIT_OPERATOR_SUCCESS,
  DELETE_OPERATOR,
  SET_DELETE_OPERATOR_SUCCESS,
  GET_NEWCOUNTRIES,
  SET_NEWCOUNTRIES,
  GET_COUNTRIES,
  SET_COUNTRIES
} from "./actionTypes"

export const getCountries = data => ({
  type: GET_COUNTRIES,
  payload:data
})
export const setCountries = data => ({
  type: SET_COUNTRIES,
  payload:data
})
export const getNewCountry = data => ({
  type: GET_NEWCOUNTRIES,
  payload:data
})
export const setNewCountry = data => ({
  type: SET_NEWCOUNTRIES,
  payload:data
})
export const getNewOperators = data => ({
    type: GET_NEW_OPERATOR,
    payload:data
  })
export const getNewOperatorSuccess = data => ({
  type: GET_NEW_OPERATOR_SUCCESS,
  payload:data
})
export const setNewOperators  = data =>({
  type:SET_NEW_OPERATOR,
  payload:data
})
export const getSelectedOperator = data => ({
  type: GET_SELECTED_OPERATOR,
  payload:data
})
export const setSelectedOperator  = data =>({
  type:SET_SELECTED_OPERATOR,
  payload:data
})
export const setSelectedOperatorSuccess = data =>({
  type:SET_SELECTED_OPERATOR_SUCCESS,
  payload:data
})
export const uploadOperator = data=>({
  type:UPLOAD_OPERATOR,
  payload:data
})
export const setuploadOperatorSuccess = data =>({
  type:SET_UPLOAD_OPERATOR_SUCCESS,
  payload:data
})
export const editOperator = data =>({
  type:EDIT_OPERATOR,
  payload:data
})
export const setEditOperatorSuccess= data =>({
  type:SET_EDIT_OPERATOR_SUCCESS,
  payload:data
})
export const deleteOperator = data =>({
  type:DELETE_OPERATOR,
  payload:data
})
export const setdeleteOperatorSuccess = data=>({
  type:SET_DELETE_OPERATOR_SUCCESS,
  payload:data
})