import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Button, Table, Input,  Modal, ModalFooter,  Container } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

const AddCountry = (props) => {

const setCountryName = props.set_country_name;
const setCountryCode = props.set_country_code;
const add_CountryCode = props.add_CountryCode;
const setMobileCountryCode = props.set_mobile_country_code;
const mobileCountry = props.add_MobileCountryCode;
const setISO3 = props.set_iso3;
const iso3Value = props.add_ISO3;
const setSubscriber = props.set_subscribe;

const dispatch = useDispatch(); 

return (
    <React.Fragment>
        <form>
                      <div className="customer-field-container" >
                        <Row>
                          <Col md="4">
                          <label className="lable-customer">Country Name</label>
                          </Col>
                          <Col md="8">
                          <Input
                            type="name"
                            className="input-operator"
                            placeholder="Enter the Name"
                            onChange={(e)=>{
                              setCountryName(e.target.value)
                          }}
                          />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4">
                          <label className="lable-customer">Mobile Country Code</label>
                          </Col>
                          <Col md="8">
                          {/* <Input
                            type="name"
                            className="input-operator"
                            placeholder="Enter the Code"
                            onChange={(e)=>{
                              // setCountryCode(e.target.value);
                              setMobileCountryCode(e.target.value);
                            }}
                          /> */}
                          <Input
                          type="name"
                          className="input-operator"
                          value={mobileCountry}
                          placeholder="Enter the Code"
                          onChange={(e) => {
                            let inputValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 3);
                            if (inputValue.length > 0) {
                              inputValue = `+${inputValue}`;
                            }
                            // console.log(inputValue);
                            setMobileCountryCode(inputValue);
                          }}
                        />

                          </Col>
                        </Row>
                        <Row>
                          <Col md="4">
                          <label className="lable-customer">Country Code</label>
                          </Col>
                          <Col md="8">
                          <Input
                            type="name"
                            className="input-operator"
                            placeholder="Enter the Code"
                            value={add_CountryCode}
                            onChange={(e)=>{
                              let inputValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 3);
                              if (inputValue.length > 0) {
                                inputValue = `+${inputValue}`;
                              }
                              setCountryCode(inputValue);
                              // setMobileCountryCode(e.target.value);
                            }}
                          />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4">
                          <label className="lable-customer">ISO 3 Code</label>
                          </Col>
                          <Col md="8">
                          {/* <Input
                            type="name"
                            className="input-operator"
                            placeholder="Enter the Code"
                            onChange={(e)=>{
                              setISO3(e.target.value);
                            }}
                          /> */}
                          <Input
                            type="name"
                            className="input-operator"
                            value = {iso3Value}
                            placeholder="Enter the Code"
                            onChange={(e) => {
                              let inputValue = e.target.value.slice(0, 3).toUpperCase();
                              inputValue = inputValue.replace(/[^A-Z]/g, '');
                              setISO3(inputValue);
                            }}
                          />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4">
                          <label className="lable-customer">Country Subscriber</label>
                          </Col>
                          <Col md="8">
                          <Input
                            type="number"
                            className="input-operator"
                            placeholder="Enter the Subscriber"
                            onChange={(e)=>{
                              setSubscriber(e.target.value);
                            }}
                          />
                          </Col>
                        </Row>
                        
                      </div>
                     
                    

                      
                    </form>
    </React.Fragment>
  )
}

export default AddCountry
