import React from 'react';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa'; // Import sort icons

const SortableHeader = ({ title, sortOrder, onSortChange }) => {
  const handleSortChange = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    onSortChange(newSortOrder);
  };

  const sortIcon = 
    sortOrder === 'asc' ? <FaSortUp /> : 
    sortOrder === 'desc' ? <FaSortDown style={{ marginBottom: "5px" }} /> : 
    <FaSort />;

  return (
    <div 
      className="sortable-header" 
      onClick={handleSortChange} 
      style={{ 
        display: 'flex', 
        justifyContent:"space-between",
        alignItems: 'center', 
        cursor: 'pointer' 
      }}
    >
      <span style={{ flexGrow: 1, textAlign: 'center' }}>{title}</span>
      {sortIcon}
    </div>
  );
};

export default SortableHeader;