export const UPLOAD_REPORT = "UPLOAD_REPORT"
export const UPLOAD_REPORT_SUCCESS = "UPLOAD_REPORT_SUCCESS"
export const SET_REPORT_DATA = "SET_REPORT_DATA"
export const GET_RECENTLY_UPLOADED_REPORTS = "GET_RECENTLY_UPLOADED_REPORTS"
export const SET_RECENTLY_UPLOADED_REPORTS = "SET_RECENTLY_UPLOADED_REPORTS"
export const GET_CUSTOMERS = "GET_CUSTOMERS"
export const SET_CUSTOMERS = "SET_CUSTOMERS"
export const GET_PARTNERS = "GET_PARTNERS"
export const SET_PARTNERS = "SET_PARTNERS"
export const GET_OPERATORS = "GET_OPERATORS"
export const SET_OPERATORS = "SET_OPERATORS"
export const GET_COUNTRIES = "GET_COUNTRIES"
export const SET_COUNTRIES = "SET_COUNTRIES"
export const GET_TEMPLATES = "GET_TEMPLATES"
export const SET_TEMPLATES = "SET_TEMPLATES"
export const DELETE_REPORT = "DELETE_REPORT"
export const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS"
export const UPDATE_HISTORY_REPORTS = "UPDATE__HISTORY_REPORTS"
export const UPDATE_HISTORY_REPORTS_SUCCESS = "UPDATE_HISTORY_REPORTS_SUCCESS"
export const REMOVE_PAIR = "REMOVE_PAIR"


