import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import dotenv from "dotenv"
import * as urls from "../../helpers/url_helper"

dotenv.config()
const url = process.env.LOCALHOST
  ? process.env.LOCALHOST
  : "https://qa_api_gtc.amerck.com"

export const customerPortalApi = createApi({
  reducerPath: "customerPortalApi",
  baseQuery: fetchBaseQuery({ baseUrl: url, timeout: 10000 }),
  endpoints: builder => ({
    getCustomers: builder.query({
      query: () => ({
        url: urls.PORTAL_GET_CUSTOMERS,
        method: "GET",
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        timeout: 4000,
      }),
    }),
    getOperators: builder.query({
      query: (customer_id) => ({
        url: urls.PORTAL_GET_OPERATORS,
        method: "GET",
        params: customer_id,
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        timeout: 4000,
      }),
    }),
    getLatestReport: builder.query({
      query: ({customer_id,operator_id}) => ({
        url: urls.PORTAL_GET_LATEST_REPORT,
        method: "GET",
        params: {customer_id,operator_id},
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        timeout: 4000,
      }),
    }),
    getKPIData: builder.query({
      query: ({customer_id,operator_id,template}) => ({
        url: urls.PORTAL_GET_KPI_DATA,
        method: "GET",
        params: {customer_id,operator_id,template},
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        timeout: 4000,
      }),
    }), 
  }),
})

export const { 
  useGetCustomersQuery, 
  useGetOperatorsQuery,
  useLazyGetLatestReportQuery,
  useLazyGetKPIDataQuery,
 } = customerPortalApi
