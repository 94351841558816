import React, { useState, useEffect } from "react"
import {
  Modal,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Tooltip,
  Spinner,
} from "reactstrap"
import { useChangePasswordMutation } from "store/user/user.api"
import toastr from "toastr"
import Swal from "sweetalert2"

const ChangePasswordUserModal = ({ isOpen, onClose, userData }) => {
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [tooltipOpenToggle, setTooltipOpenToggle] = useState(false)
  const [tooltipOpenCopy, setTooltipOpenCopy] = useState(false)
  const [user_id, setUserId] = useState(userData)
  const [changePassword, { data, isLoading: changePasswordLoading }] =
    useChangePasswordMutation()

  useEffect(() => {
    if (userData) {
      setConfirmPassword("")
      setNewPassword("")
      setUserId(userData)
    }
  }, [userData])

  const handleSaveChanges = () => {
    if (
      newPassword === confirmPassword &&
      newPassword !== "" &&
      confirmPassword !== ""
    ) {
      changePassword({
        user: {
          id: user_id,
          password: newPassword,
        },
      })
        .unwrap()
        .then(msg => {
          Swal.fire({
            title: "Success!",
            text: msg.message,
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#811113",
          })
          setConfirmPassword("")
          setNewPassword("")
          onClose()
        })
        .catch(err => {
          Swal.fire({
            title: "Error!",
            text: err.data.message,
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#811113",
          })
        })
    } else {
      toastr.error("Password does not match or password is empty!")
    }
  }

  const clearFormFields = () => {
    setNewPassword("")
    setConfirmPassword("")
  }


  const closeModal = () => {
    onClose()
    setTimeout(() => {
      clearFormFields()
    }, 500)
  }

  const handleToggleNewPassword = () => {
    setShowNewPassword(!showNewPassword)
  }

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(confirmPassword)
  }

  const toggleTooltipToggle = () => setTooltipOpenToggle(!tooltipOpenToggle)
  const toggleTooltipCopy = () => setTooltipOpenCopy(!tooltipOpenCopy)

  return (
    <Modal isOpen={isOpen} toggle={closeModal} size="md" centered>
      <div
        className="modal-header d-flex"
        style={{
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <span
            className="modal-title ps-4 "
            style={{ fontSize: "16px", fontWeight: "500" }}
          >
            CHANGE PASSWORD
          </span>
        </div>
        <div>
          <button type="button" className="close" onClick={closeModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div className="modal-body">
        <div className="mb-3 ps-4 pe-4 p-2">
          <label htmlFor="newPassword">New Password</label>
          <InputGroup>
            <Input
              type={showNewPassword ? "text" : "password"}
              id="newPassword"
              placeholder="Enter New Password"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              style={{ borderRight: "none" }}
            />
            <InputGroupAddon addonType="append">
              <Tooltip
                isOpen={tooltipOpenToggle}
                target="toggle"
                toggle={toggleTooltipToggle}
                style={{ color: "black", backgroundColor: "white" }}
              >
                Toggle Password Visibility
              </Tooltip>
              <Tooltip
                isOpen={tooltipOpenCopy}
                target="copy"
                toggle={toggleTooltipCopy}
                style={{ color: "black", backgroundColor: "white" }}
              >
                Copy to Clipboard
              </Tooltip>
              <Button
                id="toggle"
                color="secondary"
                style={{
                  background: "none",
                  borderLeft: "none",
                  borderTopLeftRadius: "0",
                  borderBottomLeftRadius: "0",
                  borderColor: "#CED4DA",
                }}
                onClick={handleToggleNewPassword}
              >
                <i
                  className={`fas ${
                    showNewPassword ? "fa-eye" : "fa-eye-slash"
                  }`}
                  style={{ color: "grey" }}
                ></i>
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <div className="mb-3 ps-4 pe-4 p-2">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <InputGroup>
            <Input
              type={showConfirmPassword ? "text" : "password"}
              id="confirmPassword"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              style={{ borderRight: "none" }}
              autocomplete="new-password"
            />
            <InputGroupAddon addonType="append">
              <Button
                id="toggle"
                color="secondary"
                style={{
                  background: "none",
                  borderLeft: "none",
                  borderTopLeftRadius: "0",
                  borderBottomLeftRadius: "0",
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "0",
                  borderRight: "none",
                  borderColor: "#CED4DA",
                }}
                onClick={handleToggleConfirmPassword}
              >
                <i
                  className={`fas ${
                    showConfirmPassword ? "fa-eye" : "fa-eye-slash"
                  }`}
                  style={{ color: "grey" }}
                ></i>
              </Button>
              <Button
                id="copy"
                color="secondary"
                style={{
                  background: "none",
                  borderLeft: "none",
                  borderTopLeftRadius: "0",
                  borderBottomLeftRadius: "0",
                  borderColor: "#CED4DA",
                }}
                onClick={handleCopyToClipboard}
              >
                <i className="fas fa-copy" style={{ color: "grey" }}></i>
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
      </div>
      <div className="modal-footer ">
        <Button
          style={{
            backgroundColor: "#811113",
            border: "none",
            height: "42px",
            marginRight: "20px",
            width: "100px",
          }}
          onClick={handleSaveChanges}
          disabled={changePasswordLoading} //Disable the button while the mutation is in progress
        >
          {changePasswordLoading ? (
            <Spinner size="sm" color="light" /> // Show spinner if loading
          ) : (
            "Save"
          )}
        </Button>
        <Button
          style={{
            backgroundColor: "#E8E8E9",
            border: "none",
            height: "42px",
            width: "100px",
          }}
          onClick={closeModal}
        >
          <span style={{ color: "#2D3648" }}>Cancel</span>
        </Button>
      </div>
    </Modal>
  )
}

export default ChangePasswordUserModal
