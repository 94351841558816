import * as aT from "./actionTypes"

export const getTemplates = data => ({
    type: aT.GET_NEW_TEMPLATES,
    payload:data
  })
export const getTemplatesSuccess = data => ({
  type: aT.GET_NEW_TEMPLATES_SUCCESS,
  payload:data
})
export const setTemplates  = data =>({
  type:aT.SET_NEW_TEMPLATES,
  payload:data
})
export const getSelectedTemplates = data => ({
  type: aT.GET_SELECTED_TEMPLATES,
  payload:data
})
export const setSelectedTemplates  = data =>({
  type:aT.SET_SELECTED_TEMPLATES,
  payload:data
})
export const setSelectedTemplateSuccess = data =>({
  type:aT.SET_SELECTED_TEMPLATE_SUCCESS,
  payload:data
})
export const uploadTemplates = data=>({
  type:aT.UPLOAD_TEMPLATE,
  payload:data
})
export const setUploadTemplateSuccess = data =>({
  type:aT.SET_UPLOAD_TEMPLATE_SUCCESS,
  payload:data
})
export const editTemplate = data =>({
  type:aT.EDIT_TEMPLATE,
  payload:data
})
export const setEditTemplateSuccess = data =>({
  type:aT.SET_EDIT_TEMPLATE_SUCCESS,
  payload:data
})
export const deleteTemplate = data =>({
  type:aT.DELETE_TEMPLATE,
  payload:data
})
export const setDeleteTemplateSuccess = data=>({
  type:aT.SET_DELETE_TEMPLATE_SUCCESS,
  payload:data
})