import * as aT from "./actionTypes";

export const getNewPartners  = data =>({
  type:aT.GET_NEW_PARTNERS,
  payload:data
})
export const getNewPartnersSuccess  = data =>({
  type:aT.GET_NEW_PARTNERS_SUCCESS,
  payload:data
})
export const setNewPartners  = data =>({
  type:aT.SET_NEW_PARTNERS,
  payload:data
})
export const getNewCountry = data => ({
  type: aT.GET_NEW_PAR_COUNTRIES,
  payload:data
})
export const setNewCountry = data => ({
  type: aT.SET_NEW_PAR_COUNTRIES,
  payload:data
})
export const getSelectedPartner = data => ({
  type: aT.GET_SELECTED_PARTNER,
  payload:data
})
export const setSelectedPartner  = data =>({
  type:aT.SET_SELECTED_PARTNER,
  payload:data
})
export const setSelectedPartnerSuccess  = data =>({
  type:aT.SET_SELECTED_PARTNER_SUCCESS,
  payload:data
})
export const uploadPartner = data=>({
  type:aT.UPLOAD_PARTNER,
  payload:data
})
export const setuploadPartnerSuccess = data =>({
  type:aT.SET_UPLOAD_PARTNER_SUCCESS,
  payload:data
})
export const editPartner = data=>({
  type:aT.EDIT_PARTNER,
  payload:data
})
export const setEditPartnerSuccess = data =>({
  type:aT.SET_EDIT_PARTNER_SUCCESS,
  payload:data
})
export const deletePartner = data=>({
  type:aT.DELETE_PARTNER,
  payload:data
})
export const setDeletePartnerSuccess = data =>({
  type:aT.SET_DELETE_PARTNER_SUCCESS,
  payload:data
})