import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  Input,
  Button,
  Table,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
//Import Breadcrumb
import Breadcrumbs from "../Common/Breadcrumb"
import toastr from "toastr"
import Swal from "sweetalert2"
import "toastr/build/toastr.min.css"
import store from "../../store/index"
import "react-phone-number-input/style.css"
import LoadingSpinner from "components/Reports/EditReports/EditTables/loader"
import jwt from "jsonwebtoken"
import {
  useCreateOtherChannelMutation,
  useDeleteOtherChannelMutation,
  useEditOtherChannelMutation,
  useGetOtherChannelsQuery,
  useLazyGetSelectedOtherChannelQuery,
} from "store/otherChannels/otherChannels.api"
import ReactPaginate from "react-paginate"
import {
  setOtherChannels,
  setSelectedChannel,
} from "store/otherChannels/otherChannels.slice"
import { get } from "lodash"

const OtherChannel = () => {
  const decodeToken = token => {
    try {
      const decodedToken = jwt.decode(token)
      return decodedToken
    } catch (error) {
      console.log("Error decoding token:", error)
      return null
    }
  }
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
    didOpen: toast => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  const dispatch = useDispatch()
  const [modal_add_screen, setmodal_add_screen] = useState(false)
  const [modal_edit_screen, setmodal_edit_screen] = useState(false)
  const [otherChannelName, setOtherChannelName] = useState("")
  const [editOtherChannelData, setEditOtherChannel] = useState("")

  const [creatOtherChannel, { isLoading: createLoading }] =
    useCreateOtherChannelMutation()
  const [deleteOtherChannel, { isLoading: deleteLoading }] =
    useDeleteOtherChannelMutation()
  const [editOtherChannel, { isLoading: editLoading }] =
    useEditOtherChannelMutation()
  const {
    data: otherChannels,
    isSuccess: getSuccess,
    isFetching: getChannelsFetching,
    refetch,
  } = useGetOtherChannelsQuery()
  const [
    getSelectedChannel,
    { data: selectedChannel_data, isFetching: selectedChannelFetching },
  ] = useLazyGetSelectedOtherChannelQuery()

  let {other_channels, selectedChannel} = useSelector(state => state.otherChannels)
  const [currentPage, setCurrentPage] = useState(0)
  const itemsPerPage = 10
  const offset = currentPage * itemsPerPage
  const [filteredChannels, setFilteredChannels] = useState(other_channels)
  const currentPageItems = filteredChannels?.slice(
    offset,
    offset + itemsPerPage
  )
  const [searchTerm, setSearchTerm] = useState("")
  const [searchText, setSearchText] = useState("")

  const token = localStorage.getItem("authUser")
  const decodedToken = decodeToken(JSON.parse(token))
  let role = decodedToken.role

  const handleEdit = async id => {
    if (id) {
      await getSelectedChannel({ id })
        .then(res => {
          dispatch(setSelectedChannel(res.data.otherChannel))
          setEditOtherChannel(res.data.otherChannel)
          setmodal_edit_screen(true)
        })
        .catch(err => {
          Toast.fire({
            title: "Error",
            text: "Error Fetching Channel",
            icon: "error",
            showCancelButton: false,
            timer: 1500,
          })
        })
    }
  }

  const creatChannel = async () => {
    if (otherChannelName) {
      await creatOtherChannel({ body: { name: otherChannelName } })
        .unwrap()
        .then(res => {
          Toast.fire({
            icon: "success",
            title: "Saved Successfully!",
          })
          setOtherChannelName("")
          tog_add_screen()
          refetch()
        })
        .catch(err => {
          Toast.fire({
            title: "Error",
            text: `Error Creating Channel : ${err.data.error}`,
            icon: "error",
            showCancelButton: false,
            timer: 1500,
          })
          tog_add_screen()
        })
    }
  }

  const saveEdits = async () => {
    if (editOtherChannelData) {
      await editOtherChannel({ body: editOtherChannelData })
        .unwrap()
        .then(res => {
          Toast.fire({
            icon: "success",
            title: "Edits Saved Successfully!",
          })
          tog_edit_screen()
          refetch()
        })
        .catch(err => {
          Toast.fire({
            icon: "error",
            title: "Error",
          })
          tog_edit_screen()
        })
    }
  }

  const deleteChannel = async id => {
    if (id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#811113",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          await deleteOtherChannel({ id })
            .unwrap()
            .then(res => {
              Toast.fire({
                icon: "success",
                title: "Deleted Successfully!",
              })
              refetch()
              setCurrentPage(0)
            })
            .catch(err => {
              Toast.fire({
                icon: "error",
                title:
                  "Deletion Failed! Please check for Batches that are associated with the Template.",
                timer: 3000,
              })
            })
        } else if (result.isDenied) {
          Swal.fire("Delete Cancelled", "", "info")
        }
      })
    }
  }

  useEffect(() => {
    // console.log(searchText);
    if (searchText === "") {
      setFilteredChannels(other_channels)
      setCurrentPage(0)
    } else {
      let temp = other_channels.filter(channel => {
        return channel.name.toLowerCase().includes(searchText.toLowerCase())
      })
      setFilteredChannels(temp)
      setCurrentPage(0)
    }
  }, [searchText])

  const columns = [
    {
      dataField: "id",
      text: "Template ID",
      sort: true,
    },
    {
      dataField: "name",
      text: "Channel Name",
      sort: true,
    },
    {
      text: "Action",
      //field: 'operate',
      title: "View",
      //align: 'center',
      //valign: 'middle',
      clickToSelect: false,
      formatter: function (value, row, index) {
        //return '<input name="elementname"  value="'+value+'"/>';
        return (
          <button
            className={`view-btn-tbl ${role === 4 ? "disabled" : ""}`}
            onClick={async () => {
              // console.log(row.id);
              handleEdit(row.id)
            }}
            disabled={role === 4}
          >
            Edit
          </button>
        )
      },
    },
    {
      text: "Action",
      //field: 'operate',
      title: "View",
      //align: 'center',
      //valign: 'middle',
      clickToSelect: false,
      formatter: function (value, row, index) {
        //return '<input name="elementname"  value="'+value+'"/>';
        return (
          <button
            className={`view-btn-tbl ${role === 4 ? "disabled" : ""}`}
            onClick={async () => {
              deleteChannel(row.id)
            }}
            disabled={role === 4}
          >
            Delete
          </button>
        )
      },
    },
  ]

  useEffect(() => {
    if (getSuccess) {
      let temp = get(otherChannels, "otherChannels", [])
      dispatch(setOtherChannels(temp))
      setFilteredChannels(temp)
    }
  }, [otherChannels])

  function tog_add_screen() {
    setmodal_add_screen(!modal_add_screen)
    setOtherChannelName("")
    removeBodyCss()
  }
  function tog_edit_screen() {
    setmodal_edit_screen(!modal_edit_screen)
    removeBodyCss()
    setEditOtherChannel({})
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  // Table Data
  const productData = [
    {
      id: 1,
      label: "+94115000001",
      value: "+94115077701",
    },
    {
      id: 2,
      label: "+94115000001",
      value: "+94115077701",
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: other_channels.length, // replace later with size(customers),
    custom: true,
    onSizePerPageChange: size => {
      setSizePerPage(size)
    },
  }
  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div>
        {" "}
        {/*className="page-content" */}
        <MetaTags>
          <title>Other Channel</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Settings" breadcrumbItem="Other Channel" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                <Row className="mb-2">
                          <Col md="8" sm="12">
                            <div className="search-box me-2 mb-2 d-inline-block w-sm-100">
                              <Row>
                                <Col>
                                  <div
                                    className="me-3"
                                    style={{
                                      border: "1px solid #D9D9D9",
                                      borderRadius: "50px",
                                      marginBottom: "10px",
                                      minWidth: "210px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i
                                      class="bx bx-search-alt search-icon sm-left-30 ps-2 pe-2 "
                                      style={{
                                        width: "10px",
                                        height: "10px",
                                      }}
                                    ></i>
                                    <input
                                      type="text"
                                      placeholder="Search"
                                      style={{
                                        width: "150px",
                                        height: "31px",
                                        border: "none",
                                        borderRadius: "10px",
                                        textAlign: "start",
                                        borderTopLeftRadius: "20px",
                                        borderBottomLeftRadius: "20px",
                                        marginTop: "2px",
                                        marginLeft: "25px",
                                      }}
                                      value={searchTerm}
                                      onChange={e => {
                                        setSearchTerm(e.target.value)
                                        setSearchText(e.target.value)
                                      }}
                                      onKeyDown={e => {
                                        if (e.key === "Enter") {
                                          // setPage(1)
                                        }
                                      }}
                                    />
                                    {searchTerm !== "" && (
                                      // <i className="bx bx-search-alt search-icon sm-left-30" />
                                      <i
                                        className="fas fa-times me-2 ms-2"
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          // setPage(1)
                                          setSearchTerm("")
                                          setSearchText("")
                                        }}
                                      ></i>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>

                          <Col md="4" sm="12" className="position-relative ">
                            <div className="d-flex ">
                              <Button
                                color="danger"
                                className="btn btn-custom md-position-absolute ms-auto p-2 bd-highlight"
                                onClick={() => {
                                  tog_add_screen()
                                }}
                                disabled={role === 4}
                              >
                                Add Channel
                              </Button>
                            </div>
                          </Col>
                </Row>
                  <div className="col-12 ms-3">
                    {filteredChannels?.length == 0 ? (
                      getSuccess == false ? (
                        <LoadingSpinner />
                      ) : (
                        <div
                          style={{
                            textAlign: "center",
                            paddingTop: "50px",
                          }}
                        >
                          No Data Found
                        </div>
                      )
                    ) : (
                      <div>

                        <Row style={{ marginRight: "15px" }}>
                          <Table className="table-responsive align-middle mb-0 report_table_edit">
                            <thead>
                              <tr>
                                <th>Template ID</th>
                                <th>Channel Name</th>
                                <th>Edit</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentPageItems?.map(file => {
                                return (
                                  <tr key={file.id}>
                                    <td>{file.id}</td>
                                    <td> {file.name} </td>
                                    <td>
                                      <button
                                        className={`view-btn-tbl ${
                                          role === 4 ? "disabled" : ""
                                        }`}
                                        onClick={async () => {
                                          // console.log(row.id);
                                          handleEdit(file.id)
                                        }}
                                        disabled={role === 4 || deleteLoading}
                                      >
                                        Edit
                                      </button>
                                    </td>
                                    <td>
                                      <button
                                        className={`view-btn-tbl ${
                                          role === 4 ? "disabled" : ""
                                        }`}
                                        onClick={async () => {
                                          deleteChannel(file.id)
                                        }}
                                        disabled={role === 4 || deleteLoading}
                                      >
                                        Delete
                                      </button>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </Row>
                      </div>
                    )}
                  </div>
                  <div className="col-12">
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      forcePage={currentPage}
                      breakLabel={"..."}
                      pageCount={Math.ceil(
                        filteredChannels.length / itemsPerPage
                      )}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={data => {
                        setCurrentPage(data.selected)
                      }}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={other_channels}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={other_channels}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="8" sm="12">
                                <div className="search-box me-2 mb-2 d-inline-block w-sm-100">
                                  <Row>
                                    <Col>
                                      {" "}
                                      <h6>Other Channel</h6>
                                    </Col>
                                    <Col>
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon sm-left-30" />
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col
                                md="4"
                                sm="12"
                                className="position-relative "
                              >
                                <div className="d-flex ">
                                  <Button
                                    color="danger"
                                    className="btn btn-custom md-position-absolute ms-auto p-2 bd-highlight"
                                    onClick={() => {
                                      tog_add_screen()
                                    }}
                                    disabled={role === 4}
                                  >
                                    Add Channel
                                  </Button>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    noDataIndication={() => {
                                      if (getSuccess == false) {
                                        return <LoadingSpinner />
                                      } else {
                                        return <div>No Data Found</div>
                                      }
                                    }}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
  
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
 
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>


                </CardBody>
              </Card>
            </Col> */}

            <Modal
              size="md"
              isOpen={modal_add_screen}
              toggle={() => {
                // saveKBData()
                tog_add_screen()
              }}
              className="modal-popscreen"
            >
              <div className="modal-header">
                <h5
                  className="modal-title mt-0"
                  id="exampleModalFullscreenLabel"
                >
                  Other Channel Details
                </h5>
                <button
                  onClick={() => {
                    setmodal_add_screen(false)
                    setOtherChannelName("")
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="customer-field-container">
                    <Row className="align-items-center">
                    <Col md="4">
                      <label className="lable-customer">Channel Name</label>
                    </Col>
                    <Col md="8">
                      <Input
                        type="name"
                        style={{
                          marginTop: 'auto'
                        }}
                        className="customer-input"
                        placeholder="Enter Channel Name"
                        value={otherChannelName}
                        onChange={e => {
                          setOtherChannelName(e.target.value)
                        }}
                      />
                    </Col>
                  </Row>
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    tog_add_screen()
                    setOtherChannelName("")
                  }}
                  disabled={createLoading}
                  className="btn btn-secondary "
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  style={{
                    backgroundColor: "#811113",
                    color: "#fff",
                  }}
                  className="btn "
                  disabled={createLoading}
                  onClick={e => {
                    creatChannel()
                  }}
                >
                  Save
                </button>
              </div>
            </Modal>

            <Modal
              size="md"
              isOpen={modal_edit_screen}
              toggle={() => {
                tog_edit_screen()
              }}
              className="modal-popscreen"
            >
              <div className="modal-header">
                <h5
                  className="modal-title mt-0"
                  id="exampleModalFullscreenLabel"
                >
                  Edit Other Channel Details
                </h5>
                <button
                  onClick={() => {
                    setmodal_edit_screen(false)
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={e => e.preventDefault()}>
                  <div className="customer-field-container">
                    <Row>
                      <Col md="4">
                        <label className="lable-customer">Channel Name</label>
                      </Col>
                      <Col md="8">
                        <Input
                          type="name"
                          style={{
                            marginTop: "auto",
                          }}
                          className="customer-input"
                          defaultValue={editOtherChannelData?.name}
                          placeholder="Enter Channel Name"
                          onKeyDown={e => {
                            if (e.key === "Enter") {
                              saveEdits()
                            }
                          }}
                          onChange={e => {
                            // setEditTemplate(e.target.value)
                            setEditOtherChannel({
                              ...editOtherChannelData,
                              name: e.target.value,
                            })
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    tog_edit_screen()
                  }}
                  disabled={editLoading}
                  className="btn btn-secondary "
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  style={{
                    backgroundColor: "#811113",
                    color: "#fff",
                  }}
                  className="btn "
                  disabled={editLoading}
                  onClick={() => {
                    saveEdits()
                  }}
                >
                  Save changes
                </button>
              </div>
            </Modal>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default OtherChannel
