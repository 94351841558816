import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Button, Table, Modal, Container } from "reactstrap"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { useDispatch, useSelector } from "react-redux"
import { getReports, searchReports } from "store/old/reports/actions"
import { getUploadedBatches } from "store/actions"
import { Link } from "react-router-dom"

const ReportTable = () => {

  const [selectedTemplate, setTemplate] = useState("")
  const [selectedOperator, setOperator] = useState("")
  const [selectedCustomer, setCustomer] = useState("")
  const [selectedDate, setTestDate] = useState("")
  const dispatch=useDispatch();
  useEffect(() => {
    // searchReport()
  }, [])

  const searchReport = () => {
    
    dispatch(searchReports({
      customer: selectedCustomer,
      operator: selectedOperator,
      template: selectedTemplate,
      date: selectedDate
    }))
  }
  
  
  const uploadedBatches = useSelector((state) => state.SourceUpload.uploadedBatches);
  const reports = useSelector((state) => state.Reports.reports);
  const selectedReports = useSelector((state) => state.Reports.selectedReport)
  const [modal_fullscreen, setmodal_fullscreen] = useState(false)

  /* console.log(JSON.stringify(reports)); */
  function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  // console.log(reports)

  // Table Data


  return (
    <React.Fragment>
    </React.Fragment>
  )
}

export default ReportTable;