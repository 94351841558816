export const GET_NEW_OPERATOR = "GET_NEW_OPERATOR"
export const GET_NEW_OPERATOR_SUCCESS = "GET_NEW_OPERATOR_SUCCESS"
export const SET_NEW_OPERATOR= "SET_NEW_OPERATOR"
export const GET_COUNTRIES = "GET_COUNTRIES"
export const SET_COUNTRIES = "SET_COUNTRIES"
export const GET_SELECTED_OPERATOR = "GET_SELECTED_OPERATOR"
export const SET_SELECTED_OPERATOR= "SET_SELECTED_OPERATOR"
export const SET_SELECTED_OPERATOR_SUCCESS= "SET_SELECTED_OPERATOR_SUCCESS"
export const UPLOAD_OPERATOR = "UPLOAD_OPERATOR"
export const SET_UPLOAD_OPERATOR_SUCCESS = "SET_UPLOAD_OPERATOR_SUCCESS"
export const EDIT_OPERATOR = "EDIT_OPERATOR"
export const SET_EDIT_OPERATOR_SUCCESS= "SET_EDIT_OPERATOR_SUCCESS"
export const DELETE_OPERATOR = "DELETE_OPERATOR"
export const SET_DELETE_OPERATOR_SUCCESS = "SET_DELETE_OPERATOR_SUCCESS"
export const GET_NEWCOUNTRIES = "GET_NEWCOUNTRIES"
export const SET_NEWCOUNTRIES = "SET_NEWCOUNTRIES"