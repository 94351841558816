import React, { useEffect, useState, useRef } from "react"
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardTitle,
  Container,
  Table,
  Button,
  Input,
} from "reactstrap"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import Select from "react-select"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { useDispatch, useSelector } from "react-redux"
import "react-phone-number-input/style.css"
import PhoneInput from "react-phone-number-input"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

const OwnA2p = props => {
  const [projectVideoLink, setProjectVideoLink] = useState([
    { id: 1, data: "" },
  ])
  const [initialState, setIntialState] = useState(1)
  const [formRows, setFormRows] = useState([])
  const [valueVideo, setValueVideo] = useState([])

  const SendState = props.sendState
  const endOfFormRowsRef = useRef(null)

  // console.log(SendState)

  if (initialState == 1) {
    const modifiedRows = [...formRows]
    var tempVidLink = [...valueVideo]
    if (SendState.length == 0) {
      modifiedRows.push({ id: modifiedRows.length + 1 })
    }
    for (var obj of SendState) {
      modifiedRows.push({ id: modifiedRows.length + 1 })
    }
    setFormRows(modifiedRows)
    SendState.forEach(async (ele, index) => {
      ele.key = index + 1
    })
    SendState.forEach((ele, index) => {
      var data = {
        key: index + 1,
        smsc: ele.gts,
      }
      tempVidLink.push(data)
      // console.log(tempVidLink);
    })
    //setSendState(defaultState);
    setValueVideo(tempVidLink)
    setIntialState(2)
  }
  const scrollToBottom = () => {
    setTimeout(() => {
      endOfFormRowsRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      })
    }, 100) // Adjust delay as needed
  }

  const onAddFormRow = () => {
    const modifiedRows = [...formRows]
    // console.log(modifiedRows);
    var temp_id = modifiedRows.length + 1
    var data = {
      key: temp_id,
    }
    modifiedRows.push({ id: modifiedRows.length + 1 })
    //defaultState.push(data);
    SendState.push(data)
    setFormRows(modifiedRows)
    scrollToBottom()
  }

  const onDeleteFormRow = id_1 => {
    var id = id_1
    // console.log(id);
    if (id != 1 && id > 1) {
      var previd = 1
      var modifiedRows = [...formRows]
      // console.log(modifiedRows);
      var i = modifiedRows.findIndex(obj => obj["id"] == id)
      modifiedRows = modifiedRows.filter(x => x["id"] != id)
      while (i < modifiedRows.length) {
        modifiedRows[i].id--
        i++
      }
      // console.log(modifiedRows);
      setFormRows(modifiedRows)
    }
    if (id == 1 && formRows.length > 1) {
      id = id + 1
      var modifiedRows = [...formRows]
      // console.log(modifiedRows);
      var i = modifiedRows.findIndex(obj => obj["id"] == id)
      modifiedRows = modifiedRows.filter(x => x["id"] != id)
      while (i < modifiedRows.length) {
        modifiedRows[i].id--
        i++
      }
      // console.log(modifiedRows);
      setFormRows(modifiedRows)
    }
    var i = SendState?.findIndex(obj => obj.key == id_1)
    // console.log(i);
    SendState.splice(i, 1)
    while (i < SendState.length) {
      SendState[i].key--
      i++
    }
    // console.log(SendState);
  }

  const setVid = (e, id) => {
    var temp = [...valueVideo]
    if (e != null && id != null) {
      //console.log(projectLongitude);

      //console.log("KEYS"+mainState.map(obj=>obj.key));
      var tempid = id
      var templongt = e

      var data = {
        key: tempid,
        smsc: templongt,
      }
      if (temp.map(obj => obj.key).includes(data.key) == false) {
        temp.push(data)
      } else if (temp.map(obj => obj.key).includes(data.key) == true) {
        var objIndex = temp.findIndex(obj => obj.key == data.key)

        temp[objIndex].smsc = data.smsc
      }

      // console.log(mainState);
    }
    setValueVideo(temp)
  }

  const deletAll = id => {
    if (
      (valueVideo?.filter(obj => obj?.key == id).map(obj => obj?.smsc))[0] !=
      null
    ) {
      deletVid(id)
    }
  }
  const deletVid = id => {
    var temp = [...valueVideo]
    var i = temp.findIndex(obj => obj.key == id)
    temp.splice(i, 1)
    while (i < temp.length) {
      temp[i].key--
      i++
    }
    setValueVideo(temp)
  }

  useEffect(() => {
    //console.log("hello");
    if (projectVideoLink["id"] != null && projectVideoLink["data"] != null) {
      var tempid = projectVideoLink["id"]
      var tempcountry = projectVideoLink["data"]

      var data = {
        key: tempid,
        smsc: projectVideoLink["data"],
      }
      if (SendState.map(obj => obj.key).includes(data.key) == false) {
        SendState.push(data)
      } else if (SendState.map(obj => obj.key).includes(data.key) == true) {
        var objIndex = SendState.findIndex(obj => obj.key == data.key)
        SendState[objIndex].smsc = data.smsc
      }

      // console.log(SendState);
    }
  }, [projectVideoLink])

  return (
    // <React.Fragment>

    //             <div className="bg-white ">
    //             <div className="container-fluid">
    //               <Row>
    //               {(formRows ).map((formRow, key) => (
    //                 <Col md="3" key={key}>
    //                   <div className="zero-margin">
    //                   <Row >
    //                   <Col >

    //                       <label htmlFor="name">SMSC : {formRow.id}</label>
    //                       {/* <Input
    //                       type="url" className="form-input"
    //                       placeholder="Enter number here"
    //                       value={valueVideo?.filter(obj=>obj.key==formRow.id)?.map(obj=>obj.smsc)}
    //                       onChange={e =>{
    //                         setVid(e.target.value, formRow.id)
    //                         setProjectVideoLink({
    //                           id :formRow.id,
    //                           data: e.target.value,
    //                         })
    //                       }}
    //                       /> */}
    //                             <PhoneInput
    //                               className="customer-phone-input"
    //                               placeholder="Enter phone number"
    //                               value={valueVideo?.filter(obj=>obj.key==formRow.id)?.map(obj=>obj.smsc).toString()}
    //                               onChange={e =>{
    //                                 if(e){
    //                                   setVid(e, formRow.id)
    //                                   setProjectVideoLink({
    //                                     id :formRow.id,
    //                                     data: e,
    //                                   })
    //                                 }
    //                               }}
    //                               />
    //                     </Col>
    //                   </Row>
    //                   </div>
    //                   <div className="d-grid">
    //                           <Input
    //                             type="button"
    //                             className="btn btn-primary"
    //                             id="delete2"
    //                             value="Delete"
    //                             onClick={() => {
    //                               onDeleteFormRow(formRow.id);
    //                               deletAll(formRow.id);
    //                               //onDeleteSendState(formRow.id);
    //                             }}
    //                           />
    //                         </div>
    //                 </Col>
    //                         ))}
    //               </Row>
    //               {/* <Row>
    //                 <Col md="3" className="credit-info-add-btn ">
    //                 <Button
    //                   className="add-smsc"
    //                   value="Add SMSC"
    //                   onClick={() => onAddFormRow()}
    //                 />

    //                 </Col>

    //                 </Row> */}
    //                 <Button
    //                         onClick={() => {
    //                         //   handleAddRowNestedismsc();
    //                         onAddFormRow();                            }}
    //                         className="add-smsc"
    //                       >
    //                         Add Number
    //                       </Button>
    //             </div>
    //           </div>

    //                {/* <button onClick={() => onAddFormRow()}className="responce-btn" id="img-btn" type="button"><i className="bx bx-add-to-queue"></i> Add New </button> */}

    // </React.Fragment>
    <React.Fragment>
      <div className="bg-white" style={{ padding: "0px" }}>
        <div className="container-fluid" style={{ padding: "12px" }}>
          <Row
            className="smsc-card p-2 pt-4"
            style={{ maxHeight: "125px", overflowY: "auto"}}
          >
            {formRows.map((formRow, key) => (
              <Col md="6" key={key} className="">
                <Row className="">
                  <label htmlFor="name">SMSC : {formRow.id}</label>
                </Row>
                <Row className="d-flex align-items-center mb-3 mt-2">
                  <div className="zero-margin col-md-10">
                    <PhoneInput
                      className="customer-phone-input"
                      placeholder="Enter Phone Number"
                      value={valueVideo
                        ?.filter(obj => obj.key == formRow.id)
                        ?.map(obj => obj.smsc)
                        .toString()}
                      // value={valueVideo
                      //   .filter(obj => obj.key == formRow.id)
                      //   .map(obj => obj.smsc)
                      //   .toString()}
                      onChange={e => {
                        if (e) {
                          setVid(e, formRow.id)
                          setProjectVideoLink({
                            id: formRow.id,
                            data: e,
                          })
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-2">
                    <FontAwesomeIcon
                      icon={faTimes}
                      className=""
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => {
                        if (SendState.length != 0) {
                          onDeleteFormRow(formRow.id)
                          deletAll(formRow.id)
                        }
                      }}
                    />
                  </div>
                </Row>
              </Col>
            ))}
            <div ref={endOfFormRowsRef} />
          </Row>
        </div>
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "1px",
          }}
        >
          <Button
            onClick={onAddFormRow}
            className="add-smsc mt-4 flex-end"
            style={{ width: "124px" }}
          >
            Add
          </Button>
        </div>
      </div>

      {/* <button onClick={() => onAddFormRow()}className="responce-btn" id="img-btn" type="button"><i className="bx bx-add-to-queue"></i> Add New </button> */}
    </React.Fragment>
  )
}

export default OwnA2p
