import * as url from "../../../helpers/url_helper"

// export async function fetchNewPartnes() {
//     try {
//         const headers = {
//             auth: JSON.parse(localStorage.getItem("authUser")),
//         }
//         const response = await fetch(url._GET_NEW_PARTNERS, {
//             method: "GET",
//             headers: headers,
//         })
//         return await response.json()
//     } catch (err) {
//         return console.log(err)
//     }

// }
export async function fetchUsers() {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
        }
        const responseData = await fetch(url._GET_USERS, {
            method: "GET",
            headers: headers,
        })
        const response = await responseData.json()
        return {response,responseData}
    } catch (err) {
        return console.log(err)
    }

}
export async function uploadNewUser(data) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
        }
        const responseData = await fetch(url._UPLOAD_USER, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        })
        const response = await responseData.json()
        return {response,responseData}
    } catch (err) {
        return console.log(err)
    }
}
export async function deleteUser(data) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
        }
        const responseData = await fetch(url._DELETE_USER, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        })
        const response = await responseData.json()
        return {response,responseData}
    } catch (err) {
        return console.log(err)
    }
}
export async function activateUser(data) {
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
        }
        const responseData = await fetch(url._ACTIVATE_USER, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        })
        const response = await responseData.json()
        return {response,responseData}
    } catch (err) {
        return console.log(err)
    }
}
export async function removeUser(data){
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
        }
        const responseData = await fetch(url._REMOVE_USER, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        })
        const response = await responseData.json()
        return {response,responseData}
    } catch (err) {
        return console.log(err)
    }
}
export async function getSelectedUser(data){
    try {
        const headers = {
            auth: JSON.parse(localStorage.getItem("authUser")),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
        }
        const responseData = await fetch(url._SELECTED_USER+data, {
            method: "GET",
            headers: headers,
        })
        const response = await responseData.json()
        return {response,responseData}
    } catch (err) {
        return console.log(err)
    }
}