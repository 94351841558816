import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Input,
  Modal,
  ModalFooter,
  Container,
} from "reactstrap"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import Contacts from "./Add_Comps/contacts"
import TestNode from "./Add_Comps/TestNode"
import { getNewOperators } from "../../store/old/customer/actions"

// import "./datatables.scss"

const AddCustomer = props => {
  const setCustomerName = props.set_customer_name
  const setCustomerCountry = props.set_customer_country
  const setCustomerOperator = props.set_customer_operators
  const setCustomerContactName = props.set_customer_contact_name
  const setCustomerContactEmail = props.set_customer_contact_email
  const setCustomerContactPhone = props.set_customer_contact_phone
  const setCustomerContactIM = props.set_customer_contact_im
  const setCustomerTestName = props.set_customer_test_name
  const setCustomerTestEmail = props.set_customer_test_email
  const setCustomerTestPhone = props.set_customer_test_phone
  const setCustomerTestIM = props.set_customer_test_IM

  const add_ownP2P_state = props.add_ownP2P_state

  const contactState = props.contact_state
  const testState = props.test_state

  const [selectedMulti, setselectedMulti] = useState(null)
  const dispatch = useDispatch()

  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti)
  }

  useEffect(() => {
    // dispatch(getCountries())
    dispatch(getNewOperators())
  }, [])

  const countries = useSelector(state => state.customer.countries)
  const operator = useSelector(state => state.customer.operators)
  const operatorData = operator?.map(obj => ({
    id: obj.id,
    label: obj.operator_customer_label,
    value: obj.operator_customer_label,
  }))
  const countryData = countries?.message?.map(obj => ({
    id: obj.id,
    label: obj.country_name,
    value: obj.iso_3,
  }))

  return (
    <React.Fragment>
      <form>
        <h5>Customer Data</h5>
        <div className="customer-field-container">
          <Row>
            <Col md="3">
              <label className="lable-customer">Customer Name</label>
            </Col>
            <Col md="3">
              <Input
                type="name"
                className="customer-input"
                placeholder="Enter the Name"
                onChange={e => {
                  setCustomerName(e.target.value)
                }}
              />
            </Col>
            {/* <Col md="3">
                <label className="lable-customer">Country</label>
                </Col>
                <Col md="3">
                <Select
                            className="customer-mult-input"
                            //isSearchable={true}
                            name="country"
                            options={countryData}
                            placeholder="Select Country"
                            onChange={(e)=>{
                                setCustomerCountry(e.label)
                            }}
                            
                          />
                </Col> */}
            <Col md="3">
              <label className="lable-customer">Operator</label>
            </Col>
            <Col md="3">
              <Select
                className="customer-mult-input"
                value={selectedMulti}
                isMulti={true}
                onChange={e => {
                  handleMulti(e)
                  setCustomerOperator(e.map(obj => obj.id))
                }}
                name="operator"
                options={operatorData}
                placeholder="Select Operator"
                classNamePrefix="select2-selection"
              />
            </Col>
          </Row>
        </div>

        <h5>Contact Details</h5>
        <div className="customer-field-container">
          <Contacts main_state={contactState} />
        </div>

        <h5>Test Node Details</h5>
        <div className="customer-field-container">
          <TestNode
            selectedMulti={selectedMulti}
            operators={operatorData}
            main_state={testState}
          />
        </div>
      </form>
    </React.Fragment>
  )
}

export default AddCustomer
