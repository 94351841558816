import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import dotenv from 'dotenv'
import * as urls from "../../helpers/url_helper"

dotenv.config()
const url = process.env.LOCALHOST?process.env.LOCALHOST:'https://qa_api_gtc.amerck.com'

export const otherChannelAPI = createApi({
    reducerPath: 'otherChannelAPI',
    baseQuery: fetchBaseQuery({ baseUrl: url, timeout: 10000 }),
    endpoints: (builder) => ({
        createOtherChannel: builder.mutation({
            query: ({body})=>({
                url: urls._CREATE_OTHER_CHANNEL,
                method: 'POST',
                body: body,
                headers: {
                    auth: JSON.parse(localStorage.getItem("authUser")),
                  },
                timeout: 4000,
            })
        }),
        editOtherChannel : builder.mutation({
            query: ({body})=>({
                url: urls._EDIT_OTHER_CHANNEL,
                method: 'POST',
                body: body,
                headers: {
                    auth: JSON.parse(localStorage.getItem("authUser")),
                  },
                timeout: 4000,
            })
        }),
        deleteOtherChannel: builder.mutation({
            query: ({id})=>({
                url: urls._DELETE_OTHER_CHANNEL,
                method: 'DELETE',
                params: {
                    id: id
                },
                headers: {
                    auth: JSON.parse(localStorage.getItem("authUser")),
                  },
                timeout: 4000,
            })
        }),
        getSelectedOtherChannel: builder.query({
            query: ({id})=>({
                url: urls._GET_SELECTED_OTHER_CHANNELS,
                method: 'GET',
                params: {
                    id: id
                },
                headers: {
                    auth: JSON.parse(localStorage.getItem("authUser")),
                  },
                timeout: 4000,
            })
        }),
        getOtherChannels: builder.query({
            query: ()=>({
                url: urls._GET_OTHER_CHANNELS,
                method: 'GET',
                headers: {
                    auth: JSON.parse(localStorage.getItem("authUser")),
                  },
                timeout: 4000,
            })
        }),
    }),
  })
  
  export const {
    useCreateOtherChannelMutation,
    useEditOtherChannelMutation,
    useDeleteOtherChannelMutation,
    useLazyGetSelectedOtherChannelQuery,
    useGetOtherChannelsQuery
  } = otherChannelAPI