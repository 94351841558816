import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Card,
  CardBody,
  Button,
  Form,
  Col,
  Toast,
  Spinner,
} from "reactstrap"
import Dropzone from "react-dropzone"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import sheet from "../../assets/images/sheet.png"
import { BiTrash } from "react-icons/bi"
import * as XLSX from "xlsx"
import Swal from "sweetalert2"
import excelimage from "../../assets/images/excel.png"
import { Link } from "react-router-dom"
import iconv from "iconv-lite"
import Papa from "papaparse"
import { useGetCombinedFilesMutation } from "store/inspector/inspector.api"
import toastr from "toastr"
import * as url from "../../helpers/url_helper"
import axios from "axios"

const smscNew = () => {
  const [selectedSmscFiles, setselectedSmscFiles] = useState([])
  const [selectedInspectorFiles, setselectedInspectorFiles] = useState([])
  const [combinedFile, setCombinedFile] = useState(null)
  const [fileName, setFileName] = useState("combined_file.xlsx")
  const [isLoading, setIsloading] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [testFileReading, setTestFileReading] = useState(false)
  const [inspectorFileReading, setInspectorFileReading] = useState(false)
  const [format, setFormat] = useState('')


  const handleAcceptedServerFiles = files => {
    setselectedSmscFiles([])
    setTestFileReading(true)
    const reader = new FileReader()
    reader.onload = async () => {
      const fileData = reader.result
      const workbook = XLSX.read(fileData, { type: "binary" })
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
      // Check the order of the header columns
      const expectedHeaders = ["Message ID", "Date", "Username"]
      const newFormatHeaders = ["ID", "Test ID text", "Date (UTC)"]
      const actualHeaders = data[0].slice(0, 3)
      // console.log(actualHeaders);
      const isHeaderOrderCorrect = expectedHeaders.every(
        (header, index) => actualHeaders[index] === header
      )
      const isNewFormat = newFormatHeaders.every(
        (header, index) => actualHeaders[index] === header
      )
      if (!isHeaderOrderCorrect && !isNewFormat) {
        // Handle error here
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Incorrect File Uploaded. Please upload a valid Test File",
        })
        setTestFileReading(false)
        // console.log('Incorrect File Uploaded. Please upload a valid test file');
        return
      }
      if(isNewFormat){
        setFormat('2')
      }
      if(isHeaderOrderCorrect){
        setFormat('1')
      }
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      )
      setselectedSmscFiles(files)
      setCombinedFile(null)
      setTestFileReading(false)
    }
    reader.readAsBinaryString(files[0])
  }

  async function handleAcceptedPartnerFiles(files) {
    setselectedInspectorFiles([])
    setInspectorFileReading(true)
    const reader = new FileReader()
    reader.onload = async () => {
      const fileData = reader.result
      const buffer = new Uint8Array(fileData)

      const utf8Buffer = iconv.decode(buffer, "utf16")
      const parsedData = Papa.parse(utf8Buffer, {
        header: true,
        delimiter: ";",
      })
      const headers = parsedData.meta.fields

      const requiredHeaders = [
        "Time received",
        "GMT offset",
        "SMSC",
        "TP-OA",
        "Text",
      ]
      const hasRequiredHeaders = requiredHeaders.every(header =>
        headers.includes(header)
      )

      if (!hasRequiredHeaders) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Incorrect File Uploaded. Please upload a valid Inspector File",
        })
        setInspectorFileReading(false)
        // console.error("CSV file does not have the required headers")
        return
      }
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      )
      setselectedInspectorFiles(files)
      setCombinedFile(null)
      setInspectorFileReading(false)
    }
    reader.readAsArrayBuffer(files[0])
  }

  const removeFile = (index, type) => {
    if (type === "smsc") {
      const updatedFiles = [...selectedSmscFiles]
      updatedFiles.splice(index, 1)
      setselectedSmscFiles(updatedFiles)
      setCombinedFile(null)
    } else if (type === "inspector") {
      const updatedFiles = [...selectedInspectorFiles]
      updatedFiles.splice(index, 1)
      setselectedInspectorFiles(updatedFiles)
      setCombinedFile(null)
    }
  }

  async function combineFiles() {
    setIsloading(true)
    setCombinedFile(null)
    setFileName("combined_file.xlsx")
    if (selectedInspectorFiles.length != 1 || selectedSmscFiles.length != 1) {
      setIsloading(false)
      return
    } else {
      const formData = new FormData()
      formData.append("test_file", selectedSmscFiles[0])
      formData.append("inspector_file", selectedInspectorFiles[0])
      formData.append("format", format)
      setTimeout(async () => {
        const headers = {
          headers: {
            auth: JSON.parse(localStorage.getItem("authUser")),
          },
          responseType: "arraybuffer",
        }
        await axios
          .post(url.SMSC_INSPECTOR, formData, headers)
          .then(response => {
            // console.log(response.error.data);
            const blob = new Blob([response.data], {
              type: "application/octet-stream",
            })
            setCombinedFile(blob)
            setFileName(selectedSmscFiles[0].name)
            toastr.success("Files merged successfully")
            setIsloading(false)
          })
          .catch(error => {
            setIsloading(false)
            console.log(error)
          })
      }, 10)
    }
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  async function downloadFile() {
    setIsDownloading(true)
    if (!combinedFile) {
      setIsDownloading(false)
      return
    }
    //get the blob in the combinedFile state and download the file for the user
    const url = window.URL.createObjectURL(combinedFile)
    const link = document.createElement("a")
    link.href = url
    link.download = fileName
    link.click()
    setIsDownloading(false)
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>SMSC Inspector</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumbs title="SMSC Inspector" breadcrumbItem="SMSC Inspector" />
        <Row>
          <div className="col-md-6 pt-5 pe-4">
            <div className="pb-2">
              <h5>GTC Test File </h5>
            </div>

            <Card className="card_new">
              <CardBody>
                <Form>
                  {testFileReading?(
                    <div className="dropzone1 d-flex justify-content-center align-items-center">
                      {/* <Spinner size="lg" color="#BF9293" /> */}
                        <span className="file_loader"></span>
                    </div>
                  ):
                  <div>
                    <Dropzone
                      className="dropzon1"
                      maxFiles={1}
                      multiple={false}
                      validator={file => {
                        var x = file.path?.split(".")
                        let extension = x[x.length - 1]
                        if (
                          extension === "xlsx"
                          // extension === "xls" ||
                          // extension === "csv"
                        ) {
                          return null
                        } else {
                          // alert("Please Select XLSX File to Continue")
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Please Select XLSX File to Continue",
                          })
                          return {
                            error: "Please Select XLSX File to Continue",
                          }
                        }
                      }}
                      onDrop={acceptedFiles => {
                        if (acceptedFiles.length != 0) {
                          handleAcceptedServerFiles(acceptedFiles)
                        }
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone1">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3 box3">
                              <i class="upload_icon fas fa-cloud-upload-alt"></i>
                            </div>
                            <h4>
                              Drop files here or{" "}
                              <span className="upload_text">
                                click to upload{" "}
                              </span>
                            </h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                  }
                </Form>
                <div className="dropzone-previews" id="file-previews">
                  {selectedSmscFiles.length === 0 ? (
                    <Row>
                      <div className="pt-4">
                        <h6 className="file_text">
                          <i
                            className=" bx bx-paperclip "
                            style={{ rotate: "140deg" }}
                          />
                          &nbsp; No file added
                        </h6>
                      </div>
                      <div className="support_text"> Supports: XLSX</div>
                      <div className="support_text">
                        {" "}
                        Maximum file size of 20mb
                      </div>
                    </Row>
                  ) : (
                    selectedSmscFiles.map((f, i) => {
                      let displayName = f.name
                      let MAX_NAME_LENGTH = 45
                      if (displayName.length > MAX_NAME_LENGTH) {
                        const ellipsis = "..."
                        const sliceLength =
                          (MAX_NAME_LENGTH - ellipsis.length) / 2
                        displayName = `${displayName.slice(
                          0,
                          sliceLength
                        )}${ellipsis}${displayName.slice(-sliceLength)}`
                      }
                      return (
                        <Card
                          style={{ boxShadow: "none" }}
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="rounded bg-light"
                                  src={excelimage}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {displayName}
                                </Link>
                                <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                              <Col className="col-auto">
                                <Button
                                  color="danger"
                                  size="sm"
                                  onClick={() => removeFile(i, "smsc")}
                                >
                                  <BiTrash />
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      )
                    })
                  )}
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="col-md-6 pt-5 ps-4">
            <div className="pb-2">
              {" "}
              <h5>GTC SMSC Inspector File </h5>
            </div>

            <Card className="card_new">
              <CardBody>
                <Form>
                  {inspectorFileReading?
                  <div className="dropzone1 d-flex justify-content-center align-items-center">
                    <span className="file_loader"></span>
                  </div>
                  :
                  <Dropzone
                  className="dropzone1"
                  maxFiles={1}
                  multiple={false}
                  validator={file => {
                    var x = file.path?.split(".")
                    let extension = x[x.length - 1]
                    if (
                      // extension === "xlsx" ||
                      // extension === "xls" ||
                      extension === "csv"
                    ) {
                      // return null
                    } else {
                      // alert("Please Select CSV File to Continue")
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Please Select CSV File to Continue",
                      })
                      return {
                        error: "Please Select CSV File to Continue",
                      }
                    }
                  }}
                  onDrop={acceptedFiles => {
                    if (acceptedFiles.length != 0) {
                      handleAcceptedPartnerFiles(acceptedFiles)
                    }
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone1">
                      <div
                        className="dz-message needsclick mt-2"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="mb-3 box3">
                          <i class="fas fa-cloud-upload-alt upload_icon "></i>
                        </div>
                        <h4>
                          Drop files here or{" "}
                          <span className="upload_text">
                            click to upload{" "}
                          </span>
                        </h4>
                      </div>
                    </div>
                  )}
                  </Dropzone>
                  }
                </Form>
                <div className="dropzone-previews" id="file-previews">
                  {selectedInspectorFiles.length === 0 ? (
                    <Row>
                      <div className="pt-4">
                        <h6 className="file_text">
                          <i
                            className=" bx bx-paperclip "
                            style={{ rotate: "140deg" }}
                          />
                          &nbsp; No file added
                        </h6>
                      </div>
                      <div className="support_text"> Supports: CSV</div>
                      <div className="support_text">
                        {" "}
                        Maximum file size of 20mb
                      </div>
                    </Row>
                  ) : (
                    selectedInspectorFiles.map((f, i) => {
                      let displayName = f.name
                      let MAX_NAME_LENGTH = 45
                      if (displayName.length > MAX_NAME_LENGTH) {
                        const ellipsis = "..."
                        const sliceLength =
                          (MAX_NAME_LENGTH - ellipsis.length) / 2
                        displayName = `${displayName.slice(
                          0,
                          sliceLength
                        )}${ellipsis}${displayName.slice(-sliceLength)}`
                      }
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="rounded bg-light"
                                  src={excelimage}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {displayName}
                                </Link>
                                <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                              <Col className="col-auto">
                                <Button
                                  color="danger"
                                  size="sm"
                                  onClick={() => removeFile(i, "inspector")}
                                >
                                  <BiTrash />
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      )
                    })
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>

      <div className="d-flex justify-content-center">
        <div className="">
          <Button
            color="danger"
            className="btn btn-custom md-position-absolute mt-3 bd-highlight"
            onClick={combineFiles}
            disabled={
              isLoading ||
              selectedInspectorFiles.length !== 1 ||
              selectedSmscFiles.length !== 1 ||
              combinedFile
            }
          >
            {isLoading ? (
              <>
                <Spinner size="sm" color="light" />{" "}
                {/* Display spinner if loading */}
                &nbsp; Merging...
              </>
            ) : (
              "Merge Files"
            )}
          </Button>
        </div>
      </div>
      <div>
        {combinedFile && (
          <div
            className="card mt-3 p-2 mb-4"
            style={{
              borderRadius: "10px",
            }}
          >
            <div
              className="pt-1 ps-3 pb-2"
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: 500,
                textAlign: "left",
              }}
            >
              Output
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-1">
                  <img
                    src={sheet}
                    alt="Sheet"
                    style={{
                      width: "73px",
                      height: "70px",
                    }}
                  />
                </div>

                  <div className="col-md-5 pt-4">
                    <div className="file_new_text">File</div>
                    <div className="merge_text"> {fileName}</div>
                  </div>

                  <div className="col-md-2"></div>

                  <div className="col-md-4 pt-4">
                    <Button
                      color="danger"
                      className="btn btn-custom1 download_btn ms-auto"
                      disabled={!combinedFile || isDownloading}
                      onClick={downloadFile}
                    >
                      {isDownloading ? (
                        <>
                          <Spinner size="sm" color="light" /> Downloading...
                        </>
                      ) : (
                        "Download"
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default smscNew
