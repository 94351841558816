import PropTypes from "prop-types"
import axios from "axios"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import * as url from "../../helpers/url_helper"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"
import jwt from "jwt-decode"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-9.png"
// actions
import {logoutUser, resetProfileFlag } from "../../store/actions"
import { getSelectedUser } from "../../store/actions"
import toastr from "toastr"
const UserProfile = props => {
  const dispatch = useDispatch()

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }))

  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [idx, setidx] = useState(1)
  const [user,setUser] = useState("")
  const [isUpdating,setIsUpdating] = useState(false)
  const [passUpdating,setPassUpdating] = useState(false)
  const [showcurPassword,setShowcurPassword] = useState(false)
  const [shownewPassword,setShownewPassword] = useState(false)
  const [curPassword,setCurPassword] = useState("")
  const [newPassword,setNewPassword] = useState("")
  const userData = useSelector(state => state.users.selectedUser)


  useEffect(async() => {
    if (localStorage.getItem("authUser")) {
      //const obj = JSON.parse(localStorage.getItem("authUser"))
      const obj = jwt(localStorage.getItem("authUser"))
      // console.log("Profile",obj);
      const headers = {
        auth: JSON.parse(localStorage.getItem("authUser")),
      }
      await axios.get(url._SELECTED_USER+obj.id,{headers}).then(response => {
        // console.log("Profile",response.data.message);
        setUser(response.data.message)
      }).catch(error => {
        console.log(error)
      })
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        // setname(obj.username)
        // setemail(obj.email)
        // setidx(obj.uid)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        // setname(obj.username)
        // setemail(obj.email)
        // setidx(obj.uid)
      }
      setTimeout(() => {
        dispatch(resetProfileFlag())
      }, 3000)
    }
  }, [dispatch, success])

  useEffect(() => {
    setname(user.username)
    setemail(user.email)
    setidx(user.id)
  }, [user])

  async function handleValidSubmit(event, values) {
    // dispatch(editProfile(values))
    const headers = {
      auth: JSON.parse(localStorage.getItem("authUser")),
    }
    const temp = {
      id : user.id,
      username : values.username
    }
    setIsUpdating(true)
    await axios.post(url._EDIT_USER,temp,{
      headers,
      onDownloadProgress: progressEvent => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if(percentCompleted==100){
          dispatch(resetProfileFlag())
        }
      },
    }).then(async response => {
      // console.log(response.data);
      setIsUpdating(false)
      // dispatch(getSelectedUser(user.id))
      const headers = {
        auth: JSON.parse(localStorage.getItem("authUser")),
      }
      await axios.get(url._SELECTED_USER+user.id,{headers}).then(response => {
        // console.log("Profile",response.data.message);
        setUser(response.data.message)
      }).catch(error => {
        console.log(error)
      })
    }).catch(error => {
      console.log(error)
      setIsUpdating(false)
      toastr.error("Error",error.response.data.message)
    })
  }
  async function handleValidSubmitPassword(event, values) {
    const headers = {
      auth: JSON.parse(localStorage.getItem("authUser")),
    }
    const temp = {
      id : user.id,
      current : values.current,
      new : values.new
    }
    setPassUpdating(true)
    await axios.post(url._CHANGE_PASSWORD,temp,{headers}).then(response => {
      setPassUpdating(false)
      setShowcurPassword(false)
      setShownewPassword(false)
      setCurPassword("")
      setNewPassword("")
      toastr.success("Success",response.data.message)
      setTimeout(() => {
        dispatch(logoutUser())
      }, 1200);
    }).catch(error => {
      console.log(error)
      setPassUpdating(false)
      toastr.error("Error",error.response.data.message)
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile | NPT Automation Tool</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="User" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <Media>
                    <div className="ms-3" style={{
                      marginRight: "20px",
                    }}>
                      <img
                        style={{
                          width: "77px",
                          height: "50px",
                        }}
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <Media body className="align-self-center">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                      </div>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Change User Name</h4>

          <Card>
            <CardBody>
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v)
                }}
              >
                <div className="form-group">
                  <AvField
                    name="username"
                    label="User Name"
                    value={user?.username}
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    required
                  />
                  {/* <AvField name="idx" value={idx} type="hidden" /> */}
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger" disabled={isUpdating}>
                    {isUpdating ? "Updating..." : "Update Username"}
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
          <h4 className="card-title mb-4">Change Password</h4>
          <Card>
            <CardBody>
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmitPassword(e, v)
                }}
              >
                <Row>
                  <Col lg="6">
                  <div className="form-group" style={{display:"flex",
                        alignItems:"flex-end",
                      }}>
                      <Col lg="10">
                      <AvField
                        name="current"
                        label="Current Password"
                        value={curPassword}
                        className="form-control"
                        placeholder="Enter Current Password"
                        type={showcurPassword?"text":"password"}
                        required
                      />
                      </Col>

                      <Button style={{
                        height:"38px",
                        marginLeft:"10px",
                      }} onClick={(e)=>{setShowcurPassword(!showcurPassword)}}>{showcurPassword ? 'Hide' : 'Show'}</Button>
                      {/* <AvField name="idx" value={idx} type="hidden" /> */}
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="form-group" style={{display:"flex",
                        alignItems:"flex-end",
                      }}>
                      <Col lg="10">
                      <AvField
                        name="new"
                        label="New Password"
                        value={newPassword}
                        className="form-control"
                        placeholder="Enter New Password"
                        type={shownewPassword?"text":"password"}
                        required
                      />
                      </Col>

                      <Button style={{
                        height:"38px",
                        marginLeft:"10px",
                      }} onClick={(e)=>{setShownewPassword(!shownewPassword)}}>{shownewPassword ? 'Hide' : 'Show'}</Button>
                      {/* <AvField name="idx" value={idx} type="hidden" /> */}
                    </div>
                  </Col>
                </Row>

                <div className="text-center mt-4">
                  <Button type="submit" color="danger" disabled={passUpdating}>
                    {passUpdating ? "Updating..." : "Change Password"}
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
