import React, { useEffect, useState, useRef } from "react"
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardTitle,
  Container,
  Table,
  Button,
  Input,
} from "reactstrap"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import Select from "react-select"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { useDispatch, useSelector } from "react-redux"
import "react-phone-number-input/style.css"
import PhoneInput from "react-phone-number-input"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

const OwnP2p = props => {
  const [projectVideoLink, setProjectVideoLink] = useState([
    { id: 1, data: "" },
  ])
  const [formRows, setFormRows] = useState([{ id: 1 }])
  const mainState = props.main_state
  const [valueVideo, setValueVideo] = useState([])
  const endOfFormRowsRef = useRef(null)

  const scrollToBottom = () => {
    setTimeout(() => {
      endOfFormRowsRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      })
    }, 100) // Adjust delay as needed
  }

  const onAddFormRow = () => {
    const modifiedRows = [...formRows]
    // console.log(modifiedRows);
    var temp_id = modifiedRows.length + 1
    var data = {
      key: temp_id,
    }
    modifiedRows.push({ id: modifiedRows.length + 1 })
    mainState.push(data)
    setFormRows(modifiedRows)
    scrollToBottom()
  }

  const onDeleteFormRow = id_1 => {
    var id = id_1
    // console.log(id);
    if (id != 1 && id > 1) {
      var previd = 1
      var modifiedRows = [...formRows]
      // console.log(modifiedRows);
      var i = modifiedRows.findIndex(obj => obj["id"] == id)
      modifiedRows = modifiedRows.filter(x => x["id"] != id)
      while (i < modifiedRows.length) {
        modifiedRows[i].id--
        i++
      }
      // console.log(modifiedRows);
      setFormRows(modifiedRows)
    }
    if (id == 1 && formRows.length > 1) {
      id = id + 1
      var modifiedRows = [...formRows]
      // console.log(modifiedRows);
      var i = modifiedRows.findIndex(obj => obj["id"] == id)
      modifiedRows = modifiedRows.filter(x => x["id"] != id)
      while (i < modifiedRows.length) {
        modifiedRows[i].id--
        i++
      }
      // console.log(modifiedRows);
      setFormRows(modifiedRows)
    }
    var i = mainState.findIndex(obj => obj.key == id_1)
    // console.log(i);
    mainState.splice(i, 1)
    while (i < mainState.length) {
      mainState[i].key--
      i++
    }
    // console.log(mainState);
  }
  const deletAll = id => {
    if (
      (valueVideo?.filter(obj => obj.key == id).map(obj => obj.p2p))[0] != null
    ) {
      deletVid(id)
    }
  }
  const setVid = (e, id) => {
    var temp = [...valueVideo]
    if (e != null && id != null) {
      //console.log(projectLongitude);

      //console.log("KEYS"+mainState.map(obj=>obj.key));
      var tempid = id
      var templongt = e

      var data = {
        key: tempid,
        p2p: templongt,
      }
      if (temp.map(obj => obj.key).includes(data.key) == false) {
        temp.push(data)
      } else if (temp.map(obj => obj.key).includes(data.key) == true) {
        var objIndex = temp.findIndex(obj => obj.key == data.key)

        temp[objIndex].p2p = data.p2p
      }

      // console.log(mainState);
    }
    setValueVideo(temp)
  }
  const deletVid = id => {
    var temp = [...valueVideo]
    var i = temp.findIndex(obj => obj.key == id)
    temp.splice(i, 1)
    while (i < temp.length) {
      temp[i].key--
      i++
    }
    setValueVideo(temp)
  }

  useEffect(() => {
    //console.log("hello");
    if (projectVideoLink["id"] != null && projectVideoLink["data"] != null) {
      var tempid = projectVideoLink["id"]
      var tempcountry = projectVideoLink["data"]

      var data = {
        key: tempid,
        a2p: projectVideoLink["data"],
      }
      if (mainState.map(obj => obj.key).includes(data.key) == false) {
        mainState.push(data)
      } else if (mainState.map(obj => obj.key).includes(data.key) == true) {
        var objIndex = mainState.findIndex(obj => obj.key == data.key)

        mainState[objIndex].a2p = data.a2p
      }
    }
  }, [projectVideoLink])

  return (
    <React.Fragment>
      <div className="bg-white ">
        <div className="container-fluid" style={{ padding: "0px" }}>
          <div>
            <Col
              className="smsc-card d-block p-2"
              md={12}
              style={{ maxHeight: "225px", overflowY: "auto" }} // Adjust maxHeight as needed
            >
              <div className="ms-2">
              <div className="row d-flex align-items-center">
              <label className="operator-label">Own P2P</label>
              </div>
                {formRows.map((formRow, key) => (
                  <Col md="12" key={key} className="">
                    <div className=" row d-flex align-items-center mb-3 mt-2 ">
                      <label className="mt-1" htmlFor="name">
                        P2P : {formRow.id}
                      </label>
                      <Col md={10}>
                        <PhoneInput
                          className="customer-phone-input"
                          placeholder="Enter Phone Number"
                          value={valueVideo
                            .filter(obj => obj.key == formRow.id)
                            .map(obj => obj.p2p)
                            .toString()}
                          onChange={e => {
                            if (e) {
                              setVid(e, formRow.id)
                              setProjectVideoLink({
                                id: formRow.id,
                                data: e,
                              })
                            }
                          }}
                        />
                      </Col>
                      <Col md={2} className="mt-2">
                        <FontAwesomeIcon
                          icon={faTimes}
                          className=""
                          style={{ color: "red" }}
                          onClick={() => {
                            if (mainState.length != 0) {
                              onDeleteFormRow(formRow.id)
                              deletAll(formRow.id)
                            }
                          }}
                        />
                      </Col>
                    </div>
                  </Col>
                ))}
                <div ref={endOfFormRowsRef} />
              </div>
            </Col>
          </div>
        </div>
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={onAddFormRow}
            className="add-smsc mt-4 flex-end"
            style={{ width: "124px" }}
          >
            Add
          </Button>
        </div>
      </div>

      {/* <button onClick={() => onAddFormRow()}className="responce-btn" id="img-btn" type="button"><i className="bx bx-add-to-queue"></i> Add New </button> */}
    </React.Fragment>
  )
}

export default OwnP2p
