import PropTypes from "prop-types";
import React from "react";

import { useEffect,useState } from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
import {
  Button,
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter
} from "reactstrap"
import LoadingSpinnerUpload from "./LoadingSpinner_upload"
import Swal from "sweetalert2"
import "toastr/build/toastr.min.css";
//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";
import store from "../../store/index";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import logoGTC from "../../assets/images/logo_login.png"

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { loginUser, socialLogin,loginSuccess} from "../../store/actions";

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.png";
import gtc_cover from "assets/images/gtc-office.jpg";

//Import config
import { facebook, google } from "../../config";

const Login = props => {

   //meta title
  document.title="Login | NPT Automation Tool";
  const [modal_pipe_upload, setmodal_pipe_upload] = useState(false)

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      // dispatch(loginUser(values, props.history));
      setmodal_pipe_upload(true)
      sendLogin(values)
    }
  });

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));
  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.history, type));
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.history, type));
    }
  };

  const sendLogin = async (values) => {
    dispatch(loginUser(values))
    let loginPromise = new Promise(async(resolve,reject)=>{
      store.subscribe(()=>{
        const loginSuccess = store.getState();
        const status = loginSuccess.Login.loading
        if(status == true){
          // console.log(status);
          resolve("Done")
        }
        if(status == false){
          // console.log(status);
          reject("Error")
        }
      })
    })
    await loginPromise.then(async(msg)=>{
      // Toast.fire({
      //   icon: 'success',
      //   title: 'Delete Success!'
      // })
      setmodal_pipe_upload(false);
      // console.log("Login Success");
      // Swal.fire('Login Success!', '', 'success')
      // Toast.fire({
      //   icon: 'success',
      //   title: 'File Deleted'
      // })
      dispatch(loginSuccess())
      window.location.href = "/source-upload"
    }).catch(async(err)=>{
      setmodal_pipe_upload(false);
      console.log("User Not Found");
      Swal.fire('User Not Found', '', 'error')
      // Toast.fire({
      //   icon: 'error',
      //   title: 'File Not Deleted'
      // })
      dispatch(loginSuccess())
    })
  }

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google");
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook");
  };

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div> */}
      {/* <div className="account-pages my-5 pt-sm-5"> */}
        {/* <Container> */}
          <div className="container-fluid login-img">
            <div className="login-image-container">
              <img className="login-logo" src={logoGTC}></img>
            </div>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={8} className="authCard">
              <Card className="overflow-hidden loginCard">
                <div className="bg-login bg-soft">
                  <Row>
                    {/* <Col>
                      <div className="text-login-cont p-4">
                        <div className="text-margin">
                          <div className="text-white">
                          <h2 className="text-login">NPT Automation Tool</h2>
                          <p className="text-login">Sign in to continue to NPT.</p>
                          </div>

                        </div>
                      </div>
                    </Col> */}
                  </Row>
                </div>
                <CardBody className="login-card-body">
                  <div>

                  </div>
                  <div className="login-body p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >

                      <div className="pb-2">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control login-input"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="pb-2">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          className="form-control login-input"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-login btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                      <div className="mt-2" style={{
                        textAlign: "center"
                      }}>
                        <p>
                          {new Date().getFullYear()} ©
                          Amerck.
                        </p>
                      </div>

                      <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3"></h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                          </li>

                          <li className="list-inline-item">

                          </li>
                        </ul>
                      </div>

                      {/* <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div> */}
                    </Form>
                  </div>
                </CardBody>
              </Card>

            </Col>
          </Row>
          </div>

        {/* </Container> */}
      {/* </div> */}
      <Modal isOpen={modal_pipe_upload} centered>
        <ModalHeader>Source Upload</ModalHeader>
        <ModalBody>
          <LoadingSpinnerUpload/>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </Modal> 
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
