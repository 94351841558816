import axios from "axios"
import {
  SET_NEW_CUSTOMER,
  SET_SELECTED_CUSTOMER,
  GET_NEW_CUSTOMER_SUCCESS,
  SET_UPLOAD_CUSTOMER_SUCCESS,
  SET_EDIT_CUSTOMER_SUCCESS,
  SET_DELETE_CUSTOMER_SUCCESS,
  SET_COUNTRIES,
  SET_NEWOPERATORS,
  SET_NEWCOUNTRIES,
  SET_SELECTED_CUSTOMER_SUCCESS
} from "./actionTypes"


const INIT_STATE = {
    customers: [],
    operators:[],
    customergetsuccess:false,
    selectedcustomer: [],
    selectedcustsuccess:false,
    countries:[],
    uploadsuccess:false,
    editsuccess:false,
    deletesuccess:false,
   }
  
  const customer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_NEW_CUSTOMER_SUCCESS:
        // state.customergetsuccess = action.payload;
        return {
          ...state,
          customergetsuccess : action.payload
        }
      case SET_SELECTED_CUSTOMER_SUCCESS:
        // state.selectedcustsuccess = action.payload;
        return {
          ...state,
          selectedcustsuccess : action.payload
        }
      case SET_NEWOPERATORS:
        // state.operators = action.payload;
        return {
          ...state,
          operators : action.payload
        }
      case SET_NEWCOUNTRIES:
        // state.countries = action.payload;
        return {
          ...state,
          countries : action.payload
        }
      case SET_NEW_CUSTOMER:
        // state.customers = action.payload;
        return {
          ...state,
          customers : action.payload
        }
      case SET_SELECTED_CUSTOMER:
        // state.selectedcustomer = action.payload;
        return {
          ...state,
          selectedcustomer : action.payload
        }
      case SET_UPLOAD_CUSTOMER_SUCCESS:
        // state.uploadsuccess = action.payload;
        return {
          ...state,
          uploadsuccess : action.payload
        }
      case SET_EDIT_CUSTOMER_SUCCESS:
        // state.editsuccess = action.payload;
        return {
          ...state,
          editsuccess : action.payload
        }
      case SET_DELETE_CUSTOMER_SUCCESS:
        // state.deletesuccess = action.payload;
        return {
          ...state,
          deletesuccess : action.payload
        }
      default:
        return state
    }
  }
  
  export default customer