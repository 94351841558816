import React, { Component } from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import ViewReports from "./EditReports/ViewReports"

export default function ReportEdit() {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Reports</title>
        </MetaTags>
        <Container fluid>
          {/* <h4>edit</h4> */}
        </Container>
        <ViewReports/>
      </div>
    </React.Fragment>
  )
}
