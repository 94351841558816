import axios from "axios"
import {
  SET_NEW_OPERATOR,
  SET_SELECTED_OPERATOR,
  SET_UPLOAD_OPERATOR_SUCCESS,
  SET_EDIT_OPERATOR_SUCCESS,
  GET_NEW_OPERATOR_SUCCESS,
  SET_DELETE_OPERATOR_SUCCESS,
  SET_COUNTRIES,
  SET_NEWCOUNTRIES,
  SET_SELECTED_OPERATOR_SUCCESS
} from "./actionTypes"


const INIT_STATE = {
    operators: [],
    getSuccess:false,
    selectedOperator: [],
    selectedOpSuccess:false,
    countries:[],
    uploadsuccess:false,
    editsuccess:false,
    deletesuccess:false,
   }
  
  const operator = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_NEW_OPERATOR_SUCCESS:
        // state.getSuccess = action.payload;
        return{
          ...state,
          getSuccess : action.payload
        }
      case SET_SELECTED_OPERATOR_SUCCESS:
        // state.selectedOpSuccess = action.payload;
        return{
          ...state,
          selectedOpSuccess : action.payload
        }
      case SET_NEWCOUNTRIES:
        // state.countries = action.payload;
        return {
          ...state,
          countries : action.payload
        }
      case SET_NEW_OPERATOR:
        // state.operators = action.payload;
        return {
          ...state,
          operators : action.payload
          // reports: [...state.reports, action.payload],
        }
      case SET_SELECTED_OPERATOR:
        // state.selectedOperator = action.payload;
        return {
          ...state,
          selectedOperator : action.payload
          // reports: [...state.reports, action.payload],
        }
      case SET_UPLOAD_OPERATOR_SUCCESS:
        // state.uploadsuccess = action.payload;
        return {
          ...state,
          uploadsuccess : action.payload
          // reports: [...state.reports, action.payload],
        }
      case SET_EDIT_OPERATOR_SUCCESS:
        // state.editsuccess = action.payload;
        return {
          ...state,
          editsuccess : action.payload
          // reports: [...state.reports, action.payload],
        }
      case SET_DELETE_OPERATOR_SUCCESS:
        // state.deletesuccess = action.payload;
        return {
          ...state,
          deletesuccess : action.payload
          // reports: [...state.reports, action.payload],
        }
      default:
        return state
    }
  }
  
  export default operator