import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import dotenv from 'dotenv'
import * as urls from "../../helpers/url_helper"

dotenv.config()
const url = process.env.LOCALHOST?process.env.LOCALHOST:'https://qa_api_gtc.amerck.com'

export const usersAPI = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({ baseUrl: url, timeout: 10000 }),
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: ({page,pageSize,searchTerm}) => ({
        url: urls._GET_NEW_USERS,
        params: {
            page: page,
            pageSize: pageSize,
            searchTerm: searchTerm
        },
        method: 'GET',
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        timeout: 10000,
      }),
    }),
    getUserById: builder.mutation({
        query: (id) => ({
            url: urls._GET_USER_BY_ID,
            method: 'POST',
            body:{
                user_id: id
            },
            headers: {
            auth: JSON.parse(localStorage.getItem("authUser")),
            },
            timeout: 10000,
        }),
    }),
    getRoles: builder.query({
        query: () => ({
            url: urls._GET_ROLES,
            method: 'GET',
            headers: {
            auth: JSON.parse(localStorage.getItem("authUser")),
            },
            timeout: 10000,
        }),
    }),
    updateUser: builder.mutation({
        query: (body) => ({
            url: urls._UPDATE_USER,
            method: 'POST',
            body: body,
            headers: {
            auth: JSON.parse(localStorage.getItem("authUser")),
            },
            timeout: 10000,
        }),
    }),
    createUser: builder.mutation({
        query: (body) => ({
            url: urls._CREATE_USER,
            method: 'POST',
            body: body,
            headers: {
            auth: JSON.parse(localStorage.getItem("authUser")),
            },
            timeout: 10000,
        }),
    }),
    getOperators: builder.query({
        query: (customer_id) => ({
            url: urls._GET_OPERATORS_NEW,
            method: 'GET',
            params: customer_id,
            headers: {
            auth: JSON.parse(localStorage.getItem("authUser")),
            },
            timeout: 10000,
        }),
    }),
    getCustomers: builder.query({
        query: () => ({
            url: urls._GET_CUSTOMERS_NEW,
            method: 'GET',
            headers: {
            auth: JSON.parse(localStorage.getItem("authUser")),
            },
            timeout: 10000,
        }),
    }),
    changePassword: builder.mutation({
        query: (body) => ({
            url: urls._CHANGE_PASSWORD_NEW,
            method: 'POST',
            body: body,
            headers: {
            auth: JSON.parse(localStorage.getItem("authUser")),
            },
            timeout: 10000,
        }),
    }),
    deleteUser: builder.mutation({
        query: (body) => ({
            url: urls._DELETE_USER_NEW,
            method: 'POST',
            body: body,
            headers: {
            auth: JSON.parse(localStorage.getItem("authUser")),
            },
            timeout: 10000,
        }),
    }),
  }),
})

export const {
  useGetUsersQuery,
  useGetUserByIdMutation,
  useGetRolesQuery,
  useUpdateUserMutation,
  useCreateUserMutation,
  useGetOperatorsQuery,
  useGetCustomersQuery,
  useChangePasswordMutation,
  useDeleteUserMutation
} = usersAPI