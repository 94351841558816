import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Switch, BrowserRouter as Router, Redirect } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"


import jwt from 'jsonwebtoken'
import { LOGOUT_USER } from "store/old/auth/login/actionTypes"
import { logoutUser } from "store/actions"

const App = props => {
  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }
  const dispatch = useDispatch()
  const decodeToken = (token) => {
    try {
      const decodedToken = jwt.decode(token);
      return decodedToken;
    } catch (error) {
      console.log('Error decoding token:', error);
      return null;
    }
  }
  const token = localStorage.getItem('authUser');
  const decodedToken = decodeToken(JSON.parse(token));
  let role = decodedToken?.role;

  useEffect(() => {
    if(decodedToken){
      const currentTime = Math.floor(Date.now() / 1000);
      if(decodedToken?.username || decodedToken.exp < currentTime){
        dispatch(logoutUser())
      }
    }
  }, [decodedToken])
  


  const Layout = getLayout()
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              route={route}
              layout={NonAuthLayout} // TODO
              //layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              decodedToken={decodedToken}
              exact
            />
          ))}

          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              route={route}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
              decodedToken={decodedToken}
            />
          ))}
          {role==1 && <Redirect to="/dashboard" />}
          {role==2 && <Redirect to="/source-upload" />}
          {role==5 && <Redirect to="/source-upload" />}
          {role==3 && <Redirect to="/source-upload" />}
          {role==4 && <Redirect to="/reports/edit" />}
          {/* <Redirect to="/source-upload" /> */}
        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
