import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import dotenv from "dotenv"
import * as urls from "../../helpers/url_helper"

dotenv.config()
const url = process.env.LOCALHOST
  ? process.env.LOCALHOST
  : "https://qa_api_gtc.amerck.com"

export const analyticsAPI = createApi({
  reducerPath: "analyticsApi",
  baseQuery: fetchBaseQuery({ baseUrl: url, timeout: 40000 }),
  endpoints: builder => ({
    getResultsOverview: builder.mutation({
      query: ({ lastXtests, template, startDate, endDate }) => ({
        url: urls.GET_NPT_RESULT_OVERVIEW,
        method: "GET",
        params: { lastXtests, template, startDate, endDate },
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        timeout: 40000,
      }),
    }),
    getGatekeeperFakeDlr: builder.mutation({
      query: ({ country_id, operator_id, gatekeeper, startDate, endDate }) => ({
        url: urls.GET_GATEKEEPER_FAKE_DLR,
        method: "POST",
        params: { country_id, operator_id, startDate, endDate },
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        body:{
          gatekeeper
        },
        timeout: 40000,
      }),
    }),
    getGatekeeperBypass: builder.mutation({
      query: ({ country_id, operator_id, gatekeeper, startDate, endDate }) => ({
        url: urls.GET_GATEKEEPER_BYPASS,
        method: "POST",
        params: { country_id, operator_id, startDate, endDate },
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        body:{
          gatekeeper
        },
        timeout: 40000,
      }),
    }),
    getSenderIdCount: builder.mutation({
      query: ({ country_id, operator_id, startDate, endDate, lastXtests, shortCode}) => ({
        url: urls.GET_SENDERID_BYPASS,
        method: "GET",
        params: { country_id, operator_id, startDate, endDate, lastXtests, shortCode},
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        timeout: 40000,
      }),
    }),
    getBypassRoutes: builder.mutation({
      query: ({ country_id, operator_id, startDate, endDate, customer_id, lastXtests, template, gatekeeper, deliveryType}) => ({
        url: urls.GET_BYPASS_ROUTES,
        method: "POST",
        params: { country_id, operator_id, startDate, endDate, customer_id, lastXtests, template},
        body: {gatekeeper, deliveryType},
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        timeout: 60000,
      }),
    }),
    getCustomerResultsOverview: builder.mutation({
      query: ({ lastXtests, template, startDate, endDate, customer_id, comparison }) => ({
        url: urls.GET_CUSTOMER_NPT_RESULT_OVERVIEW,
        method: "GET",
        params: { lastXtests, template, startDate, endDate, customer_id, comparison},
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        timeout: 40000,
      }),
    }),
    getCustomerSenderIdCount: builder.mutation({
      query: ({ country_id, operator_id, startDate, endDate, lastXtests, shortCode, customer_id}) => ({
        url: urls.GET_CUSTOMER_SENDERID_BYPASS,
        method: "GET",
        params: { country_id, operator_id, startDate, endDate, lastXtests, shortCode, customer_id},
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        timeout: 40000,
      }),
    }),
    getCustomerBypassRoutes: builder.mutation({
      query: ({ country_id, operator_id, startDate, endDate, customer_id, lastXtests, template, gatekeeper, deliveryType}) => ({
        url: urls.GET_CUSTOMER_BYPASS_ROUTES,
        method: "POST",
        params: { country_id, operator_id, startDate, endDate, customer_id, lastXtests, template},
        body: {gatekeeper, deliveryType},
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        timeout: 60000,
      })
    }),
  }),
})

export const {
  useGetResultsOverviewMutation,
  useGetGatekeeperFakeDlrMutation,
  useGetGatekeeperBypassMutation,
  useGetSenderIdCountMutation,
  useGetBypassRoutesMutation,
  useGetCustomerResultsOverviewMutation,
  useGetCustomerSenderIdCountMutation,
  useGetCustomerBypassRoutesMutation,
} = analyticsAPI
