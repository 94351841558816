import React, { useState, useEffect } from "react"
import { Modal, Button, Input, Spinner } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import Select from "react-select"
import LoadingSpinner from "components/Reports/EditReports/EditTables/loader"
import {
  useUpdateUserMutation,
  useGetOperatorsQuery,
  useGetCustomersQuery,
} from "store/user/user.api"
import Swal from "sweetalert2"
import { setCustomers, setOperators } from "store/user/user.slice"
import { get, set } from "lodash"

const EditUserModal = ({
  isOpen,
  onClose,
  userData,
  isLoading,
  roles,
  refetch,
  clearUser,
}) => {
  const dispatch = useDispatch()
  const [
    updateUser,
    {
      data: updateUser_data,
      isFetching: updateUser_fetching,
      isLoading: updateUser_loading,
    },
  ] = useUpdateUserMutation()
  const [user_id, setUser_id] = useState("")
  const [name, setName] = useState("")
  const [role, setRole] = useState("")
  const [email, setEmail] = useState("")
  const [assignCustomer, setAssignCustomer] = useState("")
  const [assignOperator, setAssignOperator] = useState([])
  const [roleOptions, setRoleOptions] = useState([])
  const [customerOptions, setCustomerOptions] = useState([])
  const [operatorOptions, setOperatorOptions] = useState([])
  const { data: operators_temp, isLoading: operatorsLoading } =
    useGetOperatorsQuery(
      { customer_id: assignCustomer },
      { refetchOnMountOrArgChange: true, refetchOnFocus: false }
    )
  const { data: customers_temp, isLoading: customersLoading } =
    useGetCustomersQuery({}, { refetchOnFocus: false })
  const { operators, customers } = useSelector(state => state.usersNew)

  useEffect(() => {
    if (operators_temp) {
      let temp = get(operators_temp, "data", [])
      temp = temp.map(obj => {
        return {
          id: obj.id,
          label: obj.country + " " + obj.operator_name,
          value: obj.country + " " + obj.operator_name,
        }
      })
      temp.sort((a, b) => {
        // console.log(a,b);
        const countryA = a.label.split(" ")[0]
        const countryB = b.label.split(" ")[0]

        // Compare country names
        if (countryA < countryB) {
          return -1
        } else if (countryA > countryB) {
          return 1
        } else {
          return 0
        }
      })
      dispatch(setOperators(temp))
    }
  }, [operators_temp])

  useEffect(() => {
    if (customers_temp) {
      let temp = get(customers_temp, "data", [])
      dispatch(
        setCustomers(
          temp.map(customer => ({
            id: customer.id,
            label: customer.name,
            value: customer.name,
          }))
        )
      )
    }
  }, [customers_temp])
  
  // Update local state when userData changes

  useEffect(() => {
    if (userData) {
      setName(userData.name || "")
      setRole(userData.role || "")
      setEmail(userData.email || "")
      setAssignOperator([])
      setAssignCustomer("")
      let customerid = get(userData, "customers_tests", []).map(obj => obj.id)
      let operatorid = get(userData, "operator_tests", []).map(obj => obj.id)
      if (customerid.length > 0) {
        setAssignCustomer(customerid[0])
      }
      if (operatorid.length > 0) {
        setAssignOperator(operatorid)
      }
    }
  }, [userData])

  const clearUserFields = () => {
    setName("")
    setRole("")
    setEmail("")
    setAssignCustomer("")
    setAssignOperator([])
  }
  const handleSaveChanges = () => {
    if(assignOperator.length>0 && assignCustomer==""){
      Swal.fire({
        title: "Error!",
        text: "Customer is Mandatory for Operator Selection",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#811113",
      })
    }
    else{
      updateUser({
        user: {
          id: userData.id,
          name: name,
          email: email,
          role: role,
          customer: assignCustomer != "" ? assignCustomer : "none",
          operators: assignOperator,
        },
      })
        .unwrap()
        .then(() => {
          Swal.fire({
            title: "Success",
            text: "User Updated Successfully",
            icon: "success",
            confirmButtonText: "Ok",
          })
          onClose()
          clearUser()
          refetch()
          clearUserFields()
        })
        .catch(error => {
          Swal.fire({
            title: "Error",
            text: error.data?.message,
            icon: "error",
            confirmButtonText: "Ok",
          })
        })
    }
  }
  const toggleHandler = () => {
    onClose()
    clearUser()
    clearUserFields()
  }
  // console.log(role)
  return (
    <Modal isOpen={isOpen} toggle={toggleHandler} size="lg" centered>
      <div
        className="modal-header d-flex"
        style={{
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h5
            className="modal-title ps-3"
            style={{ fontSize: "16px", fontWeight: "500" }}
          >
            EDIT USER
          </h5>
        </div>
        <div>
          <button type="button" className="close" onClick={onClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div className="modal-body d-flex row ps-4">
        {isLoading ? (
          <div
            className=""
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              verticalAlign: "middle",
            }}
          >
            <LoadingSpinner />
          </div>
        ) : (
          <div className="modal-body d-flex row ps-4">
            <div className="mb-3 col-md-6 ">
              <label htmlFor="name">Name</label>
              <Input
                type="text"
                id="name"
                placeholder="Enter Name"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div className="mb-3 col-md-6">
              <label htmlFor="email">Email</label>
              <Input
                type="email"
                id="email"
                placeholder="Enter Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-3 col-md-6">
              <label htmlFor="role">Role</label>
              <Select
                id="role"
                placeholder="Select Role"
                value={roles.filter(obj => obj.id === role)}
                options={roles}
                onChange={selectedOption => {
                  setRole(selectedOption.id)
                  if (selectedOption.id != 1 || selectedOption.id != 4) {
                    setAssignCustomer("")
                    setAssignOperator([])
                  }
                }}
              />
            </div>
          </div>
        )}
        {role == 1 && (
          <div className="ps-4 row">
            <div className="mb-3 col-md-6 ">
              <label htmlFor="assignCustomer">Assign Customer</label>
              <Select
                isClearable
                isSearchable
                placeholder="Select Customer"
                value={customers.filter(obj => obj.id === assignCustomer)}
                options={customers}
                onChange={selectedOption => {
                  if (selectedOption) {
                    if(selectedOption.id == assignCustomer){
                      return;
                    }
                    else{
                      setAssignCustomer(selectedOption.id)
                      setAssignOperator([])
                    }
                  } else {
                    setAssignCustomer("")
                    setAssignOperator([])
                  }
                }}
              />
            </div>
            <div className="mb-3 col-md-12">
              <label htmlFor="assignOperator">Assign Operator</label>
              <Select
                isMulti
                isClearable
                isSearchable
                value={operators.filter(obj => assignOperator.includes(obj.id))}
                options={operators}
                onChange={selectedOption =>
                  setAssignOperator(selectedOption.map(option => option.id))
                }
                placeholder="Select Operators"
              />
            </div>
          </div>
        )}
      </div>
      {/* console.log({role}); */}
      <div className="modal-footer">
        <Button
          style={{
            backgroundColor: "#811113",
            border: "none",
            height: "42px",
            width: "100px",
            marginRight: "20px",
          }}
          onClick={handleSaveChanges}
          disabled={updateUser_loading}
        >
          {updateUser_loading ? (
            <Spinner size="sm" color="light" /> // Show spinner if loading
          ) : (
            "Save"
          )}
        </Button>{" "}
        <Button
          style={{
            backgroundColor: "#E8E8E9",
            border: "none",
            height: "42px",
            width: "100px",
          }}
          onClick={onClose}
        >
          <span style={{ color: "#2D3648" }}>Cancel</span>
        </Button>
      </div>
    </Modal>
  )
}

export default EditUserModal
