import React from "react"

import ReactApexChart from "react-apexcharts"

import sampledata from "components/Reports/EditReports/EditTables/ViewKpi"
import KpiTable from "components/Reports/EditReports/EditTables/ViewKpi"

const chartapex = props => {
  const series = [
    { name: "Fake DLR Ratio(%)", data: [38.84, 37.91, 39.33, 33.87] },
    { name: "Bypass Ratio(%)", data: [3.29, 25.86, 6.36, 8.87] },
    { name: "Honest positive DLR Ratio", data: [61.16, 62.09, 60.67, 66.13] },
  ]

  let maxYValue = 0

  if (props.chartData) {
    for (var Obj of props.chartData) {
      if (Obj.name == "Fake DLR Ratio(%)") {
        for (var data1 of Obj.data) {
          if (data1 > maxYValue) {
            maxYValue = data1
          }
        }
      }
      if (Obj.name == "Bypass ratio(%)") {
        for (var data2 of Obj.data) {
          if (data2 > maxYValue) {
            maxYValue = data2
          }
        }
      }
      if (Obj.name == "Honest positive DLR ratio(%)") {
        for (var data3 of Obj.data) {
          if (data3 > maxYValue) {
            maxYValue = data3
          }
        }
      }
      // console.log("Max value out of all Delivery KPI data : " + maxYValue);
    }
  }

  // var dataCheck = kpiData.length==0?series:kpiData.delivery_chart_data;  /* deliverd, not deliverd, kpi prop have tale length ? */
  // var chartData = dataCheck.length==0?series:dataCheck; /* kpireports have tale length ? */

  const options = {
    chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
    colors: ["#556ee6", "#34c38f", "#ff0000"],
    dataLabels: { enabled: 0 },
    stroke: { width: [3, 3, 3], curve: "straight" },
    title: { text: "Delivery KPIs", align: "left" },
    grid: {
      row: { colors: ["transparent", "transparent"], opacity: 0.2 },
      borderColor: "#f1f1f1",
    },
    // markers: { style: "inverted", size: 4 },
    xaxis: {
      categories: props.dates,
      title: { text: "Month" },
    },
    yaxis: {
      title: { text: "%" },
      labels: {
        formatter: function (value) {
          return value ? value.toFixed(2) : 0 + "%"
        },
      },
      min: 0.0,
      max: maxYValue + maxYValue / 10,
      // max: Math.max(series)
    },
    legend: {
      position: "bottom",
      horizontalAlign: "right",
      offsetY: 5,
      offsetX: -15,
    },
    responsive: [
      {
        breakpoint: 600,
        options: { chart: { toolbar: { show: !1 } }, legend: { show: !1 } },
      },
    ],
  }

  return (
    <ReactApexChart
      options={options}
      series={props.chartData ? props.chartData : series}
      type="line"
      height="380"
      // className="apexcharts-canvas apexchartscq310u9c apexcharts-theme-light"
    />
  )
}

export default chartapex
