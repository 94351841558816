import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import dotenv from "dotenv"
import * as urls from "../../helpers/url_helper"

dotenv.config()
const url = process.env.LOCALHOST
  ? process.env.LOCALHOST
  : "https://qa_api_gtc.amerck.com"

export const countryAPI = createApi({
  reducerPath: "countryApi",
  baseQuery: fetchBaseQuery({ baseUrl: url, timeout: 10000 }),
  endpoints: builder => ({
    getCountries: builder.query({
      query: () => ({
        url: urls._GET_NEW_COUNTRY,
        method: "GET",
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        timeout: 4000,
      }),
    }),
    getOperatorCountries: builder.query({
      query: () => ({
        url: urls._GET_OP_COUNTRIES,
        method: "GET",
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        timeout: 4000,
      }),
    }),
    getCustomerOperatorCountries: builder.query({
      query: () => ({
        url: urls._GET_CUSTOMER_OP_COUNTRIES,
        method: "GET",
        headers: {
          auth: JSON.parse(localStorage.getItem("authUser")),
        },
        timeout: 4000,
      }),
    })
  }),
})

export const {useGetCountriesQuery, useGetOperatorCountriesQuery, useGetCustomerOperatorCountriesQuery} = countryAPI
