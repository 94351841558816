import React from "react"

import ReactApexChart from "react-apexcharts"

const chartapex = ({series,dates}) => {

  let maxYValue = 0.0

  if (series) {
    for (var Obj of series) {
      if (Obj.name=="Official MTR") {
        for( var data1 of Obj.data){
          if(data1 > maxYValue && data1 != "NaN") {
            maxYValue = parseFloat(data1).toFixed(4)
            
          }
        }
      }
      if (Obj.name=="Avg. price of official routes") {
        for( var data2 of Obj.data){
          if(data2 > maxYValue && data2 != "NaN") {
            maxYValue = parseFloat(data2).toFixed(4)
            
          }
        }
      }
      if (Obj.name=="Avg. price of delivering routes") {
        for( var data3 of Obj.data){
          if(data3 > maxYValue && data3 != "NaN") {
            // maxYValue = data3;
            maxYValue = parseFloat(data3).toFixed(4)
            
          }
        }
      }
      // console.log("Max value out of all Pricing data :" + maxYValue);
    }
  }
  //const kpiData = props.chartData?props.chartData:[];
  //var dataCheck = kpiData.length==0?series:kpiData.pricing_chart_data;  /* deliverd, not deliverd, kpi prop have tale length ? */
  //var chartData = dataCheck?.length==0?series:dataCheck; /* kpireports have tale length ? */

  const options = {
    chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
    colors: ["#556ee6", "#34c38f", "#ff0000"],
    dataLabels: { enabled: 0 },
    stroke: { width: [3, 3, 3], curve: "straight" },
    title: { text: "Pricing KPIs", align: "left" },
    grid: {
      row: { colors: ["transparent", "transparent"], opacity: 0.2 },
      borderColor: "#f1f1f1",
    },
    // markers: { style: "inverted", size: 6 },
    xaxis: {
      categories: dates,
      title: { text: "Month" },
    },
    yaxis: {
      title: { text: "Price" },
      labels: {
        formatter: function (value) {
          return value ? value.toFixed(4) : 0
        },
      },
      // categories: [0.0000, 0.0100, 0.0200, 0.0300, 0.0400, 0.0500 , 0.0600],
      min: 0.0,
      max: maxYValue * 1 + (maxYValue * 1) / 10,
    },

    legend: {
      position: "bottom",
      horizontalAlign: "right",
      offsetY: 5,
      offsetX: -15,
    },
    responsive: [
      {
        breakpoint: 600,
        options: { chart: { toolbar: { show: !1 } }, legend: { show: !1 } },
      },
    ],
  }

  return (
    <ReactApexChart
      options={options}
      //   series={props.chartData?props.chartData:series}
      series={series}
      type="line"
      height="380"
      // className="apexcharts-canvas apexchartscq310u9c apexcharts-theme-light"
    />
  )
}

export default chartapex
