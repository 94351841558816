import { applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import { composeWithDevTools } from "redux-devtools-extension"
import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import { sourceFilesAPI } from "./sourceUpload/sourceUpload.api"
import { customerAPI } from "./customer/customer.api"
import { operatorAPI } from "./operator/operator.api"
import { partnerAPI } from "./partner/partner.api"
import { templateAPI } from "./template/template.api"
import { usersAPI } from "./user/user.api"
import { customerPortalApi } from "./customerPortal/customerPortal.api"
import { reportsApi } from "./reports/reports.api"
import { inspectorAPI } from "./inspector/inspector.api";
import { analyticsAPI } from "./analytics/analytics.api"
import { countryAPI } from "./country/country.api"
import { otherChannelAPI } from "./otherChannels/otherChannels.api"



import rootReducer from "./reducers"
import rootSaga from "./sagas"

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(
      sagaMiddleware,
      sourceFilesAPI.middleware,
      customerAPI.middleware,
      operatorAPI.middleware,
      partnerAPI.middleware,
      templateAPI.middleware,
      usersAPI.middleware,
      customerPortalApi.middleware,
      reportsApi.middleware,
      inspectorAPI.middleware,
      analyticsAPI.middleware,
      countryAPI.middleware,
      otherChannelAPI.middleware
    )
  },
})
sagaMiddleware.run(rootSaga)
setupListeners(store.dispatch)

export default store
