import * as aT from "./actionTypes"

export const uploadReport = data => ({
    type: aT.UPLOAD_REPORT,
    payload: data,
})
export const uploadReportSuccess = data => ({
    type: aT.UPLOAD_REPORT_SUCCESS,
    payload: data,
})
export const setReportData = data => ({
    type: aT.SET_REPORT_DATA,
    payload: data,
})
export const getRecentlyUploadedReports = data => ({
    type: aT.GET_RECENTLY_UPLOADED_REPORTS
})
export const setRecentlyUploadedReports = data => ({
    type: aT.SET_RECENTLY_UPLOADED_REPORTS,
    payload:data    
})
export const getCustomers = data => ({
    type: aT.GET_CUSTOMERS
})
export const setCustomers = data => ({
    type: aT.SET_CUSTOMERS,
    payload:data
})
export const getPartners = data => ({
    type: aT.GET_PARTNERS
})
export const setPartners = data => ({
    type: aT.SET_PARTNERS,
    payload:data
})
export const getOperators = data => ({
    type: aT.GET_OPERATORS
})
export const setOperators = data => ({
    type: aT.SET_OPERATORS,
    payload:data
})
export const getCountries = data => ({
    type: aT.GET_COUNTRIES
})
export const setCountries = data => ({
    type: aT.SET_COUNTRIES,
    payload:data
})
export const getTemplates = data => ({
    type: aT.GET_TEMPLATES
})
export const setTemplates = data => ({
    type: aT.SET_TEMPLATES,
    payload:data
})
export const deleteReport = data => ({
    type: aT.DELETE_REPORT,
    payload:data
})
export const setDeleteReportSuccess = data => ({
    type: aT.DELETE_REPORT_SUCCESS,
    payload:data
})
export const updateReports = data => ({
    type: aT.UPDATE_HISTORY_REPORTS,
    payload:data
})
export const updateReportsSuccess = data => ({
    type: aT.UPDATE_HISTORY_REPORTS_SUCCESS,
    payload:data
})
export const removeItemFromArray = data => ({
    type: aT.REMOVE_PAIR,
    pairId: data,
  })
