import { call, put, takeEvery, all, fork, takeLatest } from "redux-saga/effects";
import * as actions from "./actions";
import * as types from "./actionTypes";
import * as request from "./request";
import {logoutUser} from "../auth/login/actions"


export function * getRecentReports({payload}) {
    try {
        const response = yield call(request.fetchRecentlyUploadedReports,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        yield put(actions.setRecentlyUploadedReports(response));
    } catch (error) {
        console.log(error)
    }
}
export function * deleteReport({payload}) {
    try {
        const response = yield call(request.deleteReports,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        if(response.message=="Report Deleted Successfully"){
            yield put(actions.setDeleteReportSuccess(true));
        }
    } catch (error) {
        console.log(error)
    }
}
export function * uploadReport({payload}) {
    try {
        const response = yield call(request.uploadReports,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        if(response.message=="Data Uploaded"){
            yield put(actions.uploadReportSuccess(true));
        }
    } catch (error) {
        console.log(error)
    }
}
export function * updateReports({payload}) {
    try {
        const response = yield call(request.updateReportData,payload)
        if(response.status==401){
            yield put(logoutUser())
        }
        if(response.message=="Report Updated Successfully"){
            yield put(actions.updateReportsSuccess(true));
        }
    } catch (error) {
        console.log(error)
    }
}
export function * getCustomers() {
    try {
        const response = yield call(request.fetchCustomers)
        if(response.status==401){
            yield put(logoutUser())
        }
        yield put(actions.setCustomers(response.data));
    } catch (error) {
        console.log(error)
    }
}
export function * getPartners() {
    try {
        const response = yield call(request.fetchPartners)
        if(response.status==401){
            yield put(logoutUser())
        }
        yield put(actions.setPartners(response.partners));
    } catch (error) {
        console.log(error)
    }
}
export function * getCountries() {
    try {
        const response = yield call(request.fetchCountries)
        if(response.status==401){
            yield put(logoutUser())
        }
        yield put(actions.setCountries(response.message));
    } catch (error) {
        console.log(error)
    }
}

export function * getOperators() {
    try {
        const response = yield call(request.fetchOperators)
        if(response.status==401){
            yield put(logoutUser())
        }
        yield put(actions.setOperators(response.message));
    } catch (error) {
        console.log(error)
    }
}

export function * getTemplates() {
    try {
        const response = yield call(request.fetchTemplates)
        if(response.status==401){
            yield put(logoutUser())
        }
        yield put(actions.setTemplates(response.templates));
    } catch (error) {
        console.log(error)
    }
}
export function * historySaga(){
    yield takeEvery(types.UPLOAD_REPORT, uploadReport);
    yield takeEvery(types.GET_RECENTLY_UPLOADED_REPORTS, getRecentReports);
    yield takeEvery(types.DELETE_REPORT, deleteReport);
    yield takeEvery(types.UPDATE_HISTORY_REPORTS, updateReports);
    yield takeEvery(types.GET_CUSTOMERS, getCustomers);
    yield takeEvery(types.GET_PARTNERS, getPartners);
    yield takeEvery(types.GET_OPERATORS, getOperators);
    yield takeEvery(types.GET_TEMPLATES, getTemplates);
    yield takeEvery(types.GET_COUNTRIES, getCountries);
}

export default historySaga;